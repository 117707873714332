import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Add, Close, Delete } from "@mui/icons-material";
import "./ManageFolder.css";
import { Tooltip } from "@mui/material";
import { FadeLoader } from "react-spinners";
import ImageModal from "../../ImageModal";
import UploadButton from "../../../Utils/UploadButton";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const ManageFolder = (props) => {
  const { jobType, deletePanelImage, deleteLoading } = props;
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  //Storing Data from API
  const [photosData, setPhotosData] = useState([]);
  const [selfieData, setSelfieData] = useState([]);
  const [panelsData, setPanelsData] = useState([]);
  const [invertersData, setInvertersData] = useState([]);
  const [batteriesData, setBatteriesData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [panelScanCount, setPanelScanCount] = useState(0);
  const [inverterScanCount, setInvterterScanCount] = useState(0);
  const [batteryScanCount, setBatteryScanCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  //Get On Site Details Starts
  const fetchOnSiteData = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`${baseURL}/onSite`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = resp?.data?.data;
      const flattenedData = data.flat();
      const result = flattenedData.filter((item) => item.jobId === jobId);
      const photos = result[0]?.photos;
      const selfies = result[0]?.photos[0]?.newExtraSelfie;
      const panels = result[0]?.panels;
      const inverters = result[0]?.inverters;
      const batteries = result[0]?.batteries;
      setPhotosData(photos[0]);
      setSelfieData(selfies);
      setPanelsData(panels);
      setInvertersData(inverters);
      setBatteriesData(batteries);
      setPanelScanCount(result[0]?.panelPhotosCount);
      setInvterterScanCount(result[0]?.inverterPhotosCount);
      setBatteryScanCount(result[0]?.batteryPhotosCount);

      if (jobType !== "SWH") {
        setPanelsData(panels);
      } else {
        setPanelsData(result[0]?.tanks);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOnSiteData();
  }, []);

  //Get On Site Details Ends

  // Function to download files
  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileUpload = async (file, key) => {
    setUploadLoading(true);
    const imageFile = file;
    const formData = new FormData();
    formData.append(key, imageFile);
    formData.append("jobId", jobId);
    const res = await axios
      .post(`${baseURL}/onsite/photos/upload`, formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        alert("Image Uploaded Successfully");
        fetchOnSiteData();
        setUploadLoading(false);
        setImageData((prevImageData) => ({
          ...prevImageData,
          [key]: response.data[0]?.photos[0].url,
          [key]: response.data[0]?.photos[0].type,
        }));
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        setUploadLoading(false);
      });
  };

  const handleDeleteImages = async (jobId, type, photoId) => {
    // setUploadLoading(true)
    try {
      const response = await axios.delete(`${baseURL}/onsite/photos/delete`, {
        params: {
          jobId: jobId,
          type: type,
          photoId: photoId,
        },
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        alert("Image Deleted Successfully");
        fetchOnSiteData();
      } else {
        console.log("Error: " + (response.data || response.statusText));
      }
      // setUploadLoading(false);
    } catch (error) {
      console.error("Error deleting image:", error);
      console.log("Error Status:", error.response?.status);
      if (error?.response?.status === 400) {
        alert("Image Delete Failed" || error?.response?.data);
      }
      // setUploadLoading(false);
    }
  };

  const handleFileInputChange = (formDataKey, index) => (e) => {
    const file = e.target.files[0];
    if (file) {
      e.target.value = "";
      handleFileUpload(file, formDataKey);
    }
  };

  const ImageTypeLabel = {
    "Inverters LED Screen": "inverterLEDScreen",
    "Meter and Switchboard": "meterAndSwitchboard",
    "Inverter Label*": "inverterLabel",
    "Inverter Front": "inverterFront",
    "Inverter Region Setting": "inverterRegionSetting",
    "Inverter Wifi Stick or SSID": "inverterWifiStickOrSSID",
    "Digital Inverter SN": "digitalInverterSN",
    "MPPT Tracker": "MPPTTracker",
    "Inverter Installed": "inverterinstalled",
  };
  const ImageTypeLabelPanel = {
    "Panel Layout Diagram": "panelLayoutDiagram",
    "Panel Serial Number Photos": "panelSerialNumberPhotos",
    "Solar PV DP/SW/AC label": "solarPVOrDPOrSWOrACLabel",
    "Module Compliance Plate*": "moduleCompliancePlate",
    "Panel Installed": "panelinstalled",
  };
  const ImageTypeLabelBattery = {
    "Battery Label": "batteryLabel",
    "Battery Installed": "batteryinstalled",
  };
  const ImageTypeLabelOtherPhotos = {
    "Rooftop DC isolator/Disconnection points label":
      "RooftopDCIsolatorOrDisconnectionPointsLabels",
    "Assignment Form (if paper)": "assignmentForm",
    "SWMS/JSA": "SWMSOrJSA",
    "Safety Guard Railing": "safetyGuardRailing",
    "Social Media Photos": "socialMediaPhotos",
    "Disconnection Points warning label": "disconnectionPointsWarningLabel",
    ECD: "ECD",
    "Neutral Link/Meter Isolation switch": "neutralLinkOrMeterIsolationSwitch",
    "Installation and Commissioning sheet": "installationAndCommissioningSheet",
    "Front Property View*": "frontPropertyView",
    "Safety Sign Near Roof Cavity Entrance": "safetySignNearRoofCavityEntrance",
    "Electrical Compliance Certificate": "complianceCertificate",
  };

  const SWHTypeLabel = {
    "Tank Compliance Photo*": "tankCompliancePlatePhoto",
    "Tank*": "tank",
    "Roof Before Install": "roofBeforeInstall",
    "Roof After Install": "roofAfterInstall",
    "Panels On Roof": "panelsOnRoof",
    "Inside The Roof": "insideTheRoof",
    "Tubes On Roof": "tubesOnRoof",
    "Inlet Outlet Pipes": "inletOutletPipes",
    "Heat Pump": "heatPump",
    "Other Photos": "otherPhotos",
  };
  const getLabelNames = (formDataKey, fileMapping) => {
    const invertedMapping = Object.entries(fileMapping).reduce(
      (acc, [key, value]) => {
        acc[value] = key;
        return acc;
      },
      {}
    );
    return invertedMapping[formDataKey] || "Unknown";
  };

  const triggerFileInput = (formDataKey) => {
    const fileInput = document.getElementById(`fileInput-${formDataKey}`);
    fileInput && fileInput.click();
  };

  //Open Image in POPUP Starts
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [modalLabel, setModalLabel] = useState("");
  const [modalDistance, setModalDistance] = useState(null);

  const openModal = (image, label, distance) => {
    setModalImage(image);
    setModalLabel(label);
    setModalDistance(distance);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalImage(null);
    setModalLabel("");
    setModalDistance(null);
  };
  //Open Image in POPUP Ends

  //Serial Photos Upload Starts

  const [editedSerialNumbers, setEditedSerialNumbers] = useState();

  const handleScanUpload = async (file, key) => {
    const uploadedFileData = setImageData((prevImageData) => ({
      ...prevImageData,
      [key]: uploadedFileData,
    }));
  };

  const handleInputPanelSerialNumberChange = (event, index) => {
    const newEditedSerialNumbers = [...editedSerialNumbers];
    newEditedSerialNumbers[index] = {
      ...newEditedSerialNumbers[index],
      serialNumber: event.target.value,
    };
    setEditedSerialNumbers(newEditedSerialNumbers);
  };

  const handleDeletePanelSerial=(panelId,type)=>{
    deletePanelImage(panelId, type).then((response)=>{
      fetchOnSiteData()
    })
  }
  return (
    <div>
      <div>
        <div className="panel-photobg">
          <div className="ImageUploadSectionsPVJobs">
            <div className="panel-photo">
              <span className="panel-ser">
                {jobType !== "SWH" ? `Extra Selfies` : null}
              </span>
            </div>
            <div className="PhotosSection"></div>
          </div>
          <div>
            {selfieData && selfieData.length > 0 ? (
              loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <FadeLoader height={20} />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                  }}
                >
                  {selfieData.map((selfie) =>
                    selfie ? (
                      <div className="panel-photomain" key={selfie._id}>
                        <img
                          className="panel-img"
                          src={selfie?.url}
                          alt="Extra Selfies"
                          onClick={() =>
                            openModal(
                              selfie?.url,
                              "Extra Selfies",
                              selfie?.distance
                            )
                          }
                        />
                        <button className="main-uploadbtn" type="button">
                          <img
                            src={`${image}/Assets/ManageFolder/download-btn.svg`}
                            alt="Download"
                            onClick={() => handleDownload(selfie?.url)}
                          />
                        </button>
                        <div className="panel-size">
                          Date:{" "}
                          {moment(selfie?.dateAndTime).format("DD/MM/YYYY")}
                        </div>

                        {selfie?.distance && (
                          <div className="panel-size">
                            Distance:{" "}
                            {selfie.distance > 1
                              ? `${selfie.distance} km`
                              : `${selfie.distance * 1000} m`}
                          </div>
                        )}
                      </div>
                    ) : null
                  )}
                </div>
              )
            ) : (
              <div className="panel-ser">
                <span>
                  {jobType === "SWH" ? null : "No Extra Selfies Available"}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="panel-photobg">
          <div className="ImageUploadSectionsPVJobs">
            <div className="panel-photo">
              <span className="panel-ser">
                {jobType !== "SWH"
                  ? `Panel Serials (${panelScanCount})`
                  : "Serial Scan"}
              </span>
            </div>
            <div className="PhotosSection"></div>
          </div>

          <div style={{ padding: "0 23px" }}>
            {jobType !== "SWH" ? (
              <UploadButton
                label="Upload Panel Scan Images /Drag and drop files here, or click to select files"
                fileTypes={["image/png", "image/jpeg", "application/pdf"]}
                formDataKey="Panel Serials"
                handleFileUpload={handleScanUpload}
                photos={panelsData}
                onSiteData={fetchOnSiteData}
                loading={loading}
                // serialScansImages={serialScanImages}
              />
            ) : null}
            {deleteLoading || loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <FadeLoader height={20} />
              </div>
            ) : panelsData && panelsData.length > 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                    alignItems: "center",
                    gap: "52px",
                    padding:"20px 0"
                  }}
                >
                  {panelsData.map((panel) =>
                    panel?.onsite ? (
                      <div
                        className="panel-photomain"
                        key={panel._id}
                        style={{
                          padding: "20px 15px",
                          border: "1px solid #000",
                          borderRadius: "15px",
                          height: "420px",
                          width: "300px",
                        }}
                      >
                        <div style={{ height: "90%" }}>
                          <img
                            // className="panel-img"
                            src={
                              jobType !== "SWH"
                                ? panel?.panelPhoto
                                : panel?.tankPhoto
                            }
                            alt={` ${
                              jobType !== "SWH"
                                ? "Panel Serial Number : "
                                : " Tank Serial Number : "
                            }: ${panel?.serialNumber}`}
                            onClick={() =>
                              openModal(
                                jobType !== "SWH"
                                  ? panel?.panelPhoto
                                  : panel?.tankPhoto,
                                jobType !== "SWH"
                                  ? ` ${
                                      panel?.serialNumber
                                        ? `Panel Serial Number: ${panel?.serialNumber}`
                                        : ""
                                    }`
                                  : `Tank Serial Number: ${panel?.serialNumber}`,
                                panel?.distance
                              )
                            }
                            style={{ height: "100%", width: "100%" }}
                          />
                        </div>
                        <button
                          className="main-uploadbtn"
                          type="button"
                          style={{
                            display: "flex",
                            gap: "10px",
                            bottom: jobType !== "SWH" ? 5 : 30,
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          {jobType !== "SWH" ? (
                            <Tooltip title="Delete Image">
                              <Delete
                                style={{
                                  fill: "#1543b0",
                                  fontSize: "22px",
                                }}
                                onClick={() =>
                                  handleDeletePanelSerial(panel?._id, "panel")
                                }
                              />
                            </Tooltip>
                          ) : null}
                          {/* <Tooltip title="Download Image">
                              <img
                                src={`${image}/Assets/ManageFolder/download-btn.svg`}
                                alt="Download"
                                onClick={() => handleDownload(panel?.panelPhoto)}
                                style={{ height: "20px" }}
                              />
                            </Tooltip> */}
                        </button>
                        <div
                          className="panel-size"
                          style={{ textAlign: "left" }}
                        >
                          {panel?.serialNumber}
                        </div>
                        {panel?.distance && (
                          <div
                            className="panel-size"
                            style={{ textAlign: "left" }}
                          >
                            Distance:{" "}
                            {panel.distance > 1
                              ? `${panel.distance} km`
                              : `${panel.distance * 1000} m`}
                          </div>
                        )}
                      </div>
                    ) : null
                  )}
                </div>
              </>
            ) : (
              <div className="panel-ser">
                <span>
                  {jobType === "SWH"
                    ? "No Tank scan avaialble"
                    : "No Panel Photos Available"}
                </span>
              </div>
            )}
          </div>
        </div>
        {jobType !== "SWH" ? (
          <>
            <div className="panel-photobg">
              <div className="ImageUploadSectionsPVJobs">
                <div className="panel-photo">
                  <span className="panel-ser">{`Inverter Serials (${inverterScanCount})`}</span>
                </div>
              </div>

              <div style={{ padding: "0 23px" }}>
                <UploadButton
                  label="Upload Inverter Scan Images /Drag and drop files here, or click to select files"
                  fileTypes={["image/png", "image/jpeg", "application/pdf"]}
                  formDataKey="Inverter Serials"
                  handleFileUpload={handleScanUpload}
                  photos={invertersData}
                  onSiteData={fetchOnSiteData}
                  // serialScansImages={serialScanImages}
                />
                {deleteLoading || loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <FadeLoader height={20} />
                  </div>
                ) : invertersData && invertersData.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: "20px",
                        gap: "12px",
                      }}
                    >
                      {invertersData.map((inverter) => (
                        <div
                          className="panel-photomain"
                          key={inverter?._id}
                          style={{
                            padding: "30px 15px",
                            border: "1px solid #000",
                            borderRadius: "15px",
                            height: "420px",
                            width: "300px",
                          }}
                        >
                          <div style={{ height: "90%" }}>
                            <img
                              // className="panel-img"
                              src={inverter?.inverterPhoto}
                              alt={`Inverter Serial Number: ${inverter?.serialNumber}`}
                              onClick={() =>
                                openModal(
                                  jobType !== "SWH"
                                    ? inverter?.inverterPhoto
                                    : null,
                                  jobType !== "SWH"
                                    ? ` ${
                                        inverter?.serialNumber
                                          ? `Inverter Serial Number: ${inverter?.serialNumber}`
                                          : ""
                                      }`
                                    : null,
                                  inverter?.distance
                                )
                              }
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>

                          <button
                            className="main-uploadbtn"
                            type="button"
                            style={{
                              display: "flex",
                              gap: "10px",
                              bottom: 10,
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <Tooltip title="Delete Image">
                              <Delete
                                style={{
                                  fill: "#1543b0",
                                  fontSize: "22px",
                                }}
                                onClick={() =>
                                  handleDeletePanelSerial(inverter?._id, "inverter")
                                }
                              />
                            </Tooltip>
                            {/* <Tooltip title="Download Image">
                              <img
                                src={`${image}/Assets/ManageFolder/download-btn.svg`}
                                alt="Download"
                                onClick={() => handleDownload(inverter?.inverterPhoto)}
                                style={{ height: "23px" }}
                              />
                            </Tooltip> */}
                          </button>

                          <p
                            className="panel-size"
                            style={{ textAlign: "left" }}
                          >
                            {inverter?.serialNumber}
                          </p>
                          {inverter?.distance && (
                            <p
                              className="panel-size"
                              style={{ textAlign: "left" }}
                            >
                              Distance:{" "}
                              {inverter.distance > 1
                                ? `${inverter.distance} km`
                                : `${inverter.distance * 1000} m`}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="panel-ser">
                    <span>No Inverter Photos Available</span>
                  </div>
                )}
              </div>
            </div>
            <div className="panel-photobg">
              <div className="ImageUploadSectionsPVJobs">
                <div className="panel-photo">
                  <span className="panel-ser">{`Battery Serials (${batteryScanCount})`}</span>
                </div>
              </div>

              <div style={{ padding: "0 23px" }}>
                <UploadButton
                  label="Upload Battery Scan Images /Drag and drop files here, or click to select files"
                  fileTypes={["image/png", "image/jpeg", "application/pdf"]}
                  formDataKey="Battery Serials"
                  handleFileUpload={handleScanUpload}
                  photos={batteriesData}
                  onSiteData={fetchOnSiteData}
                  // serialScansImages={serialScanImages}
                />
                {batteriesData && batteriesData.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: "20px",
                        gap: 12,
                      }}
                    >
                      {batteriesData.map((battery) => (
                        <div
                          className="panel-photomain"
                          key={battery?._id}
                          style={{
                            padding: "20px 15px",
                            border: "1px solid #000",
                            borderRadius: "15px",
                            height: "420px",
                            width: "300px",
                          }}
                        >
                          <div style={{ height: "90%" }}>
                            <img
                              // className="panel-img"
                              src={battery?.batteryPhoto}
                              alt={`Battery Serial Number: ${battery?.serialNumber}`}
                              onClick={() =>
                                openModal(
                                  jobType !== "SWH"
                                    ? battery?.batteryPhoto
                                    : null,
                                  jobType !== "SWH"
                                    ? ` ${
                                        battery?.serialNumber
                                          ? `Battery Serial Number :  ${battery?.serialNumber}`
                                          : ""
                                      }`
                                    : null,
                                  battery?.distance
                                )
                              }
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                          <button
                            className="main-uploadbtn"
                            type="button"
                            style={{
                              display: "flex",
                              gap: "10px",
                              bottom: 5,
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <Tooltip title="Delete Image">
                              <Delete
                                style={{
                                  fill: "#1543b0",
                                  fontSize: "22px",
                                }}
                                onClick={() =>
                                  handleDeletePanelSerial(battery?._id, "battery")
                                }
                              />
                            </Tooltip>
                            {/* <Tooltip title="Download Image">
                              <img
                                src={`${image}/Assets/ManageFolder/download-btn.svg`}
                                alt="Download"
                                onClick={() => handleDownload(battery?.batteryPhoto)}
                                style={{ height: "23px" }}
                              />
                            </Tooltip> */}
                          </button>
                          <p
                            className="panel-size"
                            style={{ textAlign: "left" }}
                          >
                            {battery?.serialNumber}
                          </p>
                          {battery?.distance && (
                            <p className="panel-size">
                              Distance:{" "}
                              {battery.distance > 1
                                ? `${battery.distance} km`
                                : `${battery.distance * 1000} m`}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="panel-ser">
                    <span>No Battery Photos Available</span>
                  </div>
                )}
              </div>
            </div>
            <div className="panel-photobg">
              <div className="ImageUploadSectionsPVJobs">
                <div className="panel-photo">
                  <span className="panel-ser">Photos</span>
                </div>
              </div>
            </div>

            <form method="post">
              <div className="panel-photo">
                <span className="PhotoSectionHeading">Inverter</span>
              </div>
              <div className={"ImagesUploadDiv"}>
                {Object.entries(ImageTypeLabel).map(
                  ([itemName, formDataKey], index) => (
                    <>
                      <div key={index} className="imageUploadInputField">
                        <div class="LabelAndImage">
                          <label
                            for="fileInput"
                            class="labelTextForImage label-Imageupload"
                          >
                            {getLabelNames(formDataKey, ImageTypeLabel)}
                          </label>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id={`fileInput-${formDataKey}`}
                            accept="image/png, image/jpeg, application/pdf"
                            name="uploadFile"
                            onChange={handleFileInputChange(formDataKey, index)}
                          />
                        </div>
                        <button
                          type="button"
                          className="uploadButton"
                          onClick={() => triggerFileInput(formDataKey)}
                        >
                          <img
                            src={`${image}/Assets/UploadFiles/doc-upload.svg`}
                            alt="Upload"
                            style={{ position: "relative", left: "300px" }}
                          />
                        </button>
                        <span style={{ position: "relative", right: "15px" }}>
                          {imageData[formDataKey]
                            ? imageData[formDataKey].name
                            : ""}
                        </span>
                      </div>
                    </>
                  )
                )}
              </div>
              {photosData &&
                (loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <FadeLoader height={20} />
                  </div>
                ) : (
                  <div className="ShowImages">
                    {Object.entries(ImageTypeLabel).map(
                      ([itemName, formDataKey], index) => (
                        <ul className="setup-selfi ShowImagesDiv">
                          {photosData[formDataKey]?.length
                            ? photosData[formDataKey].map((photo, index) => {
                                return (
                                  <li key={index} className="imageBlock">
                                    <img
                                      src={photo?.url}
                                      alt=""
                                      style={{
                                        width: "184px",
                                        height: "184px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        openModal(
                                          photo?.url,
                                          getLabelNames(
                                            formDataKey,
                                            ImageTypeLabel
                                          ),
                                          photosData[formDataKey][0]?.distance
                                        )
                                      }
                                    />
                                    <div>
                                      <span className="set-install-1">
                                        {getLabelNames(
                                          formDataKey,
                                          ImageTypeLabel
                                        )}
                                      </span>
                                      {photosData[formDataKey][0]?.distance ? (
                                        <span className="set-install-1">
                                          Distance:{" "}
                                          {photosData[formDataKey][0].distance >
                                          1
                                            ? `${photosData[formDataKey][0].distance} km`
                                            : `${
                                                photosData[formDataKey][0]
                                                  .distance * 1000
                                              } m`}
                                        </span>
                                      ) : null}
                                    </div>
                                    <Tooltip title="Delete Image">
                                      <button
                                        type="button"
                                        className="imageDeleteButton"
                                        style={{
                                          background: "rgba(44, 58, 100, 1)",
                                          color: "#fff",
                                          border: "none",
                                        }}
                                        onClick={() =>
                                          handleDeleteImages(
                                            jobId,
                                            photo?.type,
                                            photo?._id
                                          )
                                        }
                                      >
                                        ✕
                                      </button>
                                    </Tooltip>
                                  </li>
                                );
                              })
                            : null}
                        </ul>
                      )
                    )}
                  </div>
                ))}
            </form>

            <form method="post">
              <div className="panel-photo">
                <span className="PhotoSectionHeading">Panel</span>
              </div>
              <div className={"ImagesUploadDiv"}>
                {Object.entries(ImageTypeLabelPanel).map(
                  ([itemName, formDataKey], index) => (
                    <>
                      <div key={index} className="imageUploadInputField">
                        <div class="LabelAndImage">
                          <label
                            for="fileInput"
                            class="labelTextForImage label-Imageupload"
                          >
                            {getLabelNames(formDataKey, ImageTypeLabelPanel)}
                          </label>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id={`fileInput-${formDataKey}`}
                            accept="image/png, image/jpeg, application/pdf"
                            name="uploadFile"
                            onChange={handleFileInputChange(formDataKey, index)}
                          />
                        </div>
                        <button
                          type="button"
                          className="uploadButton"
                          onClick={() => triggerFileInput(formDataKey)}
                        >
                          <img
                            src={`${image}/Assets/UploadFiles/doc-upload.svg`}
                            alt="Upload"
                            style={{ position: "relative", left: "300px" }}
                          />
                        </button>
                        <span style={{ position: "relative", right: "15px" }}>
                          {imageData[formDataKey]
                            ? imageData[formDataKey].name
                            : ""}
                        </span>
                      </div>
                    </>
                  )
                )}
              </div>
              {photosData &&
                (loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <FadeLoader height={20} />
                  </div>
                ) : (
                  <div className="ShowImages">
                    {Object.entries(ImageTypeLabelPanel).map(
                      ([itemName, formDataKey], index) => (
                        <ul className="setup-selfi ShowImagesDiv">
                          {photosData[formDataKey]?.length
                            ? photosData[formDataKey].map((photo, index) => {
                                return (
                                  <li key={index} className="imageBlock">
                                    <img
                                      src={photo?.url}
                                      alt=""
                                      style={{
                                        width: "184px",
                                        height: "184px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        openModal(
                                          photo?.url,
                                          getLabelNames(
                                            formDataKey,
                                            ImageTypeLabelPanel
                                          ),
                                          photosData[formDataKey][0]?.distance
                                        )
                                      }
                                    />
                                    <span className="set-install-1">
                                      {getLabelNames(
                                        formDataKey,
                                        ImageTypeLabelPanel
                                      )}
                                    </span>
                                    {photosData[formDataKey][0]?.distance ? (
                                      <span className="set-install-1">
                                        Distance:{" "}
                                        {photosData[formDataKey][0].distance > 1
                                          ? `${photosData[formDataKey][0].distance} km`
                                          : `${
                                              photosData[formDataKey][0]
                                                .distance * 1000
                                            } m`}
                                      </span>
                                    ) : null}
                                    <Tooltip title="Delete Image">
                                      <button
                                        type="button"
                                        className="imageDeleteButton"
                                        style={{
                                          background: "rgba(44, 58, 100, 1)",
                                          color: "#fff",
                                          border: "none",
                                        }}
                                        onClick={() =>
                                          handleDeleteImages(
                                            jobId,
                                            photo?.type,
                                            photo?._id
                                          )
                                        }
                                      >
                                        ✕
                                      </button>
                                    </Tooltip>
                                  </li>
                                );
                              })
                            : null}
                        </ul>
                      )
                    )}
                  </div>
                ))}
            </form>
            <form method="post">
              <div className="panel-photo">
                <span className="PhotoSectionHeading">Battery</span>
              </div>
              <div className={"ImagesUploadDiv"}>
                {Object.entries(ImageTypeLabelBattery).map(
                  ([itemName, formDataKey], index) => (
                    <>
                      <div key={index} className="imageUploadInputField">
                        <div class="LabelAndImage">
                          <label
                            for="fileInput"
                            class="labelTextForImage label-Imageupload"
                          >
                            {getLabelNames(formDataKey, ImageTypeLabelBattery)}
                          </label>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id={`fileInput-${formDataKey}`}
                            accept="image/png, image/jpeg, application/pdf"
                            name="uploadFile"
                            onChange={handleFileInputChange(formDataKey, index)}
                          />
                        </div>
                        <button
                          type="button"
                          className="uploadButton"
                          onClick={() => triggerFileInput(formDataKey)}
                        >
                          <img
                            src={`${image}/Assets/UploadFiles/doc-upload.svg`}
                            alt="Upload"
                            style={{ position: "relative", left: "300px" }}
                          />
                        </button>
                        <span style={{ position: "relative", right: "15px" }}>
                          {imageData[formDataKey]
                            ? imageData[formDataKey].name
                            : ""}
                        </span>
                      </div>
                    </>
                  )
                )}
              </div>
              {photosData &&
                (loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <FadeLoader height={20} />
                  </div>
                ) : (
                  <div className="ShowImages">
                    {Object.entries(ImageTypeLabelBattery).map(
                      ([itemName, formDataKey], index) => (
                        <ul className="setup-selfi ShowImagesDiv">
                          {photosData[formDataKey]?.length
                            ? photosData[formDataKey].map((photo, index) => {
                                return (
                                  <li key={index} className="imageBlock">
                                    <img
                                      src={photo?.url}
                                      alt=""
                                      style={{
                                        width: "184px",
                                        height: "184px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        openModal(
                                          photo?.url,
                                          getLabelNames(
                                            formDataKey,
                                            ImageTypeLabelBattery
                                          ),
                                          photosData[formDataKey][0]?.distance
                                        )
                                      }
                                    />
                                    <span className="set-install-1">
                                      {getLabelNames(
                                        formDataKey,
                                        ImageTypeLabelBattery
                                      )}
                                    </span>
                                    {photosData[formDataKey][0]?.distance ? (
                                      <span className="set-install-1">
                                        Distance:{" "}
                                        {photosData[formDataKey][0].distance > 1
                                          ? `${photosData[formDataKey][0].distance} km`
                                          : `${
                                              photosData[formDataKey][0]
                                                .distance * 1000
                                            } m`}
                                      </span>
                                    ) : null}
                                    <Tooltip title="Delete Image">
                                      <button
                                        type="button"
                                        className="imageDeleteButton"
                                        style={{
                                          background: "rgba(44, 58, 100, 1)",
                                          color: "#fff",
                                          border: "none",
                                        }}
                                        onClick={() =>
                                          handleDeleteImages(
                                            jobId,
                                            photo?.type,
                                            photo?._id
                                          )
                                        }
                                      >
                                        ✕
                                      </button>
                                    </Tooltip>
                                  </li>
                                );
                              })
                            : null}
                        </ul>
                      )
                    )}
                  </div>
                ))}
            </form>
          </>
        ) : null}

        <form method="post">
          <div className="panel-photo ">
            <span className="PhotoSectionHeading">
              {jobType !== "SWH" ? `Other Supportive Photos` : "Photos"}
            </span>
          </div>
          <div className={"ImagesUploadDiv"}>
            {Object.entries(
              jobType !== "SWH" ? ImageTypeLabelOtherPhotos : SWHTypeLabel
            ).map(([itemName, formDataKey], index) => (
              <>
                <div key={index} className="imageUploadInputField">
                  <div class="LabelAndImage">
                    <div style={{ width: "100%" }}>
                      <label
                        for="fileInput"
                        class="labelTextForImage label-Imageupload"
                      >
                        {getLabelNames(
                          formDataKey,
                          jobType !== "SWH"
                            ? ImageTypeLabelOtherPhotos
                            : SWHTypeLabel
                        )}
                      </label>
                    </div>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id={`fileInput-${formDataKey}`}
                      accept="image/png, image/jpeg, application/pdf"
                      name="uploadFile"
                      onChange={handleFileInputChange(formDataKey, index)}
                    />
                  </div>
                  <button
                    type="button"
                    style={{}}
                    className="uploadButton"
                    onClick={() => triggerFileInput(formDataKey)}
                  >
                    <img
                      src={`${image}/Assets/UploadFiles/doc-upload.svg`}
                      alt="Upload"
                      style={{ position: "relative", left: "300px" }}
                    />
                  </button>
                  <span style={{ position: "relative", right: "15px" }}>
                    {imageData[formDataKey] ? imageData[formDataKey].name : ""}
                  </span>
                </div>
              </>
            ))}
          </div>

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <FadeLoader height={20} />
            </div>
          ) : (
            photosData && (
              <div className="ShowImages">
                {Object.entries(
                  jobType !== "SWH" ? ImageTypeLabelOtherPhotos : SWHTypeLabel
                ).map(([itemName, formDataKey], index) => (
                  <ul className="setup-selfi ShowImagesDiv">
                    {photosData[formDataKey]?.length
                      ? photosData[formDataKey].map((photo, index) => {
                          return (
                            <li key={index} className="imageBlock">
                              <img
                                src={photo?.url}
                                alt=""
                                style={{
                                  width: "184px",
                                  height: "184px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  openModal(
                                    photo?.url,
                                    getLabelNames(
                                      formDataKey,
                                      jobType !== "SWH"
                                        ? ImageTypeLabelOtherPhotos
                                        : SWHTypeLabel
                                    ),
                                    photosData[formDataKey][0]?.distance
                                  )
                                }
                              />
                              <span className="set-install-1">
                                {getLabelNames(
                                  formDataKey,
                                  jobType !== "SWH"
                                    ? ImageTypeLabelOtherPhotos
                                    : SWHTypeLabel
                                )}
                              </span>
                              {photosData[formDataKey][0]?.distance ? (
                                <span className="set-install-1">
                                  Distance:{" "}
                                  {photosData[formDataKey][0].distance > 1
                                    ? `${photosData[formDataKey][0].distance} km`
                                    : `${
                                        photosData[formDataKey][0].distance *
                                        1000
                                      } m`}
                                </span>
                              ) : null}
                              <Tooltip title="Delete Image">
                                <button
                                  className="imageDeleteButton"
                                  style={{
                                    background: "rgba(44, 58, 100, 1)",
                                    color: "#fff",
                                    border: "none",
                                  }}
                                  type="button"
                                  onClick={() =>
                                    handleDeleteImages(
                                      jobId,
                                      photo?.type,
                                      photo?._id
                                    )
                                  }
                                >
                                  ✕
                                </button>
                              </Tooltip>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                ))}
              </div>
            )
          )}
          <ImageModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            image={modalImage}
            label={modalLabel}
            distance={modalDistance}
          />
        </form>
      </div>
    </div>
  );
};

export default ManageFolder;
