import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment/moment";
import "./CustomerCertificateForm.css";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const CustomerCertificateForm = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  const [jobData, setJobData] = useState();

  const fetchData = async () => {
    try {
      const resp = await axios.get(`${baseURL}/jobMaster/${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp.data.data;
      setJobData(data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to open the browser's print dialog
  const openPrintDialog = () => {
    window.print();
  };

  return (
    <>
      <div className="main-container">
        <h2>Customer Certificate</h2>
        <br />
        <div className="customer-details">
          <h4>Retailer Details:</h4>
          <span>
            Name: {jobData?.user?.basicInformation?.firstName}{" "}
            {jobData?.user?.basicInformation?.lastName}
          </span>
          <span>
            Company Name: {jobData?.user?.companyInformation?.tradingName}
          </span>
          <span>
            Address: {jobData?.user?.companyInformation?.address?.streetNo}{" "}
            {jobData?.user?.companyInformation?.address?.streetName}{" "}
            {jobData?.user?.companyInformation?.address?.streetType}{" "}
            {jobData?.user?.companyInformation?.address?.suburb}{" "}
            {jobData?.user?.companyInformation?.address?.state}{" "}
            {jobData?.user?.companyInformation?.address?.postcode}
          </span>
          <span>
            Contact Number: {jobData?.user?.companyInformation?.mobile}
          </span>
          <span>E-Mail: {jobData?.user?.basicInformation?.emailId}</span>
        </div>
        <br />
        <div className="customer-details">
          <h4>Installer Details:</h4>
          <span>Name: {jobData?.installers[0]?.installer?.name}</span>
          <span>License Number: {jobData?.installers[0]?.installerCECID}</span>
          <span>
            Contact Number: {jobData?.installers[0]?.installer?.mobileNo}
          </span>
          <span>E-Mail: {jobData?.installers[0]?.installer?.emailId}</span>
        </div>
        <br />
        <div className="customer-details">
          <h4>Installation Details:</h4>
          <span>
            Installation Date:{" "}
            {moment(
              jobData?.jobDetails?.installationDate?.installingDate
            ).format("DD-MM-YYYY")}
          </span>
          <span>
            System Size (kW): {jobData?.jobDetails?.panelDetail?.wattage}
          </span>
          <span>
            Installation Type:{" "}
            {jobData?.jobDetails?.installationDetails?.installationType}
          </span>
        </div>
        <br />
        <div className="customer-details">
          <h4>Site Attributes:</h4>
          <span>
            Storey Type: {jobData?.jobDetails?.siteAttributes?.storey}
          </span>
        </div>
        <br />
        <div className="customer-details">
          <h4>Panel Installed:</h4>
          <span>
            Panel Model(s):{" "}
            {jobData?.jobDetails?.panelDetail?.panelInfo
              ?.map((panel) => panel.panelModel)
              .filter(Boolean)
              .join(", ")}
          </span>
          <span>
            Serial Number:{" "}
            {jobData?.panels
              ?.map((panel) => panel.serialNumber)
              .filter(Boolean)
              .join(", ")}
          </span>
          <span>Wattage (kW): {jobData?.jobDetails?.panelDetail?.wattage}</span>
          <span>STCs: {jobData?.jobDetails?.panelDetail?.stc}</span>
        </div>
        <br />
        <div className="customer-details">
          <h4>Inverter Installed:</h4>
          <span>
            Inverter Model(s):{" "}
            {jobData?.jobDetails?.inverterDetail?.inverterInfo
              ?.map((inverter) => inverter.inverterModel)
              .filter(Boolean)
              .join(", ")}
          </span>
          <span>
            Serial Number:{" "}
            {jobData?.inverters
              ?.map((inverter) => inverter.serialNumber)
              .filter(Boolean)
              .join(", ")}
          </span>
          <span>
            Capacity (kW):{" "}
            {jobData?.jobDetails?.inverterDetail?.inverterInfo
              ?.map((inverter) => inverter.inverterCapacity)
              .filter(Boolean)
              .join(", ")}
          </span>
        </div>
        <br />
        {jobData?.jobDetails?.gridConnectionDetail?.connectionType !==
        "Connected to an electricity grid without battery storage" ? (
          <div className="customer-details">
            <h4>Battery Installed:</h4>
            <span>
              Battery Model(s):{" "}
              {jobData?.jobDetails?.batteryDetail?.batteryInfo
                ?.map((battery) => battery.batteryModel)
                .filter(Boolean)
                .join(", ")}
            </span>
            <span>
              Serial Number:{" "}
              {jobData?.batteries
                ?.map((battery) => battery.serialNumber)
                .filter(Boolean)
                .join(", ")}
            </span>
            <span>
              Capacity (kW):{" "}
              {jobData?.jobDetails?.batteryDetail?.batteryInfo
                ?.map((battery) => battery.batteryCapacity)
                .filter(Boolean)
                .join(", ")}
            </span>
          </div>
        ) : null}
        <br />
        <div className="customer-details">
          <h4>Retailer Written Statement:</h4>
          <p>
            I,{" "}
            <b>
              {jobData?.user?.basicInformation?.firstName}{" "}
              {jobData?.user?.basicInformation?.lastName}
            </b>
            , confirm that the information provided above is accurate and
            complete to the best of my knowledge.
          </p>
          <br />
          <img
            src={jobData?.signatures[0]?.retailerSignature?.url}
            alt="Retailer Signature"
            style={{ width: "100px" }}
          />
          Signature
        </div>
        <br />
        <div className="customer-details">
          <h4>Reference Numbers:</h4>
          <span>
            Unique Reference Number:{" "}
            {jobData?.jobDetails?.additionalAttributes?.CRMID}
          </span>
          <span>PV Number: {jobData?.jobDetails?.jobIdentifyId}</span>
        </div>
        <br />
        <div className="customer-details">
          <h4>Warranty Details:</h4>
          <p>{jobData?.jobDetails?.warrantyDetails?.warrantyDescription}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <button
          type="button"
          // onClick={downloadPDF}
          onClick={openPrintDialog}
          style={{
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            padding: "10px 20px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>
    </>
  );
};

export default CustomerCertificateForm;
