import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { FadeLoader } from "react-spinners";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const SignaturePage = (props) => {
  const { emailId, handleSubmit, jobType } = props;
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  //Storing Data from API of Onsite
  const [signatureData, setSignatureData] = useState([]);
  const [qldSignature, setQldSignature] = useState();
  const userType = localStorage.getItem("userType")
  const [signatures, setSignatures] = useState({
    installerSignature: "",
    designerSignature: "",
    electricianSignature: "",
    customerSignature: "",
    CCEW: "",
    retailerSignature: "",
  });
  //Get On Site Details Starts
  const fetchOnSiteData = async () => {
    try {
      const resp = await axios.get(`${baseURL}/onSite`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp?.data?.data;
      const flattenedData = data.flat();
      const result = flattenedData.filter((item) => item.jobId === jobId);
      const signatures = result[0]?.signatures;
      setSignatureData(signatures);
      setQldSignature(result[0]?.qldComplianceSignature);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchOnSiteData();
  }, []);

  const [installerName, setInstallerName] = useState("");
  const [designerName, setDesignerName] = useState("");
  const [electricianName, setElectricianName] = useState("");
  const [plumberName,setPlumberName]=useState("")
  const [customerName, setCustomerName] = useState("");
  const [retailerName, setRetailerName] = useState("");
  const [CCEW, setCCEWName] = useState("");
  const [sentLoading, setSentLoading] = useState(false);

  
    const fetchJobData = async () => {
      let apiUrl = "";
      if (userType === "Trader") {
        apiUrl = `${baseURL}/traders/jobs`;
      } else {
        apiUrl = `${baseURL}/job/all`;
      }
      try {
        
        const resp = await axios.get(apiUrl, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
      const flattenedData = resp.data.jobs.flat();
      const result = flattenedData.filter((item) => item._id === jobId);
      if(result[0]?.jobType ==='PV'){
        setInstallerName(result[0]?.installerName);
        setDesignerName(result[0]?.designerName);
        setElectricianName(result[0]?.electricianName);

      }
      else{
        setInstallerName(result[0]?.plumberName);
        setElectricianName(result[0]?.electricianName);
      }
      setCustomerName(
        result[0]?.ownerDetails?.firstName +
          " " +
          result[0]?.ownerDetails?.lastName
      );
      setRetailerName(
        result[0]?.retailerData?.basicInformation?.firstName +
          " " +
          result[0]?.retailerData?.basicInformation?.lastName
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchJobData();
  }, []);
  //Get On Site Details Ends

  const [addPanelPopUp, setOwnerSignature] = useState(false);
  const [addUploadPopUp, setUploadSignature] = useState(false);

  //Upload Signature by Retailer Starts
  const [selectedFile, setSelectedFile] = useState("");

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    try {
      if (!selectedFile) {
        alert("Please select a file.");
        return;
      }
      const formData = new FormData();
      formData.append("customerSignature", selectedFile);
      formData.append("jobId", jobId);
      const response = await axios.post(
        `${baseURL}/update/signatureCustomer`,
        formData
      );

      if (response.status === 200 || response.status === 201) {
        alert("Signature Uploaded Successfully");
        fetchOnSiteData();
      } else {
        console.error("Error Occured");
      }
      setUploadSignature(false);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };
  //Upload Signature by Retailer Ends

  //Sent Mail for Signature by Retailer Starts
  const sentMail = async () => {
    setSentLoading(true);
    try {
      const requestBody = {
        jobId: jobId,
        emailId: emailId,
      };
      const response = await axios.post(
        `${baseURL}/update/send-signature-link`,
        requestBody
      );
      if (response.status === 200 || response.status === 201) {
        alert("Signature Upload Mail Sent Successfully");
        handleSubmit();
        // window.location.reload();
        setOwnerSignature(false);
      } else {
        console.error("Error occured");
      }
      setUploadSignature(false);
      setSentLoading(false);
    } catch (error) {
      alert(error?.response?.data?.message);
      setSentLoading(false);
    }
  };
  //Sent Mail for Signature by Retailer Ends
  const handleSignatureUpload = async (e, signatureId, signatureType) => {
    const file = e.target.files[0];
    if (!file) return;
    const formData = new FormData();

    formData.append("jobId", jobId);

    if (signatureType === "CCEW") {
      formData.append("signature", file);
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.patch(
          `${baseURL}/compliance/updateForWeb`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200 || response.status === 201) {
          alert("Signature uploaded successfully");

          fetchOnSiteData();
        } else {
          console.error(
            "Upload failed. Unexpected status code:",
            response.status
          );
        }
      } catch (error) {
        alert(error?.response?.data?.message || error?.message);
        console.error(error?.response?.data?.message || error?.message);
      }
    } else {
      formData.append("customerSignature", selectedFile);
      formData.append(signatureType, file);
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.post(
          `${baseURL}/update/signatureForWeb`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200 || response.status === 201) {
          alert("Signature Added. Please Save to upload changed files");
          fetchOnSiteData();
        } else {
          console.error(
            "Upload failed. Unexpected status code:",
            response.status
          );
        }
      } catch (error) {
        alert(error?.response?.data?.message || error?.message);
        console.error(error?.response?.data?.message || error?.message);
      }
    }
  };

  const updateSignature = async (field) => {
    try {
      if (!selectedFile) {
        alert("Please select a file.");
        return;
      }
      const formData = new FormData();
      formData.append("retailerSignature", selectedFile);
      formData.append("jobId", jobId);
      const response = await axios.post(
        `${baseURL}/update/signature`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert("Signature Uploaded Successfully");
        // fetchSignatureData();
        // fetchData();
        window.location.reload();
      } else {
        console.error("Error Occured");
      }
      setUploadSignature(false);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (signatureData?.length) {
      const signature = signatureData[0];
      setSignatures({
        installerSignature: signature.installerSignature?.url || "",
        designerSignature: signature.designerSignature?.url || "",
        electricianSignature: signature.electricianSignature?.url || "",
        customerSignature: signature.customerSignature?.url || "",
        retailerSignature: signature.retailerSignature?.url || "",
      });
    }
  }, [signatureData]);

  // useEffect(() => {
  //   if (qldSignature) {
  //     setSignatures((prev) => ({ ...prev, CCEW: qldSignature }));
  //   }
  // }, [qldSignature]);

  const removeSignature = (field) => {
    if (field === "CCEW") {
      setQldSignature(null);
    } else {
      setSignatures((prev) => ({ ...prev, [field]: "" }));
    }
  };
  const renderSignatureField = (
    field,
    name,
    handleSignatureUpload,
    removeSignature,
    isCCEW = false
  ) => (
    <li>
      {/* { signatures['designerSignature']} */}
      {(isCCEW && qldSignature) || field === "CCEW" ? (
        <div style={{ position: "relative" }}>
          <img
            src={qldSignature}
            alt=""
            style={{ width: "75px", height: "75px" }}
          />
          <button
            type="button"
            style={{
              position: "absolute",
              top: "0px",
              right: "5px",
              width: " 24px",
              height: "24px",
              background: "rgba(44, 58, 100, 1)",
              border: "none",
              borderRadius: "50%",
              cursor: "pointer",
              fontSize: "13px",
              fontWeight: "700",
              color: "#fff",
            }}
            onClick={() => removeSignature("CCEW")}
          >
            ✕
          </button>
        </div>
      ) : field == "retailerSignature" && signatures["retailerSignature"] ? (
        <div style={{ position: "relative" }}>
          <img
            src={signatures[field]}
            alt=""
            style={{ width: "75px", height: "75px" }}
          />
             <button
            type="button"
            style={{
              position: "absolute",
              top: "0px",
              right: "5px",
              width: " 24px",
              height: "24px",
              background: "rgba(44, 58, 100, 1)",
              border: "none",
              borderRadius: "50%",
              cursor: "pointer",
              fontSize: "13px",
              fontWeight: "700",
              color: "#fff",
            }}
            // className="add-model-close-btn"
            onClick={() => removeSignature(field)}
          >
            ✕
          </button>
        </div>
      ) : signatures[field] ? (
        <div style={{ position: "relative" }}>
          <img
            src={signatures[field]}
            alt=""
            style={{ width: "75px", height: "75px" }}
          />
          <button
            type="button"
            style={{
              position: "absolute",
              top: "0px",
              right: "5px",
              width: " 24px",
              height: "24px",
              background: "rgba(44, 58, 100, 1)",
              border: "none",
              borderRadius: "50%",
              cursor: "pointer",
              fontSize: "13px",
              fontWeight: "700",
              color: "#fff",
            }}
            // className="add-model-close-btn"
            onClick={() => removeSignature(field)}
          >
            ✕
          </button>
        </div>
      ) : field == "customerSignature" ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "",
              alignItems: "center",
              gap: "40px",
              height: "52%",
            }}
          >
            <button
              type="button"
              className="btn-ownersignature"
              style={{ width: "145px", fontSize: "12px" }}
              onClick={() => setOwnerSignature(true)}
            >
              Request Owner for Signature
            </button>
            <button
              type="button"
              className="btn-uploadsignature"
              style={{ fontSize: "12px" }}
              onClick={() => setUploadSignature(true)}
            >
              Upload Signature
            </button>
          </div>
           <span className="set-signature">Owner Signature</span>
          <b className="person-name">{customerName}</b> 
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "",
            alignItems: "center",
            gap: "40px",
            height: "52%",
          }}
        >
          <div>
            <input
              style={{ display: "none" }}
              type="file"
              id={`inputId-${field}`}
              accept="image/png"
              name="uploadFile"
              onChange={(e) => handleSignatureUpload(e, null, field)}
            />
            {/* </div> */}
          </div>
          <button
            type="button"
            style={{
              width: "140px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "42px",
              backgroundColor: "#E0EDFF",
              borderRadius: "5px",
              marginTop: "2%",
              border: "transparent",
              position: "relative",
            }}
            // className="uploadButton"
            onClick={() => document.getElementById(`inputId-${field}`).click()}
          >
            <img
              src={`${image}/Assets/UploadFiles/doc-upload.svg`}
              alt="Upload"
              style={{ position: "absolute", right: "10px" }}
            />
          </button>
        </div>
      )}
      <div>
        <span className="set-signature">{`${
          isCCEW
            ? "CCEW"
            : field === "designerSignature"
            ? "Designer"
            : field === "installerSignature"
            ?  jobType=="PV"?"Installer":"Plumber"
            : field === "electricianSignature"
            ? "Electrician"
            : field === "customerSignature"
            ? "Owner"
            : field === "retailerSignature"
            ? "Retailer"
            : ""
        } Signature`}</span>
        <b className="person-name">{name}</b>
        {signatures[field]?.distance && (
          <>
            <b className="person-name">
              Distance from Site: {signatures[field]?.distance}
            </b>
          </>
        )}
      </div>
    </li>
  );

  const renderUploadOption = (field, name, isCCEW) => (
    <li>
      {field == "customerSignature" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "",
            alignItems: "center",
            gap: "40px",
            height: "78%",
          }}
        >
          <button
            type="button"
            className="btn-ownersignature"
            style={{ width: "145px", fontSize: "12px" }}
            onClick={() => setOwnerSignature(true)}
          >
            Request Owner for Signature
          </button>
          <button
            type="button"
            className="btn-uploadsignature"
            style={{ fontSize: "12px" }}
            onClick={() => setUploadSignature(true)}
          >
            Upload Signature
          </button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "",
            alignItems: "center",
            gap: "40px",
            height: "52%",
          }}
        >
          {/* <input
          type="file"
          accept="image/png"
          onChange={(e) => handleSignatureUpload(e, null, field)}
        /> */}
          <div>
            <input
              style={{ display: "none" }}
              type="file"
              id={`inputId-${field}`}
              accept="image/png"
              name="uploadFile"
              onChange={(e) => handleSignatureUpload(e, null, field)}
            />
            {/* </div> */}
          </div>
          <button
            type="button"
            style={{
              width: "140px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "42px",
              backgroundColor: "#E0EDFF",
              borderRadius: "5px",
              marginTop: "2%",
              border: "transparent",
              position: "relative",
            }}
            // className="uploadButton"
            onClick={() => document.getElementById(`inputId-${field}`).click()}
          >
            <img
              src={`${image}/Assets/UploadFiles/doc-upload.svg`}
              alt="Upload"
              style={{ position: "absolute", right: "10px" }}
            />
          </button>
        </div>
      )}
      <div>
        <span className="set-signature">{`${
          isCCEW
            ? "CCEW"
            : field === "designerSignature"
            ? "Designer"
            : field === "installerSignature"
            ? jobType=="PV"?"Installer":"Plumber"
            : field === "electricianSignature"
            ? "Electrician"
            : field === "customerSignature"
            ? "Owner"
            : ""
        } Signature`}</span>
        <b className="person-name">{name}</b>
      </div>
    </li>
  );
  return (
    <div className="row">
      <div className="checkin-panel">
        <h3 className="panel-ser">Signature</h3>
      </div>

      <ul
        className="setup-selfi"
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "20px",
        }}
      >
        {signatureData?.length ? (
          <>
            {jobType === "PV"?
            renderSignatureField(
              "installerSignature",
              installerName,
              handleSignatureUpload,
              removeSignature
            ):  renderSignatureField(
              "installerSignature",
              installerName,
              handleSignatureUpload,
              removeSignature)
            }
            {jobType === "PV"
              ? renderSignatureField(
                  "designerSignature",
                  designerName,
                  handleSignatureUpload,
                  removeSignature
                )
              : null}
            {jobType === "PV"
              ? renderSignatureField(
                  "electricianSignature",
                  electricianName,
                  handleSignatureUpload,
                  removeSignature
                )
              : null}
            {renderSignatureField(
              "customerSignature",
              customerName,
              handleSignatureUpload,
              removeSignature
            )}
          {jobType === "PV" ?
           ( 
            renderSignatureField(
              "retailerSignature",
              retailerName,
              handleSignatureUpload,
              removeSignature
            ))
            :null}
          </>
        ) : (
          <>
            {renderUploadOption("installerSignature", installerName)}
            {jobType === "PV"
              ? renderUploadOption("designerSignature", designerName)
              : null}
            {jobType === "PV"
              ? renderUploadOption("electricianSignature", electricianName)
              : null}
            {renderUploadOption("customerSignature", customerName)}
          </>
        )}

        {jobType === "PV"
          ? qldSignature
            ? renderSignatureField(
                "CCEW",
                electricianName,
                handleSignatureUpload,
                removeSignature,
                true
              )
            : renderUploadOption("CCEW", electricianName, true)
          : null}
      </ul>
      {
        <div className="row">
          {addPanelPopUp && (
            <div className="invite-model-backdrop">
              <div className="delete-model-content ownersignature-popup-height">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 className="text-panel">Request Owner for Signature</h2>
                  <button
                    type="button"
                    class="onsitedata-model-close-btn"
                    onClick={() => setOwnerSignature(false)}
                  >
                    ✕
                  </button>
                </div>
                <span className="addphoto-bottom-border"></span>
                <div className="row">
                  <form method="post">
                    <div class="retailer-main doc-upload">
                      <input
                        className="addphoto-title"
                        type="text"
                        placeholder="Owner Email"
                        required
                        value={emailId}
                        readOnly
                      />
                    </div>
                  </form>
                </div>

                <span className="addphoto-bottom-border"></span>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {sentLoading ? (
                    <FadeLoader radius={10} color="#154397" />
                  ) : (
                    <button
                      className="onsitebtn-save"
                      type="button"
                      onClick={sentMail}
                    >
                      Send Link
                    </button>
                  )}
                  {/* <button
                    className="onsitebtn-cancel"
                    type="button"
                    onClick={() => setOwnerSignature(false)}
                  >
                    Cancel
                  </button> */}
                </div>
              </div>
            </div>
          )}
        </div>
      }
      <div className="row">
        {addUploadPopUp && (
          <div className="invite-model-backdrop">
            <div className="delete-model-content uploadsignature-popup-height">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2 className="text-panel">Upload Owner Signature</h2>
                <button
                  type="button"
                  class="onsitedata-model-close-btn"
                  onClick={() => setUploadSignature(false)}
                >
                  ✕
                </button>
              </div>
              <span className="addphoto-bottom-border"></span>

              <div className="row">
                <form method="post">
                  <div class="retailer-main doc-upload">
                    <input
                      class="photoupload-input"
                      type="file"
                      placeholder="Upload Photo"
                      accept="image/png"
                      name="customerSignature"
                      onChange={handleFileChange}
                    />
                    <span className="file-text">
                      (JPG Max 50KB, PNG Max 100KB)
                    </span>
                    <img
                      src={`${image}/Assets/OnSiteData/Signature/uploadphoto.svg`}
                      alt=""
                      className="photoupload"
                    />
                  </div>
                </form>
              </div>

              <span className="addphoto-bottom-border"></span>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="onsitebtn-save"
                  type="button"
                  onClick={uploadFile}
                >
                  Save
                </button>
                <button
                  className="onsitebtn-cancel"
                  type="button"
                  onClick={() => setUploadSignature(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignaturePage;
