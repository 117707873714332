import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import "./App.css";
import ForgetPswd from "./components/SignupForgerPswd/ForgetPswd";

import InstallerForgetPswd from "./components/SignupForgerPswd/InstallerForgetPswd";
import ResetPswd from "./components/SignupForgerPswd/ResetPswd";
import SignatureForMobile from "./components/SignatureForMobile";
import SiteInspection from "./components/SiteInspection"; 
import Dashboard from "./components/Dashboard/Dashboard";
import CreatePVJob from "./components/Dashboard/CreatePVJob";
import AddInstallerMail from "./components/Trader/Teamdashboard/AddInstallerMail";
import RetailerProfileView from "./components/Dashboard/Retailer/RetailerProfileView";
import RetailerEditProfile from "./components/Dashboard/Retailer/RetailerProfileEdit";
import ApprovedCER from "./components/Dashboard/ApprovedCER/ApprovedCER";
import LivePrice from "./components/Dashboard/LivePrice/LivePrice";
import OnSiteData from "./components/Dashboard/OnSiteData/OnSiteData";
import ManageFolder from "./components/Dashboard/CommonComponents/ManageFolder/ManageFolder";
import DocumentRequired from "./components/Dashboard/DocumentRequired";
import UploadFiles from "./components/Dashboard/UploadFiles";
import ImportantActions from "./components/Dashboard/ImportantActions";
import RetailerWrittenStatement from "./components/Dashboard/Forms/RetailerWrittenStatement/RetailerWrittenStatement"
import DownloadSTCForm from "./components/Dashboard/Forms/DownloadSTCForm/DownloadSTCForm";
import ViewSiteInspection from "./components/Dashboard/ViewSiteInspection";
import ComplianceCertificate from "./components/Dashboard/Forms/Compliance/ComplianceCertificate";
import TestingAndCommissioningForm from "./components/Dashboard/Forms/TestingAndCommissioningForm/TestingAndCommissioningForm";
import SignUp from "./components/Trader/SignUp";
import RetailersList from "./components/RetailersList";
import ProfileView from "./components/ProfileView";
import ProfileEdit from "./components/ProfileEdit";
import CreateHWJob from "./components/HotWater/CreateHWJob";
import DashboardHW from "./components/HotWater/DashboardHW/DashboardHW";
import Help from "./components/Help";
import DownloadvariationForm from "./components/Dashboard/Forms/VariationFrom/variationForm";
import PerformanceReduceForm from "./components/Dashboard/Forms/PerformaceReduceForm/PerformanceReduceForm";
import CustomerCertificateForm from "./components/Dashboard/Forms/CustomerCertificateForm/CustomerCertificateForm";
import InstallerPresenceReport from "./components/Dashboard/Forms/InstallerPresenceReport/InstallerPresenceReport";
import JobPack from "./components/Dashboard/JobPack";
import AcceptReject from "./components/SignupForgerPswd/AcceptReject";
import MultipleBattery from "./components/Dashboard/MultipleBattery";
import Home from "./components/Home/Home";
import ProposalPage from "./components/Proposal/ProposalPage";

const App = () => {

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/help" element={<Help/>}/>

      {/* <Route path="/register" element={<SignUp/>} /> */}
      <Route path="/profile-view" element={<ProfileView/>}/>
      <Route path="/profile-edit" element={<ProfileEdit/>}/>
      <Route path="/retailers" element={<RetailersList  />}/>
      <Route path="/teams" element={<RetailersList />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/onSite-data" element={<OnSiteData />} />
      <Route path="/dashboard/manage-folder" element={<ManageFolder/>}/>
      <Route path="/dashboard/written-statement" element={<RetailerWrittenStatement />} />
      <Route path="/dashboard/unsigned-stc" element={<DownloadSTCForm />} />
      <Route path="/dashboard/reduced-performance" element={<PerformanceReduceForm />} />
      <Route path="/dashboard/customer-certificate" element={<CustomerCertificateForm />} />
      <Route path="/dashboard/variation-form" element={<DownloadvariationForm/>}/>
      <Route path="/dashboard/site-inspection" element={<SiteInspection />} />
      <Route path="/dashboard/document-required" element={<DocumentRequired/>}/>
      <Route path="/dashboard/upload-files" element={<UploadFiles/>}/>
      <Route path="/dashboard/important-actions" element={<ImportantActions/>}/>
      <Route path="/dashboard/view-site-inspection" element={<ViewSiteInspection/>}/>
      <Route path="/dashboard/job-pack/:_id" element={<JobPack/>}/>
      <Route path="/dashboard-SWH/job-pack/:_id" element={<JobPack/>}/>
      <Route path="/dashboard/installer-presence" element={<InstallerPresenceReport/>}/>
      <Route path="/installer-reset-password/:_id" element={<ResetPswd isInstaller />} />
      <Route path="/createPVJob" element={<CreatePVJob />} />
      
      <Route path="/invite-installer/:_id" element={<AddInstallerMail />} />
      
      <Route path="/profile-retailer" element={<RetailerProfileView />} />
      <Route path="/edit-retailer" element={<RetailerEditProfile />} />
      <Route path="/approved-cer" element={<ApprovedCER />} />
      <Route path="/live-price" element={<LivePrice />} />

      <Route path="/signature-pad/:_id" element={<SignatureForMobile />} />
      <Route path="/compliance-certificate" element={<ComplianceCertificate />} />
      <Route path="/testing&commissioning" element={<TestingAndCommissioningForm />} />
      <Route path="/createSHWJob" element={<CreateHWJob/>}/>
      
      <Route path="/dashboard-SWH" element={<DashboardHW/>}/>

      <Route path="/forgotPassword" element={<ForgetPswd />} />
      <Route path="/resetpassword/:_id" element={<ResetPswd />} />
      <Route path="/accept-reject/:_id/:retailerId" element={<AcceptReject/>} />
      <Route path="/dashboard-SWH/unsigned-stc" element={<DownloadSTCForm />} />
      <Route path="/test" element={<MultipleBattery/>}/>
      <Route path="/home" element={<Home/>}/>
      {/* <Route path="/payments&invoice" element={<ProposalPage/>}/> */}
    </Routes>
  );
};

export default App;
