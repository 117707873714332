import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Aside from "../FixedBar/Aside";
import HeaderComponent from "../Dashboard/CommonComponents/HeaderComponent";
import "./Home.css";
import Graph from "./Graph";
import JobPieChart from "./JobPieChart";
import JobBoxes from "./JobBoxes";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const graphData = [
  {
    year: "2022",
    "In-Progress": 4000,
    Submitted: 2400,
  },
  {
    year: "2023",
    "In-Progress": 3000,
    Submitted: 1398,
  },
  {
    year: "2024",
    "In-Progress": 2780,
    Submitted: 3908,
  },
  {
    year: "2025",
    "In-Progress": 1890,
    Submitted: 4800,
  },
];
const Home = () => {
  const token = localStorage.getItem("userToken");
  const userType = localStorage.getItem("userType");

  const [data, setData] = useState();
  const fetchData = async () => {
    const api =
      userType === "Trader"
        ? `${baseURL}/traders/jobCount`
        : `${baseURL}/users/jobCount`;
    try {
      const resp = await axios.get(api, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp.data;
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const jobData = {
    totalJobs: data?.totalJobs,
    statusCounts: {
      "In-Progress": data?.statusCounts?.["in-progress"],
      Submitted: data?.statusCounts?.submitted,
      Failed: data?.statusCounts?.Failed,
      Approved: data?.statusCounts?.Approved,
    },
  };

  // const graphData = data?.data.map((item) => ({
  //   name: item.year.toString(),
  //   "In-Progress": item["in-progress"],
  //   Submitted: item.submitted,
  // }));

  const label =
    userType === "Trader" ? "Retailers connected: " : "Installers connected: ";
  const teamCount =
    userType === "Trader" ? data?.retailerCount : data?.installerCount;
  return (
    <div className="MainOuterContainer">
      <Aside />
      <div style={{width:"100%"}}>
      <div className="main-part-ratailer">
        <div className="row">
          <HeaderComponent PageTitle={"Overview"} />
        </div>
        <div >
          <JobBoxes
            label1={"Total Jobs : "}
            label2={"Completed Jobs : "}
            label3={label}
            data1={data?.totalJobs}
            data2={data?.statusCounts?.submitted}
            data3={teamCount}
          />

          <div
            className="homeDataSections"
            style={{ justifyContent: "flex-start" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "end",
                height: "440px",
                width: "600px",
              }}
              className="graphArea"
            >
              <Graph data={graphData} />
            </div>
            {jobData?.totalJobs>0?(
              <div
              className="PieChartDiv"
              style={{ display: "flex", height: "440px" }}
            >
              <JobPieChart data={jobData} />
            </div>
            ):null}
            
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Home;
