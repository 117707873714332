import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Document,
  Image,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const loadLogo = async () => {
  try {
    const response = await fetch("Assets/logo.svg");
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error loading logo:", error);
    return null;
  }
};

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    margin: 10,
  },
  section: {
    // margin:10
    width: "100%",
    border: "1px solid #000",
    display: "flex",
    flexDirection: "column",
  },
  rowData: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent:"space-between "
  },
  sectionTitle: {
    color: "#000",
    borderBottom: "1px Solid #000",
    // width:"100%",
  },
  subSectionRow: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #000",
    alignItems: "center",
  },
  RowDataTitle: {
    borderRight: "1px solid #000",
    padding: "0 2%",
    width: "25%",
    fontSize: 10,
  },
  RowDynamicData: {
    padding: " 0 2%",
    fontSize: 10,
    textOverflow: "wrap",
    width: "100%",
  },
  RowDataTitleWithoutBorder: {
    fontSize: 10,
    padding: "0 2%",
    width: "90%",
    textWrap: "wrap",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "15%",
    padding: "10px",
    // borderRight:'1px solid #000'
  },
  checkbox: {
    display: "flex",
    width: 14,
    height: 14,
    border: "1px solid #000",
  },
  checkboxColored: {
    display: "flex",
    width: 14,
    height: 14,
    border: "1px solid #000",
    backgroundColor: "#000",
  },
  columnData: {
    display: "flex",
    flexDirection: "column",
    fontSize: 12,
    padding: "0 2%",
    width: "100%",
  },
  tableSection: {
    marginTop: "6%",
  },

  RowDatainSubSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // width:"100%"
  },
  title: {
    fontSize: 14,
    fontWeight: "500",
    padding: "2%",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: { width: "100%", borderCollapse: "collapse" },
  row: { flexDirection: "row", display: "flex" },
  cell: { padding: 8, border: "1px solid #000", width: "25%", fontSize: 10 },
  headerCell: { backgroundColor: "#eee" },
  // row: { flexDirection: 'row',display:"flex" },
  cellData21: {
    border: "1px solid #000",
    width: "50%",
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cellData22: {
    padding: 8,
    width: "50%",
    fontSize: 10,
    border: "1px solid #000",
  },
  cellDataNotes: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
});

const TestingDocumnet = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  const [data, setData] = useState();

  const fetchData = async () => {
    try {
      const resp = await axios.get(
        `${baseURL}/TestingAndComision/GetForWeb/${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = resp?.data;
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Document pageLayout="singlePage">
      <Page size="A4" style={styles.page} break={true}>
        <View style={styles.rowData}>
          <Text style={{ color: "#e86123" }} fontSize="0.5rem">
            {" "}
            String inverter installation and commissioning sample
          </Text>
        </View>
        <View style={styles.tableSection}>
          <View style={styles.section}>
            <View style={styles.sectionTitle}>
              <Text style={styles.title}>INSTALLATION DETAILS</Text>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitle}>Address of installation:</Text>
              <Text style={styles.RowDynamicData}>
                {data?.installationAddress?.streetNumber}{" "}
                {data?.installationAddress?.streetName}{" "}
                {data?.installationAddress?.streetType}{" "}
                {data?.installationAddress?.suburb}{" "}
                {data?.installationAddress?.state}{" "}
                {data?.installationAddress?.postCode}{" "}
              </Text>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitle}>
                PV module manufacturer and model number:
              </Text>
              <Text style={styles.RowDynamicData}>
                {data?.panelBrand} {"("}
                {data?.panelModel}
                {")"}
              </Text>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitle}>
                Number of modules in series in a string:
              </Text>
              <Text style={styles.RowDataTitle}>
                {`MPP2 - 9 
              MPP2 - 7M`}
              </Text>
              <Text style={styles.RowDataTitle}>
                Number of strings in parallel in PV array:
              </Text>
              <Text style={styles.RowDynamicData}>
                {`MPP2 - 9 
              MPP2 - 7M`}
              </Text>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitle}>
                Inverter manufacturer and model number:
              </Text>
              <Text style={styles.RowDataTitle}>
                {data?.inverterBrand} {"("}
                {data?.inverterModel}
                {")"}
              </Text>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitle}>Number of inverters:</Text>
              <Text style={styles.RowDataTitle}>{data?.inverterQuantity}</Text>
              <Text style={styles.RowDataTitle}>Number of MPPTs:</Text>
              <Text style={styles.RowDynamicData}>
                {data?.TestingAndComision?.mppt?.length}
              </Text>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.sectionTitle}>
              <Text style={styles.title}>PV ARRAY</Text>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`PV array tilt `}
              </Text>
              <Text style={styles.RowDataTitle}>{`.....10........`}</Text>

              <Text style={styles.RowDataTitleWithoutBorder}>
                PV array orientation:
              </Text>
              <Text style={styles.RowDynamicData}>{`.....E/W......`}</Text>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Array frame is certified to AS1170.2:2011 for installation location `}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.arrayFrameCertified
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>

              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Array frame is installed to manufacturer’s instructions `}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.pvArrayFrameSecured
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`No galvanically dissimilar metals are in contact with the array frames or supports`}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.noGaivanicallyDissimilar
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>

              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Roof penetrations are suitably sealed and weatherproofed `}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.roofpenetrations
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`PV wiring losses are less than 3% at the maximum current output of the array `}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.pvWiringLosses
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>

              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Where PV array comprises multiple strings- string protection has been provided if required. `}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.wherePVArrayComprises
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Wiring is protected from mechanical damage and is appropriately supported`}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision
                      ?.wiringProtectedFromMechanicalDamage
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
              <View style={styles.columnData}>
                <Text style={styles.RowDataTitleWithoutBorder}>
                  {`Weatherproof PV array isolator mounted adjacent to the array `}
                </Text>
                <Text
                  style={styles.RowDynamicData}
                >{`(Brand / Model: …………………… `}</Text>
                <Text
                  style={styles.RowDynamicData}
                >{`Rating:……………… VDC, ……………ADC)`}</Text>
              </View>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.arrayFrameCertified
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.sectionTitle}>
              <Text style={styles.title}>LV DC and AC INSTALLATION</Text>
            </View>

            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`All low voltage wiring has been installed by a
licensed electrical tradesperson `}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.allLowVoltage
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>

              <Text style={styles.RowDataTitleWithoutBorder}>
                {`All wiring has been tested and approved by qualified
electrical tradesperson `}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.allWiringTested
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.tableSection}>
          <View style={styles.section}>
            <View style={styles.sectionTitle}>
              <Text style={styles.title}>INVERTER</Text>
            </View>
            <View style={styles.subSectionRow}>
              <View style={styles.columnData}>
                <Text style={styles.RowDataTitleWithoutBorder}>
                  {`PV array isolator mounted adjacent to the inverter`}
                </Text>
                <Text
                  style={styles.RowDynamicData}
                >{`(Brand / Model: …………………… `}</Text>
                <Text
                  style={styles.RowDynamicData}
                >{`Rating:……………… VDC, ……………ADC)`}</Text>
              </View>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.arrayFrameCertified
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Isolator is mounted on output of the inverter (where required)`}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.acIsolatorMounted
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>

            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Lockable AC circuit breaker mounted within the
            switchboard to act as the inverter main switch for the
            PV/inverter system (Rating ……….. A )`}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.acCircuitBreaker
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Inverter is installed as per manufacturer’s specification`}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.inverterInstalled
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Inverter ceases supplying power within two seconds of a
loss of AC mains`}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.inverterSuppyingPower
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Inverter does not resume supplying power until mains
have been present for more than 60 seconds. The
inverter shall not go straight to full power. It is required to
ramp up from 0%-100% over a 6 minute period.`}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.inverterNotResumeSuppyingPower
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.tableSection}>
          <View style={styles.section}>
            <View style={styles.sectionTitle}>
              <Text style={styles.title}>CONTINUITY CHECK</Text>
            </View>
            <View style={styles.subSectionRow}>
              <View style={styles.RowDatainSubSection}>
                <Text style={styles.RowDataTitleWithoutBorder}>
                  {`Circuit checked (record a description of the circuit checked in this column) `}
                </Text>
              </View>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Continuity of all string, sub-array and array cables`}
              </Text>
              <View style={styles.checkboxContainer}>
                <View
                  style={
                    data?.TestingAndComision?.arrayFrameCertified
                      ? styles.checkboxColored
                      : styles.checkbox
                  }
                >
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
            <View style={styles.subSectionRow}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Continuity of all earth connections (including module frame)`}
              </Text>
              <View style={styles.checkboxContainer}>
                <View style={styles.checkboxColored}>
                  <View style={{ flex: 1 }} />
                </View>
              </View>
            </View>
            <View style={styles.columnData}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`SYSTEM CHECK`}
              </Text>
              <Text>{`WARNING:`}</Text>
            </View>
            <View style={styles.columnData}>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`Inverter ceases supplying power within two seconds of a
loss of AC mains`}
              </Text>
              <Text style={styles.RowDataTitleWithoutBorder}>
                {`IF POLARITY IS REVERSED AT THE INVERTER DAMAGE MAY OCCUR TO THE INVERTER.`}
              </Text>
            </View>

            {/* Table */}
            <View style={styles.table}>
              {/* Table Header */}
              <View style={styles.row}>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}>Polarity</Text>
                <Text style={styles.cell}>Voltage</Text>
                <Text style={styles.cell}>Short Circuit</Text>
                <Text style={styles.cell}>Operating Current</Text>
              </View>
              {/* Table Rows */}
              {data?.TestingAndComision?.mppt?.map((item, index) => (
                <View key={index} style={styles.row}>
                  <Text style={styles.cell}>
                    String {index + 1} MPPT {index + 1}
                  </Text>
                  <Text style={styles.cell}></Text>
                  <Text style={styles.cell}>{item.testedVoltage}</Text>
                  <Text style={styles.cell}>{item.TestedSCCurrent}</Text>
                  <Text style={styles.cell}>{item.operatingCurrent}</Text>
                </View>
              ))}
              <View style={styles.row}>
                <Text style={styles.cell}>Sub-arrays where required</Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.cell}>
                  PV array at PV array switch-disconnector
                </Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.cell}>
                  Calculated PV array maximum voltage
                </Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.cell}>
                  Irradiance at time of recording the current
                </Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
              </View>
            </View>

            <View style={styles.RowDataTitleWithoutBorder}>
              <Text>
                {`Inverter ceases supplying power within two seconds of a
              oss of AC mains`}
              </Text>
            </View>
            <View style={styles.table}>
              <View style={styles.row}>
                <Text style={styles.cellData21}>
                  <Text>{`Array positive to earth`}</Text>
                  <Text>{` MPP1 & MPP2`}</Text>
                </Text>
                <Text style={styles.cellData22}>{`MΩ > 1`}</Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.row}>
                <Text style={styles.cellData21}>
                  <Text>{`Array negative to earth`}</Text>
                  <Text>{` MPP1 & MPP2`}</Text>
                </Text>
                <Text style={styles.cellData22}>{`MΩ > 1`}</Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.cellDataNotes}>
                <Text style={styles.RowDataTitleWithoutBorder}>{`Notes:`}</Text>
                <Text style={styles.RowDataTitleWithoutBorder}>{` `}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.cellData21}>
                {`CEC Accredited installer’s name:`}
              </Text>
              <Text style={styles.cellData22}>{data?.installerName}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cellData21}>
                {`CEC Accreditation number:`}
              </Text>
              <Text style={styles.cellData22}>{data?.installerCECID}</Text>
            </View>
            <View style={{ height: "auto" }}>
              <Text style={styles.RowDataTitleWithoutBorder}>{``}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cellData21}>{`Signed:`}</Text>
              <Image src={data?.TestingAndComision?.installerSignURL}></Image>
              <Text style={styles.cellData22}>
                {`Date: `}
                {data?.TestingAndComision?.date
                  ? moment(data?.TestingAndComision?.date).format("DD/MM/YYYY")
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cellData21}>
                {`CEC Accredited Designer’s name:`}
              </Text>
              <Text style={styles.cellData22}>{data?.designerName}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cellData21}>
                {`Licensed electrician’s name:(where applicable, e.g. LV work)`}
              </Text>
              <Text style={styles.cellData22}>{data?.electricianName}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cellData21}>
                {`Electrician’s license number:`}
              </Text>
              <Text style={styles.cellData22}>
                {data?.electricianLicenseNo}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cellData21}>{`Signed:`}</Text>
              <Image src={data?.TestingAndComision?.electricianSignURL}></Image>
              <Text style={styles.cellData22}>
                {`Date: `}
                {data?.TestingAndComision?.date
                  ? moment(data?.TestingAndComision?.date).format("DD/MM/YYYY")
                  : "N/A"}
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const DownloadTestingForm = () => {
  // Function to open the browser's print dialog
  const openPrintDialog = () => {
    window.print();
  };
  return (
    <div>
      {<TestingDocumnet />}
      <div style={{ padding: "4%" }}>
        <PDFDownloadLink
          document={<TestingDocumnet />}
          fileName="TestingandCommissioningForm.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <button>Loading...</button>
            ) : (
              <button onClick={openPrintDialog}>Download</button>
            )
          }
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default DownloadTestingForm;
