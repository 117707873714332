import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Aside from "../FixedBar/Aside";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const ApprovedCER = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  //Storing Data from API
  const [actionsData, setActionsData] = useState({});

  //Get On Site Details Starts
  useEffect(() => {
    const fetchActions = async () => {
      try {
        const resp = await axios.get(`${baseURL}/action/${jobId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp?.data;
        setActionsData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchActions();
  }, []);

  //Function to Convert key to Text which can be shown
  const actionsArray = [
    { name: "Panel Photos", key: "panelPhotos" },
    { name: "Inverter Photo", key: "inverterPhotos" },
    { name: "Switchboard Photo", key: "switchboardPhotos" },
    { name: "House Photo", key: "frontPropertyView" },
    { name: "Installer Signature", key: "installerSignature" },
    { name: "Designer Signature", key: "designerSignature" },
    { name: "System Owner Signature", key: "customerSignature" },
  ];

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <Aside />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div className="container">
              <div className="row">
                <div className="main-createjob">
                  <div className="text-blue">Important Pending Actions</div>
                  <span className="text-jobs">
                    Dashboard / Important Pending Actions
                  </span>
                  <div className="right-part-job"></div>
                </div>
              </div>

              <div className="row">
                <div className="notification-main">
                  <div className="notifybell-img">
                    <img
                      src={`${image}/Assets/ImportantActions/Notification.svg`}
                      alt=""
                    />
                  </div>
                </div>
                <div className="notify-text">
                  <div className="notifyround-blue">10</div>
                </div>
                <div className="person-right">
                  <div className="notifyperson-img">
                    <img
                      src={`${image}/Assets/ImportantActions/onsite-person.svg`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div style={{ display: "flex" }}>
              <div className="important-main">
                <b>Important Actions</b>
              </div>
            </div>
            <div className="team-section">
              <div className="team-main-bg">
                <table>
                  <tr
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "220px",
                    }}
                  >
                    <td className="team-main" style={{}}>
                      <b>Process</b>
                    </td>
                    <td className="team-role">
                      <b>Status</b>
                    </td>

                    <td className="team-actions">
                      <b>Actions</b>
                    </td>
                  </tr>
                </table>
                {Object.keys(actionsData).length > 0 ? (
                  <div className="team-details">
                    <table>
                      {actionsArray.map((action) => (
                        <tr
                          key={action.key}
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            gap: "0",
                          }}
                        >
                          <td
                            className="team-data-main"
                            style={{ width: "0" }}
                          ></td>
                          <td className="process-text">{action.name}</td>
                          <td className="process-status">
                            {actionsData[action.key] ? "Done" : "Pending"}
                          </td>
                          <td className="process-actions">
                            <button
                              className="add-action"
                              onClick={() => {
                                alert("This feature will be functional later.");
                              }}
                            >
                              <img
                                src={`${image}/Assets/ImportantActions/edit-btn.svg`}
                                alt=""
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px 100px 0px 20px",
                    }}
                  >
                    <p>No Important Pending Actions</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedCER;
