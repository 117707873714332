import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const JobPack = () => {
  const location = useLocation();
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  const [loading, setLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const fetchDataAndGeneratePdf = async () => {
      //   const jobId = new URLSearchParams(location.search).get("jobId");
      if (!jobId) {
        console.error("Job ID not found in URL");
        setLoading(false);
        return;
      }

      try {
        const apiUrl = `${baseURL}/get/photosAndDocuments/${jobId}`;
        const apiResponse = await axios.get(apiUrl, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!apiResponse.data || Object.keys(apiResponse.data).length === 0) {
          console.error("No data received from API");
          setLoading(false);
          return;
        }

        const files = apiResponse.data;
        const doc = new jsPDF();

        let y = 10;
        for (const urls of Object.values(files)) {
          for (let i = 0; i < urls.length; i++) {
            const url = urls[i].url;
            console.log("Fetching image from URL:", url);

            const imgResponse = await axios.get(url, {
              responseType: "arraybuffer",
            });
            if (!imgResponse.data) {
              console.error("Failed to fetch image:", url);
              continue;
            }

            const imgData =
              "data:image/jpeg;base64," +
              btoa(
                new Uint8Array(imgResponse.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
            doc.addImage(imgData, "JPEG", 10, y, 180, 160);
            y += 170;
            if (y > 250) {
              doc.addPage();
              y = 10;
            }
          }
        }

        const pdfBlob = doc.output("blob");
        if (!pdfBlob) {
          console.error("Failed to create PDF blob");
          setLoading(false);
          return;
        }

        const pdfUrl = URL.createObjectURL(pdfBlob);
        if (!pdfUrl) {
          console.error("Failed to create PDF blob URL");
          setLoading(false);
          return;
        }

        setPdfUrl(pdfUrl);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchDataAndGeneratePdf();
  }, [location.search]);

  const handlePrint = () => {
    if (pdfUrl) {
      const newWindow = window.open(pdfUrl, "_blank");
      newWindow.onload = () => newWindow.print();
    } else {
      console.error("PDF URL is null");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <button onClick={handlePrint}>Print</button>
      {pdfUrl ? (
        <iframe
          src={`${pdfUrl}#view=FitH`}
          width="100%"
          height="100%"
          style={{ border: "none", position: "absolute", top: 0, left: 0 }}
        />
      ) : (
        <div>Failed to load PDF</div>
      )}
    </div>
  );
};

export default JobPack;
