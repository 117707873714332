import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const InverterSerial = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  //Storing Data from API
  const [inverterData, setInverterData] = useState([]);

  //Get On Site Details Starts
  useEffect(() => {
    const fetchOnSiteData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/onSite`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp?.data?.data;
        const flattenedData = data.flat();
        const result = flattenedData.filter((item) => item.jobId === jobId);
        const inverters = result[0]?.inverters;
        setInverterData(inverters);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchOnSiteData();
  }, []);
  //Get On Site Details Ends

  const [addPanelPopUp, setAddInverterSerialPopUp] = useState(false);

  return (
    <>
      <div className="row">
        <div className="checkin-panel">
          <h3>Inverter Serials</h3>
        </div>

        <div className="row">
          <div style={{ display: "flex" }}>
            <div className="panelserial-bg">
              <b>Inverter Serials</b>
            </div>
            <div class="paneladd-btn">
              <button
                className="btn-invite"
                onClick={() => {
                  setAddInverterSerialPopUp(false);
                  alert("This feature will be functional later.");
                }}
              >
                Add
              </button>
            </div>
          </div>
          <div className="serial-section">
            <div className="serial-main-bg">
              <table>
                <tr className="panelserial-table">
                  <td className="inverter-serial-main">
                    <b>Serial</b>
                  </td>
                  <td className="inverter-serial-date">
                    <b>On site Scanning</b>
                  </td>

                  <td className="inverter-serial-actions">
                    <b>Action</b>
                  </td>
                </tr>
              </table>
              <table>
                {inverterData.map((inverter) => (
                  <tr className="panelserial-table" key={inverter._id}>
                    <td className="inverterserial-maindata">
                      {inverter?.serialNumber}
                    </td>
                    <td className="inverterserial-maindata">
                      {new Date(inverter?.createdAt).getFullYear()}-
                      {String(
                        new Date(inverter?.createdAt).getMonth() + 1
                      ).padStart(2, "0")}
                      -
                      {String(new Date(inverter?.createdAt).getDate()).padStart(
                        2,
                        "0"
                      )}
                    </td>

                    <td className="serial-maindatadelete">
                      <img
                        src={`${image}/Assets/OnSiteData/Inverter/onsite-action-button.svg`}
                        alt=""
                        onClick={() => {
                          setAddInverterSerialPopUp(false);
                          alert("This feature will be functional later.");
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </table>
              <div className="panel-data" style={{ display: "none" }}>
                <p>No Inverter Serial Found</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {addPanelPopUp && (
          <div className="invite-model-backdrop">
            <div className="delete-model-content inverterserial-popup-height">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="text-panel">Inverter Serials</h2>
                <button
                  class="onsitedata-model-close-btn"
                  onClick={() => setAddInverterSerialPopUp(false)}
                >
                  ✕
                </button>
              </div>
              <span className="panel-bottom-border"></span>

              <div className="row">
                <form method="post">
                  <input
                    className="onsitedata-input"
                    type="text"
                    placeholder="Serial"
                    required
                  />
                  <input
                    type="date"
                    placeholder="On site Scanning"
                    class="input-field inverterpopup-serial"
                  />
                </form>
              </div>
              <span className="panel-bottom-border"></span>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button className="onsitebtn-cancel">Cancel</button>
                <button className="onsitebtn-save">Save</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InverterSerial;
