import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Aside from "../FixedBar/Aside";
import { useNavigate } from "react-router-dom";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import HeaderComponent from "./CommonComponents/HeaderComponent";
import NotesComponent from "./NotesComponent";
import "./Dashboard.css";
import { Download, EmailRounded } from "@mui/icons-material";
import { Badge } from "@mui/material";
import moment from "moment";
import Chat from "./CommonComponents/Chat/Chat";
import { BeatLoader, FadeLoader } from "react-spinners";
import JSZip from "jszip";
import jsPDF from "jspdf";
import CreatePVJob from "./CreatePVJob";


import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';



const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const Dashboard = () => {
  const navigate = useNavigate();

  //for pagination 
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  //const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  //

  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");


  const userType = localStorage.getItem("userType");
 
  const [selectedJobTab, setSelectedJobTab] = useState("Solar PV Jobs");
  const [jobType, setJobType] = useState(false);
  const [showSPVButtons, setShowSPVButtons] = useState(false);
  const [jobData, setJobData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [jobDataForCSV, setJobDataForCSV] = useState(null);
  const [installerData, setInstallerData] = useState();
  const [designerData, setDesignerData] = useState();
  const [electricianData, setElectricianData] = useState();
  const [retailerData, setRetailerData] = useState();
  const [panelSerialData, setPanelSerialData] = useState();
  const [inverterSerialData, setInverterSerialData] = useState();
  const [loading, setLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState(
    userType === "Trader" ? 10 : 3
  );

  //Form Data starts
  const [formdata, setFormdata] = useState({
    installationAddress: {
      addressType: "",
      unitType: "",
      unitNumber: null,
      streetNumber: null,
      streetName: "",
      streetType: "",
      suburb: "",
      state: "",
      postCode: null,
    },

    installationDetails: {
      installationType: "",
      newPanelLocation: "",
      kilowatt: null,
      remarks: "",
      CECAccreditationSystem: true,
      territoryGovermentApproval: true,
      electricalSafetyDoc: true,
      AusNZStandardStatement: true,
    },

    postalAddress: {
      POBox: {
        POBoxNo: null,
        postalCode: null,
        postalArea: "",
      },
      POAddress: {
        addressType: "",
        unitType: "",
        unitNumber: null,
        streetNumber: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postCode: null,
      },
    },

    installingFor: "",
    systemOwnerType: "",

    ownerDetails: {
      firstName: "",
      lastName: "",
      companyName: "",
      mobileNum: null,
      phoneNumber: null,
      emailId: "",
      ABN: null,
      isGST: false,
      gstNo: "",
    },

    coOwnerDetail: {
      firstName: "",
      lastName: "",
      mobileNum: null,
      phoneNumber: null,
      emailId: "",
    },

    installationDate: {
      installingDate: "",
      STCDeemingPeriod: null,
      mountingType: "",
    },

    gridConnectionDetail: {
      connectionType: "",
      systemType: "",
      nmi: "",
      gridAppReferenceNo: "",
      meterNum: null,
      distributor: "",
    },

    panelDetail: {
      panelInfo: {
        panelBrand: "",
        panelModel: "",
        panelPower: null,
        Quantity: null,
        wattage: null,
        stc: null,
        financialPayment: null,
        isAssignPanelJob: false,
      },
    },

    inverterDetail: {
      inverterInfo: {
        inverterBrand: "",
        inverterSeries: "",
        inverterModel: "",
        inverterSupplier: "",
        Quantity: null,
      },
    },

    batteryDetail: {
      batteryInfo: {
        manufacturer: "",
        batteryBrand: "",
        batterySeries: "",
        batteryModel: "",
        Quantity: null,
        batterySystemAggregate: false,
        defaultManufacturerBatteryStorage: false,
      },
    },

    warrantyDetail: {
      warrantyYear: null,
      warrantyDescription: "",
    },

    instructionForInstallation: "",

    additionalAttributes: {
      CRMID: "",
      currentEnergyRetailer: "",
      WillThereBeAnyOptimisersInstalled: false,
    },

    siteAttributes: {
      roof: "",
      storey: "",
      panelType: "",
      phase: "",
    },

    design: "",
    customInvoice: "",
    uploadElectricityBill: "",
    STCForm: "",
    STCInvoice: "",
    testingCertificate: "",
    noticeOfCompletion: "",

    installers: [],
    installerName: "",
    designers: [],
    designerName: "",
    electricians: [],
    electricianName: "",
  });
  //Form Data ends

  // Installation Team View Start

  const [viewActionsPanel, setViewActionsPanel] = useState(false);
  // Team View System Info Starts
  const viewActions = (jobId) => {
    localStorage.setItem("jobId", jobId);
    setViewActionsPanel(true);
  };
  // Installation Team View Ends

  //Submit Job PopUp Starts
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  //Submit Job PopUp Starts

  //Delete Job PopUp Starts

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(`${baseURL}/job/deleteJob/${jobId}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        alert("Job Deleted Successfully");
        localStorage.removeItem("jobId");
        window.location.reload();
      } else {
        console.log("Error: " + (response.data || response.statusText));
      }
    } catch (error) {
      console.error("Error in update request:", error);
      console.log("Error status:", error.response?.status);
    }
    setShowDeleteConfirmation(false);
  };

  //Delete Job PopUp Ends



  //API Call to get Job Details Starts
  const [jobDataShow, setJobDataShow] = useState([]);
  const [jobLoading, setJobLoading] = useState(false);
  const [statusCounts, setStatusCounts] = useState({});
  const ITEMS_PER_PAGE = 20;

  let [totalC, setTotalC] = useState(0);

  
  // Mapping of status labels to status codes
  const STATUS_CODE_MAP = {
    "default": 0,
    "assigned": 1,
    "accepted": 2,
    "in-progress": 3,
    "completed": 4,
    "rejected": 5,
    "job-finished": 6,
    "parked": 7,
    "site-inspection": 8,
    "cancelled": 9,
    "submitted": 10,
    "Waiting For Approval": 11,
    "Resubmitted after failure": 12,
    "Failed": 13,
    "Approved": 14
  };
  
  const updateTotalPages = (counts) => {
    let totalCount;
    
    if (activeFilter === null) {
      totalCount = Object.values(counts).reduce((sum, count) => sum + count, 0);
    } else if (activeFilter === 3) {
      totalCount = (counts['in-progress'] || 0) + (counts['job-finished'] || 0) +(counts['default']);
    } else if (activeFilter === 17) { 
      totalCount = (counts['Waiting For Approval'] || 0) + (counts['Failed'] || 0) + (counts['Approved'] || 0);
    } else {
      const statusLabel = Object.keys(STATUS_CODE_MAP).find(key => STATUS_CODE_MAP[key] === activeFilter);
      totalCount = counts[statusLabel] || 0;
    }

    
   // setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
  };

  
  const test = async (page) => {
    setJobLoading(true);
    let apiUrl = "";
    if (userType === "Trader") {
      apiUrl = `${baseURL}/traders/jobs`;
    } else {
      apiUrl = `${baseURL}/job/all`;
    }
    try {
      const resp = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page,
          limit: ITEMS_PER_PAGE
        }
       
      });
      setJobLoading(false);

      const data = resp.data.jobs;
      const pvData =  data.filter((item) => item?.jobType === "PV");
        // userType === "Trader"
        //   ? data?.jobs.filter((job) => job?.jobType === "PV")
        //   : data.filter((item) => item?.jobType === "PV");
 
         

      setJobData(data);
      setJobDataShow(pvData);
      setJobLoading(false);
      setStatusCounts(resp.data.statusCount);


      updateTotalPages(resp.data.statusCount);
      setData(resp.data.items);

      // let totalP = Math.ceil(resp.data.totalJobs / 5)
      // setTotalPages(totalP);
      //   console.log("Total P  "+totalP)

     } catch (err) {
      console.log("Error:", err);
      setJobLoading(false);
    }
  };
  
  useEffect(() => {
    test(currentPage);
  }, [currentPage]);

  useEffect(() => {
    updateTotalPages(statusCounts);
  }, [activeFilter, statusCounts]);
  
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  


  const handleEditJob = (jobId) => {
    localStorage.setItem("jobId", jobId);
    const editJobWindow = window.open("/createPVJob", "_blank");

    const checkWindowClosed = setInterval(() => {
      if (editJobWindow.closed) {
        clearInterval(checkWindowClosed);
        setRefresh((prev) => !prev);
      }
    }, 500);
  };

  //Job Status Change API Call Starts
  const handleButtonClick = async (status) => {
    try {
      const jobId = localStorage.getItem("jobId");
      const requestData = {
        newStatus: status,
      };
      const response = await axios.patch(
        `${baseURL}/users/jobschangeStatus/${jobId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Job Status Updated Successfully");
        localStorage.removeItem("jobId");
        window.location.reload();
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };
  //Job Status Change API Call Ends
  //API Call to get Job Details Ends

  // Filter Jobs Logic Starts
  const [searchQuery, setSearchQuery] = useState("");
  const [showQuickFilters, setShowQuickFilters] = useState(false);
 
  const filteredJobList = useMemo(() => {
    let filteredList = jobDataShow;
    if (searchQuery) {
      filteredList = filteredList.filter((jobDataArray) =>
        [
          jobDataArray?.ownerDetails?.firstName,
          jobDataArray?.ownerDetails?.lastName,
          jobDataArray?.ownerDetails?.companyName,
          jobDataArray?.additionalAttributes?.CRMID,
          jobDataArray?.jobIdentifyId,
        ]
          .join(" ")
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }
    // Filter by job status
    if (activeFilter !== null && filteredList) {
      filteredList = filteredList.filter(
        (jobDataArray) =>
          (activeFilter === 8 && jobDataArray?.siteInspection === true) ||
          (activeFilter === 3 &&
            (jobDataArray?.statusCode === 3 ||
              jobDataArray?.statusCode === 6)) ||
          (activeFilter === 17 &&
            (jobDataArray?.statusCode === 11 ||
              jobDataArray?.statusCode === 13 ||
              jobDataArray?.statusCode === 14)) ||
          (activeFilter !== 8 &&
            activeFilter !== 3 &&
            activeFilter !== 17 &&
            jobDataArray?.statusCode === activeFilter)

      );

      // //setTotalPages(filteredList.length);
      // setTotalC(filteredList.length)

      setTotalC(filteredList.length);
      setTotalPages(Math.ceil(filteredList.length / ITEMS_PER_PAGE));

      return filteredList;

      
    }
    
  }, [jobDataShow, searchQuery, activeFilter, totalC]);

  

  const clearFilters = () => {
    setShowQuickFilters(false);
    setActiveFilter(userType === "Trader" ? 10 : 3);
    setSearchQuery("");
  };
  const reverseFilteredJobList = [...filteredJobList];

  // Filter Jobs Logic Ends
  

  // ToolTips Starts
  const showTooltip = (e) => {
    const tooltip = e.currentTarget.querySelector(".tooltip");
    if (tooltip) {
      tooltip.classList.add("show-tooltip");
    }
  };

  const hideTooltip = (e) => {
    const tooltip = e.currentTarget.querySelector(".tooltip");
    if (tooltip) {
      tooltip.classList.remove("show-tooltip");
    }
  };

  const toggleTooltip = (e) => {
    const tooltip = e.currentTarget.querySelector(".tooltip");
    if (tooltip) {
      tooltip.classList.toggle("show-tooltip");
    }
  };
  // ToolTips Ends

  // Create Job by uploading file Starts
  const [file, setFile] = useState(null);
  const handleUploadButtonClick = () => {
    if (userType !== "Trader") {
      document.getElementById("fileInput").click();
    }
  };
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile.type !== "text/csv") {
      alert("Please select a CSV file.");
      return;
    }

    try {
      const csvData = await readCSVFile(selectedFile);
      await createJobsFromCSV(csvData);
    } catch (error) {
      console.error("Error creating jobs:", error);
      alert(error?.message || " Error creating jobs from CSV data");
    }
  };
  const readCSVFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result;
        resolve(csvData);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };
  const createJobsFromCSV = async (csvData) => {
    const rows = csvData.split("\n");
    const headers = rows
      .shift()
      .split(",")
      .map((header) => header.trim());

    for (const row of rows) {
      const rowData = row.split(",").map((data) => data.trim());
      if (rowData.length === 0 || rowData.every((cell) => cell === ""))
        continue;

      const formData = prepareFormData(rowData, headers);
      try {
        await createJob(formData);
      } catch (error) {
        console.error("Error creating job from row:", row, error);
        throw new Error("Error creating jobs from CSV data");
      }
    }
  };
  const prepareFormData = (rowData, headers) => {
    const formData = {};
    headers.forEach((header, index) => {
      const keys = header.split("_");
      let ref = formData;

      keys.forEach((key, idx) => {
        if (idx === keys.length - 1) {
          ref[key] = rowData[index];
        } else {
          if (!ref[key]) ref[key] = {};
          ref = ref[key];
        }
      });
    });
    return formData;
  };
  const createJob = async (formData) => {
    setLoading(true);
    try {
      const formDataObject = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataObject.append(key, JSON.stringify(formData[key]));
      });

      const response = await axios.post(
        `${baseURL}/job/create-job`,
        formDataObject,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Jobs Created Successfully");
      window.location.reload();
      setLoading(false);
    } catch (error) {
      console.error("Error creating job:", error);
      setLoading(false);
      throw error;
    }
  };
  // Create Job by uploading file Ends

  //Toogle button functions for Generate SPV button click
  const handleChooseJob = () => {
    window.open("/dashboard-SWH", "_blank");
    return;
  };

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleKeyChange = async (jobId) => {
    setButtonLoading(true);
    try {
      const formData = new FormData();
      formData.append("isSPV", true);

      const response = await axios.patch(
        `${baseURL}/job/update/${jobId}`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setShowSPVButtons(true);
        setTimeout(() => {
          navigate("/dashboard");
        }, 800);
        setButtonLoading(false);
        test();
      } else {
        console.error("Login failed. Unexpected status code:", response.status);
        setButtonLoading(false);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
      setButtonLoading(false);
    }
  };
  const handleGenerateButton = (data) => {
    if (data?.isSPV) {
      alert("Feature coming soon");
    } else {
      if (data.panelDetail.isAssignPanelJob === true) {
        alert("This is a Non-SPV Job.\nThis feature is only for SPV Job");
      } else {
        setJobType(true);
        handleKeyChange(data?._id);
      }
    }
  };
  const handleDownloadButtons = (field, id) => {
    localStorage.setItem("jobId", id);
    if (field === "CustCerti") {
      window.open("/dashboard/customer-certificate", "_blank");
    }
  };
  // const convertToCSV = (result, id) => {
  //   const csvRows = [];
  //   const headers = Object.keys(result);
  //   csvRows.push(headers.join(","));
  //   const values = headers.map((header) =>
  //     JSON.stringify(result[header], replaceNullWithEmptyString)
  //   );

  //   csvRows.push(values.join(","));
  //   return csvRows.join("\n");
  // };
  const replaceNullWithEmptyString = (key, value) =>
    value === null ? "" : value;
  // Convert to xml function

  const objectToXml = (headers, values) => {
    let xml = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n';
    xml += `<RecRegistryApplicationRequest>\n`;
    xml += `  <Registration id="Installation">\n`;
    xml += `    <InstallationProductVerification id="verifiedproducts">\n`;

    const addXmlNode = (key, value, indentLevel) => {
      const indent = " ".repeat(indentLevel * 2);
      if (typeof value === "object" && !Array.isArray(value)) {
        xml += `${indent}<${key}>\n`;
        Object.entries(value).forEach(([subKey, subValue]) => {
          addXmlNode(subKey, subValue, indentLevel + 1);
        });
        xml += `${indent}</${key}>\n`;
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          addXmlNode(key, item, indentLevel);
        });
      } else {
        xml += `${indent}<${key}>${XMLSafeValue(value)}</${key}>\n`;
      }
    };

    headers.forEach((header) => {
      if (values[0][header] !== undefined) {
        addXmlNode(header, values[0][header], 4);
      }
    });

    xml += `    </InstallationProductVerification>\n`;
    xml += `  </Registration>\n`;
    xml += `</RecRegistryApplicationRequest>\n`;

    return xml;
  };

  function convertValueToXML(header, value) {
    let xml = "";
    // values.forEach(value => {
    xml += `  <${header}>${XMLSafeValue(value)}</${header}>\n`;
    // });
    return xml;
  }
  const XMLSafeValue = (value) => {
    if (typeof value === "string") {
      return value.replace(/[<>&'"]/g, (c) => {
        switch (c) {
          case "<":
            return "&lt;";
          case ">":
            return "&gt;";
          case "&":
            return "&amp;";
          case "'":
            return "&apos;";
          case '"':
            return "&quot;";
          default:
            return c;
        }
      });
    }
    return value;
  };


  // const fetchJobData = async () => {
  //   setJobLoading(true);
  //   try {
  //     const resp = await axios.get(`${baseURL}/job/all`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
      
  // console.log("look here again")
  // console.log(resp.data)
  //     setJobLoading(false);
  //     const flattenedData = resp.data.jobs.flat();
  //     const result = flattenedData.filter((item) => item._id === jobId);
  //     setJobDataForCSV(result[0]);
  //   } catch (error) {
  //     setJobLoading(false);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  
  
  // const fetchSerialData = async () => {
  //   if (userType === "Trader") {
  //   try {
  //     const resp = await axios.get(
  //       `${baseURL}/onsitePanel/serialNumber/${jobId}`
  //     );

  //     const data = resp?.data;
  //     const panelSerial = data?.panelSerialNumbers.join(" ");
  //     const inverterSerial = data?.inverterSerialNumbers.join(" ");
  //     setPanelSerialData(panelSerial);
  //     setInverterSerialData(inverterSerial);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  //   }
  // }
  
  const fetchTeamData = async () => {
    try {
      const resp = await axios.get(`${baseURL}/installer/details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp.data.installerData;
      const installer = data.filter(
        (item) => item._id === jobDataForCSV?.installers[0]
      );
      const designer = data.filter(
        (item) => item._id === jobDataForCSV?.designers[0]
      );
      const electrician = data.filter(
        (item) => item._id === jobDataForCSV?.electricians[0]
      );
      setInstallerData(installer[0]);
      setDesignerData(designer[0]);
      setElectricianData(electrician[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRetailerData = async () => {
    try {
      const resp = await axios.get(`${baseURL}/traders/getRetailers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp.data.retailers;
      const retailer = data.filter(
        (item) => item._id === jobDataForCSV?.userId
      );
      setRetailerData(retailer[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   //fetchJobData();
  //   //fetchSerialData();
  // }, []);

  useEffect(() => {
    fetchTeamData();
    if (userType === "Trader") {
      fetchRetailerData();
    }
  }, [jobDataForCSV]);

  // DATA FOR CSV FILE
  const headers = [
    "Type of system",
    "Installation date",
    "Was a solar retailer involved in the procurement and installation of the system?",
    "System/panel brand",
    "System/panel model",
    "Inverter manufacturer",
    "Inverter series",
    "Inverter model number",
    "Type of connection to the electricity grid",
    "System mounting type",
    "Is a site specific audit report available?",
    "Are you installing a complete unit (adding capacity to an existing system is not considered a complete unit)?",
    "If this system is additional capacity to an existing system please provide detailed information on the position of the new panels and inverter (if applicable). System upgrades without a note explaining new panel locations will be failed by the Clean Energy Regulator",
    "For what period would you like to create RECs",
    "What is the rated power output (in kW) of your small generation unit",
    "Number of panels",
    "Number of inverters",
    "Inverter serial number(s)",
    "Do you wish to use the default resource availability figure?",
    "What is your resource availability (hours per annum) for your system?",
    "Equipment model serial number(s)",
    "Are you creating certificates for a system that has previously been failed by the Clean Energy Regulator?",
    "Accreditation code",
    "Explanatory notes for re-creating certificates previously failed",
    "Reference",
    "Certificate tag",
    "Designer CEC accreditation statement",
    "Installer CEC accreditation statement",
    "Design and Installation of Unit Statement",
    "Local, State and Territory government requirements",
    "Electrical safety documentation",
    "Unit componentry statements",
    "On-site attendance written statements",
    "On-site attendance evidence statements",
    "Not grid-connected statement",
    "True, correct and complete statement",
    "Retailer CEC accreditation statement",
    "Retailer unit performance statement",
    "Retailer completion and connection statements",
    "Retailer provision of unit information statements",
    "Retailer Conflicts of interest",
    "Retailer eligibility statement",
    "Retailer True, correct and complete statement",
    "Installation property type",
    "Single or multi-story",
    "Installation property name, boat name or chassis number",
    "Installation unit type",
    "Installation unit number",
    "Installation street number",
    "Installation street name",
    "Installation street type",
    "Installation town/suburb",
    "Installation state",
    "Installation postcode",
    "Installation latitude",
    "Installation longitude",
    "Is there more than one SGU at this address?",
    "If the address entered above does not adequately describe the location of the system please provide further detailed information for the Clean Energy Regulator to locate the system",
    "Additional system information",
    "Owner type",
    "Owner organisation name",
    "Owner First Name",
    "Owner Surname",
    "Owner phone",
    "Owner fax",
    "Owner mobile",
    "Owner email",
    "Owner address type",
    "Owner postal delivery type",
    "Owner postal delivery number",
    "Owner unit type",
    "Owner unit number",
    "Owner street number",
    "Owner street name",
    "Owner street type",
    "Owner town/suburb",
    "Owner state",
    "Owner postcode",
    "Owner country",
    "Installer first name",
    "Installer surname",
    "CEC accredited installer number",
    "Installer phone",
    "Installer fax",
    "Installer mobile",
    "Installer email",
    "Installer address type",
    "Installer postal delivery type",
    "Installer postal delivery number",
    "Installer unit type",
    "Installer unit number",
    "Installer street number",
    "Installer street name",
    "Installer street type",
    "Installer town/suburb",
    "Installer state",
    "Installer postcode",
    "Electrician first name",
    "Electrician surname",
    "Licensed electrician number",
    "Electrician phone",
    "Electrician fax",
    "Electrician mobile",
    "Electrician email",
    "Electrician address type",
    "Electrician postal delivery type",
    "Electrician postal delivery number",
    "Electrician unit type",
    "Electrician unit number",
    "Electrician street number",
    "Electrician street name",
    "Electrician street type",
    "Electrician town/suburb",
    "Electrician state",
    "Electrician postcode",
    "Designer first name",
    "Designer surname",
    "CEC accredited designer number",
    "Designer phone",
    "Designer fax",
    "Designer mobile",
    "Designer email",
    "Designer address type",
    "Designer postal delivery type",
    "Designer postal delivery number",
    "Designer unit type",
    "Designer unit number",
    "Designer street number",
    "Designer street name",
    "Designer street type",
    "Designer town/suburb",
    "Designer state",
    "Designer postcode",
    "Retailer name",
    "Retailer ABN",
    "Retailer representative",
    "Retailer role",
    "National metering identifier (NMI)",
    "Battery storage manufacturer",
    "Battery storage model",
    "Is the battery system part of an aggregated control?",
    "Has the installer changed default manufacturer setting of the battery storage system?",
    "Signed data package",
    "Documents Zip File",
    "Installation Type",
    "Installation Type Additional Information",
  ];

  const totalQuantity = jobDataForCSV?.panelDetail?.panelInfo?.reduce(
    (total, panel) => {
      return total + (panel.Quantity || 0);
    },
    0
  );
  // const values = [
  //   [
  //     jobDataForCSV?.gridConnectionDetail?.systemType,
  //     moment(jobDataForCSV?.installationDate?.installingDate).format(
  //       "DD/MM/YYYY"
  //     ),
  //     jobDataForCSV?.panelDetail?.panelBrand,
  //     jobDataForCSV?.panelDetail?.panelInfo[0]?.panelModel,
  //     jobDataForCSV?.inverterDetail?.inverterBrand,
  //     jobDataForCSV?.inverterDetail?.inverterInfo[0]?.inverterSeries,
  //     jobDataForCSV?.inverterDetail?.inverterInfo[0]?.inverterModel,
  //     jobDataForCSV?.gridConnectionDetail?.connectionType,
  //     jobDataForCSV?.installationDate?.mountingType,
  //     "",
  //     jobDataForCSV?.panelDetail?.panelBrand &&
  //     jobDataForCSV?.inverterDetail?.inverterBrand
  //       ? "Yes"
  //       : "No",
  //     jobDataForCSV?.installationDetails?.installingType === "Additional"
  //       ? "Yes"
  //       : "No",
  //     jobDataForCSV?.installationDate?.STCDeemingPeriod,
  //     jobDataForCSV?.panelDetail?.wattage,
  //     totalQuantity,
  //     "",
  //     "",
  //     panelSerialData,
  //     inverterSerialData,
  //     "",
  //     "",
  //     "",
  //     jobDataForCSV?.additionalAttributes?.CRMID,
  //     "",
  //     jobDataForCSV?.installationDetails?.CECAccreditationSystem === true
  //       ? "Yes"
  //       : "No",
  //     jobDataForCSV?.installationDetails?.territoryGovernmentApproval === true
  //       ? "Yes"
  //       : "No",
  //     jobDataForCSV?.installationDetails?.electricalSafetyDoc === true
  //       ? "Yes"
  //       : "No",
  //     jobDataForCSV?.installationDetails?.AusNZStandardStatement === true
  //       ? "Yes"
  //       : "No",
  //     "",
  //     jobDataForCSV?.installingFor,
  //     jobDataForCSV?.siteAttributes?.storey,
  //     "",
  //     jobDataForCSV?.installationAddress?.unitType,
  //     jobDataForCSV?.installationAddress?.unitNumber,
  //     jobDataForCSV?.installationAddress?.streetNumber,
  //     jobDataForCSV?.installationAddress?.streetName,
  //     jobDataForCSV?.installationAddress?.streetType,
  //     jobDataForCSV?.installationAddress?.suburb,
  //     jobDataForCSV?.installationAddress?.state,
  //     jobDataForCSV?.installationAddress?.postCode,
  //     jobDataForCSV?.installationAddress?.latitude,
  //     jobDataForCSV?.installationAddress?.longitude,
  //     "",
  //     "",
  //     jobDataForCSV?.warrantyDetails?.warrantyDescription,
  //     jobDataForCSV?.systemOwnerType,
  //     jobDataForCSV?.ownerDetails?.companyName,
  //     jobDataForCSV?.ownerDetails?.firstName,
  //     jobDataForCSV?.ownerDetails?.lastName,
  //     jobDataForCSV?.ownerDetails?.phoneNumber,
  //     "",
  //     jobDataForCSV?.ownerDetails?.mobileNum,
  //     jobDataForCSV?.ownerDetails?.emailId,
  //     jobDataForCSV?.postalAddress?.POAddress?.addressType,
  //     "",
  //     "",
  //     jobDataForCSV?.postalAddress?.POAddress?.unitType,
  //     jobDataForCSV?.postalAddress?.POAddress?.unitNumber,
  //     jobDataForCSV?.postalAddress?.POAddress?.streetNumber,
  //     jobDataForCSV?.postalAddress?.POAddress?.streetName,
  //     jobDataForCSV?.postalAddress?.POAddress?.streetType,
  //     jobDataForCSV?.postalAddress?.POAddress?.suburb,
  //     jobDataForCSV?.postalAddress?.POAddress?.state,
  //     jobDataForCSV?.postalAddress?.POAddress?.postCode,
  //     "Australia",
  //     jobDataForCSV?.installerName,
  //     "",
  //     installerData?.installerCECID,
  //     "",
  //     "",
  //     installerData?.installer?.mobileNo,
  //     installerData?.installer?.emailId,
  //     installerData?.installer?.fullAddress?.addressType,
  //     "",
  //     "",
  //     installerData?.installer?.fullAddress?.unitType,
  //     installerData?.installer?.fullAddress?.unitNumber,
  //     installerData?.installer?.fullAddress?.streetNumber,
  //     installerData?.installer?.fullAddress?.streetName,
  //     installerData?.installer?.fullAddress?.streetType,
  //     installerData?.installer?.fullAddress?.suburb,
  //     installerData?.installer?.fullAddress?.state,
  //     installerData?.installer?.fullAddress?.postCode,
  //     jobDataForCSV?.electricianName,
  //     "",
  //     electricianData?.installerCECID,
  //     "",
  //     "",
  //     electricianData?.installer?.mobileNo,
  //     electricianData?.installer?.emailId,
  //     electricianData?.installer?.fullAddress?.addressType,
  //     "",
  //     "",
  //     electricianData?.installer?.fullAddress?.unitType,
  //     electricianData?.installer?.fullAddress?.unitNumber,
  //     electricianData?.installer?.fullAddress?.streetNumber,
  //     electricianData?.installer?.fullAddress?.streetName,
  //     electricianData?.installer?.fullAddress?.streetType,
  //     electricianData?.installer?.fullAddress?.suburb,
  //     electricianData?.installer?.fullAddress?.state,
  //     electricianData?.installer?.fullAddress?.postCode,
  //     jobDataForCSV?.designerName,
  //     "",
  //     designerData?.installerCECID,
  //     "",
  //     "",
  //     designerData?.installer?.mobileNo,
  //     designerData?.installer?.emailId,
  //     designerData?.installer?.fullAddress?.addressType,
  //     "",
  //     "",
  //     designerData?.installer?.fullAddress?.unitType,
  //     designerData?.installer?.fullAddress?.unitNumber,
  //     designerData?.installer?.fullAddress?.streetNumber,
  //     designerData?.installer?.fullAddress?.streetName,
  //     designerData?.installer?.fullAddress?.streetType,
  //     designerData?.installer?.fullAddress?.suburb,
  //     designerData?.installer?.fullAddress?.state,
  //     designerData?.installer?.fullAddress?.postCode,
  //     `${retailerData?.basicInformation?.firstName} ${retailerData?.basicInformation?.lastName}`,
  //     retailerData?.companyInformation?.ABN,
  //     jobDataForCSV?.gridConnectionDetail?.nmi,
  //     jobDataForCSV?.batteryDetail?.batteryBrand === true ? "Yes" : "No",
  //     jobDataForCSV?.batteryDetail?.batteryInfo[0]?.batteryModel,
  //     jobDataForCSV?.batteryDetail?.batterySystemAggregate === true
  //       ? "Yes"
  //       : "No",
  //     jobDataForCSV?.batteryDetail?.defaultManufacturerBatteryStorage === true
  //       ? "Yes"
  //       : "No",
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  // ];

  const headersForXML = [
    "RecRegistryApplicationRequest",
    "Registration",
    "InstallationProductVerification",
    "VerifiedDateTime",
    "Installer",
    "ID",
    "FirstName",
    "LastName",
    "InstallationAddress",
    "StreetNumber",
    "StreetName",
    "StreetType",
    "Suburb",
    "Postcode",
    "State",
    "Location",
    "Latitude",
    "Longitude",
    "ManuallyEntered",
    "OwnerEmail",
    "InstallationDate",
    "Products",
    "Product",
    "SerialNumber",
    "ResponsibleSupplier",
    "ModelNumber",
    "FlashTest",
  ];

  const [jobDataXML, setJobDataXML] = useState();
  const [panelsData, setPanelsDataXML] = useState();
  const [invertersData, setInvertersDataXML] = useState();
  const [batteryData, setBatteryDataXML] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const SeparateName = (name) => {
    const fullName = name;

    if (fullName) {
      const nameParts = fullName?.split(" ");
      setFirstName(nameParts[0]);
      setLastName(nameParts.slice(1).join(" ")); // Handles multiple last names
      // console.log('First Name:', firstName);
      // console.log('Last Name:', lastName);
    } else return;
  };
  const valuesForXML = [
    {
      VerifiedDateTime:
        jobDataXML?.jobDetails?.installationDate?.installingDate,
      Installer: {
        ID: jobDataXML?.installers[0]?.installerCECID,
        FirstName: jobDataXML?.jobDetails?.installerName && firstName,
        LastName: lastName && lastName,
      },
      InstallationAddress: {
        StreetNumber: jobDataXML?.jobDetails?.installationAddress?.streetNumber,
        StreetName: jobDataXML?.jobDetails?.installationAddress?.streetName,
        StreetType: jobDataXML?.jobDetails?.installationAddress?.streetType,
        Suburb: jobDataXML?.jobDetails?.installationAddress?.suburb,
        Postcode: jobDataXML?.jobDetails?.installationAddress?.postCode,
        State: jobDataXML?.jobDetails?.installationAddress?.state,
        Location: {
          Latitude: jobDataXML?.jobDetails?.installationAddress?.latitude,
          Longitude: jobDataXML?.jobDetails?.installationAddress?.longitude,
          ManuallyEntered: "No", // Example value, change as required
        },
      },
      OwnerEmail: jobDataXML?.jobDetails?.ownerDetails?.emailId,
      InstallationDate:
        jobDataXML?.jobDetails?.installationDate?.installingDate,
      Products: jobDataXML?.jobDetails?.panelDetails?.panelInfo?.map(
        (product) => ({
          SerialNumber: product?.SerialNumber,
          ResponsibleSupplier: jobDataXML?.jobDetails?.panelDetails?.panelBrand,
          ModelNumber: product.panelModel,
        })
      ),
    },
  ];

  // const fetchDataXML = async () => {
  //   try {
  //     const resp = await axios.get(`${baseURL}/jobMaster/${jobId}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     const data = resp.data.data;
  //     setJobDataXML(data[0]);
  //     setPanelsDataXML(data[0]?.panels);
  //     setInvertersDataXML(data[0]?.inverters);
  //     setBatteryDataXML(data[0]?.batteries);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchDataXML();
  // }, []);

  // const handleExport = async (field, id) => {
  //   localStorage.setItem("jobId", id);
  //   const flattenedData = jobData?.jobs?.flat();
  //   const result = flattenedData.find((item) => item?._id === id);
  //   if (field === "CSV" || field === "CSVX") {
  //     const csvContent = [
  //       headers.join(", "),
  //       ...values.map((row) => row.join(", ")),
  //     ].join("\n");

  //     const blob = new Blob([csvContent], { type: "text/csv" });
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "data.csv";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     URL.revokeObjectURL(url);
  //   } else {
  //     try {
  //       const formData = new FormData();
  //       formData.append("countXML", result?.countXML + 1);
  //       const response = await axios.patch(
  //         `${baseURL}/job/update/${id}`,
  //         formData,
  //         {
  //           withCredentials: true,
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );
  //       if (response.status === 200 || response.status === 201) {
  //         setShowSPVButtons(true);
  //         fetchJobData();
  //       } else {
  //         console.error(
  //           "Login failed. Unexpected status code:",
  //           response.status
  //         );
  //       }
  //     } catch (error) {
  //       alert(error?.response?.data?.message);
  //     }
  //     SeparateName(jobDataXML?.jobDetails.installerName);

  //     const xmlData = objectToXml(headersForXML, valuesForXML);
  //     const encodedXmlData = new TextEncoder().encode(xmlData);

  //     const blob = new Blob([encodedXmlData], { type: "application/xml" });

  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.setAttribute("hidden", "");
  //     a.setAttribute("href", url);
  //     a.setAttribute("download", "download.xml");
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //   }
  // };
  const handleExport = async (field, id) => {
    try {
      const resp = await axios.get(`${baseURL}/jobMaster/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp.data.data[0];
      const jobDetails = data?.jobDetails;
      const installers = data?.installers[0];
      const designers = data?.designers[0];
      const electricians = data?.electricians[0];
      const retailer = data?.user;
      const panels = data?.panels;
      const inverters = data?.inverters;
      const panelSerial = panels.map((panel) => panel.serialNumber).join(";");
      const inverterSerial = inverters
        .map((inverter) => inverter.serialNumber)
        .join(";");

      const formatDateForDeemingPeriod = (date) => {
        if (!date) return "";

        const deemingPeriodString =
          {
            1: "One Year",
            2: "Two Years",
            3: "Three Years",
            4: "Four Years",
            5: "Five Years",
            6: "Six Years",
            7: "Seven Years",
          }[date] || "";

        return deemingPeriodString;
      };

      const reference =
        jobDetails?.installingFor === "Individual"
          ? (jobDetails?.ownerDetails?.firstName || "") +
            " " +
            (jobDetails?.ownerDetails?.lastName || "")
          : jobDetails?.ownerDetails?.companyName || "";

      const values = [
        [
          jobDetails?.gridConnectionDetail?.systemType,
          moment(jobDetails?.installationDate?.installingDate).format(
            "DD/MM/YYYY"
          ),
          "Yes",
          jobDetails?.panelDetail?.panelInfo
            ?.map((panel) => panel.panelBrand)
            ?.join(";"),
          jobDetails?.panelDetail?.panelInfo
            ?.map((panel) => panel.panelModel)
            ?.join(";"),
          jobDetails?.inverterDetail?.inverterInfo
            ?.map((inverter) => inverter.inverterBrand)
            ?.join(";"),
          jobDetails?.inverterDetail?.inverterInfo
            ?.map((inverter) => inverter.inverterSeries)
            ?.join(";"),
          jobDetails?.inverterDetail?.inverterInfo
            ?.map((inverter) => inverter.inverterModel)
            ?.join(";"),
          jobDetails?.gridConnectionDetail?.connectionType,
          jobDetails?.installationDate?.mountingType,
          "",
          jobDetails?.installationDetails?.installationType === "Additional" ||
          jobDetails?.installationDetails?.installationType === "Extension"
            ? "No"
            : "Yes",
          jobDetails?.installationDetails?.installationType === "Additional" ||
          jobDetails?.installationDetails?.installationType === "Extension"
            ? jobDetails?.installationDetails?.remarks
            : "",
          `${formatDateForDeemingPeriod(
            jobDetails?.installationDate?.STCDeemingPeriod
          )}`,
          jobDetails?.panelDetail?.wattage,
          jobDetails?.panelDetail?.panelInfo?.reduce((total, panel) => {
            return total + (panel.Quantity || 0);
          }, 0),
          jobDetails?.inverterDetail?.inverterInfo?.reduce(
            (total, inverter) => {
              return total + (inverter.Quantity || 0);
            },
            0
          ),
          inverterSerial,
          "",
          "",
          panelSerial,
          jobDetails?.failedPV?.isItForPreviouslyFailedPV === true
            ? "Yes"
            : "No",
          jobDetails?.failedPV?.isItForPreviouslyFailedPV === true
            ? jobDetails?.failedPV?.accreditationCode
            : "",
          jobDetails?.failedPV?.isItForPreviouslyFailedPV === true
            ? jobDetails?.failedPV?.explanatoryNotes
            : "",
          reference,
          "",
          "Yes",
          "Yes",
          "Yes",
          jobDetails?.installationDetails?.territoryGovermentApproval === true
            ? "Yes"
            : "No",
          jobDetails?.installationDetails?.electricalSafetyDoc === true
            ? "Yes"
            : "No",
          "Yes",
          "Yes",
          "Yes",
          "No",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          jobDetails?.systemOwnerType,
          jobDetails?.siteAttributes?.storey
            ? `${jobDetails?.siteAttributes?.storey} story`
            : "",
          "",
          jobDetails?.installationAddress?.unitType,
          jobDetails?.installationAddress?.unitNumber,
          jobDetails?.installationAddress?.streetNumber,
          jobDetails?.installationAddress?.streetName,
          jobDetails?.installationAddress?.streetType === "Cres"
            ? "Cr"
            : jobDetails?.installationAddress?.streetType === "Ave"
            ? "AV"
            : jobDetails?.installationAddress?.streetType,

          jobDetails?.installationAddress?.suburb,
          jobDetails?.installationAddress?.state,
          jobDetails?.installationAddress?.postCode,
          jobDetails?.installationAddress?.latitude,
          jobDetails?.installationAddress?.longitude,
          jobDetails?.installationDetails?.installationType === "First Time" ||
          jobDetails?.installationDetails?.installationType === "Replacement"
            ? "No"
            : "Yes",
          "",
          jobDetails?.installationDetails?.installationType === "Replacement"
            ? jobDetails?.installationDetails?.remarks
            : "",
          jobDetails?.installingFor,
          jobDetails?.ownerDetails?.companyName
            ? jobDetails?.ownerDetails?.companyName
            : "",
          jobDetails?.ownerDetails?.firstName
            ? jobDetails?.ownerDetails?.firstName
            : "",
          jobDetails?.ownerDetails?.lastName
            ? jobDetails?.ownerDetails?.lastName
            : "",
          jobDetails?.ownerDetails?.phoneNumber,
          "",
          jobDetails?.ownerDetails?.mobileNum,
          jobDetails?.ownerDetails?.emailId,
          jobDetails?.postalAddress?.POAddress?.addressType,
          "",
          "",
          jobDetails?.postalAddress?.POAddress?.unitType,
          jobDetails?.postalAddress?.POAddress?.unitNumber,
          jobDetails?.postalAddress?.POAddress?.streetNumber,
          jobDetails?.postalAddress?.POAddress?.streetName,
          jobDetails?.postalAddress?.POAddress?.streetType === "Cres"
            ? "Cr"
            : jobDetails?.postalAddress?.POAddress?.streetType === "Ave"
            ? "AV"
            : jobDetails?.postalAddress?.POAddress?.streetType,
          jobDetails?.postalAddress?.POAddress?.suburb,
          jobDetails?.postalAddress?.POAddress?.state,
          jobDetails?.postalAddress?.POAddress?.postCode,
          "AU",
          jobDetails.installerName.trim().split(" ").slice(0, -1).join(" "),
          jobDetails.installerName.trim().split(" ").pop(),
          installers?.installerCECID,
          installers?.installer?.mobileNo,
          "",
          installers?.installer?.mobileNo,
          installers?.installer?.emailId,
          installers?.installer?.fullAddress?.addressType,
          "",
          "",
          installers?.installer?.fullAddress?.unitType,
          installers?.installer?.fullAddress?.unitNumber,
          installers?.installer?.fullAddress?.streetNumber,
          installers?.installer?.fullAddress?.streetName,
          installers?.installer?.fullAddress?.streetType === "Cres"
            ? "Cr"
            : installers?.installer?.fullAddress?.streetType === "Ave"
            ? "AV"
            : installers?.installer?.fullAddress?.streetType,
          installers?.installer?.fullAddress?.suburb,
          installers?.installer?.fullAddress?.state,
          installers?.installer?.fullAddress?.postCode,
          jobDetails.electricianName.trim().split(" ").slice(0, -1).join(" "),
          jobDetails.electricianName.trim().split(" ").pop(),
          electricians?.electricianLicenseNo,
          electricians?.installer?.mobileNo,
          "",
          electricians?.installer?.mobileNo,
          electricians?.installer?.emailId,
          electricians?.installer?.fullAddress?.addressType,
          "",
          "",
          electricians?.installer?.fullAddress?.unitType,
          electricians?.installer?.fullAddress?.unitNumber,
          electricians?.installer?.fullAddress?.streetNumber,
          electricians?.installer?.fullAddress?.streetName,
          electricians?.installer?.fullAddress?.streetType === "Cres"
            ? "Cr"
            : electricians?.installer?.fullAddress?.streetType === "Ave"
            ? "AV"
            : electricians?.installer?.fullAddress?.streetType,
          electricians?.installer?.fullAddress?.suburb,
          electricians?.installer?.fullAddress?.state,
          electricians?.installer?.fullAddress?.postCode,
          jobDetails.designerName.trim().split(" ").slice(0, -1).join(" "),
          jobDetails.designerName.trim().split(" ").pop(),
          designers?.designerCECID,
          designers?.installer?.mobileNo,
          "",
          designers?.installer?.mobileNo,
          designers?.installer?.emailId,
          designers?.installer?.fullAddress?.addressType,
          "",
          "",
          designers?.installer?.fullAddress?.unitType,
          designers?.installer?.fullAddress?.unitNumber,
          designers?.installer?.fullAddress?.streetNumber,
          designers?.installer?.fullAddress?.streetName,
          designers?.installer?.fullAddress?.streetType === "Cres"
            ? "Cr"
            : designers?.installer?.fullAddress?.streetType === "Ave"
            ? "AV"
            : designers?.installer?.fullAddress?.streetType,
          designers?.installer?.fullAddress?.suburb,
          designers?.installer?.fullAddress?.state,
          designers?.installer?.fullAddress?.postCode,
          retailer?.companyInformation?.tradingName,
          retailer?.companyInformation?.ABN,
          `${retailer?.basicInformation?.firstName} ${retailer?.basicInformation?.lastName}`,
          retailer?.basicInformation?.role,
          jobDetails?.gridConnectionDetail?.nmi,
          jobDetails?.batteryDetail?.batteryInfo[0]?.batteryBrand !== ""
            ? jobDetails?.batteryDetail?.batteryInfo
                ?.map((battery) => battery.batteryBrand)
                ?.join(";")
            : "",
          jobDetails?.batteryDetail?.batteryInfo[0]?.batteryBrand !== ""
            ? jobDetails?.batteryDetail?.batteryInfo
                ?.map((battery) => battery.batteryModel)
                ?.join(";")
            : "",
          jobDetails?.batteryDetail?.batterySystemAggregate === true
            ? "Yes"
            : "",
          jobDetails?.batteryDetail?.defaultManufacturerBatteryStorage === true
            ? "Yes"
            : "",
          "",
          "",
          (jobDetails?.installationDetails?.installationType === "First Time"
            ? "New"
            : jobDetails?.installationDetails?.installationType) + " system",
          jobDetails?.installationDetails?.installationType === "Additional" ||
          jobDetails?.installationDetails?.installationType === "Extension"
            ? jobDetails?.installationDetails?.remarks
            : "",
        ],
      ];

      const escapeCsvValue = (value) => {
        if (typeof value === "string" && value.includes(",")) {
          return `"${value}"`;
        }
        return value;
      };
      const csvContent = [
        headers.map(escapeCsvValue).join(","),
        ...values.map((row) => row.join(",")),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "csvData.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //Download CSV of Reports Starts
  const handleDownload = () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    const currentDateString = `${day}_${month}_${year}`;

    const headers = [
      "Job ID",
      "Owner First Name",
      "Owner Last Name",
      "Installation Date",
      "Address",
      "Installer",
      "Designer",
      "Electrician",
      "Job Status",
      "Total No. of Panels",
      "Rated Power",
      "STC",
    ];

    let csv = headers.join(",") + "\n";
    const allData = jobData;

    allData.forEach((data) => {
      // Only include jobs where jobIdentifyId starts with "PV"
      if (data?.jobIdentifyId?.startsWith("PV")) {
        let address = `${data?.installationAddress?.addressType ?? ""} ${
          data?.installationAddress?.unitType ?? ""
        } ${data?.installationAddress?.unitNumber ?? ""} ${
          data?.installationAddress?.streetNumber ?? ""
        } ${data?.installationAddress?.streetName ?? ""} ${
          data?.installationAddress?.streetType ?? ""
        } ${data?.installationAddress?.suburb ?? ""} ${
          data?.installationAddress?.state ?? ""
        } ${data?.installationAddress?.postCode ?? ""}`;

        let row = [
          data?.jobIdentifyId ?? "",
          data?.ownerDetails?.firstName ?? "",
          data?.ownerDetails?.lastName ?? "",
          data?.installationDate?.installingDate
            ? moment(data?.installationDate?.installingDate).format(
                "DD/MM/YYYY"
              )
            : "",
          address,
          data?.installerName ?? "",
          data?.designerName ?? "",
          data?.electricianName ?? "",
          data?.statusDescription ?? "",
          data?.panelDetail?.panelInfo.reduce(
            (acc, obj) => acc + obj.Quantity,
            0
          ) ?? "",
          data?.panelDetail?.wattage ?? "",
          data?.panelDetail?.stc ?? "",
        ].join(",");

        csv += row + "\n";
      }
    });
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Job_Status_${currentDateString}.csv`;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };
  //Download CSV of Reports Ends

  //Job Pack Function Starts

  const [jobPackLoadingID, setJobPackLoadingID] = useState(null);

  const downloadAllFormsZip = async (responseData) => {
    try {
      const zip = new JSZip();
      const imagesFolder = zip.folder("images");

      for (const urls of Object.values(responseData)) {
        for (let i = 0; i < urls.length; i++) {
          const url = urls[i].url;
          try {
            const response = await axios.get(url, {
              responseType: "arraybuffer",
            });
            const filename = url.split("/").pop();
            imagesFolder.file(`${filename}`, response.data, { binary: true });
          } catch (error) {
            console.error(`Error fetching image ${url}:`, error);
          }
        }
      }

      // Generate zip content
      const content = await zip.generateAsync({ type: "blob" });
      // Create download link and trigger download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = "forms.zip";
      link.click();
      // Clean up
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const downloadZip = async (data) => {
    const jobId = data?._id;
    localStorage.setItem("jobId", jobId);
    let apiUrl = `${baseURL}/get/photosAndDocuments/${jobId}`;
    try {
      const apiResponse = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const files = apiResponse.data;
      await downloadAllFormsZip(files);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const openJobPackTab = async (jobId) => {
    const url = `/dashboard/job-pack/${jobId}`;
    window.open(url, "_blank");
  };

  const handleDownloadClick = async (data) => {
    setJobPackLoadingID(data._id);
    await downloadZip(data);
    await openJobPackTab(data._id);
    setJobPackLoadingID(null);
  };

  //Job Pack Function Ends
  const handleInstallerPresenseClick = (jobId, endSelfieStatus) => {
    if (endSelfieStatus === 0) {
      alert("Job not finished yet from Installer Side.");
    } else {
      localStorage.setItem("jobId", jobId);
      window.open("/dashboard/installer-presence", "_blank");
    }
  };

  return (
    <div className="MainOuterContainer">
      <Aside />
      <div style={{ width: "100%" }}>
        <div className="main-part-ratailer">
          <div>
            <HeaderComponent
              PageTitle={userType == "Trader" ? "Trader Jobs" : "Retailer Jobs"}
              location="Dashboard"
              handleUploadButtonClick={handleUploadButtonClick}
              handleFileChange={handleFileChange}
              JobType="Solar PV"
              loading={loading}
              getJobs={test}
            />
          </div>
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              gap: "22%",
            }}
            className="STCOuterDiv"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "auto",
                width: "100%",
              }}
              className="STCMainDiv"
            >
              <div className="STCPriceSection ">
                <p className="PriceDataText"> Unregistered STCs : </p>
                <span className="priceTextLive">$39.40</span>
              </div>
              <div className="STCPriceSection ">
                <p className="PriceDataText"> Registered STCs : </p>
                <span className="priceTextLive">$39+GST</span>
              </div>
            </div>
            <div className="TabsDiv">
              <div className="tabsButtonDIv">
                <button
                  type="button"
                  style={{
                    backgroundColor:
                      selectedJobTab === "Solar PV Jobs"
                        ? "rgba(0, 67, 229, 0.3)"
                        : "transparent",
                    color: selectedJobTab === "Solar PV Jobs" ? "#fff" : "#333",
                  }}
                  onClick={() => setSelectedJobTab("Solar PV Jobs")}
                >
                  <img
                    src="/Assets/DashboardPV/solar-panel.svg"
                    alt="Solar panel"
                    width={"33px"}
                  />
                  <span style={{ fontSize: "14px", lineHeight: "16px" }}>
                    Solar PV
                  </span>
                </button>
                <button
                  type="button"
                  style={{
                    backgroundColor:
                      selectedJobTab === "Hot Water"
                        ? "rgba(0, 67, 229, 0.3)"
                        : "transparent",
                    color: selectedJobTab === "Hot Water" ? "#fff" : "#333",
                  }}
                  onClick={handleChooseJob}
                >
                  <img
                    src="/Assets/DashboardPV/hot-surface.svg"
                    width={"33px"}
                  />
                  <span style={{ fontSize: "14px", lineHeight: "16px" }}>
                    {" "}
                    Hot Water
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="search-filter-section">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search by Owner Name, Company Name, CRM ID or PV Job Number"
                name="search"
                className="input-search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="buttons-container">
              <div className="btn-filter">
                <button
                  className="filter-text"
                  onClick={() => {
                    setShowQuickFilters(true);
                  }}
                >
                  Filter
                </button>
              </div>
              <div className="btn-clear">
                <button
                  className="clear-text"
                  onClick={() => {
                    clearFilters();
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          {showQuickFilters ? (
            <div className="quick-filter" style={{ gap: "6px" }}>
              <span style={{ fontSize: "12px", width: "8%" }}>
                Quick Filters:
              </span>
              {userType === "Retailer" ? (
                <button
                  className={`filter-job-btn ${
                    activeFilter === 3 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(3)}
                >
                  In Progress
                </button>
              ) : null}

              <button
                className={`filter-submit-btn ${
                  activeFilter === 10 ? "selected" : ""
                }`}
                onClick={() => setActiveFilter(10)}
              >
                {userType === "Retailer" ? "Submitted" : "In Progress"}
              </button>

              {userType === "Trader" ? (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 11 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(11)}
                >
                  Waiting For Approval
                </button>
              ) : (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 7 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(7)}
                >
                  Parked
                </button>
              )}

              {userType === "Trader" ? (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 12 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(12)}
                >
                  Resubmitted after failure
                </button>
              ) : (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 8 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(8)}
                >
                  Site Inspection
                </button>
              )}
              {userType === "Trader" ? (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 13 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(13)}
                >
                  Failed
                </button>
              ) : (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 9 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(9)}
                >
                  Cancelled
                </button>
              )}
              {userType === "Trader" ? (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 14 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(14)}
                >
                  Approved
                </button>
              ) : (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 17 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(17)}
                >
                  Completed
                </button>
              )}
            </div>
          ) : null}
          <div className="row">
            <div className="filter-two-btn"></div>
          </div>
          {userType === "Retailer" && reverseFilteredJobList?.length > 0 ? (
            <div>
              <div
                style={{
                  width: "200px",
                  float: "right",
                  justifyContent: "center",
                  display:
                    activeFilter === 10 || activeFilter === 3 ? "flex" : "none",
                }}
                className="reportsButtonDiv"
              >
                <button
                  type="button"
                  className="buttonForReports"
                  onClick={handleDownload}
                >
                  <Download sx={{ fontSize: "large", marginRight: "2px" }} />{" "}
                  Reports
                </button>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="dashboard-information">
              {jobLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FadeLoader color="#154397" radius={800} />
                </div>
              ) : reverseFilteredJobList?.length > 0 ? (
                reverseFilteredJobList.map((data, outerIndex) => (
                  <div className="information-bg" key={outerIndex}>
                    <div className="dashboard-part">
                      <div className="DashboardFirstSection">
                        <div className="DashboardCardFirstSectionRow">
                          <div className="dash-text">
                            <p className="inform-text">
                              {data?.jobIdentifyId || "N/A"}
                            </p>
                            <p className="show-date">
                              {data?.additionalAttributes?.CRMID ||
                                "CRM ID: N/A"}
                            </p>
                            <p>
                              <span className="create-date">
                                Installation Date:
                                <b className="show-date">
                                  {data?.installationDate?.installingDate
                                    ? `${String(
                                        new Date(
                                          data.installationDate?.installingDate
                                        ).getDate()
                                      ).padStart(2, "0")}-${String(
                                        new Date(
                                          data.installationDate?.installingDate
                                        ).getMonth() + 1
                                      ).padStart(2, "0")}-${new Date(
                                        data.installationDate?.installingDate
                                      ).getFullYear()}`
                                    : "N/A"}
                                </b>
                              </span>
                            </p>
                            <p>
                              <span className="create-install">
                                {userType === "Trader"
                                  ? "Retailer"
                                  : "Installer"}
                                :
                                <b className="show-install">
                                  {userType === "Trader"
                                    ? data?.retailerData?.companyInformation
                                        ?.tradingName
                                      ? `${data?.retailerData?.companyInformation?.tradingName}`
                                      : "N/A"
                                    : data?.installerName
                                    ? data.installerName
                                    : "N/A"}
                                  {/* {userType === "Trader"
                                    ? data?.retailerData?.basicInformation
                                        ?.firstName &&
                                      data?.retailerData?.basicInformation
                                        ?.lastName
                                      ? `${data?.retailerData?.basicInformation?.firstName} ${data?.retailerData?.basicInformation?.lastName}`
                                      : "N/A"
                                    : data?.installerName
                                    ? data.installerName
                                    : "N/A"} */}
                                </b>
                              </span>
                            </p>
                          </div>
                          <div className="DetailsOfOwner">
                            <div
                              className="iconAndDetail"
                              style={{ gap: "8px" }}
                            >
                              <img
                                src={`${image}/Assets/DashboardPV/User.svg`}
                                alt=""
                              />
                              {data?.installingFor === "Individual" ? (
                                <div className="user-name">
                                  {data?.ownerDetails?.firstName &&
                                  data?.ownerDetails?.lastName
                                    ? `${data.ownerDetails.firstName} ${data.ownerDetails.lastName}`
                                    : "N/A"}
                                </div>
                              ) : (
                                <div className="user-name">
                                  {data?.ownerDetails?.companyName
                                    ? data.ownerDetails.companyName
                                    : "N/A"}
                                </div>
                              )}
                            </div>
                            <div
                              className="iconAndDetail"
                              style={{ gap: "5px" }}
                            >
                              <img
                                src={`${image}/Assets/DashboardPV/Location.svg`}
                                alt=""
                                className="user-icon location-icon"
                              />
                              <div className="user-text user-address">
                                {data?.installationAddress &&
                                (data.installationAddress.unitType ||
                                  data.installationAddress.unitNumber ||
                                  data.installationAddress.streetNumber ||
                                  data.installationAddress.streetName ||
                                  data.installationAddress.streetType ||
                                  data.installationAddress.suburb ||
                                  data.installationAddress.state ||
                                  data.installationAddress.postCode) ? (
                                  <>
                                    {data.installationAddress.unitType &&
                                      `${data.installationAddress.unitType} `}
                                    {data.installationAddress.unitNumber &&
                                      `${data.installationAddress.unitNumber}, `}
                                    {data.installationAddress.streetNumber &&
                                      `${data.installationAddress.streetNumber} `}
                                    {data.installationAddress.streetName &&
                                      `${data.installationAddress.streetName} `}
                                    {data.installationAddress.streetType &&
                                      `${data.installationAddress.streetType}, `}
                                    {data.installationAddress.suburb &&
                                      `${data.installationAddress.suburb}, `}
                                    {data.installationAddress.state &&
                                      `${data.installationAddress.state}, `}
                                    {data.installationAddress.postCode &&
                                      `${data.installationAddress.postCode}`}
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </div>
                            <div
                              className="iconAndDetail"
                              style={{ gap: "5px" }}
                            >
                              <EmailRounded
                                className="user-icon"
                                sx={{ width: "20px" }}
                              />
                              <div
                                style={{ overflowWrap: "wrap" }}
                                className="emailSection"
                              >
                                <p className="email-id-owner">
                                  {data?.ownerDetails?.emailId || "N/A"}
                                </p>
                              </div>
                            </div>
                            <div
                              className="iconAndDetail"
                              style={{ alignItems: "center", gap: "5px" }}
                            >
                              <RememberMeIcon sx={{ fontSize: "19px" }} />
                              <div className="user-text">
                                {data?.ownerDetails?.mobileNum
                                  ? data?.ownerDetails?.mobileNum
                                  : "N/A"}
                              </div>
                            </div>
                            <div
                              className="iconAndDetail"
                              style={{ alignItems: "center", gap: "5px" }}
                            >
                              <RememberMeIcon sx={{ fontSize: "19px" }} />
                              <div className="user-text">
                                {data?.ownerDetails?.phoneNumber
                                  ? data?.ownerDetails?.phoneNumber
                                  : "N/A"}
                              </div>
                            </div>
                            <p
                              className="iconAndDetail"
                              style={{ alignItems: "center" }}
                            >
                              <span>Property Type:</span>
                              <b className="show-Residential">
                                {data?.systemOwnerType || "N/A"}
                              </b>
                            </p>
                            <p
                              className="iconAndDetail"
                              style={{ alignItems: "center" }}
                            >
                              {" "}
                              <span>
                                NMI:
                                <b className="show-Residential">
                                  {data?.gridConnectionDetail?.nmi || "N/A"}
                                </b>
                              </span>
                            </p>
                          </div>
                          {viewActionsPanel && (
                            <div className="invite-model-backdrop background-viewbg">
                              <div
                                className="delete-model-content actionpopup-height"
                                style={{ height: "465px" }}
                              >
                                <div className="row">
                                  <h3 className="popupblue-text">
                                    {data?.jobIdentifyId} - Action
                                    <button
                                      className="invite-model-close-btn"
                                      onClick={() => {
                                        localStorage.removeItem("jobId");
                                        setViewActionsPanel(false);
                                      }}
                                    >
                                      ✕
                                    </button>
                                  </h3>
                                  <span className="bottom-border-popup"></span>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <div
                                        className="main-square"
                                        onClick={() => {
                                          navigate(
                                            "/dashboard/document-required"
                                          );
                                        }}
                                      >
                                        <Download sx={{ marginRight: "2px" }} />{" "}
                                        Documents
                                      </div>
                                      <div
                                        className="main-square"
                                        onClick={() => {
                                          navigate("/dashboard/upload-files");
                                        }}
                                      >
                                        Upload Files
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <div
                                        className="main-square"
                                        onClick={() => {
                                          window.open(
                                            "dash/written-statement",
                                            "_blank"
                                          );
                                        }}
                                      >
                                        View Written Statement
                                      </div>
                                      <div
                                        className="main-square"
                                        onClick={() => {
                                          navigate(
                                            "/dashboard/important-actions"
                                          );
                                        }}
                                      >
                                        Important Pending Actions
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <div
                                        className="main-square"
                                        onClick={() => {
                                          navigate(
                                            "/dashboard/view-site-inspection"
                                          );
                                        }}
                                      >
                                        View Site Inspection
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                ></div>
                              </div>
                            </div>
                          )}
                          {showSubmitConfirmation && (
                            <div className="modal-backdrop">
                              <div
                                className="modal-centered"
                                style={{ height: "160px" }}
                              >
                                <h2>Are you sure you want to submit?</h2>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="button-invite"
                                    onClick={() => {
                                      setShowSubmitConfirmation(false);
                                      handleButtonClick(10);
                                    }}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() =>
                                      setShowSubmitConfirmation(false)
                                    }
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {showDeleteConfirmation && (
                            <div className="modal-backdrop">
                              <div
                                className="modal-centered"
                                style={{ height: "160px" }}
                              >
                                <h2>Are you sure you want to delete?</h2>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    className="btn-cancel"
                                    onClick={handleDelete}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="button-invite"
                                    onClick={() => {
                                      setShowDeleteConfirmation(false);
                                      localStorage.removeItem("jobId");
                                    }}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="main-stc text-stc">
                            <span className="stc-count">STCs </span>
                            <span className="stc-count">
                              {data?.panelDetail?.stc ?? "N/A"}
                            </span>
                            <span className="stc-text">
                              STC Amount:&nbsp;&nbsp;
                              {data?.panelDetail?.financialPayment
                                ? `$${data.panelDetail.financialPayment}`
                                : "N/A"}
                            </span>
                            <span className="stc-text">
                              Kilo Watt:&nbsp;&nbsp;
                              {data?.panelDetail?.wattage
                                ? `${data.panelDetail.wattage} kW`
                                : "N/A"}
                            </span>
                            {data?.statusCode === 3 ||
                            data?.statusCode === 6 ||
                            data?.statusCode === 11 ||
                            data?.statusCode === 13 ||
                            data?.statusCode === 14 ? (
                              <span className="stc-text">
                                Status:&nbsp;&nbsp;
                                {data?.statusDescription &&
                                  data.statusDescription.replace(
                                    /\b\w/g,
                                    (char) => char.toUpperCase()
                                  )}
                              </span>
                            ) : null}
                          </div>
                          <div className="system-info">
                            <ul className="system-all">
                              <li className="system-img buttonBox ">
                                <button
                                  type="button"
                                  className="traderDashButtons button"
                                  style={{ background: "#BD0000" }}
                                  onClick={() => handleEditJob(data?._id)}
                                >
                                  Edit Job
                                </button>
                              </li>
                              <li className="system-battery buttonBox ">
                                <button
                                  type="button"
                                  className="traderDashButtons "
                                  onClick={() => {
                                    localStorage.setItem("jobId", data?._id);
                                    window.open(
                                      "/dashboard/unsigned-stc",
                                      "_blank"
                                    );
                                  }}
                                >
                                  STC Form
                                </button>
                              </li>
                              <li className="system-img buttonBox">
                                <button
                                  type="button"
                                  className="traderDashButtons"
                                  onClick={() => {
                                    if (
                                      !(
                                        data?.installationAddress?.state ===
                                          "QLD" ||
                                        data?.installationAddress?.state ===
                                          "NSW"
                                      )
                                    ) {
                                      window.alert(
                                        "Compliance is only available for Queensland and New South Wales."
                                      );
                                    } else {
                                      localStorage.setItem("jobId", data?._id);
                                      window.open(
                                        "/compliance-certificate",
                                        "_blank"
                                      );
                                    }
                                  }}
                                >
                                  Compliance
                                </button>
                              </li>
                              <li className="system-battery buttonBox">
                                {userType === "Trader" ? (
                                  <button
                                    type="button"
                                    className="traderDashButtons"
                                    onClick={() => {
                                      handleDownloadButtons(
                                        "CustCerti",
                                        data?._id
                                      );
                                    }}
                                    disabled={data?.statusCode === 3}
                                  >
                                    Customer Certificate
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="traderDashButtons"
                                    onClick={() => {
                                      if (data?.statusCode !== 3) {
                                        alert(
                                          "This Job is already finished from Installer Side."
                                        );
                                      } else {
                                        localStorage.setItem(
                                          "jobId",
                                          data?._id
                                        );
                                        navigate("/dashboard/site-inspection");
                                      }
                                    }}
                                  >
                                    Site Inspection
                                  </button>
                                )}
                              </li>
                              {data?.statusCode === 7 ? (
                                <li className="system-img buttonBox">
                                  <button
                                    type="button"
                                    className="traderDashButtons"
                                    onClick={() => {
                                      localStorage.setItem("jobId", data?._id);
                                      handleButtonClick(3);
                                    }}
                                  >
                                    Unpark Job
                                  </button>
                                </li>
                              ) : (
                                <>
                                  <li className="system-img buttonBox">
                                    {userType === "Trader" ? (
                                      <button
                                        type="button"
                                        className="traderDashButtons button"
                                        onClick={() =>
                                          handleExport("CSV", data?._id)
                                        }
                                        disabled={data?.statusCode === 3}
                                      >
                                        {data?.isSPV ? (
                                          <>
                                            <Download
                                              fontSize="small"
                                              sx={{ marginRight: "4px" }}
                                            />
                                            CSVX
                                          </>
                                        ) : (
                                          <>
                                            <Download
                                              fontSize="small"
                                              sx={{ marginRight: "4px" }}
                                            />
                                            CSV
                                          </>
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="traderDashButtons"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "jobId",
                                            data?._id
                                          );
                                          handleButtonClick(7);
                                        }}
                                      >
                                        Park Job
                                      </button>
                                    )}
                                  </li>
                                </>
                              )}
                              {userType === "Trader" ? (
                                <li className="system-img buttonBox">
                                  <button
                                    type="button"
                                    className="traderDashButtons button"
                                    style={{ display: "none" }}
                                    onClick={() =>
                                      handleDownloadButtons("SRES-CSV")
                                    }
                                    disabled={data?.statusCode === 3}
                                  >
                                    {data?.isSPV ? (
                                      <>
                                        <Download
                                          fontSize="small"
                                          sx={{ marginRight: "4px" }}
                                        />{" "}
                                        SRES CSVX
                                      </>
                                    ) : (
                                      <>
                                        <Download
                                          fontSize="small"
                                          sx={{ marginRight: "4px" }}
                                        />
                                        SRES CSV
                                      </>
                                    )}
                                  </button>
                                </li>
                              ) : (
                                <>
                                  {data?.siteInspection === true ? (
                                    <>
                                      <li className="system-img buttonBox">
                                        <button
                                          type="button"
                                          className="traderDashButtons"
                                          onClick={() => {
                                            localStorage.setItem(
                                              "jobId",
                                              data?._id
                                            );
                                            navigate(
                                              "/dashboard/view-site-inspection"
                                            );
                                          }}
                                          disabled={data?.statusCode === 7}
                                        >
                                          View Site Inspection
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          type="button"
                                          className="traderDashButtons"
                                          onClick={() => {
                                            localStorage.setItem(
                                              "jobId",
                                              data?._id
                                            );
                                            const statusArray = [
                                              data?.status[0]
                                                ?.CustomerSignStatus,
                                              data?.status[0]?.PanelScanStatus,
                                              data?.status[0]
                                                ?.designerDeclarationStatus,
                                              data?.status[0]
                                                ?.documentsUploadStatus,
                                              data?.status[0]
                                                ?.electricianDeclarationStatus,
                                              data?.status[0]
                                                ?.installerDeclarationStatus,
                                              data?.status[0]
                                                ?.installerEndSelfie,
                                              data?.status[0]
                                                ?.installerMiddleSelfie,
                                              data?.status[0]
                                                ?.installerStartSelfie,
                                              data?.status[0]
                                                ?.inverterScanStatus,
                                              data?.status[0]?.photosStatus,
                                            ];
                                            const allStatusesFinished =
                                              statusArray.every(
                                                (status) => status === 1
                                              );
                                            if (!allStatusesFinished) {
                                              alert("Job Not Finished Yet.");
                                              return;
                                            } else {
                                              window.open(
                                                "/dashboard/written-statement",
                                                "_blank"
                                              );
                                            }
                                          }}
                                          // disabled={data?.statusCode === 7}
                                        >
                                          Retailer Declaration
                                        </button>
                                      </li>
                                    </>
                                  ) : (
                                    <li className="system-img buttonBox">
                                      <button
                                        type="button"
                                        className="traderDashButtons"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "jobId",
                                            data?._id
                                          );
                                          const statusArray = [
                                            data?.status[0]?.CustomerSignStatus,
                                            data?.status[0]?.PanelScanStatus,
                                            data?.status[0]
                                              ?.designerDeclarationStatus,
                                            data?.status[0]
                                              ?.documentsUploadStatus,
                                            data?.status[0]
                                              ?.electricianDeclarationStatus,
                                            data?.status[0]
                                              ?.installerDeclarationStatus,
                                            data?.status[0]?.installerEndSelfie,
                                            data?.status[0]
                                              ?.installerMiddleSelfie,
                                            data?.status[0]
                                              ?.installerStartSelfie,
                                            data?.status[0]?.inverterScanStatus,
                                            data?.status[0]?.photosStatus,
                                          ];
                                          const allStatusesFinished =
                                            statusArray.every(
                                              (status) => status === 1
                                            );
                                          if (!allStatusesFinished) {
                                            alert("Job Not Finished Yet.");
                                            return;
                                          } else {
                                            window.open(
                                              "/dashboard/written-statement",
                                              "_blank"
                                            );
                                          }
                                        }}
                                        // disabled={data?.statusCode === 7}
                                      >
                                        Retailer Declaration
                                      </button>
                                    </li>
                                  )}
                                </>
                              )}
                              {/* </li> */}
                              {userType === "Trader" ? (
                                <li
                                  className="system-img buttonBox"
                                  style={{ display: "none" }}
                                >
                                  <button
                                    type="button"
                                    className="traderDashButtons button XMLButton"
                                    onClick={() =>
                                      data?.isSPV
                                        ? handleExport("XML", data?._id)
                                        : handleGenerateButton(data)
                                    }
                                    disabled={data?.statusCode === 3}
                                  >
                                    {buttonLoading ? (
                                      <BeatLoader color="#fff" />
                                    ) : data?.isSPV ? (
                                      <Badge
                                        badgeContent={data?.countXML}
                                        color="success"
                                        style={{
                                          display: "flex",
                                          marginTop: "6px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "#fff",
                                            marginRight: "6px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <Download
                                            sx={{ marginRight: "2px" }}
                                          />{" "}
                                          XML
                                        </span>
                                      </Badge>
                                    ) : (
                                      `Generate SDP`
                                    )}
                                  </button>
                                </li>
                              ) : null}
                              {data?.statusCode === 6 ? (
                                <li className="system-img buttonBox ">
                                  <button
                                    type="button"
                                    className="traderDashButtons button"
                                    onClick={() => {
                                      localStorage.setItem("jobId", data?._id);
                                      handleButtonClick(3);
                                    }}
                                  >
                                    Revisit Site
                                  </button>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                        <div>
                          <ul className="system-detail">
                            <li className="system-img panel-details">
                              {data?.panelDetail?.panelInfo.map(
                                (panel, index) => (
                                  <div key={index} class="text-no">
                                    {panel.panelModel && panel.Quantity ? (
                                      <p>
                                        {panel.panelBrand} {panel.panelModel} -{" "}
                                        <b>
                                          {"("}
                                          {panel.Quantity}
                                          {")"}
                                        </b>
                                      </p>
                                    ) : (
                                      <p> Panel Details N/A</p>
                                    )}
                                  </div>
                                )
                              )}
                            </li>
                            <li className="system-img panel-details">
                              {/* <span >
                            <b> */}
                              {data?.inverterDetail?.inverterInfo &&
                                data.inverterDetail.inverterInfo.map(
                                  (inverter, index) => (
                                    <div key={index} class="text-no">
                                      {inverter.inverterModel &&
                                      inverter.Quantity ? (
                                        <p>
                                          {inverter.inverterBrand}{" "}
                                          {inverter.inverterModel} -{" "}
                                          <b>
                                            {"("}
                                            {inverter.Quantity}
                                            {")"}
                                          </b>
                                        </p>
                                      ) : (
                                        <p> Inverter Details N/A</p>
                                      )}
                                    </div>
                                  )
                                )}
                              {/* </b>
                          </span> */}
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="jobCardRightSection">
                        <div className="all-person">
                          <ul className="popupsystem-all">
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/panels-blue.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Panels Scan Status
                                </span>
                              </a>
                              <p className="house-no">
                                {data?.panelsUploadedCount}
                              </p>
                              {data?.status[0]?.PanelScanStatus === 1 ? (
                                <img
                                  className="tick-mark"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/panel.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Inverter Scan Status
                                </span>
                              </a>

                              {data?.status[0]?.inverterScanStatus === 1 ? (
                                <img
                                  className="tick-panel"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/installer-person.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Installer Signature Status
                                </span>
                              </a>

                              {data?.status[0]?.installerDeclarationStatus ===
                              1 ? (
                                <img
                                  className="tick-right"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/designer-person.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Designer Signature Status
                                </span>
                              </a>

                              {data?.status[0]?.designerDeclarationStatus ===
                              1 ? (
                                <img
                                  className="tick-right"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/ele-man.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Customer Signature Status
                                </span>
                              </a>

                              {data?.status[0]?.CustomerSignStatus === 1 ? (
                                <img
                                  className="tick-right"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/house-with-no.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Property Image Status
                                </span>
                              </a>

                              {data?.status[0]?.propertyScanStatus === 1 ? (
                                <img
                                  className="tick-right"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                          </ul>
                          <ul className="teampopup-all">
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/action.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Documents Upload Status
                                </span>
                              </a>
                              {data?.status[0]?.documentsUploadStatus === 1 ? (
                                <img
                                  className="tick-action"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/person-main.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Photos Upload Status
                                </span>
                              </a>
                              {data?.status[0]?.photosStatus === 1 ? (
                                <img
                                  className="tick-panel"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/start-selfi.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Start Selfie Status
                                </span>
                              </a>

                              {data?.status[0]?.installerStartSelfie === 1 ? (
                                <img
                                  className="tick-right"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/middle-selfi.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Middle Selfie Status
                                </span>
                              </a>

                              {data?.status[0]?.installerMiddleSelfie === 1 ? (
                                <img
                                  className="tick-right"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/end-selfi.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  End Selfie Status
                                </span>
                              </a>

                              {data?.status[0]?.installerEndSelfie === 1 ? (
                                <img
                                  className="tick-right"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/ele-man.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Retailer Signature Status
                                </span>
                              </a>

                              {data?.status[0]?.retailerSignStatus === 1 ? (
                                <img
                                  className="tick-right"
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                          </ul>
                        </div>
                        <div className="JobActions">
                          {userType != "Trader" ? (
                            <ul className="system-all buttonContainer">
                              <li className="system-img">
                                {activeFilter !== 17 ? (
                                  <button
                                    className="edit-job"
                                    style={{
                                      display:
                                        data?.statusCode === 10
                                          ? "none"
                                          : "flex",
                                    }}
                                    onClick={() => {
                                      if (data?.statusCode !== 6) {
                                        alert(
                                          "Job Not Finished Yet from Installer Side."
                                        );
                                        return;
                                      }
                                      localStorage.setItem("jobId", data?._id);
                                      setShowSubmitConfirmation(true);
                                    }}
                                    disabled={data?.statusCode === 7}
                                  >
                                    Submit Job
                                  </button>
                                ) : null}
                              </li>
                            </ul>
                          ) : (
                            <ul className="system-all buttonContainer">
                              <li className="system-img">
                                <button
                                  className="edit-job"
                                  onClick={() => {
                                    localStorage.setItem("jobId", data?._id);
                                    handleButtonClick(11);
                                  }}
                                  style={{
                                    display:
                                      activeFilter !== 10 ? "none" : "block",
                                  }}
                                >
                                  Submit to CER
                                </button>
                              </li>
                            </ul>
                          )}
                          <ul className="system-all buttonContainer">
                            <li className="system-img">
                              <button
                                className="edit-job"
                                style={{ fontSize: "12px" }}
                                onClick={() => {
                                  handleDownloadClick(data);
                                }}
                                // disabled={data?.statusCode === 7}
                              >
                                {jobPackLoadingID === data._id ? (
                                  <BeatLoader color="#fff" />
                                ) : (
                                  "Job Pack"
                                )}
                              </button>
                            </li>
                          </ul>
                          <ul className="system-all buttonContainer">
                            <li className="system-img">
                              <button
                                className="edit-job"
                                style={{ fontSize: "12px" }}
                                onClick={() => {
                                  handleInstallerPresenseClick(
                                    data?._id,
                                    data?.status[0]?.installerEndSelfie
                                  );
                                }}
                              >
                                Installer Presence
                              </button>
                            </li>
                          </ul>

                          <ul>
                            <NotesComponent data={data} test={test} />
                          </ul>
                          {userType != "Trader" ? (
                            <ul className="system-all buttonContainer">
                              <li className="system-img">
                                {activeFilter !== 17 ? (
                                  <button
                                    type="button"
                                    className="edit-job"
                                    onClick={() => {
                                      localStorage.setItem("jobId", data?._id);
                                      {
                                        data?.statusCode === 9
                                          ? setShowDeleteConfirmation(true)
                                          : handleButtonClick(9);
                                      }
                                    }}
                                    disabled={data?.statusCode === 7}
                                  >
                                    {data?.statusCode === 9
                                      ? "Delete"
                                      : "Cancel"}{" "}
                                    Job
                                  </button>
                                ) : null}
                              </li>
                            </ul>
                          ) : null}

                          {userType !== "Trader" && data?.statusCode === 9 ? (
                            <ul className="system-all buttonContainer">
                              <li className="system-img">
                                <button
                                  type="button"
                                  className="edit-job"
                                  onClick={() => {
                                    localStorage.setItem("jobId", data?._id);
                                    handleButtonClick(3);
                                  }}
                                >
                                  Resume Job
                                </button>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    </div>
                   
                    <span className="dash-border-bottom"></span>

                
                  </div>
                  
                  
                  
                ))
                
                
                
              ) : (
                <p style={{ textAlign: "center" }}>No Jobs Available</p>
              )}
              
            </div>
            

              {
                

                  totalPages > 0 &&
                    <Stack spacing={2} alignItems="center">
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Stack>  
                  
                
              }
          </div>
          
    

        </div>
        
      </div>
      <Chat userType={userType} />
    </div>
  );
};

export default Dashboard;
