import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_GOOGLE_MAP_API;

const InstallerPresenceReport = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  const [date, setDate] = useState();
  const [address, setAddress] = useState();
  const [installerName, setInstallerName] = useState();
  const [CECID, setCECID] = useState();
  const [photos, setPhotos] = useState([]);

  const fetchData = async () => {
    try {
      const resp = await axios.get(`${baseURL}/jobMaster/${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp.data.data[0];
      console.log(data);
      setDate(data?.jobDetails?.installationDate?.installingDate);
      setAddress(data?.jobDetails?.installationAddress);
      setInstallerName(data?.jobDetails?.installerName);
      setCECID(data?.installers[0]?.installerCECID);
      setPhotos(data?.photos[0]?.installerPresence);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  // Function to open the browser's print dialog
  const openPrintDialog = () => {
    window.print();
  };
  const NameWithSpace = (name) => {
    return name.replace(/([A-Z]+)/g, " $1").trim();
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2 style={{ paddingTop: "15px" }}>Installer Presence Report</h2>
      <br />
      <div style={{ display: "flex", gap: "10%", width: "572px" }}>
        <div style={{ justifyContent: "flex-start" }}>
          <p>Installer Name: {installerName}</p>
          <p>CECID: {CECID}</p>
        </div>
        <div style={{ justifyContent: "flex-start" }}>
          <p>Installer Date: {moment(date).format("DD/MM/YYYY")}</p>
          <p>
            Instalaltion Address: {address?.unitType} {address?.unitNumber},{" "}
            {address?.streetNumber} {address?.streetName} {address?.streetType}{" "}
            {address?.suburb} {address?.state} {address?.postCode}
          </p>
        </div>
      </div>
      <br />
      {photos?.map((photo, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            gap: "10%",
            padding: "10px",
            marginLeft:
              photo.distance === null || photo.distance === undefined
                ? "-370px"
                : "0px",
          }}
        >
          <div style={{ justifyContent: "flex-start" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img
                src={photo?.url}
                alt={photo?.type}
                style={{ width: "220px", height: "220px" }}
              />
              <label>{NameWithSpace(photo?.type).toUpperCase()}</label>
            </div>
          </div>
          <div style={{ justifyContent: "flex-start" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {photo.latitude && photo.longitude ? (
                <div style={{ position: "relative", right: "0" }}>
                  <GoogleMap
                    mapContainerStyle={{
                      width: "371px",
                      height: "156px",
                      overflow: "hidden",
                    }}
                    center={{
                      lat: photo.latitude,
                      lng: photo.longitude,
                    }}
                    zoom={15}
                    onLoad={onMapLoad}
                    options={{
                      mapTypeControl: true,
                    }}
                  >
                    <MarkerF
                      position={{
                        lat: photo.latitude,
                        lng: photo.longitude,
                      }}
                    />
                  </GoogleMap>
                </div>
              ) : null}
              {photo.distance ? (
                <>
                  <label>Latitude: {photo.latitude}</label>
                  <label>Longitude: {photo.longitude}</label>
                  <label>
                    Distance from Site:{" "}
                    {photo.distance > 1
                      ? `${photo.distance} km`
                      : `${photo.distance * 1000} m`}
                  </label>
                </>
              ) : null}
            </div>
          </div>
        </div>
      ))}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <button
          type="button"
          // onClick={downloadPDF}
          onClick={openPrintDialog}
          style={{
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            padding: "5px 10px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "12px",
            cursor: "pointer",
          }}
        >
          Print
        </button>
      </div>
    </div>
  );
};

export default InstallerPresenceReport;
