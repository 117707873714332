import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Aside from "./FixedBar/Aside";
import HeaderComponent from "./Dashboard/CommonComponents/HeaderComponent";
import { format } from "date-fns";
import moment from "moment";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const SiteInspection = () => {
  const token = localStorage.getItem("userToken");
  const retailerId = localStorage.getItem("userId");
  const navigate = useNavigate();

  const [inspectorData, setInspectorData] = useState([]);

  useEffect(() => {
    const test = async () => {
      try {
        const resp = await axios.get(`${baseURL}/installer/details`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = resp.data;

        const filteredData = data.installerData
          .map((innerArray) => innerArray)
          .filter((innerArray) =>
            innerArray.retailers.some(
              (retailer) =>
                retailer._id === retailerId && retailer.isConnect === 1
            )
          )
          .filter(
            (installer) =>
              installer.installer &&
              installer.installer.selectYourRole &&
              installer.installer.selectYourRole.includes("Installer")
          );

        const simplifiedData = filteredData.map((installerData) => ({
          name: installerData?.installer?.name,
          id: installerData?._id,
          cecExpiry: format(
            new Date(installerData?.installerlicenceExpiry),
            "yyyy-MM-dd"
          ),
        }));

        setInspectorData(simplifiedData);
      } catch (err) {}
    };

    test();
  }, []);

  const [formData, setFormData] = useState({
    startTime: "",
    endTime: "",
    inspectorName: "",
    inspectorId: "",
    jobId: "",
  });

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selectedData = inspectorData.find(
      (installer) => installer.id === selectedId
    );
    setFormData((prev) => ({
      ...prev,
      inspectorName: selectedData.name,
      inspectorId: selectedData.id,
    }));
  };

  const handleInspectionTime = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      jobId: localStorage.getItem("jobId"),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${baseURL}/users/siteInspection`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert("Site Inspection Created Successfully");

        setTimeout(() => {
          navigate("/dashboard");
        }, 800);
      } else {
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  return (
    <div>
      {/* <div style={{ display: "flex" }}> */}
        {/* <div> */}
          <Aside />
        {/* </div> */}
        <div>     
        <div className="main-part-ratailer">
          <div>

          <HeaderComponent
            PageTitle={"Assign Site Inspection"}
            location="Dashboard"
            subTitle="Dashboard/View Site Inspection"
            />
            </div>
          <form onSubmit={handleSubmit}>
            <div className="assign-bg">
              <h3 className="site-text">Assign Site Inspection</h3>
              <br />
              <div className="assign-site-inspection-main-class">
                <div className="assign-site-inspection-sub-class">
                  <p>
                    <b>Start Time</b>
                  </p>
                  <input
                    className="start-time"
                    type="datetime-local"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleInspectionTime}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p>
                    <b>End Time</b>
                  </p>
                  <input
                    className="end-time"
                    type="datetime-local"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleInspectionTime}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p>
                    <b>Select Inspector</b>
                  </p>
                  <div className="end-time">
                    <select
                      onChange={handleSelectChange}
                      value={inspectorData.id}
                      required
                      className="end-time"
                    >
                      <option value="">SELECT</option>
                      {inspectorData.map((installer) => {
                        const date = moment(formData.startTime);
                        let cecExpiry = moment(installer?.cecExpiry);
                        const fifteenDaysBeforeExpiry = cecExpiry.diff(
                          date,
                          "days"
                        );
                        if (fifteenDaysBeforeExpiry <= 14) {
                          return null;
                        } else {
                          return (
                            <option key={installer.id} value={installer.id}>
                              {installer.name}
                            </option>
                          );
                        }
                        <option key={installer.id} value={installer.id}>
                          {installer.name}
                        </option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <button type="submit" className="submit-main-btn">
                Submit
              </button>
              <button onClick={() => navigate(-1)} className="cancelbtn">
                Cancel
              </button>
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default SiteInspection;
