import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const SignatureForMobile = () => {
  const { _id } = useParams();
  localStorage.setItem("SignId", _id);

  const navigate = useNavigate();
  const sigCanvasRef = useRef({});
  const [url, setURL] = useState();

  const handleClear = () => {
    sigCanvasRef.current.clear();
    setURL(null);
  };

  const handleGenerate = async () => {
    if (!sigCanvasRef.current.isEmpty()) {
      const signatureDataURL = sigCanvasRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setURL(signatureDataURL);

      fetch(signatureDataURL)
        .then((res) => res.blob())
        .then(async (blob) => {
          const signatureFile = new File([blob], "customerSignature.png", {
            type: "image/png",
          });

          const formData = new FormData();
          formData.append("jobId", _id);
          formData.append("customerSignature", signatureFile);

          try {
            const response = await axios.post(
              `${baseURL}/update/signatureCustomer`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (response.status === 200 || response.status === 201) {
              alert("Signature Sent Successfully");
              window.close();
            } else {
            }
          } catch (error) {
            alert("Error Submitting Signature");
          }
        });
    } else {
      alert("Please provide a signature first.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "15px",
      }}
    >
      {url && (
        <img
          src={url}
          alt="Signature preview"
          style={{ border: "1px solid black", width: "100px", height: "100px" }}
        />
      )}
      <SignatureCanvas
        ref={sigCanvasRef}
        penColor="black"
        canvasProps={{
          width: 500,
          height: 500,
          style: { border: "2px solid black" },
          className: "sigCanvas",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <button onClick={handleGenerate}>Save</button>
        <button onClick={handleClear}>Reset</button>
      </div>
    </div>
  );
};

export default SignatureForMobile;
