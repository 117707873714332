import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate, useHistory } from "react-router-dom";
import axios from "axios";
import Popup from "reactjs-popup";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const Login = () => {
  const [userType, setUserType] = useState("Retailer");
  const [eye, setEye] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const storedUserName = Cookies.get("userName");
    const storedPassword = Cookies.get("password");
    if (storedUserName && storedPassword) {
      setUserName(storedUserName);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      userName: userName,
      password: password,
      userType: userType,
    };

    if (userName === "") {
      setErrorMsg("Please enter username");
      setLoading(false);
    } else if (password === "") {
      setErrorMsg("Please enter password");
      setLoading(false);
    } else {
      if (rememberMe) {
        Cookies.set("userName", userName, { expires: 1 });
        Cookies.set("password", password, { expires: 1 });
      } else {
        Cookies.remove("userName");
        Cookies.remove("password");
      }

      let apiUrl = "";
      if (userType === "Trader") {
        apiUrl = `${baseURL}/traders/login`;
      } else {
        apiUrl = `${baseURL}/users/login`;
      }

      try {
        const response = await axios.post(apiUrl, requestData);
        if (response.status === 200) {
          const responToken = response.data.token;
          const userType = response.data.userType;
          const firstName = response?.data?.basicInformation?.firstName;
          const lastName = response?.data?.basicInformation?.lastName;
          const initials =
            firstName.charAt(0).toUpperCase() +
            lastName.charAt(0).toUpperCase();
          const name = `${firstName} ${lastName}`;

          let userId;
          if (userType === "Trader") {
            userId = response?.data?.traderId;
          } else {
            userId = response?.data?.userId;
          }

          localStorage.setItem("userToken", responToken);
          localStorage.setItem("userId", userId);
          localStorage.setItem("userType", userType);
          localStorage.setItem("initials", initials);
          localStorage.setItem("name", name);
          if (userType === "Trader") {
            navigate("/retailers");
          } else {
            navigate("/dashboard");
          }
        } else {
          setErrorMsg("Login failed. Please try again.");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert(error?.response?.data?.message);
          setErrorMsg("Username or password is incorrect.");
        } else if (error.response && error.response.status === 404) {
          console.log(error.response);
          setErrorMsg(
            error.response.data.message ||
              "An error occurred. Please try again."
          );
        } else if (error.response && error.response.status === 403) {
          console.log(error.response);
          setErrorMsg("An error occurred.");
          alert(error.response.data.message || "An error occurred.");
        } else {
          console.log(error.response);
          setErrorMsg("An error occurred. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const eyeChange = () => {
    setEye(!eye);
  };

  const handleNavigation = () => {
    navigate("/forgotPassword", { state: { userType: userType } });
  };

  return (
    <div className="login-container">
      <div className="bg-overlay"></div>
      <div style={{ height: "72%" }}>
        <div className="logo">
          <img
            src="/Assets/images/uveaa-logo.svg"
            alt="Logo Here"
            width={"200px"}
          />
        </div>
        <section className="login-box main-box">
          <div className="retailer-logo">
            <p>Login</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              type="button"
              style={{
                backgroundColor:
                  userType === "Retailer"
                    ? "rgba(0, 67, 229, 0.3)"
                    : "transparent",
                color: "#333",
                border: "1px solid #ccc",
                padding: "8px 16px",
                cursor: "pointer",
              }}
              onClick={() => setUserType("Retailer")}
            >
              Retailer
            </button>
            <button
              type="button"
              style={{
                backgroundColor:
                  userType === "Trader"
                    ? "rgba(0, 67, 229, 0.3)"
                    : "transparent",
                color: "#333",
                border: "1px solid #ccc",
                padding: "8px 16px",
                cursor: "pointer",
              }}
              onClick={() => setUserType("Trader")}
            >
              Trader
            </button>
          </div>
          <div className="row">
            <form className="form-container">
              <label htmlFor="username" className="Label-text-Login">
                User Name
              </label>
              <div className="user-form">
                <input
                  id="username"
                  type="text"
                  value={userName || ""}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Username"
                />
                <div className="login-form-imag"></div>
              </div>
              <div className="pas-form">
                <div className="text-password">
                  <label htmlFor="password" className="label-password">
                    Password
                  </label>
                </div>
                <input
                  id="password"
                  className="psd-toggle"
                  type={eye ? "text" : "password"}
                  value={password || ""}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                <div className="login-form-imag lock-password"></div>
                <button onClick={eyeChange} type="button" className="eye-btn">
                  <img
                    className="eye-login"
                    src={
                      !eye
                        ? `${image}/Assets/LogIn/openview-eye.svg`
                        : `${image}/Assets/LogIn/view-eye.svg`
                    }
                    alt=""
                  />
                </button>
              </div>

              <br />
              <div className="Login_Fgpwd_container">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />{" "}
                  Remember Me
                </label>
                <button
                  type="button"
                  onClick={handleNavigation}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Forgot password?
                </button>
              </div>

              <button
                loging="true"
                className="login-btn"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? "Logging in..." : "LOGIN"}
              </button>

              <span
                style={{
                  color: "red",
                  position: "relative",
                  textAlign: "center",
                  margin: "0 auto",
                }}
              >
                {errorMsg}
              </span>
            </form>
          </div>
        </section>
        <div className="Loging-signForg-container">
          {/* <span className="signup-text">Don't have an account ?</span>

          <Popup
            trigger={
              <Link to="/register" className="sign-up">
                Signup
              </Link>
            }
          ></Popup> */}

          <div className="trouble-login" style={{ display: "none" }}>
            <span>Trouble in logging? </span>
            <a href="/">get in Touch</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
