import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { NameFromURL } from "../Utils";
import { FadeLoader } from "react-spinners";
import { Tooltip } from "@mui/material";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const DocumentRequired = (props) => {
  const { jobType, getJobData, jobData } = props;
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  //Storing Data from API
  const [documentData, setDocumentData] = useState([]);
  const [getLoading, setGetLoading] = useState(false);
  const fetchOnSiteData = async () => {
    setGetLoading(true);
    try {
      const resp = await axios.get(`${baseURL}/onSite`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp?.data?.data;
      const flattenedData = data.flat();
      const result = flattenedData.filter((item) => item.jobId === jobId);
      const documents = result[0]?.documents;
      setDocumentData(documents);
      setGetLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setGetLoading(false);
    }
  };
  //Get On Site Details Starts
  useEffect(() => {
    fetchOnSiteData();
  }, []);
  //Get On Site Details Ends

  // Function to download files
  const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //Function to convert data into Title Case
  const toTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (sub) => sub.toUpperCase())
      .trim();
  };

  //Upload Files Starts
  const [formdata, setformData] = useState(jobData);

  const fileMapping = {
    // "STC Form": "STCForm",
    "STC Invoice": "STCInvoice",
    Design: "design",
    "Custom Invoice": "customInvoice",
    "Electricity Bill": "uploadElectricityBill",
    "Testing Certificate": "testingCertificate",
    "Notice of Completion": "noticeOfCompletion",
    "Electrical Compliance Certificate": "electricalCompliance",
    "Testing and Commissioning Sheet": "testingAndCommissioningSheet",
    "Installation Sketch": "installationSketch",
    "Variation Form": "variationForm",
    "Reduced Performance Form": "reducedPerformanceForm",
    "EWR Form": "ewrForm",
    "Customer Invoice": "customerInvoice",
    "Voltage Rise Calculation": "voltageRiseCalculation",
    "Other Documents": "otherDocuments",
  };

  const SWHFileMapping = {
    "Customer Invoice": "customerInvoice",
    "Claim STC Payment": "claimStcPayment",
    "Electrical Compliance": "electricalCompliance",
    "Plumbing Certificate": "plumbingCertificate",
    "Other Documents": "otherDocuments",
  };
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (file, key) => {
    setformData((prevFormData) => ({
      ...prevFormData,
      [key]: file,
    }));
    setGetLoading(true);

    try {
      const formData = new FormData();

      const relevantFields = [
        "design",
        "customInvoice",
        "uploadElectricityBill",
        "STCInvoice",
        "testingCertificate",
        "noticeOfCompletion",
        "electricalComplianceCertificate",
        "customerInvoice",
        "claimStcPayment",
        "electricalCompliance",
        "plumbingCertificate",
        "testingAndCommissioningSheet",
        "installationSketch",
        "variationForm",
        "reducedPerformanceForm",
        "ewrForm",
        "customerInvoice",
        "voltageRiseCalculation",
        "otherDocuments",
      ];

      relevantFields.forEach((field) => {
        if (jobData.hasOwnProperty(field)) {
          formData.append(field, jobData[field]);
        }
      });
      formData.append(key, file);
      formData.append("jobId", jobId);
      const response = await axios.post(
        `${baseURL}/onSite/Documents/upload`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Files Uploaded Successfully");
        fetchOnSiteData();
        setGetLoading(false);
      } else {
        console.log("Error: " + (response.data || response.statusText));
        setGetLoading(false);
      }
    } catch (error) {
      console.error("Error in update request:", error);
      console.log("Error status:", error.response?.status);
      alert(error.response?.data?.message);
      setGetLoading(false);
    }
  };

  const handleFileDelete = async (docId, formDataKey, field) => {
    if (field == "Uploaded") {
      try {
        const response = await axios.delete(`${baseURL}/onSite/deleteForWeb`, {
          params: {
            jobId: jobId,
            type: formDataKey,
            documentId: docId,
          },
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 || response.status === 201) {
          alert("Document Deleted Successfully");
          fetchOnSiteData();
        } else {
          console.log("Error: " + (response.data || response.statusText));
        }
        // setUploadLoading(false);
      } catch (error) {
        console.error("Error deleting Document:", error);
        console.log("Error Status:", error.response?.status);
        if (error?.response?.status === 400) {
          alert("Document Delete Failed" || error?.response?.data);
        }
        // setUploadLoading(false);
      }
    } else {
      setformData((prevFormData) => {
        const newFormData = { ...prevFormData };
        delete newFormData[formDataKey];
        return newFormData;
      });
    }
  };
  const handleFileInputChange = (formDataKey, index) => (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFileUpload(file, formDataKey);
    }
  };

  const triggerFileInput = (formDataKey) => {
    const fileInput = document.getElementById(`fileInput-${formDataKey}`);
    fileInput && fileInput.click();
  };

  const getReadableName = (formDataKey, fileMapping) => {
    const invertedMapping = Object.entries(fileMapping).reduce(
      (acc, [key, value]) => {
        acc[value] = key;
        return acc;
      },
      {}
    );
    return invertedMapping[formDataKey] || "Unknown";
  };
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="row">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="panel-photo">
              <span className="panel-ser">Documents</span>
            </div>
            <div className="row">
              <p className="panel-ser">Upload Documents</p>
              <div className="ImagesUploadDiv">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <FadeLoader height={20} />
                  </div>
                ) : (
                  Object.entries(
                    jobType !== "SWH" ? fileMapping : SWHFileMapping
                  ).map(([itemName, formDataKey], index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        padding: "10px 5px",
                        borderRadius: "5px",
                        gap: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "220px",
                          height: "52px",
                          backgroundColor: "#E0EDFF",
                          borderRadius: "5px",
                        }}
                      >
                        <div class="text-requireddoc">
                          {/* <a href={jobData[formDataKey]} target="_blank"> */}
                          <label
                            for="surName"
                            class="label-requireddoc label-maindoc"
                            style={{ width: "240px" }}
                          >
                            {getReadableName(
                              formDataKey,
                              jobType !== "SWH" ? fileMapping : SWHFileMapping
                            )}
                          </label>
                          {/* </a> */}
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id={`fileInput-${formDataKey}`}
                            accept="image/png, image/jpeg, application/pdf"
                            name="uploadFile"
                            onChange={handleFileInputChange(formDataKey, index)}
                          />
                        </div>
                        <span
                          style={{ position: "relative", cursor: "pointer" }}
                        >
                          {/* {formdata[formDataKey] ? (
                            <a href={jobData[formDataKey]} target="_blank">
                              {" "}
                              {getReadableName(
                                formDataKey,
                                jobType !== "SWH" ? fileMapping : SWHFileMapping
                              )}{" "}
                            </a>
                          ): ( */}
                          No File selected
                          {/* )} */}
                        </span>
                        {/* {formdata &&
                        formdata?.hasOwnProperty(formDataKey) &&
                        formdata[formDataKey] &&
                        formdata[formDataKey] !== "" ? ( */}
                        {/* <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginRight: "14px",
                            }}
                          > */}
                        {/* <button
                              type="button"
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleDownload(
                                  formdata[formDataKey],
                                  formdata[formDataKey].name
                                )
                              }
                            >
                              <img
                                src={`${image}/Assets/ManageFolder/download-btn.svg`}
                                alt="Download"
                              />
                            </button>
                            <button
                              type="button"
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                              }}
                              onClick={() => handleFileDelete(formDataKey)}
                            >
                              <img
                                src={`${image}/Assets/UploadFiles/subtract.svg`}
                                alt="Delete"
                              />
                            </button>
                          </div> */}
                        {/* // ) : ( */}
                        <button
                          type="button"
                          style={{
                            backgroundColor: "#E0EDFF",
                            border: "none",
                            cursor: "pointer",
                            marginRight: "15px",
                          }}
                          onClick={() => triggerFileInput(formDataKey)}
                        >
                          <img
                            src={`${image}/Assets/UploadFiles/doc-upload.svg`}
                            alt="Upload"
                            style={{
                              position: "relative",
                            }}
                          />
                        </button>
                        {/* )} */}
                      </div>

                      {/* {formdata[formDataKey] && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "auto",
                            height: "52px",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              padding: "0 15px",
                            }}
                          >
                            <button
                              type="button"
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                              onClick={() =>
                                handleDownload(
                                  formdata[formDataKey],
                                  formdata[formDataKey].name
                                )
                              }
                            >
                              <img
                                src={`${image}/Assets/ManageFolder/download-btn.svg`}
                                alt="Download"
                              />
                            </button>
                            <button
                              type="button"
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                              onClick={() => handleFileDelete(formDataKey)}
                            >
                              <img
                                src={`${image}/Assets/UploadFiles/subtract.svg`}
                                alt="Delete"
                              />
                            </button>
                          </div>
                        </div>
                      )} */}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div>
            {getLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FadeLoader height={20} />
              </div>
            ) : documentData && documentData?.length ? (
              <>
                {" "}
                <span className="panel-ser">Uploaded documents</span>
                <div style={{ display: "flex", flexWrap: "wrap",flexDirection:"row" }}>
                  {/* <div> */}
                    {documentData?.map((doc, index) => (
                      <div key={index}  style={{ display: "flex", flexWrap: "wrap" ,flexDirection:"row"}}>
                        {doc?.documents
                          .filter((document) => !document?.deleted) 
                          .map((document, docIndex) => (
                            <div key={docIndex}>
                              <div className="design-document">
                                <div
                                  className="upload-label"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: "25px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div className="download-document">
                                    <a
                                      href={document.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={`${image}/Assets/DocumentRequired/Download.svg`}
                                        alt="Download"
                                        className="download-icon"
                                      />
                                    </a>
                                  </div>

                                  <img
                                    src={`${image}/Assets/DocumentRequired/google-docs.svg`}
                                    alt="Document"
                                    className="doc-google"
                                    style={{ width: "100px" }}
                                  />
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: 10,
                                      top: 10,
                                    }}
                                  >
                                    <Tooltip title="Delete Document">
                                      <button
                                        type="button"
                                        className="add-model-close-btn"
                                        onClick={() =>
                                          handleFileDelete(
                                            document._id,
                                            doc.type,
                                            "Uploaded"
                                          )
                                        }
                                        style={{
                                          fontSize: "15px",
                                          background: "#1543b0",
                                        }}
                                      >
                                        ✕
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                              <span
                                style={{
                                  marginLeft: "20px",
                                  color: "rgba(44, 58, 100, 1)",
                                  fontWeight: "400",
                                }}
                              >
                                {doc.type === "uploadElectricityBill"
                                  ? "Electricity Bill"
                                  : doc.type === "ewrForm"
                                  ? "EWR Form"
                                  : doc.type === "STCInvoice"
                                  ? "STC Invoice"
                                  : toTitleCase(doc.type)}
                              </span>
                              {document.distance ? (
                                <span
                                  style={{
                                    marginLeft: "20px",
                                    color: "rgba(44, 58, 100, 1)",
                                    fontWeight: "400",
                                  }}
                                >
                                  Distance:{" "}
                                  {document.distance > 1
                                    ? `${document.distance} km`
                                    : `${document.distance * 1000} m`}
                                </span>
                              ) : null}
                            </div>
                          ))}
                      </div>
                    ))}
                  {/* </div> */}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentRequired;
