import React from "react";
import Aside from "./FixedBar/Aside";
import HeaderComponent from "./Dashboard/CommonComponents/HeaderComponent";

const Help = () => {
  return (
    <div className="MainOuterContainer">
      <Aside />
      <div style={{width:'100%'}}>
      <div className=" main-part-ratailer">
        <div className="row">
          <HeaderComponent PageTitle={"Help"} />
          <div className="help-section-dashboard">
            <iframe
              width="100%"
              height="100%"
              src="https://installer-profile-photos.s3.ap-southeast-2.amazonaws.com/videoTutorial.mp4"
              title="Video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
