import React from 'react';
import './Home.css'
const JobBoxes = (props) => {
    const{label1,label2,label3,data1,data2,data3}=props
    return (
        <div style={{marginTop:20, }} className="homeDataSections"s>
             <div className="boxStyle">
              <div style={{ fontSize: "20px", textAlign: "center" }}>
                {label1}
              </div>
              <div style={{ fontSize: "25px", fontWeight: "bold" }}>{data1|| 'N/A'}</div>
            </div>
            <div className="boxStyle">
              <div style={{ fontSize: "20pxx", textAlign: "center" }}>
                {label2}
              </div>
              <div style={{ fontSize: "25px", fontWeight: "bold" }}>{`${data2}` || 'N/A'}</div>
            </div>
            <div className="boxStyle">
              <div style={{ fontSize: "20px", textAlign: "center" }}>
                {label3}
              </div>
              <div style={{ fontSize: "25px", fontWeight: "bold" }}>{data3 ||'N/A'}</div>
            </div>
        </div>
    );
}

export default JobBoxes;
