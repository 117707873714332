import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Aside from "../FixedBar/Aside";
import HeaderComponent from "./CommonComponents/HeaderComponent";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const ViewSiteInspection = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");
  const userType = localStorage.getItem("userType")
  const navigate = useNavigate();

  const [siteInspectionData, setSiteInspectionData] = useState();
  const [jobData, setJobData] = useState();
  useEffect(() => {
    const test = async () => {
      try {
        const resp = await axios.get(
          `${baseURL}/users/siteInspectionGet/${jobId}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = resp?.data;
        setSiteInspectionData(result[0]);
      } catch (err) {}
    };
    test();
  }, []);

  useEffect(() => {
    const fetchJobData = async () => {
      
      let apiUrl = "";
      if (userType === "Trader") {
        apiUrl = `${baseURL}/traders/jobs`;
      } else {
        apiUrl = `${baseURL}/job/all`;
      }
      try {
        
        const resp = await axios.get(apiUrl, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        const flattenedData = resp.data.jobs.flat();
        const result = flattenedData.filter((item) => item._id === jobId);
        setJobData(result[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchJobData();
  }, []);

  function formatDateTimeLocal(date) {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}T${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    return formattedDate;
  }
  const startTime = formatDateTimeLocal(siteInspectionData?.startTime);
  const endTime = formatDateTimeLocal(siteInspectionData?.endTime);
  const installationDate = formatDateTimeLocal(
    jobData?.installationDate?.installingDate
  );

  return (
    <>
      <div >
        {/* <div> */}
          <Aside />
        {/* </div> */}
        <div style={{width:"100%"}}>
        <div className="main-part-ratailer">

       
          <HeaderComponent
            PageTitle={"Site Inspection"}
            subTitle={"Dashboard / Site Inspection"}
          />
         
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <form>
              <div>
                <div className="assign-bg">
                  <br />
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      <p>
                        <b>Start Time</b>
                      </p>
                      <input
                        className="start-time"
                        type="datetime-local"
                        name="startTime"
                        value={startTime || ""}
                        readOnly
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <p>
                        <b>End Time</b>
                      </p>
                      <input
                        className="end-time"
                        type="datetime-local"
                        name="endTime"
                        value={endTime || ""}
                        readOnly
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      <p>
                        <b>Inspector</b>
                      </p>
                      <div className="select-input">
                        <input
                          type="text"
                          className="select-input"
                          placeholder="Inspector Name"
                          value={siteInspectionData?.inspectorName}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "6px",
                      alignItems: "start",
                    }}
                  >
                    <div>
                      <b className="customer-text">Customer Details</b>
                    </div>
                    <div className="main-detail">
                      <input
                        className="customer-input"
                        id="customername"
                        name="customername"
                        type="text"
                        placeholder="Customer Name"
                        readOnly
                        value={`${jobData?.ownerDetails?.firstName} ${jobData?.ownerDetails?.lastName} ${jobData?.ownerDetails?.companyName}`}
                      />
                      <input
                        className="customer-input"
                        id="customeraddress"
                        name="customeraddress"
                        type="text"
                        placeholder="Customer
                Address"
                        readOnly
                        value={`${jobData?.installationAddress?.unitNumber} ${jobData?.installationAddress?.streetName} ${jobData?.installationAddress?.suburb} ${jobData?.installationAddress?.state} ${jobData?.installationAddress?.postCode}`}
                      />
                      <input
                        className="customer-input"
                        id="customerphone"
                        name="customerphone"
                        type="text"
                        placeholder="Customer
                Phone"
                        readOnly
                        value={jobData?.ownerDetails?.mobileNum}
                      />
                      <input
                        className="customer-input"
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Customer
                E-mail"
                        readOnly
                        value={jobData?.ownerDetails?.emailId}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      alignItems: "start",
                    }}
                  >
                    <div>
                      <b className="customer-text">Installer Details</b>
                    </div>

                    <div className="main-detail">
                      <input
                        className="customer-input"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="
                Name"
                        readOnly
                        value={jobData?.installerName}
                      />
                      <input
                        class="start-time"
                        type="datetime-local"
                        name="startTime"
                        readOnly
                        value={installationDate || ""}
                      ></input>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <b className="customer-text">Health and Safety</b>
                    <div className="siteInspectionSection">
                      <div class="form-group ">
                        <b className="gst-info">
                          Is there clear access to the roof?
                        </b>
                        <div>
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="roofaccessyes"
                                type="radio"
                                className="input-text"
                                name="roofaccessyes"
                                value="true"
                                checked={
                                  siteInspectionData?.clearAccessToRoof === true
                                }
                                readOnly
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="roofaccessyes"
                              >
                                Yes
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="roofaccessno"
                                type="radio"
                                className="input-text"
                                name="roofaccessno"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.clearAccessToRoof ===
                                  false
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="roofaccessno"
                              >
                                No
                              </label>
                            </li>
                          </ul>
                          <div className="retailer-main upload-files">
                            <input
                              className="currentbill-input"
                              type="text"
                              id="uploadElectricityBill"
                              placeholder="Roof Image"
                              accept="image/png, image/jpeg, application/pdf"
                              readOnly
                              value={siteInspectionData?.clearAccessToRoofUrl}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <b className="gst-info">
                          Will a scissor lift be required to safely access the
                          roof?
                        </b>
                        <div>
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="saftyaccessyes"
                                type="radio"
                                className="input-text"
                                name="saftyaccessyes"
                                value="true"
                                readOnly
                                checked={
                                  siteInspectionData?.liftRequired === true
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="saftyaccessyes"
                              >
                                Yes
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="saftyaccessno"
                                type="radio"
                                className="input-text"
                                name="saftyaccessno"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.liftRequired === false
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="saftyaccessno"
                              >
                                No
                              </label>
                            </li>
                          </ul>
                          <div className="retailer-main upload-files">
                            <input
                              className="currentbill-input"
                              id="dropzoneyes"
                              name="dropzoneyes"
                              type="text"
                              placeholder="If No"
                              readOnly
                              value={
                                siteInspectionData?.liftRequiredDescription
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <b className="customer-text">
                      Do you have to cordon off a public footpath or public area
                      as a drop zone / falling objects to complete this
                      installation?
                    </b>
                    <div class="siteInspectionSection siteInspectionColumn">
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="dropyes"
                            type="radio"
                            className="input-text"
                            name="dropyes"
                            value="true"
                            readOnly
                            checked={
                              siteInspectionData?.publicAreaAsDropZone === true
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="dropyes"
                          >
                            Yes
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="dropno"
                            type="radio"
                            className="input-text"
                            name="dropno"
                            value="false"
                            readOnly
                            checked={
                              siteInspectionData?.publicAreaAsDropZone === false
                            }
                          />
                          <label style={{ cursor: "pointer" }} htmlFor="dropno">
                            No
                          </label>
                        </li>
                      </ul>

                      <div>
                        <input
                          className="approx-input"
                          id="uploadfile"
                          type="text"
                          placeholder="If No"
                          readOnly
                          value={
                            siteInspectionData?.publicAreaAsDropZoneDescription
                          }
                        />
                      </div>

                      <div>
                        <input
                          class="approx-input"
                          id="approx"
                          name="approx"
                          type="text"
                          placeholder=" Approx. roof height (in meters)"
                          readOnly
                          value={siteInspectionData?.heightOfRoof}
                        />

                        <input
                          class="pitch-input"
                          id="pitchroof"
                          name="pitchroof"
                          type="text"
                          placeholder="Approx. pitch of roof (in degrees)"
                          readOnly
                          value={siteInspectionData?.pitchOfRoof}
                        />
                        <input
                          class="currentbill-input"
                          type="text"
                          id="approxupload"
                          placeholder="Public Area "
                          accept="image/png, image/jpeg, application/pdf"
                          name="approxupload"
                          readOnly
                          value={siteInspectionData?.publicAreaAsDropZoneUrl}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="siteInspectionSection ">
                    <div>
                      <b className="gst-info">
                        How many floors of the property?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="propertysingle"
                              type="radio"
                              className="input-text"
                              name="propertysingle"
                              readOnly
                              checked={
                                siteInspectionData?.floorsOfProperty ===
                                "Single"
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="propertysingle"
                            >
                              Single
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="propertydouble"
                              type="radio"
                              className="input-text"
                              name="propertydouble"
                              checked={
                                siteInspectionData?.floorsOfProperty ===
                                "Double"
                              }
                              readOnly
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="propertydouble"
                            >
                              Double
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="propertytriple"
                              type="radio"
                              className="input-text"
                              name="propertytriple"
                              readOnly
                              checked={
                                siteInspectionData?.floorsOfProperty ===
                                "Triple"
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="propertytriple"
                            >
                              Triple
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <b className="gst-info ">
                        Will you need a design to install within 2 meters from a
                        live edge?
                      </b>
                      <div className="siteInspectionSection ">
                        <ul className="owner-main ">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="meteryes"
                              type="radio"
                              className="input-text"
                              name="meteryes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.within2mOfEdge === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="meteryes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="meterno"
                              type="radio"
                              className="input-text"
                              name="meterno"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.within2mOfEdge === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="meterno"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files marginForSection">
                          <input
                            className="customer-input"
                            id="meterfromlive"
                            type="text"
                            placeholder="If No"
                            name="meterfromlive"
                            readOnly
                            value={
                              siteInspectionData?.within2mOfEdgeDescription
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="siteInspectionSection siteInspectionColumn">
                    <b className="gst-info">
                      At present roof has any opening facing risk to falls?
                    </b>
                    <ul className="owner-main">
                      <li className="for-gst">
                        {" "}
                        <input
                          id="openingyes"
                          type="radio"
                          className="input-text"
                          name="openingyes"
                          value="true"
                          readOnly
                          checked={
                            siteInspectionData?.openingRiskToFalls === true
                          }
                        />
                        <label style={{ cursor: "pointer" }} html="openingyes">
                          Yes
                        </label>
                      </li>
                      <li className="textfor-gst">
                        {" "}
                        <input
                          id="openingno"
                          type="radio"
                          className="input-text"
                          name="openingno"
                          value="false"
                          readOnly
                          checked={
                            siteInspectionData?.openingRiskToFalls === false
                          }
                        />
                        <label
                          style={{ cursor: "pointer" }}
                          htmlFor="openingno"
                        >
                          No
                        </label>
                      </li>
                    </ul>
                    <div className="retailer-main upload-files">
                      <input
                        className="customer-input"
                        id="openinginput"
                        type="text"
                        placeholder="If No"
                        name="openinginput"
                        readOnly
                        value={
                          siteInspectionData?.openingRiskToFallsDescription
                        }
                      />
                    </div>
                  </div>

                  <div className="siteInspectionSection marginForSection">
                    <b>Pre-Install Checks for residential property</b>
                  </div>
                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">
                        Is roof having skylights? If ‘Yes’ Photo is mandatory
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="photoyes"
                              type="radio"
                              className="input-text"
                              name="photoyes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.roofSkylights === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="photoyes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="photono"
                              type="radio"
                              className="input-text"
                              name="photono"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.roofSkylights === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="photono"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <input
                            className="currentbill-input"
                            style={{ paddingLeft: "5px" }}
                            type="text"
                            id="photoupload"
                            placeholder="Skylights Image"
                            accept="image/png, image/jpeg, application/pdf"
                            name="photoupload"
                            readOnly
                            value={siteInspectionData?.roofSkyligthsUrl}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do you notice an overhead electrical risk?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="electricalyes"
                              type="radio"
                              className="input-text"
                              name="electricalyes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.electricalRisk === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="electricalyes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="electricalno"
                              type="radio"
                              className="input-text"
                              name="isGST"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.electricalRisk === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="electricalno"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <input
                            className="customer-input"
                            id="electricalinput"
                            type="text"
                            placeholder="Description"
                            name="electricalinput"
                            readOnly
                            value={
                              siteInspectionData?.electricalRiskDescription
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="siteInspectionSection siteInspectionColumn">
                    <b className="gst-info">
                      Do you notice any suspicion or known asbestos containing
                      materials at the property? like switchboard, roof, walls
                      (When in suspect, ask the customer, and/or retailer.)
                    </b>
                    <div className="form-group">
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="materialsyes"
                            type="radio"
                            className="input-text"
                            name="materialsyes"
                            value="true"
                            readOnly
                            checked={
                              siteInspectionData?.asbestosMaterials === true
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="materialsyes"
                          >
                            Yes
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="materialno"
                            type="radio"
                            className="input-text"
                            name="materialno"
                            value="false"
                            readOnly
                            checked={
                              siteInspectionData?.asbestosMaterials === false
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="materialno"
                          >
                            No
                          </label>
                        </li>
                      </ul>
                      <div className="retailer-main upload-files">
                        <input
                          className="currentbill-input"
                          style={{ paddingLeft: "5px" }}
                          type="text"
                          id="materialuplaod"
                          placeholder="Image"
                          accept="image/png, image/jpeg, application/pdf"
                          name="materialuplaod"
                          readOnly
                          value={siteInspectionData?.asbestosMaterialUrl}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="siteInspectionSection marginForSection">
                    <b>Pre-Install Checks for residential property</b>
                  </div>
                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">
                        Is this property having any solar system now?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="systemyes"
                              type="radio"
                              className="input-text"
                              name="systemyes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.existingSolarSystem === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="systemyes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="systemno"
                              type="radio"
                              className="input-text"
                              name="systemno"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.existingSolarSystem ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="systemno"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <input
                            className="customer-input"
                            id="systemupload"
                            type="text"
                            placeholder="No. of Existing Panels"
                            name="systemupload"
                            readOnly
                            value={siteInspectionData?.noOfExistingPanels}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <b className="gst-info">Should it be removed?</b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="removedyes"
                              type="radio"
                              className="input-text"
                              name="removedyes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.existingSolarSystemRemove ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="removedyes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="removedno"
                              type="radio"
                              className="input-text"
                              name="removedno"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.existingSolarSystemRemove ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="removedno"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <b className="gst-info">
                            Is there any cellular (wi-fi) coverage?
                          </b>
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="coverageyes"
                                type="radio"
                                className="input-text"
                                name="coverageyes"
                                value="true"
                                readOnly
                                checked={
                                  siteInspectionData?.wifiCoverage === true
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="coverageyes"
                              >
                                Yes
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="coverageno"
                                type="radio"
                                className="input-text"
                                name="coverageno"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.wifiCoverage === false
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="coverageno"
                              >
                                No
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="siteInspectionSection">
                    <div className="retailer-main upload-files">
                      <b className="gst-info">
                        Is there an analogue or digital electricity meter?
                      </b>
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="electricityyes"
                            type="radio"
                            className="input-text"
                            name="electricityyes"
                            value="analogue"
                            readOnly
                            checked={
                              siteInspectionData?.electricityMeterType ===
                              "Analogue"
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="electricityyes"
                          >
                            Analogue
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="electricityno"
                            type="radio"
                            className="input-text"
                            name="electricityno"
                            value="digital"
                            readOnly
                            checked={
                              siteInspectionData?.electricityMeterType ===
                              "Digital"
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="electricityno"
                          >
                            Digital
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="siteInspectionSection  siteInspectionColumn marginForSection">
                    <div className="roof-text">
                      <b>Roof Information</b>
                    </div>
                    <div className="roof-bg">
                      <div class="form-group">
                        <b className="gst-info">
                          Which material is used to make the roof?
                        </b>
                        <div className="form-group">
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="usedyes"
                                type="radio"
                                className="input-text"
                                name="usedyes"
                                value="true"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial === "Steel"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="usedyes"
                              >
                                Steel
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="usedno"
                                type="radio"
                                className="input-text"
                                name="usedno"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial === "Tile"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="usedno"
                              >
                                Tile
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="usedcementno"
                                type="radio"
                                className="input-text"
                                name="usedcementno"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial === "Cement"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="usedcementno"
                              >
                                Cement
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="terracotta"
                                type="radio"
                                className="input-text"
                                name="terracotta"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial ===
                                  "Terracotta"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="terracotta"
                              >
                                Terracotta
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="kliplock"
                                type="radio"
                                className="input-text"
                                name="kliplock"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial ===
                                  "Kliplock"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="kliplock"
                              >
                                Kliplock
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="metal"
                                type="radio"
                                className="input-text"
                                name="metal"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial ===
                                  "Metal Decramastic"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="metal"
                              >
                                Metal Decramastic
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="standing"
                                type="radio"
                                className="input-text"
                                name="standing"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial ===
                                  "Metal Standing Seam"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="standing"
                              >
                                Metal Standing Seam
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="tinno"
                                type="radio"
                                className="input-text"
                                name="tinno"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial ===
                                  "Metal Tin"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="tinno"
                              >
                                Metal Tin
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="other"
                                type="radio"
                                className="input-text"
                                name="other"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial === "Other"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="other"
                              >
                                Other
                              </label>
                            </li>
                          </ul>
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="tileno"
                                type="radio"
                                className="input-text"
                                name="tileno"
                                value="true"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial ===
                                  "Tile Clay (Terracotta)"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="tileno"
                              >
                                Tile Clay (Terracotta)
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="concrete"
                                type="radio"
                                className="input-text"
                                name="concrete"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial ===
                                  "Tile Concrete"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="concrete"
                              >
                                Tile Concrete
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="slateno"
                                type="radio"
                                className="input-text"
                                name="slateno"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.roofMaterial ===
                                  "Tile Slate"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="slateno"
                              >
                                Tile Slate
                              </label>
                            </li>
                          </ul>
                          <div className="retailer-main upload-files">
                            <input
                              className="currentbill-input"
                              style={{ paddingLeft: "5px" }}
                              type="text"
                              id="slatenoupload"
                              placeholder="Roof Material Image"
                              accept="image/png, image/jpeg, application/pdf"
                              name="slatenoupload"
                              readOnly
                              value={siteInspectionData?.roofMaterilaUrl}
                            />
                            {/* <img
                            src={`${image}/Assets/CreateJob/doc-upload.svg`}
                            alt=""
                            className="upload"
                          /> */}
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <b className="gst-info">
                          Specify the current condition of the roof
                        </b>
                        <div className="form-group">
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="conditionyes"
                                type="radio"
                                className="input-text"
                                name="conditionyes"
                                value="true"
                                readOnly
                                checked={
                                  siteInspectionData?.conditionOfRoof ===
                                  "Brittle"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="conditionyes"
                              >
                                Brittle
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="slippery"
                                type="radio"
                                className="input-text"
                                name="slippery"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.conditionOfRoof ===
                                  "Slippery"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="slippery"
                              >
                                Slippery
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="rusty"
                                type="radio"
                                className="input-text"
                                name="rusty"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.conditionOfRoof ===
                                  "Rusty"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="rusty"
                              >
                                Rusty
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="rusty"
                                type="radio"
                                className="input-text"
                                name="rusty"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.conditionOfRoof === "Poor"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="rusty"
                              >
                                Poor
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="fair"
                                type="radio"
                                className="input-text"
                                name="fair"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.conditionOfRoof === "Fair"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="fair"
                              >
                                Fair
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="good"
                                type="radio"
                                className="input-text"
                                name="good"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.conditionOfRoof === "Good"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="good"
                              >
                                Good
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">
                        Do you notice any issue with roof space / cavity /
                        accessing ceiling area
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="ceilingyes"
                              type="radio"
                              className="input-text"
                              name="ceilingyes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.issueWithRoof === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="ceilingyes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="ceilingno"
                              type="radio"
                              className="input-text"
                              name="ceilingno"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.issueWithRoof === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="ceilingno"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <input
                            className="customer-input"
                            id="ceilingupload"
                            name="ceilingupload"
                            type="text"
                            placeholder="Description"
                            readOnly
                            value={siteInspectionData?.issueWIthRoofDescription}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do you see enough roof space to accommodate panel
                        layout?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="panelyes"
                              type="radio"
                              className="input-text"
                              name="panelyes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.spaceForPanelLayout === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="panelyes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="panelno"
                              type="radio"
                              className="input-text"
                              name="panelno"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.spaceForPanelLayout ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="panelno"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">
                        Do you see any object disturbing the panel layout?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="objectyes"
                              type="radio"
                              className="input-text"
                              name="objectyes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.objectDisturbingPanelLayout ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="objectyes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="objectno"
                              type="radio"
                              className="input-text"
                              name="objectno"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.objectDisturbingPanelLayout ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="objectno"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <input
                            className="currentbill-input"
                            style={{ paddingLeft: "5px" }}
                            type="text"
                            id="objectnoupload"
                            placeholder="Object Disturbing Panel Layout"
                            accept="image/png, image/jpeg, application/pdf"
                            name="objectnoupload"
                            readOnly
                            value={siteInspectionData?.spaceForPanelLayoutUrl}
                          />
                          {/* <img
                          src={`${image}/Assets/CreateJob/doc-upload.svg`}
                          alt=""
                          className="upload"
                        /> */}
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do you see a path from panels to proposed inverter
                        location?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="locationyes"
                              type="radio"
                              className="input-text"
                              name="locationyes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.panelToInverterPath === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="locationyes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="locationno"
                              type="radio"
                              className="input-text"
                              name="locationno"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.panelToInverterPath ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="locationno"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="siteInspectionSection marginForSection siteInspectionColumn">
                    <b>Provide data for Tin exclusively</b>
                    <b className="gst-info">
                      Are you sure that screw lines are suitable to hold the
                      layout as provided?
                    </b>
                    <div className="form-group">
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="suitableyes"
                            type="radio"
                            className="input-text"
                            name="suitableyes"
                            value="true"
                            readOnly
                            checked={
                              siteInspectionData?.screwLinesSuitable === true
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="suitableyes"
                          >
                            Yes
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="suitableno"
                            type="radio"
                            className="input-text"
                            name="suitableno"
                            value="false"
                            readOnly
                            checked={
                              siteInspectionData?.screwLinesSuitable === false
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="suitableno"
                          >
                            No
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div class="retailer-main upload-files">
                      <input
                        class="customer-input"
                        id="suitable"
                        name="suitable"
                        type="text"
                        placeholder="Description"
                        readOnly
                        value={
                          siteInspectionData?.screwLinesSuitableDescription
                        }
                      />
                    </div>
                  </div>

                  <div class="siteInspectionSection marginForSection siteInspectionColumn">
                    <b>Provide data for Terracotta exclusively</b>
                    <b className="gst-info">
                      Did you complete an edge to pitch walk and what # of
                      panels were broken (if any)?
                    </b>
                    <div className="form-group">
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="exclusively"
                            type="radio"
                            className="input-text"
                            name="exclusively"
                            value="true"
                            readOnly
                            checked={
                              siteInspectionData?.noOfBrokenPanels === true
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="exclusively"
                          >
                            Yes
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="exclusivelyno"
                            type="radio"
                            className="input-text"
                            name="exclusivelyno"
                            value="false"
                            readOnly
                            checked={
                              siteInspectionData?.noOfBrokenPanels === false
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="exclusivelyno"
                          >
                            No
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <input
                        class="customer-input"
                        id="exclusively"
                        name="exclusively"
                        type="text"
                        placeholder="Description"
                        readOnly
                        value={siteInspectionData?.noOfBrokenPanelsDescription}
                      />
                    </div>
                  </div>

                  <div class="siteInspectionSection marginForSection siteInspectionColumn">
                    <div className="info-battery">
                      <b>Battery Information</b>
                    </div>
                    <div className="info-batterybg">
                      <div className="row"></div>
                      <div style={{ display: "flex" }}>
                        <div class="form-group">
                          <b className="gst-info">
                            Can you find a suitable place to install a battery
                            in accordance with AS/NZS 5139:2019?
                          </b>
                          <div className="form-group">
                            <ul className="owner-main">
                              <li className="for-gst">
                                {" "}
                                <input
                                  id="battery"
                                  type="radio"
                                  className="input-text"
                                  name="battery"
                                  value="true"
                                  readOnly
                                  checked={
                                    siteInspectionData?.suitablePlaceForBattery ===
                                    true
                                  }
                                />
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor="battery"
                                >
                                  Yes
                                </label>
                              </li>
                              <li className="textfor-gst">
                                {" "}
                                <input
                                  id="batteryno"
                                  type="radio"
                                  className="input-text"
                                  name="batteryno"
                                  value="false"
                                  readOnly
                                  checked={
                                    siteInspectionData?.suitablePlaceForBattery ===
                                    false
                                  }
                                />
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor="batteryno"
                                >
                                  No
                                </label>
                              </li>
                            </ul>
                            <div className="retailer-main upload-files">
                              <div class="retailer-main upload-files">
                                <div class="retailer-main upload-files">
                                  <input
                                    class="customer-input"
                                    id="batteryinfo"
                                    type="text"
                                    placeholder="Image"
                                    name="batteryinfo"
                                    readOnly
                                    value={
                                      siteInspectionData?.suitablePlaceForBatteryDescription
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <b className="gst-info">
                            Should the inverter and battery be installed in the
                            same location?
                          </b>
                          <div className="form-group">
                            <ul className="owner-main">
                              <li className="for-gst">
                                {" "}
                                <input
                                  id="inverteryes"
                                  type="radio"
                                  className="input-text"
                                  name="inverteryes"
                                  value="true"
                                  readOnly
                                  checked={
                                    siteInspectionData?.inverterBatterySameLocation ===
                                    true
                                  }
                                />
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor="inverteryes"
                                >
                                  Yes
                                </label>
                              </li>
                              <li className="textfor-gst">
                                {" "}
                                <input
                                  id="inverterno"
                                  type="radio"
                                  className="input-text"
                                  name="inverterno"
                                  value="false"
                                  readOnly
                                  checked={
                                    siteInspectionData?.inverterBatterySameLocation ===
                                    false
                                  }
                                />
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor="inverterno"
                                >
                                  No
                                </label>
                              </li>
                            </ul>
                            <input
                              class="customer-input"
                              id=""
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="siteInspectionSection marginForSection siteInspectionColumn">
                    <div style={{ display: "flex", gap: "200px" }}>
                      <div class="form-group">
                        <b className="gst-info">
                          Do you want the battery to be?
                        </b>
                        <div className="form-group">
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="wantyes"
                                type="radio"
                                className="input-text"
                                name="wantyes"
                                value="true"
                                readOnly
                                checked={
                                  siteInspectionData?.internalOrExternalBattery ===
                                  "Internal"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="internal"
                              >
                                Internal
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="external"
                                type="radio"
                                className="input-text"
                                name="external"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.internalOrExternalBattery ===
                                  "External"
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="external"
                              >
                                External
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group">
                        <b className="gst-info">
                          Is the battery exposed to direct sunlight?
                        </b>
                        <div className="form-group">
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="sunlightYes"
                                type="radio"
                                className="input-text"
                                name="sunlightYes"
                                value="true"
                                readOnly
                                checked={
                                  siteInspectionData?.batteryExposedSunlight ===
                                  true
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="sunlightYes"
                              >
                                Yes
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="sunlightNo"
                                type="radio"
                                className="input-text"
                                name="sunlightNo"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.batteryExposedSunlight ===
                                  false
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="sunlightNo"
                              >
                                No
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="siteInspectionSection marginForSection">
                    <div class="form-group">
                      <b className="gst-info">Does it need shade?</b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="shadeYes"
                              type="radio"
                              className="input-text"
                              name="shadeYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.batteryShade === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="shadeYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="shadeNo"
                              type="radio"
                              className="input-text"
                              name="shadeNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.batteryShade === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="shadeNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do you notice any modem with a genuine internet
                        connection?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="internetYes"
                              type="radio"
                              className="input-text"
                              name="internetYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.modemInternetConnection ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              for="internetYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="internetNo"
                              type="radio"
                              className="input-text"
                              name="internetNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.modemInternetConnection ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="internetNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="siteInspectionSection ">
                    <div class="form-group">
                      <b className="gst-info">
                        Do you see the pathway from the modem to the battery
                        inverter?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="pathwayYes"
                              type="radio"
                              className="input-text"
                              name="pathwayYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.pathFromModemToBatteryInverter ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="pathwayYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="pathwayNo"
                              type="radio"
                              className="input-text"
                              name="pathwayNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.pathFromModemToBatteryInverter ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="pathwayNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <input
                          class="customer-input"
                          id="pathwayNo"
                          name="pathwayNo"
                          type="text"
                          placeholder="Description"
                          readOnly
                          value={
                            siteInspectionData?.pathFromModemToBatteryInverterDescription
                          }
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do you see a pathway from the modem to the MSB?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="modemYes"
                              type="radio"
                              className="input-text"
                              name="modemYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.pathFromModemToMSB === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="modemYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="modemNo"
                              type="radio"
                              className="input-text"
                              name="modemNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.pathFromModemToMSB === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="modemNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="siteInspectionSection ">
                    <div class="form-group">
                      <br />
                      <b className="gst-info">
                        Does the battery location provide clearance 600mm left
                        and right and 900mm above from any unassociated
                        equipment? (Aircon, Gas Cylinders, Light switch etc)
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="provideYes"
                              type="radio"
                              className="input-text"
                              name="provideYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.batteryClearanceFromEquipment ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="provideYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="provideNo"
                              type="radio"
                              className="input-text"
                              name="provideNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.batteryClearanceFromEquipment ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="provideNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="siteInspectionSection ">
                    <div class="form-group">
                      <b className="gst-info">
                        Do you ensure that the proposed battery is wall fire
                        rated?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="proposedYes"
                              type="radio"
                              className="input-text"
                              name="proposedYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.wallFireRated === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="proposedYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="proposedNo"
                              type="radio"
                              className="input-text"
                              name="proposedNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.wallFireRated === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="proposedNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do you see the room on the other side of the wall
                        inhabitable? (i.e. Hallway, Kitchen, Bathroom, Laundry
                        etc.)
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="sideYes"
                              type="radio"
                              className="input-text"
                              name="sideYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.inhabitableRoom === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="sideYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="sideNo"
                              type="radio"
                              className="input-text"
                              name="sideNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.inhabitableRoom === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="sideNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="siteInspectionSection" style={{ gap: "57px" }}>
                    <div class="form-group">
                      <b className="gst-info">
                        Is the battery location 600+ mm far from any doorway?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="doorwayYes"
                              type="radio"
                              className="input-text"
                              name="doorwayYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.batteryClearanceFromDoorway ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="doorwayYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="doorwayNo"
                              type="radio"
                              className="input-text"
                              name="doorwayNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.batteryClearanceFromDoorway ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="doorwayNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do you see the battery location Under a staircase?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="staircaseYes"
                              type="radio"
                              className="input-text"
                              name="staircaseYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.underStaircase === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="staircaseYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="staircaseNo"
                              type="radio"
                              className="input-text"
                              name="staircaseNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.underStaircase === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="staircaseNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="siteInspectionSection">
                    <div class="form-group">
                      <br />
                      <b className="gst-info">
                        Do you ensure the battery place is more than 600mm left
                        or right or more than 900mm below the opening area of a
                        window that ventilates a habitable room?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="placeYes"
                              type="radio"
                              className="input-text"
                              name="placeYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.batteryClearanceFromventilates ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="placeYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="placeNo"
                              type="radio"
                              className="input-text"
                              name="placeNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.batteryClearanceFromventilates ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="placeNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="siteInspectionSection">
                    <div className="info-batterybg">
                      <div className="row"></div>
                      <div style={{ display: "flex" }}>
                        <div class="form-group">
                          <b className="gst-info">
                            Will you need battery bollards?
                          </b>
                          <div className="form-group">
                            <ul className="owner-main">
                              <li className="for-gst">
                                {" "}
                                <input
                                  id="bollardsYes"
                                  type="radio"
                                  className="input-text"
                                  name="bollardsYes"
                                  value="true"
                                  readOnly
                                  checked={
                                    siteInspectionData?.bollardRequired === true
                                  }
                                />
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor="bollardsYes"
                                >
                                  Yes
                                </label>
                              </li>
                              <li className="textfor-gst">
                                {" "}
                                <input
                                  id="bollardsNo"
                                  type="radio"
                                  className="input-text"
                                  name="bollardsNo"
                                  value="false"
                                  readOnly
                                  checked={
                                    siteInspectionData?.bollardRequired ===
                                    false
                                  }
                                />
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor="bollardsNo"
                                >
                                  No
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="form-group">
                            <input
                              class="customer-input"
                              id="bollardsupload"
                              type="text"
                              name="bollardsupload"
                              placeholder="No. of bollards needed"
                              readOnly
                              value={siteInspectionData?.noBollardRequired}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">
                        Is overall battery installation proposed place comply
                        with AS5139?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="complyYes"
                              type="radio"
                              className="input-text"
                              name="complyYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.complyWithAS5139 === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="complyYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="complyNo"
                              type="radio"
                              className="input-text"
                              name="complyNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.complyWithAS5139 === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="complyNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do you find any suitable alternate battery place that
                        will comply?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="alternateYes"
                              type="radio"
                              className="input-text"
                              name="alternateYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.alternateBatteryPlace ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="alternateYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="alternateNo"
                              type="radio"
                              className="input-text"
                              name="alternateNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.alternateBatteryPlace ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="alternateNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="siteInspectionSection siteInspectionColumn">
                    <div className="inverter-site">
                      <b>Inverters Information</b>
                    </div>
                    <div className="site-inverterbg">
                      <div className="info-batterybg">
                        <div className="row"></div>
                        <div style={{ display: "flex", gap: "200px" }}>
                          <div class="form-group">
                            <b className="gst-info">
                              Is suggested inverter suitable for this solution?
                            </b>
                            <div className="form-group">
                              <ul className="owner-main">
                                <li className="for-gst">
                                  {" "}
                                  <input
                                    id="solutionYes"
                                    type="radio"
                                    className="input-text"
                                    name="solutionYes"
                                    value="true"
                                    readOnly
                                    checked={
                                      siteInspectionData?.suitableInverter ===
                                      true
                                    }
                                  />
                                  <label
                                    style={{ cursor: "pointer" }}
                                    for="gstYes"
                                  >
                                    Yes
                                  </label>
                                </li>
                                <li className="textfor-gst">
                                  {" "}
                                  <input
                                    id="solutionYes"
                                    type="radio"
                                    className="input-text"
                                    name="isGST"
                                    value="false"
                                    readOnly
                                    checked={
                                      siteInspectionData?.suitableInverter ===
                                      false
                                    }
                                  />
                                  <label
                                    style={{ cursor: "pointer" }}
                                    for="gstNo"
                                  >
                                    No
                                  </label>
                                </li>
                              </ul>
                              <div className="retailer-main upload-files">
                                <input
                                  className="currentbill-input"
                                  style={{ paddingLeft: "5px" }}
                                  type="text"
                                  id="solutionupload"
                                  placeholder="Inverter Location Image"
                                  accept="image/png, image/jpeg, application/pdf"
                                  name="solutionupload"
                                  readOnly
                                  value={
                                    siteInspectionData?.suitableInverterUrl
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <b className="gst-info">
                              Will this inverter require shade protection?
                            </b>
                            <div className="form-group">
                              <ul className="owner-main">
                                <li className="for-gst">
                                  {" "}
                                  <input
                                    id="requireYes"
                                    type="radio"
                                    className="input-text"
                                    name="requireYes"
                                    value="true"
                                    readOnly
                                    checked={
                                      siteInspectionData?.inverterShade === true
                                    }
                                  />
                                  <label
                                    style={{ cursor: "pointer" }}
                                    htmlFor="requireYes"
                                  >
                                    Yes
                                  </label>
                                </li>
                                <li className="textfor-gst">
                                  {" "}
                                  <input
                                    id="requireNo"
                                    type="radio"
                                    className="input-text"
                                    name="requireNo"
                                    value="false"
                                    readOnly
                                    checked={
                                      siteInspectionData?.inverterShade ===
                                      false
                                    }
                                  />
                                  <label
                                    style={{ cursor: "pointer" }}
                                    htmlFor="requireNo"
                                  >
                                    No
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">
                        Is there any obstruction at the inverter location?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="obstructionYes"
                              type="radio"
                              className="input-text"
                              name="obstructionYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.inverterObstruction === true
                              }
                            />
                            <label style={{ cursor: "pointer" }} for="gstYes">
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="obstructionNo"
                              type="radio"
                              className="input-text"
                              name="obstructionNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.inverterObstruction ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="obstructionNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <input
                            className="currentbill-input"
                            style={{ paddingLeft: "5px" }}
                            type="text"
                            id="obstructionupload"
                            placeholder="Obstruction at Inverter Image"
                            accept="image/png, image/jpeg, application/pdf"
                            name="obstructionupload"
                            readOnly
                            value={siteInspectionData?.inverterObstructionUrl}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Is AC isolation point required at inverter?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="isolationYes"
                              type="radio"
                              className="input-text"
                              name="isolationYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.inverterACIsolation === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="isolationYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="isolationNo"
                              type="radio"
                              className="input-text"
                              name="isolationNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.inverterACIsolation ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="isolationNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">
                        Is there any existing inverter to be retained?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="existingYes"
                              type="radio"
                              className="input-text"
                              name="existingYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.inverterToRetain === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="existingYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="existingtNo"
                              type="radio"
                              className="input-text"
                              name="existingtNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.inverterToRetain === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="existingtNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <input
                            className="currentbill-input"
                            style={{ paddingLeft: "5px" }}
                            type="text"
                            id="existingupload"
                            placeholder="Retained Inverter Image"
                            accept="image/png, image/jpeg, application/pdf"
                            name="existingupload"
                            readOnly
                            value={siteInspectionData?.inverterToRetainUrl}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div className="form-group">
                        <input
                          class="approx-input"
                          id="notes"
                          name="notes"
                          readOnly
                          type="text"
                          placeholder=" Inverter Inspection Notes"
                          style={{ height: "109px" }}
                          value={
                            siteInspectionData?.inverterInspectionDescription
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="siteInspectionSection siteInspectionColumn">
                    <div className="swith-bg">
                      <b>Switchboard Information</b>
                    </div>
                    <div
                      className="siteInspectionSection"
                      style={{ marginLeft: 0 }}
                    >
                      <div class="form-group">
                        <b className="gst-info">
                          Can you access the switchboard without any difficulty?
                        </b>
                        <div className="form-group">
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="withoutYes"
                                type="radio"
                                className="input-text"
                                name="withoutYes"
                                value="true"
                                readOnly
                                checked={
                                  siteInspectionData?.accessToSwitchBoard ===
                                  true
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="withoutYes"
                              >
                                Yes
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="withoutNo"
                                type="radio"
                                className="input-text"
                                name="withoutNo"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.accessToSwitchBoard ===
                                  false
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="withoutNo"
                              >
                                No
                              </label>
                            </li>
                          </ul>
                          <div className="retailer-main upload-files">
                            <input
                              className="currentbill-input"
                              style={{ paddingLeft: "5px" }}
                              type="text"
                              id="withoutupload"
                              placeholder="Access to Switchboard Image"
                              accept="image/png, image/jpeg, application/pdf"
                              name="withoutupload"
                              readOnly
                              value={siteInspectionData?.accessToSwitchBoardUrl}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <b className="gst-info">
                          Is the switchboard in safe / moderate condition?
                        </b>
                        <div className="form-group">
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="safeYes"
                                type="radio"
                                className="input-text"
                                name="safeYes"
                                value="true"
                                readOnly
                                checked={
                                  siteInspectionData?.switchBoardCondition ===
                                  true
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="safeYes"
                              >
                                Yes
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="safeNo"
                                type="radio"
                                className="input-text"
                                name="safeNo"
                                value="false"
                                readOnly
                                checked={
                                  siteInspectionData?.switchBoardCondition ===
                                  false
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="safeNo"
                              >
                                No
                              </label>
                            </li>
                          </ul>
                          <div style={{ display: "flex" }}>
                            {" "}
                            <div class="retailer-main upload-files">
                              <input
                                class="customer-input"
                                id="safeinputYes"
                                type="text"
                                placeholder="If No"
                                name="safeinputYes"
                                readOnly
                                value={
                                  siteInspectionData?.switchBoardConditionDescription
                                }
                              />
                              <input
                                className="currentbill-input"
                                style={{ paddingLeft: "5px" }}
                                type="text"
                                id="safeinputupload"
                                placeholder="Switch Board Condition Image"
                                accept="image/png, image/jpeg, application/pdf"
                                name="safeinputupload"
                                readOnly
                                value={
                                  siteInspectionData?.switchBoardConditionUrl
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="siteInspectionSection"
                      style={{ marginLeft: 0 }}
                    >
                      <div class="form-group">
                        <b className="gst-info">How many phases?</b>
                        <div className="form-group">
                          <ul className="owner-main">
                            <li className="for-gst">
                              {" "}
                              <input
                                id="phases1Yes"
                                type="radio"
                                className="input-text"
                                name="phases1Yes"
                                value="true"
                                readOnly
                                checked={siteInspectionData?.noOfPhases === "1"}
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="phases1Yes"
                              >
                                1
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="phases2Yes"
                                type="radio"
                                className="input-text"
                                name="phases2Yes"
                                value="false"
                                readOnly
                                checked={siteInspectionData?.noOfPhases === "2"}
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="phases2Yes"
                              >
                                2
                              </label>
                            </li>
                            <li className="textfor-gst">
                              {" "}
                              <input
                                id="phases3Yes"
                                type="radio"
                                className="input-text"
                                name="phases3Yes"
                                value="false"
                                readOnly
                                checked={siteInspectionData?.noOfPhases === "3"}
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="phases3Yes"
                              >
                                3
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group">
                        <div className="form-group">
                          <div style={{ display: "flex" }}>
                            <div class="retailer-main upload-files">
                              <input
                                class="customer-input"
                                id="phases"
                                name="phases3Yes"
                                type="text"
                                placeholder="National Meter Identifier"
                                readOnly
                                value={siteInspectionData?.nmiNo}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">Does switchboard comply?</b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="switchboardYes"
                              type="radio"
                              className="input-text"
                              name="switchboardYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.switchBoardComply === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="switchboardYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="switchboardNo"
                              type="radio"
                              className="input-text"
                              name="switchboardNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.switchBoardComply === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="switchboardNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <input
                            className="currentbill-input"
                            style={{ paddingLeft: "5px" }}
                            type="text"
                            id="switchboardupload"
                            placeholder="Switchboard Comply Image"
                            accept="image/png, image/jpeg, application/pdf"
                            name="switchboardupload"
                            readOnly
                            value={siteInspectionData?.switchBoardComplyUrl}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">Switchboard & meter situation?</b>
                      <div className="form-group">
                        <div style={{ display: "flex" }}>
                          {" "}
                          <div class="retailer-main upload-files">
                            <input
                              className="currentbill-input"
                              style={{ paddingLeft: "5px" }}
                              type="text"
                              id="situationupload"
                              placeholder="Switchboard Situation Image"
                              accept="image/png, image/jpeg, application/pdf"
                              name="situationupload"
                              readOnly
                              value={
                                siteInspectionData?.switchBoardSituationUrl
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="siteInspectionSection siteInspectionColumn">
                    <div class="form-group">
                      <b className="gst-info">
                        Essential Energy Region only – Provide site diagrams
                        showing cable diameters & cable lengths from point of
                        connection to battery and solar inverter locations.
                      </b>
                      <div className="form-group">
                        <div className="retailer-main upload-files">
                          <input
                            className="currentbill-input"
                            style={{ paddingLeft: "5px" }}
                            type="text"
                            id="diagrams"
                            placeholder="Essential Energy Region Image"
                            accept="image/png, image/jpeg, application/pdf"
                            name="diagrams"
                            readOnly
                            value={siteInspectionData?.EERUrl}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="siteInspectionSection siteInspectionColumn">
                    <div class="form-group">
                      <b className="gst-info">Size of the main switch</b>
                      <div className="form-group">
                        <div className="retailer-main upload-files">
                          <input
                            className="currentbill-input"
                            style={{ paddingLeft: "5px" }}
                            type="text"
                            id="switchupload"
                            placeholder="Main Switch Size Image"
                            accept="image/png, image/jpeg, application/pdf"
                            name="switchupload"
                            readOnly
                            value={siteInspectionData?.sizeOfSwitchBoardUrl}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">Is MSB connected with</b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="connectedYes"
                              type="radio"
                              className="input-text"
                              name="connectedYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.MSBConnection === "House"
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="connectedYes"
                            >
                              House
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="poleNo"
                              type="radio"
                              className="input-text"
                              name="poleNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.MSBConnection === "Pole"
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="poleNo"
                            >
                              Pole
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="shedNo"
                              type="radio"
                              className="input-text"
                              name="shedNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.MSBConnection === "Shed"
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="shedNo"
                            >
                              Shed
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="OtherNo"
                              type="radio"
                              className="input-text"
                              name="OtherNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.MSBConnection === "Other"
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="OtherNo"
                            >
                              Other
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <input
                            className="currentbill-input"
                            style={{ paddingLeft: "5px" }}
                            type="text"
                            id="Otheruplaod"
                            placeholder="MSB Connected Image"
                            accept="image/png, image/jpeg, application/pdf"
                            name="Otheruplaod"
                            readOnly
                            value={siteInspectionData?.MSBConnectionUrl}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">Diameter of main supply cable</b>
                      <div className="form-group">
                        <div style={{ display: "flex" }}>
                          {" "}
                          <div class="retailer-main upload-files">
                            <input
                              className="currentbill-input"
                              style={{ paddingLeft: "5px" }}
                              type="text"
                              id="switchboardupload"
                              placeholder="Diameter of Main Supply Cable"
                              accept="image/png, image/jpeg, application/pdf"
                              name="switchboardupload"
                              readOnly
                              value={siteInspectionData?.diameterMainCable}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <br />
                      <b className="gst-info">
                        What is the import/export of the house with consumption
                        meters before any loads? Can we achieve this by
                        installing the meter in the main board / in an enclosure
                        right next to the main board?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="enclosureYes"
                              type="radio"
                              className="input-text"
                              name="enclosureYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.consumptionBeforeLoads ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="enclosureYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="enclosureNo"
                              type="radio"
                              className="input-text"
                              name="enclosureNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.consumptionBeforeLoads ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="enclosureNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">
                        Is existing solar / the proposed solar on the shed?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="existingYes"
                              type="radio"
                              className="input-text"
                              name="existingYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.existingSolarShed === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="existingYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="existingNo"
                              type="radio"
                              className="input-text"
                              name="existingNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.existingSolarShed === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="gexistingNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do we need to measure solar using a PV meter before any
                        loads? Do we need a trench from the shed to capture the
                        solar?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="captureYes"
                              type="radio"
                              className="input-text"
                              name="captureYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.PVMeterNeeded === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="captureYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="captureNo"
                              type="radio"
                              className="input-text"
                              name="captureNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.PVMeterNeeded === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="captureNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div style={{ display: "flex" }}>
                          {" "}
                          <div class="retailer-main upload-files">
                            <input
                              className="currentbill-input"
                              style={{ paddingLeft: "5px" }}
                              type="text"
                              id="captureupload"
                              placeholder="PV Meter Image"
                              accept="image/png, image/jpeg, application/pdf"
                              name="captureupload"
                              readOnly
                              value={siteInspectionData?.PVMeterNeededUrl}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="siteInspectionSection">
                    <div class="form-group">
                      <b className="gst-info">
                        Do you need a cable of 20M to connect to the inverter?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="cableYes"
                              type="radio"
                              className="input-text"
                              name="cableYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.cableToConnectInverter ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="cableYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="cableNo"
                              type="radio"
                              className="input-text"
                              name="cableNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.cableToConnectInverter ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="cableNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do you need a cable of 20M to connect to the battery?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="needYes"
                              type="radio"
                              className="input-text"
                              name="needYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.cableToConnectBattery ===
                                true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="needYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="needNo"
                              type="radio"
                              className="input-text"
                              name="needNo"
                              value="false"
                              checked={
                                siteInspectionData?.cableToConnectBattery ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="needNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div style={{ display: "flex" }}>
                          {" "}
                          <div class="retailer-main upload-files">
                            <input
                              className="currentbill-input"
                              style={{ paddingLeft: "5px" }}
                              type="text"
                              id="needupload"
                              placeholder="Cable for Battery Image"
                              accept="image/png, image/jpeg, application/pdf"
                              name="needupload"
                              readOnly
                              value={siteInspectionData?.cableToConnectUrl}
                            />
                            {/* <img
                              src={`${image}/Assets/CreateJob/doc-upload.svg`}
                              alt=""
                              className="upload"/> */}
                            <input
                              class="customer-input"
                              id=""
                              type="text"
                              placeholder="If No"
                              readOnly
                              value={
                                siteInspectionData?.cableToConnectDescription
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="siteInspectionSection siteInspectionColumn">
                    <b>Design of the Proposed Solution</b>
                    <div>
                      <input
                        class="approx-input"
                        id="system"
                        name="system"
                        type="text"
                        placeholder="System Size (kW)"
                        readOnly
                        value={siteInspectionData?.systemSize}
                      ></input>

                      <input
                        class="pitch-input"
                        id="inverters"
                        name="inverters"
                        type="text"
                        placeholder="Number (#) of inverters"
                        readOnly
                        value={siteInspectionData?.noOfInverters}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="siteInspectionSection siteInspectionColumn">
                  <b>Design Specification</b>
                  <div>
                    <input
                      class="approx-input"
                      id="Panelbrand"
                      name="Panelbrand"
                      type="text"
                      placeholder="Panel brand"
                      readOnly
                      value={siteInspectionData?.panelBrand}
                    />

                    <input
                      class="pitch-input"
                      id="inverterbrand"
                      type="text"
                      placeholder="Inverter brand"
                      readOnly
                      value={siteInspectionData?.inverterBrand}
                    />
                  </div>
                </div>

                <div className="siteInspectionSection marginForSection">
                  <b>Panel Layout Information</b>

                  <div
                    className="siteInspectionSection "
                    style={{ marginLeft: "0" }}
                  >
                    <input
                      className="customer-input"
                      id="north"
                      name="north"
                      type="text"
                      placeholder="
                      North
                      (#)"
                      readOnly
                      value={siteInspectionData?.panelNorth}
                    />
                    <input
                      className="customer-input"
                      id="south"
                      name="south"
                      type="text"
                      placeholder="South(#)"
                      readOnly
                      value={siteInspectionData?.panelSouth}
                    />
                    <input
                      className="customer-input"
                      id="east"
                      name="east"
                      type="text"
                      placeholder="East(#)"
                      readOnly
                      value={siteInspectionData?.panelEast}
                    />
                    <input
                      className="customer-input"
                      id=""
                      type="text"
                      placeholder="West(#)"
                      readOnly
                      value={siteInspectionData?.panelWest}
                    />

                    <input
                      className="customer-input"
                      id="number"
                      name="number"
                      type="text"
                      placeholder="
                      Total number (#) of panels"
                      readOnly
                      value={siteInspectionData?.noOfPanels}
                    />
                    <input
                      className="customer-input"
                      id="shading"
                      name="shading"
                      readOnly
                      type="text"
                      placeholder="Is there any shading issue?"
                      value={siteInspectionData?.panelShadingIssue}
                    />
                    <input
                      className="customer-input"
                      id="location"
                      name="location"
                      type="text"
                      placeholder="Where is the location of the inverter?"
                      readOnly
                      value={siteInspectionData?.inverterLocation}
                    />
                  </div>
                </div>
                <div className="siteInspectionSection marginForSection">
                  <div class="form-group">
                    <b className="gst-info">
                      Do you need a cage for the inverter?
                    </b>
                    <div className="form-group">
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="cageYes"
                            type="radio"
                            className="input-text"
                            name="cageYes"
                            value="true"
                            readOnly
                            checked={
                              siteInspectionData?.cageForInverter === true
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="cageYes"
                          >
                            Yes
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="cageNo"
                            type="radio"
                            className="input-text"
                            name="cageNo"
                            value="false"
                            readOnly
                            checked={
                              siteInspectionData?.cageForInverter === false
                            }
                          />
                          <label style={{ cursor: "pointer" }} htmlFor="cageNo">
                            No
                          </label>
                        </li>
                      </ul>
                      <div className="retailer-main upload-files">
                        <div class="retailer-main upload-files">
                          <div class="retailer-main upload-files">
                            <input
                              class="customer-input"
                              id="cageupload"
                              name="cageupload"
                              type="text"
                              placeholder="If NO"
                              readOnly
                              value={
                                siteInspectionData?.cageForInverterDescription
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <b className="gst-info">Specify tilt frames?</b>
                    <div className="form-group">
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="framesYes"
                            type="radio"
                            className="input-text"
                            name="framesYes"
                            value="true"
                            readOnly
                            checked={siteInspectionData?.tiltFrames === true}
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="framesYes"
                          >
                            Yes
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="framesNo"
                            type="radio"
                            className="input-text"
                            name="framesNo"
                            value="false"
                            readOnly
                            checked={siteInspectionData?.tiltFrames === false}
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="framesNo"
                          >
                            No
                          </label>
                        </li>
                      </ul>
                      <input
                        class="customer-input"
                        id="degrees"
                        name="degrees"
                        type="text"
                        placeholder="Degrees"
                        readOnly
                        value={siteInspectionData?.tiltFramesDegrees}
                      />
                      <input
                        class="customer-input"
                        id="tilts"
                        name="tilts"
                        type="text"
                        placeholder="Number of
                            tilts (#)"
                        readOnly
                        value={siteInspectionData?.noOfTiltFrames}
                      />
                    </div>
                  </div>
                </div>

                <div className="siteInspectionSection marginForSection">
                  <div class="form-group">
                    <b className="gst-info">Will you need an optimizer?</b>
                    <div className="form-group">
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="optimizerYes"
                            type="radio"
                            className="input-text"
                            name="optimizerYes"
                            value="true"
                            readOnly
                            checked={
                              siteInspectionData?.needOfOptimizer === true
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="optimizerYes"
                          >
                            Yes
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="optimizerNo"
                            type="radio"
                            className="input-text"
                            name="optimizerNo"
                            value="false"
                            readOnly
                            checked={
                              siteInspectionData?.needOfOptimizer === false
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="optimizerNo"
                          >
                            No
                          </label>
                        </li>
                      </ul>
                      <div className="retailer-main upload-files">
                        <div class="retailer-main upload-files">
                          <div class="retailer-main upload-files">
                            <input
                              class="customer-input"
                              id="optimizerinput"
                              name="optimizerinput"
                              type="text"
                              placeholder="If NO"
                              readOnly
                              value={
                                siteInspectionData?.needOfOptimizerDescription
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <b className="gst-info">
                      Is the battery to be AC or DC coupled?
                    </b>
                    <div className="form-group">
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="coupledYes"
                            type="radio"
                            className="input-text"
                            name="coupledYes"
                            value="true"
                            readOnly
                            checked={siteInspectionData?.batteryAcOrDc === true}
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="coupledYes"
                          >
                            AC
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="coupledNo"
                            type="radio"
                            className="input-text"
                            name="coupledNo"
                            value="false"
                            readOnly
                            checked={
                              siteInspectionData?.batteryAcOrDc === false
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="coupledNo"
                          >
                            DC
                          </label>
                        </li>
                      </ul>
                      <input
                        class="customer-input"
                        id="degreesmain"
                        name="degreesmain"
                        type="text"
                        placeholder="Degrees"
                        readOnly
                        value={siteInspectionData?.batteryAcOrDcDescription}
                      />
                    </div>
                  </div>
                </div>

                <div className="siteInspectionSection marginForSection">
                  <div class="form-group">
                    <b className="gst-info">
                      Would you like to request for structural engineering
                      report?
                    </b>
                    <div className="form-group">
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="reportYes"
                            type="radio"
                            className="input-text"
                            name="reportYes"
                            value="true"
                            readOnly
                            checked={
                              siteInspectionData?.structuralEngineeringReport ===
                              true
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="reportYes"
                          >
                            Yes
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="reportNo"
                            type="radio"
                            className="input-text"
                            name="reportNo"
                            value="false"
                            readOnly
                            checked={
                              siteInspectionData?.structuralEngineeringReport ===
                              false
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="reportNo"
                          >
                            No
                          </label>
                        </li>
                      </ul>
                      <div className="retailer-main upload-files">
                        <div class="retailer-main upload-files">
                          <div class="retailer-main upload-files">
                            <input
                              class="customer-input"
                              id="reportfield"
                              name="reportfield"
                              type="text"
                              placeholder="Description"
                              readOnly
                              value={
                                siteInspectionData?.structuralEngineeringReportDescription
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <b className="gst-info">
                      Do you need an internal cable from the inverter to the
                      array?
                    </b>
                    <div className="form-group">
                      <ul className="owner-main">
                        <li className="for-gst">
                          {" "}
                          <input
                            id="arrayYes"
                            type="radio"
                            className="input-text"
                            name="arrayYes"
                            value="true"
                            readOnly
                            checked={
                              siteInspectionData?.internalCableFromInverter ===
                              true
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="arrayYes"
                          >
                            Yes
                          </label>
                        </li>
                        <li className="textfor-gst">
                          {" "}
                          <input
                            id="arrayNo"
                            type="radio"
                            className="input-text"
                            name="arrayNo"
                            value="false"
                            readOnly
                            checked={
                              siteInspectionData?.internalCableFromInverter ===
                              false
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="arrayNo"
                          >
                            No
                          </label>
                        </li>
                      </ul>
                      <input
                        class="customer-input"
                        id="arrayinput"
                        name="arrayinput"
                        type="text"
                        placeholder="Description"
                        readOnly
                        value={
                          siteInspectionData?.internalCableFromInverterDescription
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="siteInspectionSection marginForSection siteInspectionColumn">
                  <b>Final Outcome</b>

                  <div
                    className="siteInspectionSection"
                    style={{ marginLeft: 0 }}
                  >
                    <div class="form-group">
                      <b className="gst-info">
                        Do you think the attached layout will work?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="outcomeYes"
                              type="radio"
                              className="input-text"
                              name="outcomeYes"
                              value="true"
                              readOnly
                              checked={siteInspectionData?.layoutWork === true}
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="outcomeYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="outcomeNo"
                              type="radio"
                              className="input-text"
                              name="outcomeNo"
                              value="false"
                              readOnly
                              checked={siteInspectionData?.layoutWork === false}
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="outcomeNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <div class="retailer-main upload-files">
                            <div class="retailer-main upload-files">
                              <input
                                class="customer-input"
                                id="outcome"
                                name="outcomeNo"
                                type="text"
                                placeholder="Description"
                                readOnly
                                value={
                                  siteInspectionData?.layoutWorkDescription
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Did you share the result of the pre-inspection with the
                        client?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="clientYes"
                              type="radio"
                              className="input-text"
                              name="clientYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.resultWithClient === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="clientYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="clientNo"
                              type="radio"
                              className="input-text"
                              name="clientNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.resultWithClient === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="clientNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <input
                          class="customer-input"
                          id="clientfield"
                          type="text"
                          placeholder="Description"
                          name="clientfield"
                          readOnly
                          value={
                            siteInspectionData?.resultWIthClientDescription
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="siteInspectionSection siteInspectionColumn">
                  <b>Other</b>
                  <div
                    className="siteInspectionSection"
                    style={{ marginLeft: 0 }}
                  >
                    <div class="form-group">
                      <b className="gst-info">
                        Has {siteInspectionData?.firstName}{" "}
                        {siteInspectionData?.lastName}, the customer, been
                        informed of the results of site inspection ?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="customerYes"
                              type="radio"
                              className="input-text"
                              name="customerYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.resultWithCustomer === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="customerYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="customerNo"
                              type="radio"
                              className="input-text"
                              name="customerNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.resultWithCustomer === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="customerNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <div className="retailer-main upload-files">
                          <div class="retailer-main upload-files">
                            <div class="retailer-main upload-files">
                              <input
                                class="customer-input"
                                id="customerfield"
                                name="customerfield"
                                type="text"
                                placeholder="Description"
                                readOnly
                                value={
                                  siteInspectionData?.resultWithCustomerDescription
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <b className="gst-info">
                        Do you see any additional cost for this installation?
                      </b>
                      <div className="form-group">
                        <ul className="owner-main">
                          <li className="for-gst">
                            {" "}
                            <input
                              id="additionalYes"
                              type="radio"
                              className="input-text"
                              name="additionalYes"
                              value="true"
                              readOnly
                              checked={
                                siteInspectionData?.additionalCost === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="additionalYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="textfor-gst">
                            {" "}
                            <input
                              id="additionalNo"
                              type="radio"
                              className="input-text"
                              name="additionalNo"
                              value="false"
                              readOnly
                              checked={
                                siteInspectionData?.additionalCost === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              for="additionalNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <input
                          class="customer-input"
                          id="additionalfield"
                          name="additionalfield"
                          type="text"
                          placeholder="Description"
                          readOnly
                          value={siteInspectionData?.additionalCostDescription}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="siteInspectionSection siteInspectionColumn">
                  <b>Additional Photos</b>

                  <div
                    className="siteInspectionSection "
                    style={{ marginLeft: 0 }}
                  >
                    <input
                      className="currentbill-input"
                      style={{ paddingLeft: "5px" }}
                      type="text"
                      id="additionalPhotos"
                      placeholder="Additional Photos"
                      name="additionalPhotos"
                      readOnly
                      value={siteInspectionData?.additonalPhotosUrl}
                    />
                  </div>
                </div>
              </div>
              <div className="site-footerbg">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    type="back"
                    className="back-main-btn"
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSiteInspection;
