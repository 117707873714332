import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./CreateJobModal.css";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const CreateJobModal = ({ isOpen, onClose, JobType, getJobs }) => {
  const token = localStorage.getItem("userToken");
  const [selectedTab, setSelectedTab] = useState(JobType);
  const navigate = useNavigate();

  const [installingForOptions, setInstallingForOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}/get/masterData?type=installingFor`)
      .then((response) => {
        const master = response.data;
        setInstallingForOptions(master.data[0].values);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setSelectedTab(JobType);
  }, []);
  //Form Data Starts

  const [ownerDetails, setOwnerDetails] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    mobileNum: "",
    phoneNumber: "",
    emailId: "",
    ABN: null,
    isGST: false,
    gstNo: "",
  });

  const [installingFor, setInstallingFor] = useState("");

  const formdata = {
    installationAddress: {
      addressType: "Physical",
      unitType: "",
      unitNumber: null,
      streetNumber: null,
      streetName: "",
      streetType: "",
      suburb: "",
      state: "",
      postCode: null,
      latitude: null,
      longitude: null,
    },

    installationDetails: {
      installationType: "",
      newPanelLocation: "",
      kilowatt: null,
      remarks: "",
      CECAccreditationSystem: true,
      territoryGovermentApproval: true,
      electricalSafetyDoc: true,
      AusNZStandardStatement: true,
    },

    postalAddress: {
      POBox: {
        POBoxNo: null,
        postalCode: null,
        postalArea: "",
      },
      POAddress: {
        addressType: "",
        unitType: "",
        unitNumber: null,
        streetNumber: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postCode: null,
      },
    },

    systemOwnerType: "",

    coOwnerDetail: {
      firstName: "",
      lastName: "",
      mobileNum: "",
      phoneNumber: "",
      emailId: "",
    },

    installationDate: {
      installingDate: "",
      STCDeemingPeriod: null,
      mountingType: "",
    },

    gridConnectionDetail: {
      connectionType: "",
      systemType: "S.G.U. - Solar (Deemed)",
      nmi: "",
      gridAppReferenceNo: "",
      meterNum: null,
      distributor: "",
    },

    panelDetail: {
      panelInfo: [
        {
          panelBrand: "",
          panelModel: "",
          panelPower: null,
          Quantity: null,
        },
      ],
      wattage: null,
      stc: null,
      financialPayment: null,
      isAssignPanelJob: false,
    },

    inverterDetail: {
      inverterInfo: [
        {
          inverterBrand: "",
          inverterSeries: "",
          inverterModel: "",
          inverterSupplier: "",
          inverterCapacity: null,
          Quantity: null,
        },
      ],
    },

    batteryDetail: {
      batteryInfo: [
        {
          manufacturer: "",
          batteryBrand: "",
          batterySeries: "",
          batteryModel: "",
          batteryCapacity: null,
          Quantity: null,
        },
      ],
      batterySystemAggregate: false,
      defaultManufacturerBatteryStorage: false,
    },

    warrantyDetails: {
      warrantyYear: null,
      warrantyDescription: "",
    },

    instructionForInstallation: "",

    additionalAttributes: {
      jobReferenceNumber: "",
      currentEnergyRetailer: "",
      WillThereBeAnyOptimisersInstalled: false,
    },

    siteAttributes: {
      roof: "",
      storey: "",
      panelType: "",
      phase: "",
    },

    failedPV: {
      isItForPreviouslyFailedPV: false,
      accreditationCode: "",
      explanatoryNotes: "",
    },

    design: "",
    customInvoice: "",
    uploadElectricityBill: "",
    STCForm: "",
    STCInvoice: "",
    testingCertificate: "",
    noticeOfCompletion: "",

    installers: [],
    installerName: "",
    designers: [],
    designerName: "",
    electricians: [],
    electricianName: "",

    jobType: "PV",
  };

  const formdata2 = {
    installationAddress: {
      addressType: "Physical",
      unitType: "",
      unitNumber: null,
      streetNumber: null,
      streetName: "",
      streetType: "",
      suburb: "",
      state: "",
      postCode: null,
      latitude: null,
      longitude: null,
    },

    postalAddress: {
      POBox: {
        POBoxNo: null,
        postalCode: null,
        postalArea: "",
      },
      POAddress: {
        addressType: "Physical",
        unitType: "",
        unitNumber: null,
        streetNumber: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postCode: null,
      },
    },

    systemOwnerType: "",
    installationType: "",

    coOwnerDetail: {
      firstName: "",
      lastName: "",
      mobileNum: null,
      phoneNumber: null,
      emailId: "",
    },

    installationDate: {
      installingDate: "",
      STCDeemingPeriod: null,
      mountingType: "",
    },

    siteAttributes: {
      roof: "",
      storey: "",
      panelType: "",
      phase: "",
    },

    hotWaterDetail: {
      hotWaterBrand: "",
      hotWaterInfo: {
        hotWaterModel: "",
        hotWaterCapacity: null,
        hotWaterQuantity: 1,
      },
      isHeatPump: false,
      noOfPanels: null,
      stc: null,
      financialPayment: null,
    },

    isInstallerSelfie: false,

    plumbers: [],
    plumberName: "",
    gasfitters: [],
    gasfitterName: "",
    electricians: [],
    electricianName: "",

    instructionForInstallation: "",

    installationDetails: {
      CECAccreditationSystem: true,
      territoryGovermentApproval: true,
      electricalSafetyDoc: true,
      AusNZStandardStatement: true,
    },

    notes: "",

    failedSWH: {
      isItForPreviouslyFailedSWH: false,
      accreditationCode: "",
      explanatoryNotes: "",
    },

    jobType: "SWH",
  };
  //Form Data Ends

  //Function to add FormData Starts
  const handleInputCustomerDetails = (event) => {
    const { name, type, value } = event.target;

    const updateOwnerDetails = (data, key, val) => {
      return {
        ...data,
        [key]: val,
      };
    };

    if (name === "phoneNumber") {
      if (value.startsWith("0")) {
        setOwnerDetails((prevOwnerDetails) =>
          updateOwnerDetails(prevOwnerDetails, name, value)
        );
      } else {
        const sanitizedValue = value.replace(/^0+/, "");
        setOwnerDetails((prevOwnerDetails) =>
          updateOwnerDetails(prevOwnerDetails, name, sanitizedValue)
        );
      }
    } else {
      setOwnerDetails((prevOwnerDetails) =>
        updateOwnerDetails(
          prevOwnerDetails,
          name,
          type === "number" ? Number(value) : value
        )
      );
    }
  };

  const handleModalClose = () => {
    onClose();
  };
  //Function to add FormData Ends

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      const formObject = selectedTab === "Solar PV" ? formdata : formdata2;
      Object.keys(formObject).forEach((key) => {
        formData.append(key, JSON.stringify(formObject[key]));
      });
      formData.append("ownerDetails", JSON.stringify(ownerDetails));
      formData.append("installingFor", installingFor);

      const response = await axios.post(`${baseURL}/job/create-job`, formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      document.body.classList.remove("page-modal-open");
      const jobId = response?.data?.newJobPost?._id;
      localStorage.setItem("jobId", jobId);
      if (response.status === 200 || response.status === 201) {
        alert("Job is Created");
        setTimeout(() => {
          if (selectedTab === "Solar PV") {
            window.open("/createPVJob", "_blank");
          } else {
            window.open("/createSHWJob", "_blank");
          }
        }, 800);
        getJobs();
        handleModalClose();
      } else {
        console.error("Login failed. Unexpected status code:", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay-createJob" onClick={onClose}>
      <div
        className="modal-content-createJob"
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ display: "flex", gap: "300px" }} className="modalHeader">
          <h2>Create Job</h2>
          <button className="add-model-close-btn" onClick={onClose}>
            ✕
          </button>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20%",
          }}
        >
          <div style={{ height: "10%" }}>
            <button
              type="button"
              style={{
                backgroundColor:
                  selectedTab === "Solar PV"
                    ? "rgba(0, 67, 229, 0.3)"
                    : "transparent",
                color: selectedTab === "Solar PV" ? "#fff" : "#333",
                border: "1px solid #ccc",
                padding: "2px 16px",
                cursor: "pointer",
              }}
              onClick={() => setSelectedTab("Solar PV")}
            >
              <img
                src="/Assets/DashboardPV/solar-panel.svg"
                alt="Solar panel"
                width={"23px"}
              />
              <span>Solar PV</span>
            </button>
            <button
              type="button"
              style={{
                backgroundColor:
                  selectedTab === "Hot Water"
                    ? "rgba(0, 67, 229, 0.3)"
                    : "transparent",
                color: selectedTab === "Hot Water" ? "#fff" : "#333",
                border: "1px solid #ccc",
                padding: "2px 16px",
                cursor: "pointer",
              }}
              onClick={() => setSelectedTab("Hot Water")}
            >
              <img src="/Assets/DashboardPV/hot-surface.svg" width={"22px"} />
              <span>Hot Water</span>
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <span style={{ paddingLeft: "17px" }}>Installing For</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <select
              className="detail-input"
              style={{ width: "440px", marginRight: "0" }}
              name="installingFor"
              value={installingFor || ""}
              onChange={(e) => setInstallingFor(e.target.value)}
              autoComplete="off"
              required
            >
              <option value="" disabled>
                SELECT
              </option>
              {installingForOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option}
                </option>
              ))}
            </select>
            {installingFor === "Individual" ? (
              <>
                <input
                  className="detail-input"
                  style={{ width: "440px", marginRight: "0" }}
                  type="text"
                  name="firstName"
                  placeholder="First Name*"
                  maxLength={30}
                  value={ownerDetails?.firstName || ""}
                  onChange={handleInputCustomerDetails}
                  autoComplete="off"
                  required
                />

                <input
                  className="detail-input"
                  style={{ width: "440px", marginRight: "0" }}
                  type="text"
                  name="lastName"
                  maxLength={30}
                  placeholder="Last Name*"
                  value={ownerDetails?.lastName || ""}
                  onChange={handleInputCustomerDetails}
                  autoComplete="off"
                  required
                />
              </>
            ) : (
              <input
                className="detail-input"
                style={{ width: "440px", marginRight: "0" }}
                type="text"
                name="companyName"
                placeholder="Company Name*"
                maxLength={60}
                value={ownerDetails?.companyName || ""}
                onChange={handleInputCustomerDetails}
                autoComplete="off"
                required
              />
            )}

            <input
              className="detail-input"
              style={{ width: "440px", marginRight: "0" }}
              type="email"
              name="emailId"
              placeholder="Email ID"
              value={ownerDetails?.emailId || ""}
              maxLength={30}
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
              onChange={handleInputCustomerDetails}
              autoComplete="off"
              required
            />

            <input
              className="detail-input"
              style={{ width: "440px", marginRight: "0" }}
              type="text"
              name="mobileNum"
              placeholder="Mobile Number"
              value={ownerDetails?.mobileNum || ""}
              onChange={handleInputCustomerDetails}
              autoComplete="off"
              required
              onKeyDown={(e) => {
                if (e.target.value.length > 14) {
                  window.alert("Mobile number must be of 14 digits.");
                  e.target.value = " ";
                }
              }}
            />
            <input
              className="detail-input"
              style={{ width: "440px", marginRight: "0" }}
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              value={ownerDetails?.phoneNumber || ""}
              onChange={handleInputCustomerDetails}
              autoComplete="off"
            />
          </div>
          <div style={{ display: "flex" }}>
            <div className="add-model-actions">
              <button type="button" onClick={onClose} className="cancel-hover">
                Cancel
              </button>
              <button type="submit" className="submit-hover">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateJobModal;
