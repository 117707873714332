import axios from "axios";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const UploadButton = ({
  label,
  fileTypes,
  formDataKey,
  photos,
  onSiteData,
}) => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileTypes,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  });
  const handleFileUpload = async (files, key, type) => {
    let fileKey = "";
    setLoading(true);

    if (key === "Panel Serials") {
      fileKey = "panelPhotos";
    } else if (key === "Inverter Serials") {
      fileKey = "inverterPhoto";
    } else {
      fileKey = "batteryPhoto";
    }

    const uploadPromises = [];
    let errorOccurred = false;
    // Iterate over each file
    for (const file of files) {
      const formData = new FormData();
      formData.append(fileKey, file);
      formData.append("jobId", jobId);
      const token = localStorage.getItem("userToken");

      let apiURL =
        key === "Panel Serials"
          ? "onsitePanel/update"
          : key === "Inverter Serials"
          ? "onsiteInverter/update"
          : "onsiteBattery/update";

      const uploadPromise = axios
        .post(`${baseURL}/${apiURL}`, formData, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          onSiteData();
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          errorOccurred = true; // Set flag to true on error
          setLoading(false);
        });

      uploadPromises.push(uploadPromise);
    }

    Promise.all(uploadPromises)
      .then(() => {
        if (!errorOccurred) {
          alert("All images uploaded successfully");
          setFiles([]);
        } else {
          alert("Failed to upload Images.");
          setFiles([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading images:", error);
        setLoading(false);
      });
  };

  return (
    <>
      <div
        className="imageUploadInputField"
        style={{ height: "200px", padding: "20px" }}
      >
        <div className="LabelAndImage">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <label
              htmlFor={`fileInput-${formDataKey}`}
              className="labelTextForImage label-Imageupload"
            >
              {label}
            </label>
          </div>
        </div>
      </div>
      {files.length > 0 && (
        <div
          style={{
            display: "flex",
            width: "90%",
            justifyContent: "center",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <p>{files?.length} file(s) selected</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "40px",
              flexWrap: "wrap",
            }}
          >
            {files?.length ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "90%",
                }}
              >
                <button
                  type="button"
                  className="buttonForReports"
                  onClick={() => handleFileUpload(files, formDataKey)}
                  disabled={loading}
                >
                  {loading ? "Uploading..." : "Upload"}
                </button>
              </div>
            ) : null}
            {files.map((file, index) => (
              <div key={index}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  style={{ maxWidth: "200px" }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default UploadButton;
