import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CreateJobModal from "./CreateJobModal/CreateJobModal";
import { Avatar, Tooltip } from "@mui/material";
import { Download } from "@mui/icons-material";
import { DotLoader } from "react-spinners";
const HeaderComponent = (props) => {
  const {
    PageTitle,
    location,
    subTitle,
    handleUploadButtonClick,
    handleFileChange,
    JobType,
    loading,
    getJobs,
  } = props;

  const windowLocation = useLocation();

  const openModal = () => {
    setIsModalOpen(true);

    document.body.classList.add("page-modal-open");
  };
  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("page-modal-open");
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const image = process.env.PUBLIC_URL;
  //Upload and Download File Starts
  const fileUrl =
    "https://installer-profile-photos.s3.ap-southeast-2.amazonaws.com/Sample_JobPostData.csv";
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDownload = () => {
    window.open(fileUrl, "job-template.csv");
  };
  //Upload and Download File Ends

  // function to get initials
  const NameInitials = (name) => {
    name = localStorage.getItem("name");
    return name?.match(/(\b\S)?/g).join("");
  };
  return (
    <div className="row">
      <div className="main-jobpart">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="text-blue">{PageTitle || "Retailer Dashboard"}</div>
          <div
            style={{
             
            }}
            className="headerButtonsSection"
          >
            {userType != "Trader" && location == "Dashboard" ? (
              <div
                className="buttons-container"
                style={{
                  width:
                    windowLocation.pathname === "/dashboard" ? "50%" : "30%",
                }}
              >
                <button
                  className="btn-createjob createJobButton"
                  onClick={openModal}
                >
                  Create Job
                </button>
                {windowLocation.pathname === "/dashboard" ? (
                  <button
                    type="button"
                    className="btn-createjob"
                    onClick={() => setShowModal(true)}
                  >
                    Import Jobs
                  </button>
                ) : null}
                {showModal && (
                  <div className="add-model-backdrop">
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "476px",
                        background: "white",
                        padding: "20px",
                        zIndex: "1000",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        height: "372px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3>Upload Job File</h3>
                        <button
                          className="add-model-close-btn"
                          type="button"
                          onClick={handleCloseModal}
                        >
                          ✕
                        </button>
                      </div>
                      <br />
                      <hr />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          paddingTop: "20px",
                          alignItems: "center",
                          justifyContent: "space-around",
                          height: "80%",
                        }}
                      >
                        <p>
                          {loading
                            ? "Uploading the file. Please wait..."
                            : `Please upload the CSV file containing the job details.`}
                        </p>
                        <div>
                          {loading ? (
                            <DotLoader color="rgb(21,67,176)" height={15} />
                          ) : (
                            <>
                              <div
                                style={{
                                  marginTop: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={handleDownload}
                                  className="buttonForReports"
                                  style={{ width: "190px" }}
                                >
                                  <Download sx={{ fontSize: "large" }} />{" "}
                                  Template
                                </button>
                              </div>
                              <br />

                              <button
                                type="button"
                                className="buttonForReports"
                                style={{ width: "190px" }}
                                onClick={() => {
                                  handleUploadButtonClick();
                                }}
                                disabled={loading}
                              >
                                {"Upload Jobs"}
                              </button>
                              <input
                                type="file"
                                id="fileInput"
                                accept=".csv"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  handleFileChange(e);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {showModal && (
                  <div
                    style={{
                      position: "fixed",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: "999",
                    }}
                    onClick={handleCloseModal}
                  ></div>
                )}
              </div>
            ) : location === "TeamsPage" ? null : null}
            <CreateJobModal
              isOpen={isModalOpen}
              onClose={closeModal}
              JobType={JobType}
              getJobs={getJobs}
            />
            <div style={{ display: "none" }}>
              <div className="notification">
                <div className="bell-icon">
                  <img src={`${image}/Assets/LogIn/Notification.svg`} alt="" />
                </div>
              </div>
              <div className="notification-text">
                <div className="notify-round">10</div>
              </div>
            </div>
            <div className="person-right">
              <Tooltip title={localStorage.getItem("name")}>
                <Avatar
                  onClick={() => {
                    navigate("/profile-view");
                  }}
                  style={{
                    backgroundColor: "rgb(21 67 176)",
                    textAlign: "center",
                    fontSize: "1rem",
                    width: "2.6rem",
                    cursor: "pointer",
                  }}
                >
                  {NameInitials()}
                </Avatar>
              </Tooltip>
            </div>
          </div>
        </div>
        <span className="text-jobs">{subTitle}</span>
      </div>
    </div>
  );
};

export default HeaderComponent;
