import React, { useState, useEffect } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const MultipleBattery = () => {
  const [formdata, setFormdata] = useState({
    panelDetail: {
      panelInfo: [
        {
          panelBrand: "",
          panelModel: "",
          panelPower: null,
          Quantity: null,
        },
      ],
      wattage: null,
      stc: null,
      financialPayment: null,
      isAssignPanelJob: false,
    },
  });

  const [activeProductType, setActiveProductType] = useState("batteries");
  const [searchTerms, setSearchTerms] = useState({
    panels: "",
    inverters: "",
    batteries: "",
  });

  const [showPanelDropdown, setShowPanelDropdown] = useState(false);
  const [showInverterDropdown, setShowInverterDropdown] = useState(false);
  const [showBatteryDropdown, setShowBatteryDropdown] = useState(false);
  const [panelData, setPanelData] = useState([]);
  const [inverterData, setInverterData] = useState([]);
  const [batteryData, setBatteryData] = useState([]);

  const constructApiEndpoint = (productType) => {
    return `${baseURL}/${productType}/get?search=${searchTerms[productType]}`;
  };

  const handleProductChange = (type, value) => {
    setSearchTerms((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleProductSelection = (type, product) => {
    if (type === "panel") {
      setShowPanelDropdown(false);
      const newPanelInfo = {
        panelBrand: product.Brand || "",
        panelSupplier: product.Brand || "",
        panelModel: product["Model Number"] || "",
        panelPower: product.Watt || "",
      };
      let panelInfo = [...formdata.panelDetail.panelInfo];
      if (panelInfo.length > 0 && panelInfo[0].panelBrand === "") {
        panelInfo.shift();
      }
      setFormdata((prevFormData) => ({
        ...prevFormData,
        panelDetail: {
          ...prevFormData.panelDetail,
          panelInfo: [...panelInfo, newPanelInfo],
        },
      }));
      setSearchTerms({
        panels: "",
        inverters: "",
        batteries: "",
      });
    } else if (type === "inverter") {
      setShowInverterDropdown(false);
      const newInverterInfo = {
        inverterBrand: product.Manufacturer || "",
        inverterSupplier: product.Manufacturer || "",
        inverterSeries: product.Series || "",
        inverterModel: product["Model Number"] || "",
        inverterCapacity: product["AC Power (kW)"] || "",
      };
      let inverterInfo = [...formdata.inverterDetail.inverterInfo];
      if (inverterInfo.length > 0 && inverterInfo[0].inverterBrand === "") {
        inverterInfo.shift();
      }
      setFormdata((prevFormData) => ({
        ...prevFormData,
        inverterDetail: {
          ...prevFormData.inverterDetail,
          inverterInfo: [...inverterInfo, newInverterInfo],
        },
      }));
      setSearchTerms({
        panels: "",
        inverters: "",
        batteries: "",
      });
    } else {
      setShowBatteryDropdown(false);
      const newBatteryInfo = {
        manufacturer: product?.Brand || "",
        batteryBrand: product?.Brand || "",
        batterySeries: product.Series || "",
        batteryModel: product["Model Number"] || "",
        batteryCapacity: product["Usable Capacity (kWh)"] || "",
        Quantity: null,
      };
      let batteryInfo = [...formdata.batteryDetail.batteryInfo];
      if (batteryInfo.length > 0 && batteryInfo[0].batteryBrand === "") {
        batteryInfo.shift();
      }
      setFormdata((prevFormData) => ({
        ...prevFormData,
        batteryDetail: {
          ...prevFormData.batteryDetail,
          batteryInfo: [...batteryInfo, newBatteryInfo],
        },
      }));
      setSearchTerms({
        panels: "",
        inverters: "",
        batteries: "",
      });
    }
  };

  useEffect(() => {
    const searchTerm = searchTerms[activeProductType];
    if (searchTerm && searchTerm.length > 0) {
      const endpoint = constructApiEndpoint(activeProductType);
      axios
        .get(endpoint)
        .then((response) => {
          if (activeProductType === "panels") {
            setPanelData(response.data);
          } else if (activeProductType === "inverters") {
            setInverterData(response.data);
          } else {
            setBatteryData(response.data);
          }
        })
        .catch((error) => {});
    }
  }, [searchTerms, activeProductType]);

  const [panelBrandList, setPanelBrandList] = useState([]);
  const [inverterBrandList, setInverterBrandList] = useState([]);
  const [batteryBrandList, setBatteryBrandList] = useState([]);

  // useEffect(() => {
  //   const fetchBrandModels = async () => {
  //     const requests = formdata?.batteryDetail?.batteryInfo.map(
  //       async (battery) => {
  //         const response = await axios.get(
  //           `${baseURL}/batteries/get?search=${battery?.batteryBrand}`
  //         );
  //         return response?.data;
  //       }
  //     );
  //     const brandModels = await Promise.all(requests);
  //     setBatteryBrandList(brandModels);
  //   };

  //   fetchBrandModels();
  // }, [formdata?.batteryDetail?.batteryInfo]);

  // useEffect(() => {
  //   const fetchBrandModels = async () => {
  //     const requests = formdata?.inverterDetail?.inverterInfo.map(
  //       async (inverter) => {
  //         const response = await axios.get(
  //           `${baseURL}/inverters/get?search=${inverter?.inverterBrand}`
  //         );
  //         return response?.data;
  //       }
  //     );
  //     const brandModels = await Promise.all(requests);
  //     setInverterBrandList(brandModels);
  //   };

  //   fetchBrandModels();
  // }, [formdata?.inverterDetail?.inverterInfo]);

  useEffect(() => {
    const fetchBrandModels = async () => {
      const requests = formdata?.panelDetail?.panelInfo.map(async (panel) => {
        const response = await axios.get(
          `${baseURL}/panels/get?search=${panel?.panelBrand}`
        );
        return response?.data;
      });
      const brandModels = await Promise.all(requests);
      setPanelBrandList(brandModels);
    };

    fetchBrandModels();
  }, [formdata?.panelDetail?.panelInfo]);

  //   const handleAddBatteryInfo = () => {
  //     const newBatteryInfo = {
  //       batterySeries: "",
  //       batteryModel: "",
  //       batteryCapacity: null,
  //       Quantity: null,
  //     };

  //     if (formdata.batteryDetail.batteryInfo.length === 0) {
  //       setFormdata({
  //         ...formdata,
  //         batteryDetail: {
  //           ...formdata.batteryDetail,
  //           batteryInfo: [newBatteryInfo],
  //         },
  //       });
  //     } else {
  //       setFormdata({
  //         ...formdata,
  //         batteryDetail: {
  //           ...formdata.batteryDetail,
  //           batteryInfo: [...formdata.batteryDetail.batteryInfo, newBatteryInfo],
  //         },
  //       });
  //     }
  //     setMultiplefieldsBattery(true);
  //   };

  // const handleInverterModelChange = (e, index) => {
  //   const selectedModelNumber = e.target.value;
  //   const selectedInverterModel = inverterBrandList[index]?.find(
  //     (inverter) => inverter["Model Number"] === selectedModelNumber
  //   );

  //   setFormdata((prevFormData) => ({
  //     ...prevFormData,
  //     inverterDetail: {
  //       ...prevFormData.inverterDetail,
  //       inverterInfo: prevFormData.inverterDetail.inverterInfo.map(
  //         (inverter, idx) => {
  //           if (idx === index) {
  //             return {
  //               ...inverter,
  //               inverterModel: selectedModelNumber,
  //               inverterSeries: selectedInverterModel?.Series || "",
  //               inverterCapacity:
  //                 selectedInverterModel["AC Power (kW)"] || null,
  //             };
  //           }
  //           return inverter;
  //         }
  //       ),
  //     },
  //   }));
  // };

  const handlePanelModelChange = (e, index) => {
    const selectedModelNumber = e.target.value;
    const selectedPanelModel = panelBrandList[index]?.find(
      (panel) => panel["Model Number"] === selectedModelNumber
    );
    console.log(selectedPanelModel);

    setFormdata((prevFormData) => ({
      ...prevFormData,
      panelDetail: {
        ...prevFormData.panelDetail,
        panelInfo: prevFormData.panelDetail.panelInfo.map((panel, idx) => {
          if (idx === index) {
            return {
              ...panel,
              panelModel: selectedModelNumber,
              panelSupplier: selectedPanelModel.Brand || "",
              panelPower: selectedPanelModel.Watt || null,
            };
          }
          return panel;
        }),
      },
    }));
  };

  // const handleBatteryModelChange = (e, index) => {
  //   console.log(e, index);
  //   const selectedModelNumber = e.target.value;
  //   const selectedBatteryModel = batteryBrandList[index]?.find(
  //     (battery) => battery["Model Number"] === selectedModelNumber
  //   );

  //   setFormdata((prevFormData) => ({
  //     ...prevFormData,
  //     batteryDetail: {
  //       ...prevFormData.batteryDetail,
  //       batteryInfo: prevFormData.batteryDetail.batteryInfo.map(
  //         (battery, idx) => {
  //           if (idx === index) {
  //             return {
  //               ...battery,
  //               batteryModel: selectedModelNumber,
  //               batterySeries: selectedBatteryModel?.Series || "",
  //               batteryCapacity:
  //                 selectedBatteryModel["Usable Capacity (kWh)"] || "",
  //             };
  //           }
  //           return battery;
  //         }
  //       ),
  //     },
  //   }));
  // };

  // const handleInputsInverterInfo = (e, index) => {
  //   const { name, type, value } = e.target;
  //   setFormdata((prev) => ({
  //     ...prev,
  //     inverterDetail: {
  //       ...prev.inverterDetail,
  //       inverterInfo: prev.inverterDetail.inverterInfo.map((info, i) => {
  //         if (i !== index) {
  //           return info;
  //         }
  //         return {
  //           ...info,
  //           [name]:
  //             type === "number" || type === "radio"
  //               ? type === "radio"
  //                 ? value === "true"
  //                   ? true
  //                   : false
  //                 : Number(value)
  //               : value,
  //         };
  //       }),
  //     },
  //   }));
  // };

  const handleInputsPanelInfo = (e, index) => {
    const { name, type, value } = e.target;
    if (type === "radio") {
      setFormdata((prev) => ({
        ...prev,
        panelDetail: {
          ...prev.panelDetail,
          [name]: value === "true" ? true : false,
        },
      }));
    } else {
      setFormdata((prev) => ({
        ...prev,
        panelDetail: {
          ...prev.panelDetail,
          panelInfo: prev.panelDetail.panelInfo.map((info, i) => {
            if (i !== index) {
              return info;
            }
            return {
              ...info,
              [name]: type === "number" ? Number(value) : value,
            };
          }),
        },
      }));
    }
  };

  // const handleInputsBatteryInfo = (e, index) => {
  //   const { name, type, value } = e.target;
  //   setFormdata((prev) => ({
  //     ...prev,
  //     batteryDetail: {
  //       ...prev.batteryDetail,
  //       batteryInfo: prev.batteryDetail.batteryInfo.map((info, i) => {
  //         if (i !== index) {
  //           return info;
  //         }
  //         return {
  //           ...info,
  //           [name]:
  //             type === "number" || type === "radio"
  //               ? type === "radio"
  //                 ? value === "true"
  //                   ? true
  //                   : false
  //                 : Number(value)
  //               : value,
  //         };
  //       }),
  //     },
  //   }));
  // };

  const handleDeleteField = (field, index) => {
    if (field === "battery") {
      const updatedBatteryInfo = formdata.batteryDetail.batteryInfo.filter(
        (_, idx) => idx !== index
      );
      setFormdata({
        ...formdata,
        batteryDetail: {
          ...formdata.batteryDetail,
          batteryInfo: updatedBatteryInfo,
        },
      });
    } else if (field === "inverter") {
      const updatedInverterInfo = formdata?.inverterDetail?.inverterInfo.filter(
        (_, idx) => idx !== index
      );
      setFormdata({
        ...formdata,
        inverterDetail: {
          ...formdata.inverterDetail,
          inverterInfo: updatedInverterInfo,
        },
      });
    } else {
      const updatedPanelInfo = formdata?.panelDetail?.panelInfo.filter(
        (_, idx) => idx !== index
      );
      setFormdata({
        ...formdata,
        panelDetail: {
          ...formdata.panelDetail,
          panelInfo: updatedPanelInfo,
        },
      });
    }
  };

  // const handleBatteryBlur = () => {
  //   setTimeout(() => {
  //     setShowBatteryDropdown(false);
  //   }, 200);
  // };

  // const handleInverterBlur = () => {
  //   setTimeout(() => {
  //     setShowInverterDropdown(false);
  //   }, 200);
  // };

  const handlePanelBlur = () => {
    setTimeout(() => {
      setShowPanelDropdown(false);
    }, 200);
  };

  return (
    <div className="panel-information">
      <div className="panel-main-bg postal-address">
        <p className="sectionTitleText">Panel</p>
        {console.log(formdata?.panelDetail)}
      </div>
      <input
        className="search-panel"
        style={{ width: "785px" }}
        type="text"
        placeholder="Search Panel"
        value={searchTerms?.panels || ""}
        autoComplete="off"
        onChange={(e) => handleProductChange("panels", e.target.value)}
        onFocus={() => {
          setActiveProductType("panels");
          setShowPanelDropdown(true);
        }}
        onBlur={handlePanelBlur}
      />
      {showPanelDropdown && (
        <table
          className="createjob_panelbrand_ul-container"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "785px",
            marginLeft: "21px",
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: 1000,
          }}
        >
          <thead>
            <tr className="PanelDataDropDown">
              <th style={{ width: "35%" }}>Brand</th>
              <th style={{ width: "25%" }}>Model</th>
              <th style={{ width: "20%" }}> Watt</th>
              <th style={{ width: "20%" }}>Expiry Date</th>
            </tr>
          </thead>
          <tbody className="tableBodyDropdown">
            {panelData.length > 0 ? (
              panelData.map((panel, index) => (
                <tr
                  className="PanelDataDropDown"
                  key={index}
                  onClick={() => handleProductSelection("panel", panel)}
                >
                  <td style={{ width: "35%" }}>{panel.Brand}</td>
                  <td style={{ width: "25%" }}>{panel["Model Number"]}</td>
                  <td style={{ width: "20%" }}>{panel.Watt}</td>
                  <td style={{ width: "20%" }}>{panel["Expiry Date"]}</td>
                </tr>
              ))
            ) : (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <td colSpan="4">No Panel Data found</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <div className="panelAndAddButton">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {Array.isArray(formdata?.panelDetail?.panelInfo) &&
            formdata?.panelDetail.panelInfo.map((panel, index) => (
              <div key={index} className="panelAndAddButton">
                <div className="InfoAndDeleteContainer">
                  <input
                    className="brand-input"
                    type="text"
                    placeholder="Brand"
                    value={panel.panelBrand || ""}
                    autoComplete="off"
                  />

                  <div className="invertermodel-boxes">
                    <select
                      name="panelModel"
                      className="installing-type"
                      value={panel.panelModel}
                      defaultValue={""}
                      onChange={(e) => handlePanelModelChange(e, index)}
                    >
                      <option value="" disabled>
                        Model
                      </option>
                      {panelBrandList[index]?.map((panelModel, idx) => (
                        <option key={idx} value={panelModel["Model Number"]}>
                          {panelModel["Model Number"]}
                        </option>
                      ))}
                    </select>
                  </div>

                  <input
                    className="supplier-input"
                    type="text"
                    style={{ display: "none" }}
                    placeholder="Supplier"
                    value={panel.panelSupplier || ""}
                    readOnly
                  />

                  <input
                    className="quantity-text"
                    placeholder="Power"
                    id="panelbrand-input"
                    type="number"
                    name="panelPower"
                    value={panel.panelPower || ""}
                    autoComplete="off"
                    readOnly
                  />

                  <input
                    className="quantity-text"
                    type="number"
                    placeholder="Quantity"
                    name="Quantity"
                    value={panel.Quantity || ""}
                    onChange={(e) => handleInputsPanelInfo(e, index)}
                  />
                  {/* {(ismultipleFieldsPanel ||
                    formdata?.panelDetail?.panelInfo.length > 1) &&
                  index > 0 ? (
                    <div style={{ height: "100%" }}>
                      <button
                        type="button"
                        className="delete-field-button"
                        onClick={() => handleDeleteField("panel", index)}
                      >
                        Delete
                      </button>
                    </div>
                  ) : null} */}
                </div>
              </div>
            ))}
        </div>

        {/* <div class="paneladd-btn">
          <Tooltip title="Add Inverter">
            <button
              type="button"
              className="plus-add"
              onClick={handleAddInverterInfo}
            >
              Add
            </button>
          </Tooltip>
        </div> */}
      </div>

      {/* {inverterCapacityError ? (
        <div>
          <p
            style={{
              color: "red",
              margin: "0px 20px 0 20px",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            {`Selected Inverter does not match the requirements of selected Panels Wattage.`}
          </p>
        </div>
      ) : null} */}
    </div>
  );
};

export default MultipleBattery;
