import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const OnsitePhotos = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  //Storing Data from API
  const [photosData, setPhotosData] = useState([]);

  //Get On Site Details Starts
  useEffect(() => {
    const fetchOnSiteData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/onSite`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp?.data?.data;
        const flattenedData = data.flat();
        const result = flattenedData.filter((item) => item.jobId === jobId);
        const photos = result[0]?.photos;
        setPhotosData(photos);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchOnSiteData();
  }, []);
  //Get On Site Details Ends

  const [addPanelPopUp, setAddPhotoPopup] = useState(false);

  return (
    <>
      <div className="row">
        <div className="checkin-panel">
          <h3>Photos</h3>
        </div>

        <div className="row">
          <div className="panel-roofbg">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b className="text-roof">Panels installed on Roof</b>
              <div class="paneladd-btn">
                <button
                  className="btn-addphoto"
                  onClick={() => {
                    setAddPhotoPopup(false);
                    alert("This feature will be functional later.");
                  }}
                >
                  <span className="`${image}/Assets/OnSiteData/Photos/plussign.svg`">
                    <img
                      src={`${image}/Assets/OnSiteData/Photos/plussign.svg`}
                      alt=""
                    />
                  </span>
                  Add Photo
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="panel-install">
              {photosData[0]?.panelinstalled &&
              photosData[0]?.panelinstalled?.length > 0 ? (
                <ul className="setup-selfi">
                  {photosData[0]?.panelinstalled.map((photo, index) => {
                    return (
                      <li key={index}>
                        <img
                          src={photo?.url}
                          alt=""
                          style={{ width: "184px", height: "184px" }}
                        />
                        <span className="set-install-1">Installation</span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className="setup-selfi">
                  <li>
                    <span className="inverter-install-text">
                      The Installer has not provided any photo yet.
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="panel-roofbg">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b className="text-roof">Inverter Installed</b>
              <div class="paneladd-btn">
                <button
                  className="btn-addphoto"
                  onClick={() => {
                    setAddPhotoPopup(false);
                    alert("This feature will be functional later.");
                  }}
                >
                  <span className="`${image}/Assets/OnSiteData/Photos/plussign.svg`">
                    <img
                      src={`${image}/Assets/OnSiteData/Photos/plussign.svg`}
                      alt=""
                    />
                  </span>
                  Add Photo
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="panel-install">
              {photosData[0]?.inverterFront &&
              photosData[0]?.inverterFront?.length > 0 ? (
                <ul className="setup-selfi">
                  {photosData[0]?.inverterFront.map((photo, index) => {
                    return (
                      <li key={index}>
                        <img
                          src={photo?.url}
                          alt=""
                          style={{ width: "184px", height: "184px" }}
                        />
                        <span className="set-install-1">Inverter</span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className="setup-selfi">
                  <li>
                    <span className="inverter-install-text">
                      The Installer has not provided any photo yet.
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="panel-roofbg">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b className="text-roof">Battery Installed</b>
              <div class="paneladd-btn">
                <button
                  className="btn-addphoto"
                  onClick={() => {
                    setAddPhotoPopup(false);
                    alert("This feature will be functional later.");
                  }}
                >
                  <span className="`${image}/Assets/OnSiteData/Photos/plussign.svg`">
                    <img
                      src={`${image}/Assets/OnSiteData/Photos/plussign.svg`}
                      alt=""
                    />
                  </span>
                  Add Photo
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="panel-install">
              {photosData[0]?.batteryFront &&
              photosData[0]?.batteryFront?.length > 0 ? (
                <ul className="setup-selfi">
                  {photosData[0]?.batteryFront.map((photo, index) => {
                    return (
                      <li key={index}>
                        <img
                          src={photo?.url}
                          alt=""
                          style={{ width: "184px", height: "184px" }}
                        />
                        <span className="set-install-1">Battery</span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className="setup-selfi">
                  <li>
                    <span className="inverter-install-text">
                      The Installer has not provided any photo yet.
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="panel-roofbg">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b className="text-roof">Switch Board Open</b>
              <div class="paneladd-btn">
                <button
                  className="btn-addphoto"
                  onClick={() => {
                    setAddPhotoPopup(false);
                    alert("This feature will be functional later.");
                  }}
                >
                  <span className="`${image}/Assets/OnSiteData/Photos/plussign.svg`">
                    <img
                      src={`${image}/Assets/OnSiteData/Photos/plussign.svg`}
                      alt=""
                    />
                  </span>
                  Add Photo
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="panel-install">
              {photosData[0]?.meterAndSwitchboard &&
              photosData[0]?.meterAndSwitchboard?.length > 0 ? (
                <ul className="setup-selfi">
                  {photosData[0]?.meterAndSwitchboard.map((photo, index) => {
                    return (
                      <li key={index}>
                        <img
                          src={photo?.url}
                          alt=""
                          style={{ width: "184px", height: "184px" }}
                        />
                        <span className="set-install-1">Switch Board</span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className="setup-selfi">
                  <li>
                    <span className="inverter-install-text">
                      The Installer has not provided any photo yet.
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="panel-roofbg">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b className="text-roof">Front of House</b>
              <div class="paneladd-btn">
                <button
                  className="btn-addphoto"
                  onClick={() => {
                    setAddPhotoPopup(false);
                    alert("This feature will be functional later.");
                  }}
                >
                  <span className="`${image}/Assets/OnSiteData/Photos/plussign.svg`">
                    <img
                      src={`${image}/Assets/OnSiteData/Photos/plussign.svg`}
                      alt=""
                    />
                  </span>
                  Add Photo
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="panel-install">
              {photosData[0]?.frontPropertyView &&
              photosData[0]?.frontPropertyView?.length > 0 ? (
                <ul className="setup-selfi">
                  {photosData[0]?.frontPropertyView.map((photo, index) => {
                    return (
                      <li key={index}>
                        <img
                          src={photo?.url}
                          alt=""
                          style={{ width: "184px", height: "184px" }}
                        />
                        <span className="set-install-1">House Front</span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className="setup-selfi">
                  <li>
                    <span className="inverter-install-text">
                      The Installer has not provided any photo yet.
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>

        {
          <div className="row">
            <div style={{ display: "flex" }}></div>
          </div>
        }
      </div>
      <div className="row">
        {addPanelPopUp && (
          <div className="invite-model-backdrop">
            <div className="delete-model-content photoupload-popup-height">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2 className="text-panel">Add Photo</h2>
                <button
                  class="onsitedata-model-close-btn"
                  onClick={() => {
                    setAddPhotoPopup(false);
                    alert("This feature will be functional later.");
                  }}
                >
                  ✕
                </button>
              </div>
              <span className="addphoto-bottom-border"></span>

              <div className="row">
                <form method="post">
                  <div class="retailer-main doc-upload">
                    <input
                      class="photoupload-input"
                      type="file"
                      placeholder="Upload Photo"
                      accept="image/png, image/jpeg, application/pdf"
                      name="design"
                    />
                    <span className="file-text">
                      (JPG Max 50KB, PNG Max 100KB)
                    </span>
                    <img
                      src={`${image}/Assets/OnSiteData/Photos/uploadphoto.svg`}
                      alt=""
                      className="photoupload"
                    />
                    <input
                      className="addphoto-title"
                      type="text"
                      placeholder="Title"
                      required
                    />
                  </div>
                </form>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button className="onsitebtn-cancel">Cancel</button>
                <button className="onsitebtn-save">Save</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OnsitePhotos;
