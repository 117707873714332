import React, { useState } from "react";
import Modal from "react-modal";
import moment from "moment";

const customStyles = {
  content: {
    width: "fit-content",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const ImageModal = ({ isOpen, onRequestClose, image, label, distance }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <>
        <img src={image} alt={label} style={{ width: "auto",height:'100%'}} />
        <div style={{ marginTop: "0px" }}>{label}</div>
        {distance && (
          <div>
            Distance:{" "}
            {distance > 1 ? `${distance} km` : `${distance * 1000} m`}
          </div>
        )}

        {/* Close button using provided code */}
        <button
          className="add-model-close-btn"
          style={{ position: "absolute", top: "20px", right: "25px" }}
          onClick={() => {
            onRequestClose();
          }}
        >
          ✕
        </button>
      </>
    </Modal>
  );
};

export default ImageModal;
