import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "./DownloadSTCForm.css";
import moment from "moment";

import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const styles = StyleSheet.create({
  blackText: {
    color: "#000000",
  },
  doucmentStyle: {
    padding: "4% 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  page: {
    display: "flex",
    flexDirection: "column",
    padding: "20 30",
    margin: "10 0",
  },
  logoDiv: {
    width: "20%",
  },
  CompanyName: {
    fontSize: 15,
    width: "18%",
  },
  section: {
    // margin:10
    width: "100%",
    border: "1px solid #000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  rowData: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 5,
    // width: "10%",
    // justifyContent:"space-between "
  },
  sectionTitle: {
    color: "#000",
    borderBottom: "1px Solid #000",
    // width:"100%",
  },
  subSectionRow: {
    display: "flex",
    flexDirection: "row",
    // borderBottom: "1px solid #000",
    alignItems: "center",
    gap: 20,
    paddingBottom: 10,
  },

  checkbox: {
    display: "flex",
    width: 14,
    height: 14,
    border: "1px solid #000",
    marginRight: 5,
  },

  title: {
    fontSize: 14,
    fontWeight: "b00",
    padding: "2%",
    textAlign: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  TitleText: {
    // width: "60%",
    // textAlign: "center",
    fontSize: 15,
    fontWeight: "bold",
    // textDecoration: "underline",
    paddingBottom: 10,
    color: "#505251",
  },
  hr: {
    marginTop: 19,
    marginBottom: 12,
    height: 1,
    backgroundColor: "rgb(21 67 176)",
  },
  textStyle: {
    color: "#505251",
    fontSize: 10,
    // textDecoration: "underline",
    fontWeight: 550,
  },
  textStyleDynamic: {
    color: "#505251",
    fontSize: 10,
    fontWeight: 550,
    textOverflow: "wrap",
  },
  SectionsInRow: {
    display: "flex",
    flexDirection: "row",
    gap: 100,
  },
  FirstRow: {
    gap: "160px",
  },
  boldText: {
    color: "#505251",
    fontSize: 10,
    fontWeight: "650",
    textOverflow: "wrap",
  },
  TextStylePara: {
    color: "#505251",
    fontSize: 10,
    fontWeight: 550,
    textOverflow: "wrap",
    padding: "8 0 2",
  },
  checkboxContainer: {
    flexDirection: "row",
    // alignItems: "center",
    gap: "10px",
  },
  label: {
    fontSize: 10,
    color: "#505251",
    fontWeight: 550,
    overflow: "hidden",
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "12px",
  },
  row: { flexDirection: "row", gap: "50px" },
  productsRow: { flexDirection: "row", gap: "5px", height: "28px" },
  cell: {
    padding: 5,
    fontSize: 8,
    fontWeight: 600,
    width: "30%",
    flexWrap: "wrap",
  },
  cellFirst: {
    width: "1%",
  },
  headerCell: {},
  HeaderRowData: {
    display: "flex",
    flexDirection: "row",
    gap: "180px",
    alignItems: "center",
  },
  HeaderText: {
    fontSize: 14,
    width: "50%",
  },
  imageStyle: {
    width: "100px",
  },
  textStyleDynamicPropertyType: {
    color: "#505251",
    fontSize: 10,
    fontWeight: 550,
    textOverflow: "wrap",
  },
  PropertyType: {
    width: "200px",
  },
  rowDataSWH: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
    flexWrap: "wrap",
    // width: "10%",
    // justifyContent:"space-between "
  },
  cellSWH: {
    padding: 5,
    fontSize: 10,
    fontWeight: 600,
    width: "30%",
    flexWrap: "wrap",
  },
  addressDynamic: {
    textOverflow: "wrap",
    width: 200,
  },
  ownerDetailSection: {
    width: "100%",
  },
  SignatureName: {
    fontSize: 12,
    fontWeight: "bold",
  },
  DeclarationPara: {
    color: "#505251",
    fontSize: 10,
    fontWeight: 550,
    textOverflow: "wrap",
    padding: "8 0",
  },
  TextStyleParaList: {
    paddingLeft: 10,
  },
  boldText: {
    fontWeight: "extrabold",
    fontSize: 11,
  },
  extraSpace: {
    paddingBottom: 100,
  },
  tableSWH: {
    display: "flex",
    flexDirection: "row",
    gap: 40,
    justifyContent: "left",
  },
  TwoCellTable: {
    width: "30%",
    textAlign: "left",
  },
  secondCelltable: {
    // paddingLeft:28,
    width: "70%",
  },
  panelManuCell: {
    width: "40%",
  },
});

const STCFormData = ({ jobData }) => {
  const [retailerData, setRetailerData] = useState([]);
  // Fetching Data Logic:
  function numberToWord(number) {
    const words = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
    ];
    if (number < 0 || number > 10) {
      return "Invalid";
    }
    return words[number];
  }

  const Checkbox = ({ checked, label }) => (
    <View style={styles.checkboxContainer}>
      <View
        style={[styles.checkbox, checked && { backgroundColor: "black" }]}
      />
      <Text style={styles.label}>{label}</Text>
    </View>
  );

  const totalQuantity = jobData?.jobDetails?.panelDetail?.panelInfo?.reduce(
    (total, panel) => {
      return total + (panel.Quantity || 0);
    },
    0
  );
  return (
    <Document id="mainContainer" style={styles.doucmentStyle}>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.HeaderRowData}>
          <View>
            {/* <Image src={logo} style={styles.logoImage} /> */}
            <Text style={styles.CompanyName}>
              {jobData?.user?.companyInformation?.tradingName}
            </Text>
          </View>

          <Text style={styles.HeaderText}>STC Assignment Form</Text>
        </View>
        <View style={styles.hr} />
        <View style={[styles.SectionsInRow, styles.FirstRow]}>
          <View>
            <Text style={[styles.textStyle, styles.blackText]}>
              {`${jobData?.jobDetails?.jobIdentifyId || "N/A"} `}{" "}
            </Text>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Installation Date </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {jobData?.jobDetails?.installationDate?.installingDate
                  ? moment(
                      jobData?.jobDetails?.installationDate?.installingDate
                    ).format("DD/MM/YYYY")
                  : `N/A`}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>STC Deeming Period </Text>
              {jobData?.jobDetails?.installationDate?.STCDeemingPeriod !==
              undefined ? (
                <Text style={[styles.textStyleDynamic, styles.blackText]}>
                  {" - "}
                  {`${numberToWord(
                    jobData?.jobDetails?.installationDate?.STCDeemingPeriod
                  )} Years`}
                </Text>
              ) : (
                " - "
              )}
            </View>
          </View>
          <View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Total STCs </Text>
              <Text style={[styles.textStyle, styles.blackText]}>
                - {jobData?.jobDetails?.panelDetail?.stc || "N/A"}{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Wattage </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                - {jobData?.jobDetails?.panelDetail?.wattage || "N/A"} kW{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Number of Panels </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                {" "}
                - {totalQuantity}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.hr} />
        <View style={styles.SectionsInRow}>
          <View style={styles.ownerDetailSection}>
            <Text style={styles.TitleText}>Owner Details - </Text>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>
                {`${
                  jobData?.installingFor == "Individual"
                    ? "Name"
                    : "Company Name"
                }`}{" "}
              </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {(jobData?.jobDetails?.ownerDetails?.firstName &&
                  [
                    jobData?.jobDetails?.ownerDetails?.firstName,
                    jobData?.jobDetails?.ownerDetails?.lastName,
                  ].join(" ")) ||
                  jobData?.jobDetails?.ownerDetails?.companyName ||
                  "N/A"}{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Address - </Text>
              <Text style={[styles.textStyleDynamic, styles.addressDynamic]}>
                {"  "}
                {jobData?.jobDetails?.installationAddress?.unitType &&
                  `${jobData?.jobDetails?.installationAddress?.unitType} `}
                {jobData?.jobDetails?.installationAddress?.unitNumber &&
                  `${jobData?.jobDetails?.installationAddress?.unitNumber}, `}
                {jobData?.jobDetails?.installationAddress?.streetNumber &&
                  `${jobData?.jobDetails?.installationAddress?.streetNumber} `}
                {jobData?.jobDetails?.installationAddress?.streetName &&
                  `${jobData?.jobDetails?.installationAddress?.streetName} `}
                {jobData?.jobDetails?.installationAddress?.streetType &&
                  `${jobData?.jobDetails?.installationAddress?.streetType}, `}
                {jobData?.jobDetails?.installationAddress?.suburb &&
                  `${jobData?.jobDetails?.installationAddress?.suburb}, `}
                {jobData?.jobDetails?.installationAddress?.state &&
                  `${jobData?.jobDetails?.installationAddress?.state}, `}
                {jobData?.jobDetails?.installationAddress?.postCode &&
                  `${jobData?.jobDetails?.installationAddress?.postCode}`}{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Email</Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                - {jobData?.jobDetails?.ownerDetails?.emailId || "N/A"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Mo </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {jobData?.jobDetails?.ownerDetails?.mobileNum
                  ? jobData?.jobDetails?.ownerDetails?.mobileNum
                  : "N/A"}
              </Text>
            </View>
          </View>
          <View style={styles.ownerDetailSection}>
            <Text style={styles.TitleText}>Co-Owner Details - </Text>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Name </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {(jobData?.jobDetails?.coOwnerDetail?.firstName &&
                  [
                    jobData?.jobDetails?.coOwnerDetail?.firstName,
                    jobData?.jobDetails?.coOwnerDetail?.lastName,
                  ].join(" ")) ||
                  jobData?.jobDetails?.coOwnerDetail?.companyName ||
                  "N/A"}{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Address </Text>
              <Text style={[styles.textStyleDynamic, styles.addressDynamic]}>
                {" "}
                -{" "}
                {jobData?.jobDetails?.postalAddress?.POAddress?.unitTypes &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.unitType} `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.unitNumber &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.unitNumber}, `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.streetNumber &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.streetNumber} `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.streetName &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.streetName} `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.streetType &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.streetType}, `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.suburb &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.suburb}, `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.state &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.state}, `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.postCode &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.postCode}`}{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Email </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                - {jobData?.jobDetails?.coOwnerDetail?.emailId || "N/A"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Mo </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {jobData?.jobDetails?.coOwnerDetail?.mobileNum
                  ? jobData?.jobDetails?.coOwnerDetail?.mobileNum
                  : "N/A"}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Property Details - </Text>
          <View style={styles.SectionsInRow}>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Type </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                - {jobData?.jobDetails?.systemOwnerType || "N/A"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Storey</Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                - {jobData?.jobDetails?.siteAttributes?.storey || "N/A"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>NMI</Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                - {jobData?.jobDetails?.gridConnectionDetail?.nmi || "N/A"}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Installation Details -</Text>
          <View>
            <Text style={styles.textStyle}>
              Installation Type -{" "}
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                {jobData?.jobDetails?.installationDetails?.installationType}
              </Text>
            </Text>
            {!jobData?.jobDetails?.installationType !== "First Time" ? (
              <View style={styles.rowData}>
                <Text style={styles.textStyle}>Comment </Text>
                <Text style={[styles.textStyleDynamic, styles.blackText]}>
                  {" "}
                  - {jobData?.jobDetails?.installationDetails?.remarks || "N/A"}
                </Text>
              </View>
            ) : null}
            <View style={styles.rowData}>
              <Text style={styles.textStyleDynamic}>
                Is this application creating certificates for a system that has
                previously been failed by the CER ?{" "}
              </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                {" "}
                -{" "}
                {jobData?.jobDetails?.failedPV?.isItForPreviouslyFailedPV ===
                true
                  ? "Yes"
                  : "No"}
              </Text>
            </View>
            {jobData?.jobDetails?.failedPV?.isItForPreviouslyFailedPV ===
            true ? (
              <View style={styles.rowData}>
                <Text style={styles.textStyle}>Explanatory notes - </Text>
                <Text style={[styles.textStyleDynamic, styles.blackText]}>
                  {" "}
                  {jobData?.jobDetails?.failedPV?.explanatoryNotes}
                </Text>
              </View>
            ) : null}
            <View style={styles.TextStylePara}>
              <Text style={styles.textStyle}>
                Connection Type of Electricity Grid -{" "}
                <Text style={[styles.textStyleDynamic, styles.blackText]}>
                  {" "}
                  {jobData?.jobDetails?.gridConnectionDetail?.connectionType}
                </Text>
              </Text>
              <Text style={styles.textStyle}>
                Is the battery system part of an aggregated control? -{" "}
                <Text style={[styles.textStyleDynamic, styles.blackText]}>
                  {" "}
                  {jobData?.jobDetails?.batteryDetail?.batterySystemAggregate
                    ? "Yes"
                    : "No"}
                </Text>
              </Text>
              <Text style={styles.textStyle}>
                Has the installer changed default manufacturer setting of the
                battery storage system -{" "}
                <Text style={[styles.textStyleDynamic, styles.blackText]}>
                  {" "}
                  {jobData?.jobDetails?.batteryDetail
                    ?.defaultManufacturerBatteryStorage
                    ? "Yes"
                    : "No"}
                </Text>
              </Text>
            </View>
          </View>
          <Text style={styles.TextStylePara}>
            (If this system is additional capacity to an existing system, please
            provide detailed information on the position of the new panels and
            inverter (if applicable). System upgrades without a note explaining
            new panel locations will be failed by the Clean Energy Regulator.)
          </Text>

          <Text style={styles.TextStylePara}>
            Are you installing a complete unit (adding capacity to an existing
            system is not considered a complete unit)
          </Text>
          {/* <View style={styles.subSectionRow}>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>First Time -</Text>
              <Text style={styles.textStyleDynamic}>
                {jobData?.jobDetails?.installationDetails?.installationType ===
                "First Time"
                  ? "Yes"
                  : "No"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Replacement -</Text>
              <Text style={styles.textStyleDynamic}>
                {jobData?.jobDetails?.installationDetails?.installationType ===
                "Replacement"
                  ? "Yes"
                  : "No"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Additional -</Text>
              <Text style={styles.textStyleDynamic}>
                {jobData?.jobDetails?.installationDetails?.installationType ===
                "Additional"
                  ? "Yes"
                  : "No"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Existing -</Text>
              <Text style={styles.textStyleDynamic}>
                {jobData?.jobDetails?.installationDetails?.installationType ===
                "Extension"
                  ? "Yes"
                  : "No"}
              </Text>
            </View>
          </View> */}
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Documents Required </Text>
          <View style={styles.rowData}>
            <Text style={styles.textStyleDynamic}>
              EC accreditation statement{" "}
            </Text>
            <Text style={[styles.textStyleDynamic, styles.blackText]}>
              {` - ${
                jobData?.jobDetails?.installationDetails?.CECAccreditationSystem
                  ? "Yes"
                  : "No"
              }`}
            </Text>
          </View>
          <View style={styles.rowData}>
            <Text style={styles.textStyleDynamic}>
              Local, State and Territory government Siting approvals{" "}
            </Text>
            <Text style={[styles.textStyleDynamic, styles.blackText]}>
              {` - ${
                jobData?.jobDetails?.installationDetails
                  ?.territoryGovermentApproval
                  ? "Yes"
                  : "No"
              }`}
            </Text>
          </View>
          <View style={styles.rowData}>
            <Text style={styles.textStyleDynamic}>
              Electrical safety documentation{" "}
            </Text>
            <Text style={[styles.textStyleDynamic, styles.blackText]}>
              {` - ${
                jobData?.jobDetails?.installationDetails?.electricalSafetyDoc
                  ? "Yes"
                  : "No"
              }`}
            </Text>
          </View>
          <View style={styles.rowData}>
            <Text style={styles.textStyleDynamic}>
              Australian/New Zealand standards statement{" "}
            </Text>
            <Text style={[styles.textStyleDynamic, styles.blackText]}>
              {` - ${
                jobData?.jobDetails?.installationDetails?.AusNZStandardStatement
                  ? "Yes"
                  : "No"
              }`}
            </Text>
          </View>
        </View>
        <View style={styles.hr} />
        <View style={styles.extraSpace} />
        <View>
          <Text style={styles.TitleText}>Customer Declaration</Text>
          <Text style={styles.DeclarationPara}>
            I am the owner of the{" "}
            <Text style={styles.blackText}>
              {jobData?.jobDetails?.gridConnectionDetail?.connectionType !==
              "Stand-alone (not connected to an electricity grid)"
                ? jobData?.jobDetails?.gridConnectionDetail?.systemType
                : "Off Grid"}
            </Text>{" "}
            mentioned in this STC Assignment form and I agree to assign my right
            to create{" "}
            <Text style={styles.blackText}>
              {jobData?.jobDetails?.panelDetail?.stc}
            </Text>{" "}
            STCs to{" "}
            <Text style={styles.blackText}>
              {jobData?.traderDetails?.companyInformation?.tradingName}
            </Text>{" "}
            for a period of{" "}
            <Text style={styles.boldText}>
              {jobData?.jobDetails?.installationDate?.STCDeemingPeriod
                ? `${numberToWord(
                    jobData?.jobDetails?.installationDate?.STCDeemingPeriod
                  )} Years`
                : "N/A"}
            </Text>{" "}
            commencing
            <Text style={styles.blackText}>
              {" "}
              {`${
                jobData?.jobDetails?.installationDate?.installingDate
                  ? moment(
                      jobData?.jobDetails?.installationDate?.installingDate
                    ).format("DD/MM/YYYY")
                  : `N/A`
              }`}
            </Text>
            <Text style={styles.boldText}>
              . I have not previously assigned or created any STCs for this
              system within this period. I understand that this system is
              eligible for{" "}
              <Text style={styles.blackText}>
                {jobData?.jobDetails?.panelDetail?.stc}
              </Text>
            </Text>{" "}
            STCs and in exchange for assigning my right to create{" "}
            {jobData?.jobDetails?.panelDetail?.stc} STCs I will receive a
            monetary discount from the retailer of the system. By signing this
            form I acknowledge that the accredited installer and / or supervisor
            named in this form physically attended and installed / supervised
            the installation of my PV system. I am aware that penalties can be
            applied for providing misleading information in this form under the
            Renewable Energy (Electricity) Act 2000. I understand that if this
            assignment is unable to be created, unable to be approved or becomes
            invalid, I will reimburse{" "}
            <Text style={styles.blackText}>
              {jobData?.traderDetails?.companyInformation?.tradingName}
            </Text>{" "}
            for the full value of the monetary discount. I understand that
            personal information (my address and/or contact details) collected
            in relation to my{" "}
            <Text style={styles.blackText}>
              {jobData?.jobDetails?.gridConnectionDetail?.connectionType !==
              "Stand-alone (not connected to an electricity grid)"
                ? jobData?.jobDetails?.gridConnectionDetail?.systemType
                : "Off Grid"}
            </Text>{" "}
            may be used and disclosed to third parties for purposes that
            include: validation of the solar PV panels that form part of my{" "}
            <Text style={styles.blackText}>
              {jobData?.jobDetails?.gridConnectionDetail?.connectionType !==
              "Stand-alone (not connected to an electricity grid)"
                ? jobData?.jobDetails?.gridConnectionDetail?.systemType
                : "Off Grid"}
            </Text>{" "}
            , product warranty and product quality purposes, and monitoring and
            evaluating products. I consent to this collection, use and
            disclosure.
          </Text>
          <View>
            {jobData?.signatures[0]?.customerSignature ? (
              <Image
                src={jobData?.signatures[0]?.customerSignature?.url}
                alt="Customer Signature"
                style={styles.imageStyle}
              />
            ) : null}
            <Text style={styles.boldText}>
              {jobData?.signatures[0]?.customerSignature?.dateAndTime ? (
                <Text>
                  {moment(
                    jobData?.signatures[0]?.customerSignature?.dateAndTime
                  ).format("DD/MM/YYYY")}
                </Text>
              ) : null}
            </Text>
          </View>
          <View>
            <Text style={styles.textStyleDynamic}>
              {jobData?.jobDetails?.ownerDetails?.firstName}{" "}
              {jobData?.jobDetails?.ownerDetails?.lastName}
            </Text>
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Installer Written Statement - </Text>
          <Text style={styles.TextStylePara}>
            I{" "}
            <Text style={styles.blackText}>
              {jobData?.installers[0]?.installer?.name}
            </Text>
            , was the accredited installer of and hold the appropriate
            accreditation type for the{" "}
            <Text style={styles.boldText}>
              {jobData?.jobDetails?.gridConnectionDetail?.connectionType !==
              "Stand-alone (not connected to an electricity grid)"
                ? jobData?.jobDetails?.gridConnectionDetail?.systemType
                : "Off Grid"}
            </Text>{" "}
            installation at{" "}
            <Text style={styles.boldText}>
              {jobData?.jobDetails?.installationAddress?.unitType &&
                `${jobData?.jobDetails?.installationAddress?.unitType} `}
              {jobData?.jobDetails?.installationAddress?.unitNumber &&
                `${jobData?.jobDetails?.installationAddress?.unitNumber}, `}
              {jobData?.jobDetails?.installationAddress?.streetNumber &&
                `${jobData?.jobDetails?.installationAddress?.streetNumber}, `}
              {jobData?.jobDetails?.installationAddress?.streetName &&
                `${jobData?.jobDetails?.installationAddress?.streetName} `}
              {jobData?.jobDetails?.installationAddress?.streetType &&
                `${jobData?.jobDetails?.installationAddress?.streetType}, `}
              {jobData?.jobDetails?.installationAddress?.suburb &&
                `${jobData?.jobDetails?.installationAddress?.suburb}, `}
              {jobData?.jobDetails?.installationAddress?.state &&
                `${jobData?.jobDetails?.installationAddress?.state}, `}
              {jobData?.jobDetails?.installationAddress?.postCode &&
                `${jobData?.jobDetails?.installationAddress?.postCode}`}
            </Text>{" "}
            and verify that:
          </Text>
          <Text style={styles.TextStylePara}>
            {" "}
            I have complied with all the relevant installation requirements of
            the accreditation scheme for which I am accredited under for the
            installation of the unit
          </Text>
          <Text style={styles.TextStylePara}>
            {" "}
            All local, State or Territory government requirements have been met
            for the installation of the unit
          </Text>
          <Text style={styles.TextStylePara}>
            The electrical wiring associated with the installation of the unit
            was undertaken by an electrical worker holding an unrestricted
            license for electrical work issued by the State or Territory
            authority for the place where the unit was installed
          </Text>
          <Text style={styles.TextStylePara}>
            I have provided the owner of the unit with any documents required by
            the laws of the jurisdiction in which the unit was installed
            certifying that the installation of the unit complies with the
            relevant laws relating to safety and technical standards
          </Text>
          <Text style={styles.TextStylePara}>
            I received a copy of the design of the unit,
          </Text>
          <View>
            <Text style={styles.TextStylePara}>
              The design was not modified during the installation of the unit
              and it was installed in all material aspects, in accordance with
              the design
            </Text>
          </View>
          <View style={styles.TextStylePara}>
            <Text style={styles.TextStylePara}>
              The design was modified during the installation of the unit and
              the modifications were consistent with all relevant requirements
              of the accreditation scheme for the design of the unit and that
              the unit was installed, in all material aspects, in accordance
              with the modified design
            </Text>
          </View>
          <View>
            <Text style={styles.TextStylePara}>
              The unit will perform consistently with the design or modified
              design (as applicable)
            </Text>
          </View>
          <Text style={styles.TextStylePara}>
            That the inverter used in the installation of the unit was an
            approved eligible model of inverter and if grid connected complies
            <Text style={styles.boldText}>
              {jobData?.jobDetails?.inverterDetail?.inverterInfo?.inverterModel}
            </Text>
          </Text>
          <Text style={styles.TextStylePara}>
            That the model(s) of PV modules used in the installation of the unit
            was an approved eligible PV module{" "}
          </Text>
          <Text style={styles.TextStylePara}>
            I was on-site to install or supervise the installation of the unit
            in accordance with the accreditation scheme under which I am
            accredited
          </Text>
          <Text style={styles.TextStylePara}>
            I have and can provide evidence demonstrating that I have met the
            on-site attendance obligations in accordance with the accreditation
            scheme under which I am accredited.
          </Text>
          <Text style={styles.DeclarationPara}>
            I declare that the above statements are true, correct and complete
            and understand that under the Renewable Energy (Electricity) Act
            2000 and Renewable Energy (Electricity) Regulations 2001 penalties
            apply for providing false or misleading information. I understand
            that giving false or misleading information is a serious offence
            under the Criminal Code Act 1995.
          </Text>
          <View style={styles.logoDiv}>
            {jobData?.signatures[0]?.installerSignature ? (
              <Image
                src={jobData?.signatures[0]?.installerSignature?.url}
                alt="installer Signature"
              />
            ) : null}
            <Text style={styles.boldText}>
              {jobData?.signatures[0]?.installerSignature?.dateAndTime ? (
                <Text>
                  {moment(
                    jobData?.signatures[0]?.installerSignature?.dateAndTime
                  ).format("DD/MM/YYYY")}
                </Text>
              ) : null}
            </Text>
          </View>
          <View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Installer: </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.installers[0]?.installer?.name}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Phone: </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.installers[0]?.installer?.mobileNo}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Address : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.installers[0]?.installer?.fullAddress?.unitType &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.unitType} `}
                {jobData?.installers[0]?.installer?.fullAddress?.unitNumber &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.unitNumber}, `}
                {jobData?.installers[0]?.installer?.fullAddress?.streetNumber &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.streetNumber}, `}
                {jobData?.installers[0]?.installer?.fullAddress?.streetName &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.streetName} `}
                {jobData?.installers[0]?.installer?.fullAddress?.streetType &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.streetType}, `}
                {jobData?.installers[0]?.installer?.fullAddress?.suburb &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.suburb}, `}
                {jobData?.installers[0]?.installer?.fullAddress?.state &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.state}, `}
                {jobData?.installers[0]?.installer?.fullAddress?.postCode &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.postCode}`}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Accreditation Number : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.installers[0]?.installerCECID}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Designer Written Statement - </Text>
          <Text style={styles.TextStylePara}>
            I{" "}
            <Text style={styles.blackText}>
              {jobData?.installers[0]?.installer?.name}
            </Text>
            , was the accredited installer of and hold the appropriate
            accreditation type for the{" "}
            <Text style={styles.boldText}>
              {jobData?.jobDetails?.gridConnectionDetail?.connectionType !==
              "Stand-alone (not connected to an electricity grid)"
                ? jobData?.jobDetails?.gridConnectionDetail?.systemType
                : "Off Grid"}
            </Text>{" "}
            or the solar PV system installed at{" "}
            <Text style={styles.boldText}>
              {jobData?.jobDetails?.installationAddress?.unitType &&
                `${jobData?.jobDetails?.installationAddress?.unitType} `}
              {jobData?.jobDetails?.installationAddress?.unitNumber &&
                `${jobData?.jobDetails?.installationAddress?.unitNumber}, `}
              {jobData?.jobDetails?.installationAddress?.streetNumber &&
                `${jobData?.jobDetails?.installationAddress?.streetNumber}, `}
              {jobData?.jobDetails?.installationAddress?.streetName &&
                `${jobData?.jobDetails?.installationAddress?.streetName} `}
              {jobData?.jobDetails?.installationAddress?.streetType &&
                `${jobData?.jobDetails?.installationAddress?.streetType}, `}
              {jobData?.jobDetails?.installationAddress?.suburb &&
                `${jobData?.jobDetails?.installationAddress?.suburb}, `}
              {jobData?.jobDetails?.installationAddress?.state &&
                `${jobData?.jobDetails?.installationAddress?.state}, `}
              {jobData?.jobDetails?.installationAddress?.postCode &&
                `${jobData?.jobDetails?.installationAddress?.postCode}`}
            </Text>{" "}
            and verify that I hold the appropriate accreditation type in
            relation to the design of the unit and the design complies with all
            relevant requirements of the accreditation scheme under which I am
            accredited.
          </Text>
          <Text style={styles.DeclarationPara}>
            I declare that the above statements are true, correct and complete
            and understand that penalties apply under the Renewable Energy
            (Electricity) Act 2000 and Renewable Energy (Electricity)
            Regulations 2001 for providing false or misleading information. I
            understand that giving false or misleading information is a serious
            offence under the Criminal Code Act 1995.
          </Text>
          <View style={styles.logoDiv}>
            {jobData?.signatures[0]?.designerSignature?.url ? (
              <Image src={jobData?.signatures[0]?.designerSignature?.url} />
            ) : null}
            <Text style={styles.boldText}>
              {jobData?.signatures[0]?.designerSignature?.dateAndTime ? (
                <Text>
                  {moment(
                    jobData?.signatures[0]?.designerSignature?.dateAndTime
                  ).format("DD/MM/YYYY")}
                </Text>
              ) : null}
            </Text>
          </View>
          <View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Designer : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.designers[0]?.installer?.name}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Phone: </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.designers[0]?.installer?.mobileNo}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Address : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.designers[0]?.installer?.fullAddress?.unitType &&
                  `${jobData?.designers[0]?.installer?.fullAddress?.unitType} `}
                {jobData?.designers[0]?.installer?.fullAddress?.unitNumber &&
                  `${jobData?.designers[0]?.installer?.fullAddress?.unitNumber}, `}
                {jobData?.designers[0]?.installer?.fullAddress?.streetNumber &&
                  `${jobData?.designers[0]?.installer?.fullAddress?.streetNumber}, `}
                {jobData?.designers[0]?.installer?.fullAddress?.streetName &&
                  `${jobData?.designers[0]?.installer?.fullAddress?.streetName} `}
                {jobData?.designers[0]?.installer?.fullAddress?.streetType &&
                  `${jobData?.designers[0]?.installer?.fullAddress?.streetType}, `}
                {jobData?.designers[0]?.installer?.fullAddress?.suburb &&
                  `${jobData?.designers[0]?.installer?.fullAddress?.suburb}, `}
                {jobData?.designers[0]?.installer?.fullAddress?.state &&
                  `${jobData?.designers[0]?.installer?.fullAddress?.state}, `}
                {jobData?.designers[0]?.installer?.fullAddress?.postCode &&
                  `${jobData?.designers[0]?.installer?.fullAddress?.postCode}`}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Accreditation Number :</Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.designers[0]?.designerCECID}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Electrician Details - </Text>
          <View style={styles.logoDiv}>
            {jobData?.signatures[0]?.electricianSignature ? (
              <Image
                src={jobData?.signatures[0]?.electricianSignature.url}
                alt="electrician Signature"
              />
            ) : null}
            <Text style={styles.boldText}>
              {jobData?.signatures[0]?.electricianSignature?.dateAndTime ? (
                <Text>
                  {moment(
                    jobData?.signatures[0]?.electricianSignature?.dateAndTime
                  ).format("DD/MM/YYYY")}
                </Text>
              ) : null}
            </Text>
          </View>
          <View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Electrician : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.electricians[0]?.installer?.name}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Phone: </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.electricians[0]?.installer?.mobileNo}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Address : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.installer?.fullAddress?.unitType &&
                  `${jobData?.electricians[0]?.installer?.fullAddress?.unitType} `}
                {jobData?.electricians[0]?.installer?.fullAddress?.unitNumber &&
                  `${jobData?.electricians[0]?.installer?.fullAddress?.unitNumber}, `}
                {jobData?.electricians[0]?.installer?.fullAddress
                  ?.streetNumber &&
                  `${jobData?.electricians[0]?.installer?.fullAddress?.streetNumber}, `}
                {jobData?.electricians[0]?.installer?.fullAddress?.streetName &&
                  `${jobData?.electricians[0]?.installer?.fullAddress?.streetName} `}
                {jobData?.electricians[0]?.installer?.fullAddress?.streetType &&
                  `${jobData?.electricians[0]?.installer?.fullAddress?.streetType}, `}
                {jobData?.electricians[0]?.installer?.fullAddress?.suburb &&
                  `${jobData?.electricians[0]?.installer?.fullAddress?.suburb}, `}
                {jobData?.electricians[0]?.installer?.fullAddress?.state &&
                  `${jobData?.electricians[0]?.installer?.fullAddress?.state}, `}
                {jobData?.electricians[0]?.installer?.fullAddress?.postCode &&
                  `${jobData?.electricians[0]?.installer?.fullAddress?.postCode}`}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>License Number : </Text>
              <Text style={styles.textStyleDynamic}>
                {jobData?.electricians[0]?.electricianLicenseNo}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>
            Retailer Declaration & Written Statement -
          </Text>
          {/* <View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Designer :</Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.installers[0]?.installer?.name}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Phone:</Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.installers[0]?.installer?.mobileNo}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Address :</Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.installers[0]?.installer?.fullAddress?.unitType &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.unitType} `}
                {jobData?.installers[0]?.installer?.fullAddress?.unitNumber &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.unitNumber}, `}
                {jobData?.installers[0]?.installer?.fullAddress?.streetNumber &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.streetNumber}, `}
                {jobData?.installers[0]?.installer?.fullAddress?.streetName &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.streetName} `}
                {jobData?.installers[0]?.installer?.fullAddress?.streetType &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.streetType}, `}
                {jobData?.installers[0]?.installer?.fullAddress?.suburb &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.suburb}, `}
                {jobData?.installers[0]?.installer?.fullAddress?.state &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.state}, `}
                {jobData?.installers[0]?.installer?.fullAddress?.postCode &&
                  `${jobData?.installers[0]?.installer?.fullAddress?.postCode}`}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Accreditation Number :</Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.installers[0]?.installerCECID}
              </Text>
            </View>
          </View> */}
          <Text style={styles.TextStylePara}>
            I{" "}
            <Text style={styles.blackText}>
              {jobData?.user?.basicInformation?.firstName}{" "}
              {jobData?.user?.basicInformation?.lastName}
            </Text>
            , am the authorized representative of{" "}
            <Text style={styles.blackText}>
              {jobData?.user?.companyInformation?.tradingName}
            </Text>{" "}
            (ABN:{" "}
            <Text style={styles.blackText}>
              {jobData?.user?.companyInformation?.ABN})
            </Text>{" "}
            that sold the solar PV system to{" "}
            <Text style={styles.blackText}>
              {jobData?.jobDetails?.ownerDetails?.firstName}{" "}
              {jobData?.jobDetails?.ownerDetails?.lastName}
            </Text>{" "}
            and verify that:
          </Text>
          <View style={styles.ListType}>
            <Text style={styles.TextStylePara}>
              <Text style={styles.boldText}>
                {"   "} - {jobData?.installers[0]?.installer?.name || "N/A"}
              </Text>
              , installed the unit at{" "}
              <Text style={styles.bold}>
                {jobData?.jobDetails?.installationAddress?.unitType &&
                  `${jobData?.jobDetails?.installationAddress?.unitType} `}
                {jobData?.jobDetails?.installationAddress?.unitNumber &&
                  `${jobData?.jobDetails?.installationAddress?.unitNumber}, `}
                {jobData?.jobDetails?.installationAddress?.streetNumber &&
                  `${jobData?.jobDetails?.installationAddress?.streetNumber} `}
                {jobData?.jobDetails?.installationAddress?.streetName &&
                  `${jobData?.jobDetails?.installationAddress?.streetName} `}
                {jobData?.jobDetails?.installationAddress?.streetType &&
                  `${jobData?.jobDetails?.installationAddress?.streetType}, `}
                {jobData?.jobDetails?.installationAddress?.suburb &&
                  `${jobData?.jobDetails?.installationAddress?.suburb}, `}
                {jobData?.jobDetails?.installationAddress?.state &&
                  `${jobData?.jobDetails?.installationAddress?.state}, `}
                {jobData?.jobDetails?.installationAddress?.postCode &&
                  `${jobData?.jobDetails?.installationAddress?.postCode}`}{" "}
              </Text>{" "}
              and they are
            </Text>
            {jobData?.retailerDeclaration &&
            jobData?.retailerDeclaration?.radio1 ? (
              <View style={styles.rowData}>
                {/* <Checkbox label="an employee or" checked={jobData?.retailerDeclaration?.radio1} /> */}
                <Text style={styles.TextStylePara}> {"    "} an employee </Text>
              </View>
            ) : null}
            {jobData?.retailerDeclaration &&
            !jobData?.retailerDeclaration?.radio1 ? (
              <View style={styles.rowData}>
                {/* <Checkbox label="SubContractor" checked={!jobData?.retailerDeclaration?.radio1} /> */}
                <Text style={styles.TextStylePara}>
                  {" "}
                  {"    "} SubContractor{" "}
                </Text>
              </View>
            ) : null}
            <Text style={styles.TextStylePara}>
              {"   "} {"  "} of{" "}
              <Text style={styles.boldText}>
                {jobData?.user?.companyInformation?.tradingName}
              </Text>
            </Text>
            <Text style={[styles.TextStylePara, styles.TextStyleParaList]}>
              - that the unit will perform in accordance with the
            </Text>
            {jobData?.retailerDeclaration &&
            jobData?.retailerDeclaration?.radio2 ? (
              <View style={styles.rowData}>
                <Text style={styles.TextStylePara}>{"  "} Contractor</Text>
                {"  "}
              </View>
            ) : (
              <View style={styles.rowData}>
                <Text style={[styles.TextStylePara, styles.TextStyleParaList]}>
                  {"   "} Quote accepted {"    "}{" "}
                </Text>
              </View>
            )}
            <Text style={[styles.TextStylePara, styles.TextStyleParaList]}>
              - for the sale of the unit to the owner of the unit, except to the
              extent that the performance is prevented by circumstances outside
              the solar retailer’s control.
            </Text>
            <Text style={[styles.TextStylePara, styles.TextStyleParaList]}>
              - that the unit is complete and generating electricity or capable
              of generating electricity
              {"    "}{" "}
            </Text>
            <Text style={[styles.TextStylePara, styles.TextStyleParaList]}>
              - that if the unit is grid connected, the unit is connected to the
              grid or{" "}
              <Text style={styles.boldText}>
                {jobData?.user?.companyInformation?.tradingName}{" "}
              </Text>
              has completed its obligations under the contract (or quote
              accepted) relating to the connection of the unit to the grid{" "}
              {"  "}{" "}
            </Text>
            {"    "}{" "}
            <Text style={[styles.TextStylePara, styles.TextStyleParaList]}>
              <Text style={styles.boldText}>
                - {jobData?.user?.companyInformation?.tradingName}
              </Text>{" "}
              provided information in writing to{" "}
              <Text style={styles.boldText}>{`${
                " " +
                jobData?.jobDetails?.ownerDetails?.firstName +
                " " +
                jobData?.jobDetails?.ownerDetails?.lastName
              }  `}</Text>{" "}
              about feed in tariffs and export limits for the unit and it is
              true, correct and complete. {"    "}{" "}
            </Text>
            <Text style={[styles.TextStylePara, styles.TextStyleParaList]}>
              - any actual or potential conflicts of interest of{" "}
              <Text style={styles.boldText}>
                {jobData?.user?.companyInformation?.tradingName}
              </Text>{" "}
              provided relating to the sale or installation of the unit, or the
              creation of certificates for the unit, including any conflicts of
              interest in relation to persons or entities related to{" "}
              <Text style={styles.boldText}>
                {jobData?.user?.companyInformation?.tradingName}
              </Text>{" "}
              , have been disclosed to{" "}
              <Text style={styles.boldText}>{`${
                " " +
                jobData?.jobDetails?.ownerDetails?.firstName +
                " " +
                jobData?.jobDetails?.ownerDetails?.lastName
              }  `}</Text>
              and managed appropriately.
            </Text>
          </View>
          {/* <Text style={styles.TextStylePara}>
            
            
          </Text> */}
          <Text style={[styles.TextStylePara, styles.TextStyleParaList]}>
            <Text style={styles.boldText}>
              - A declaration deeming{" "}
              {jobData?.user?.companyInformation?.tradingName}{" "}
            </Text>{" "}
            ineligible to make statement under regulation 20AH is not in effect
            on the day the statement is given.{" "}
          </Text>
          <Text style={styles.TextStylePara}>
            I declare that the above statements are true, correct and complete
            and understand that penalties apply under the Renewable Energy
            (Electricity) Act 2000 and Renewable Energy (Electricity)
            Regulations 2001 for providing false or misleading information. I
            understand that giving false or misleading information is a serious
            offence under the Criminal Code Act 1995.
          </Text>
          <Text style={styles.TextStylePara}>
            I{" "}
            <Text style={styles.TextStylePara}>
              {jobData?.user?.companyInformation?.tradingName} am aware that
              penalties can be applied for providing misleading information in
              this form under the Renewable Energy (Electricity) Act 2000. I
              understand that if this assignment is unable to be created, unable
              to be approved or becomes invalid at any point in the future, I
              will reimburse{" "}
              {jobData?.traderDetails?.companyInformation?.tradingName} for the
              full value of monies paid to me.{" "}
            </Text>
          </Text>
        </View>
        <View style={styles.logoDiv}>
          {jobData?.signatures[0]?.retailerSignature?.url ? (
            <Image
              src={jobData?.signatures[0]?.retailerSignature?.url}
              alt="Retailer Signature"
            />
          ) : null}

          {jobData?.signatures[0]?.retailerSignature?.dateAndTime ? (
            <Text style={styles.TextStylePara}>
              {moment(
                jobData?.signatures[0]?.retailerSignature?.dateAndTime
              ).format("DD/MM/YYYY")}
            </Text>
          ) : null}

          <Text style={styles.TextStylePara}>
            <Text style={styles.boldText}>
              {jobData?.user?.basicInformation?.firstName}{" "}
              {jobData?.user?.basicInformation?.lastName}{" "}
            </Text>
          </Text>
          <Text style={styles.TextStylePara}>(Director )</Text>
        </View>
        <View style={styles.hr} />
        <View style={styles.extraSpace} />
        <View>
          <Text style={styles.TitleText}>Panel Details</Text>
          {/* <View style={styles.subSectionRow}>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>TOTAL STCs</Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                - {jobData?.jobDetails?.panelDetail?.stc || "N/A"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>TOTAL NUMBER OF PANELS</Text>
              <Text style={styles.textStyleDynamic}> - {totalQuantity}</Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>RATED POWER OUTPUT (kW)</Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                - {jobData?.jobDetails?.panelDetail?.wattage || "N/A"}
              </Text>
            </View>
          </View> */}
          <View>
            <View style={[styles.productsRow, styles.headerRow]}>
              <Text style={[styles.cell, styles.cellFirst]}>No</Text>
              <Text style={[styles.cell, styles.panelManuCell]}>
                MANUFACTURER
              </Text>
              <Text style={[styles.cell, styles.panelManuCell]}>MODEL</Text>
              <Text style={[styles.cell, styles.headerCell]}>
                SERIAL NUMBER
              </Text>
            </View>
            {jobData?.panels?.map((panel, index) => (
              <View style={styles.productsRow} key={panel._id}>
                <Text style={[styles.cell, styles.cellFirst]}>{index + 1}</Text>
                <Text style={[styles.cell, styles.panelManuCell]}>
                  {panel?.panelBrand || "N/A"}
                </Text>
                <Text style={[styles.cell, styles.panelManuCell]}>
                  {panel?.panelModel}
                </Text>
                <Text style={[styles.cell]}>{panel?.serialNumber}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Inverter Details</Text>
          <View>
            <View style={[styles.productsRow, styles.headerRow]}>
              <Text style={[styles.cell, styles.cellFirst]}>No</Text>
              <Text style={[styles.cell, styles.headerCell]}>MANUFACTURER</Text>
              <Text style={[styles.cell, styles.headerCell]}>MODEL</Text>
              <Text style={[styles.cell, styles.headerCell]}>SERIES</Text>
              <Text style={[styles.cell, styles.headerCell]}>
                SERIAL NUMBER
              </Text>
            </View>
            {jobData?.inverters?.map((inverter, index) => (
              <View style={styles.productsRow} key={inverter._id}>
                <Text style={[styles.cell, styles.cellFirst]}>{index + 1}</Text>
                <Text style={styles.cell}>{inverter?.inverterBrand}</Text>
                <Text style={styles.cell}>{inverter?.inverterModel}</Text>
                <Text style={styles.cell}>{inverter?.inverterSeries}</Text>
                <Text style={styles.cell}>{inverter?.serialNumber}</Text>
              </View>
            ))}
          </View>
        </View>
        {jobData?.jobDetails?.gridConnectionDetail?.connectionType ===
        "Connected to an electricity grid with battery storage" ? (
          <>
            <View style={styles.hr} />
            <View>
              <Text style={styles.TitleText}>Battery Details</Text>
              <View style={[styles.productsRow, styles.headerRow]}>
                <Text style={[styles.cell, styles.cellFirst]}>No</Text>
                <Text style={[styles.cell, styles.headerCell]}>
                  MANUFACTURER
                </Text>
                <Text style={[styles.cell, styles.headerCell]}>MODEL</Text>
                <Text style={[styles.cell, styles.headerCell]}>SERIES</Text>
                <Text style={[styles.cell, styles.headerCell]}>
                  SERIAL NUMBER
                </Text>
              </View>
              {jobData?.batteries?.map((battery, index) => (
                <View style={styles.productsRow} key={battery._id}>
                  <Text style={[styles.cell, styles.cellFirst]}>
                    {index + 1}
                  </Text>
                  <Text style={styles.cell}>{battery?.batteryBrand}</Text>
                  <Text style={styles.cell}>{battery?.batteryModel}</Text>
                  <Text style={styles.cell}>{battery?.batterySeries}</Text>
                  <Text style={styles.cell}>{battery?.serialNumber}</Text>
                </View>
              ))}
            </View>
          </>
        ) : null}
      </Page>
    </Document>
  );
};

const STCFormDataSWH = ({ jobData }) => {
  function numberToWord(number) {
    const words = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
    ];
    if (number < 0 || number > 10) {
      return "Invalid";
    }
    return words[number];
  }
  const totalQuantity = jobData?.jobDetails?.panelDetail?.panelInfo?.reduce(
    (total, panel) => {
      return total + (panel.Quantity || 0);
    },
    0
  );

  return (
    <Document id="mainContainer" style={styles.doucmentStyle}>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.HeaderRowData}>
          <View>
            {/* <Image src={logo} style={styles.logoImage} /> */}
            <Text style={styles.CompanyName}>
              {jobData?.user?.companyInformation?.tradingName}
            </Text>
          </View>

          <Text style={styles.HeaderText}>STC Assignment Form</Text>
        </View>
        <View style={styles.hr} />
        <View style={[styles.SectionsInRow, styles.FirstRow]}>
          <View style={styles.TwoCellTable}>
            <Text tyle={[styles.textStyleDynamic, styles.blackText]}>
              {`${jobData?.jobDetails?.jobIdentifyId || "N/A"} `}{" "}
            </Text>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Installation Date </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {jobData?.jobDetails?.installationDate?.installingDate
                  ? moment(
                      jobData?.jobDetails?.installationDate?.installingDate
                    ).format("DD/MM/YYYY")
                  : `N/A`}
              </Text>
            </View>
            <View style={[styles.rowData]}>
              <Text style={styles.textStyle}>STC Deeming Period </Text>
              {jobData?.jobDetails?.installationDate?.STCDeemingPeriod !==
              undefined ? (
                <Text style={[styles.textStyleDynamic, styles.blackText]}>
                  {" - "}
                  {`${numberToWord(
                    jobData?.jobDetails?.installationDate?.STCDeemingPeriod
                  )} Years`}
                </Text>
              ) : (
                " - "
              )}
            </View>
          </View>
          <View style={styles.TwoCellTable}>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Total STCs </Text>
              <Text style={[styles.textStyle, styles.blackText]}>
                - {jobData?.jobDetails?.hotWaterDetail?.stc}{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Capacity </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {
                  jobData?.jobDetails?.hotWaterDetail?.hotWaterInfo
                    ?.hotWaterCapacity
                }
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.hr} />
        <View style={styles.SectionsInRow}>
          <View style={styles.ownerDetailSection}>
            <Text style={styles.TitleText}>System Owner Details </Text>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>
                {`${
                  jobData?.installingFor == "Individual"
                    ? "Name"
                    : "Company Name"
                }`}{" "}
              </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {(jobData?.jobDetails?.ownerDetails?.firstName &&
                  [
                    jobData?.jobDetails?.ownerDetails?.firstName,
                    jobData?.jobDetails?.ownerDetails?.lastName,
                  ].join(" ")) ||
                  jobData?.jobDetails?.ownerDetails?.companyName ||
                  "N/A"}{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Address - </Text>
              <Text style={[styles.textStyleDynamic, styles.addressDynamic]}>
                {"  "}
                {jobData?.jobDetails?.installationAddress?.unitType &&
                  `${jobData?.jobDetails?.installationAddress?.unitType} `}
                {jobData?.jobDetails?.installationAddress?.unitNumber &&
                  `${jobData?.jobDetails?.installationAddress?.unitNumber}, `}
                {jobData?.jobDetails?.installationAddress?.streetNumber &&
                  `${jobData?.jobDetails?.installationAddress?.streetNumber} `}
                {jobData?.jobDetails?.installationAddress?.streetName &&
                  `${jobData?.jobDetails?.installationAddress?.streetName} `}
                {jobData?.jobDetails?.installationAddress?.streetType &&
                  `${jobData?.jobDetails?.installationAddress?.streetType}, `}
                {jobData?.jobDetails?.installationAddress?.suburb &&
                  `${jobData?.jobDetails?.installationAddress?.suburb}, `}
                {jobData?.jobDetails?.installationAddress?.state &&
                  `${jobData?.jobDetails?.installationAddress?.state}, `}
                {jobData?.jobDetails?.installationAddress?.postCode &&
                  `${jobData?.jobDetails?.installationAddress?.postCode}`}{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Email </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                - {jobData?.jobDetails?.ownerDetails?.emailId || "N/A"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Mo </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {jobData?.jobDetails?.ownerDetails?.mobileNum
                  ? jobData?.jobDetails?.ownerDetails?.mobileNum
                  : "N/A"}
              </Text>
            </View>
          </View>
          <View style={styles.ownerDetailSection}>
            <Text style={styles.TitleText}>Co-Owner Details - </Text>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Name </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {(jobData?.jobDetails?.coOwnerDetail?.firstName &&
                  [
                    jobData?.jobDetails?.coOwnerDetail?.firstName,
                    jobData?.jobDetails?.coOwnerDetail?.lastName,
                  ].join(" ")) ||
                  jobData?.jobDetails?.coOwnerDetail?.companyName ||
                  "N/A"}{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Address </Text>
              <Text style={[styles.textStyleDynamic, styles.addressDynamic]}>
                {" "}
                - {"  "}
                {jobData?.jobDetails?.postalAddress?.POAddress?.unitTypes &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.unitType} `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.unitNumber &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.unitNumber}, `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.streetNumber &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.streetNumber} `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.streetName &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.streetName} `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.streetType &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.streetType}, `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.suburb &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.suburb}, `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.state &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.state}, `}
                {jobData?.jobDetails?.postalAddress?.POAddress?.postCode &&
                  `${jobData?.jobDetails?.postalAddress?.POAddress?.postCode}`}{" "}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Email </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                - {jobData?.jobDetails?.coOwnerDetail?.emailId || "N/A"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Mo </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                -{" "}
                {jobData?.jobDetails?.coOwnerDetail?.mobileNum
                  ? jobData?.jobDetails?.coOwnerDetail?.mobileNum
                  : "N/A"}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Property Details - </Text>
          <View style={styles.tableSWH}>
            <View style={[styles.rowData, styles.TwoCellTable]}>
              <Text style={styles.textStyle}>Type </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                - {jobData?.jobDetails?.installationType || "N/A"}
              </Text>
            </View>
            {/* <View style={[styles.rowData,styles.secondCelltable]}>
              <Text style={[styles.textStyle,styles?.TextStyleParaList]}>Storey </Text>
              <Text style={[styles.textStyleDynamic, styles.blackText]}>
                - {jobData?.jobDetails?.siteAttributes?.storey || "N/A"}
              </Text>
            </View> */}
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Installation Details -</Text>
          <View style={styles.row}>
            <Text style={styles.cellSWH}>
              Is there more than one Solar Water Heater at this address? -{" "}
              {" No"}
            </Text>
            <View style={styles.cellSWH}>
              <Text>
                Installation date :{" "}
                {moment(
                  jobData?.jobDetails?.installationDate?.installingDate
                ).format("DD/MM/YYYY") || "N/A"}
              </Text>
              <Text>
                (Note: STCs must be claimed within 12 months of this date) This
                date should be the latest date shown on either trade completion
                certificate
              </Text>
            </View>
            <Text style={styles.cellSWH}>
              {" "}
              Is the volumetric capacity of this installation greater than 700L?{" "}
              {`${
                jobData?.jobDetails?.hotWaterDetail?.hotWaterInfo
                  ?.hotWaterCapacity > 700
                  ? "Yes"
                  : "No"
              } `}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cellSWH}>
              If yes to above, please describe where this System is installed in
              comparison to previous SWH systems at the address:
            </Text>
            <View style={styles.cellSWH}>
              <Text></Text>
              <Text></Text>
            </View>
            <Text style={styles.cellSWH}></Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cellSWH}>
              I have attached a statutory declaration signed by the person
              creating STCs for the above System - Yes
            </Text>
            <View style={styles.cellSWH}>
              <Text>
                I have attached a statutory declaration which confirms the above
                System is appropriately sized - Yes
              </Text>
            </View>
            <Text style={styles.cellSWH}>
              The changeover type :{" "}
              {jobData?.jobDetails?.installationType || "N/A"}
            </Text>
          </View>
        </View>
        <View style={styles.hr} />
        <View />
        <View>
          <Text style={styles.TitleText}>Customer Declaration</Text>
          <Text style={styles.DeclarationPara}>
            I am the owner of the{" "}
            <Text style={styles.boldText}>Solar Hot Water system</Text>{" "}
            mentioned in this STC Assignment form and I agree to assign my right
            to create STCs to{" "}
            <Text style={styles.boldText}>
              {jobData?.traderDetails?.companyInformation?.tradingName || "N/A"}
            </Text>{" "}
            for a period of{" "}
            <Text style={styles.boldText}>
              {jobData?.jobDetails?.installationDate?.STCDeemingPeriod
                ? `${numberToWord(
                    jobData?.jobDetails?.installationDate?.STCDeemingPeriod
                  )} Years`
                : "N/A"}
            </Text>{" "}
            commencing
            <Text style={styles.boldText}>
              {" "}
              {`${
                jobData?.jobDetails?.installationDate?.installingDate
                  ? moment(
                      jobData?.jobDetails?.installationDate?.installingDate
                    ).format("DD/MM/YYYY")
                  : `N/A`
              }`}
              . I have not previously assigned or created any STCs for this
              system within this period. I understand that this system is
              eligible for <Text style={styles.boldText}></Text>
              {jobData?.jobDetails?.hotWaterDetail?.stc || "N/A"}
            </Text>{" "}
            STCs and in exchange for assigning my right to create STCs I will
            receive a monetary discount from the retailer of the system. I am
            aware that penalties can be applied for providing misleading
            information in this form under the Renewable Energy (Electricity)
            Act 2000. I understand that if this assignment is unable to be
            created, unable to be approved or becomes invalid, I will reimburse
            ({jobData?.traderDetails?.companyInformation?.tradingName || "N/A"})
            for the full value of the monetary discount.
          </Text>
          <View>
            {jobData?.signatures[0]?.customerSignature ? (
              <Image
                src={jobData?.signatures[0]?.customerSignature?.url}
                alt="Customer Signature"
                style={styles.imageStyle}
              />
            ) : null}
            <Text style={styles.boldText}>
              {jobData?.signatures[0]?.customerSignature?.dateAndTime ? (
                <Text>
                  {moment(
                    jobData?.signatures[0]?.customerSignature?.dateAndTime
                  ).format("DD-MM-YYYY")}
                </Text>
              ) : null}
            </Text>
          </View>
          <View>
            <Text style={styles.textStyleDynamic}>
              {jobData?.jobDetails?.ownerDetails?.firstName || "N/A"}{" "}
              {jobData?.jobDetails?.ownerDetails?.lastName}
            </Text>
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Plumber - </Text>
          <View style={styles.logoDiv}>
            {jobData?.signatures[0]?.installerSignature ? (
              <Image
                src={jobData?.signatures[0]?.installerSignature?.url || "N/A"}
                alt="plumber Signature"
              />
            ) : null}
            <Text style={styles.boldText}>
              {jobData?.signatures[0]?.installerSignature?.dateAndTime ? (
                <Text>
                  {(jobData?.signatures[0]?.installerSignature?.dateAndTime &&
                    moment(
                      jobData?.signatures[0]?.installerSignature?.dateAndTime
                    ).format("DD-MM-YYYY")) ||
                    "N/A"}
                </Text>
              ) : null}
            </Text>
          </View>
          <View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Plumber : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.plumbers[0]?.installer?.name || "N/A"}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Phone : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.plumbers[0]?.installer?.mobileNo}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Address : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.plumbers[0]?.installer?.fullAddress?.unitType &&
                  `${jobData?.plumbers[0]?.installer?.fullAddress?.unitType} `}
                {jobData?.plumbers[0]?.installer?.fullAddress?.unitNumber &&
                  `${jobData?.plumbers[0]?.installer?.fullAddress?.unitNumber}, `}
                {jobData?.plumbers[0]?.installer?.fullAddress?.streetNumber &&
                  `${jobData?.plumbers[0]?.installer?.fullAddress?.streetNumber}, `}
                {jobData?.plumbers[0]?.installer?.fullAddress?.streetName &&
                  `${jobData?.plumbers[0]?.installer?.fullAddress?.streetName} `}
                {jobData?.plumbers[0]?.installer?.fullAddress?.streetType &&
                  `${jobData?.plumbers[0]?.installer?.fullAddress?.streetType}, `}
                {jobData?.plumbers[0]?.installer?.fullAddress?.suburb &&
                  `${jobData?.plumbers[0]?.installer?.fullAddress?.suburb}, `}
                {jobData?.plumbers[0]?.installer?.fullAddress?.state &&
                  `${jobData?.plumbers[0]?.installer?.fullAddress?.state}, `}
                {jobData?.plumbers[0]?.installer?.fullAddress?.postCode &&
                  `${jobData?.plumbers[0]?.installer?.fullAddress?.postCode}`}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Accreditation Number : </Text>
              <Text style={styles.textStyleDynamic}>
                {jobData?.plumbers[0]?.plumberCECID}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.hr} />
        <View>
          <Text style={styles.TitleText}>Gasfitter - </Text>
          <View style={styles.logoDiv}>
            {/* {jobData?.signatures[0]?.installerSignature ? (
              <Image
                src={jobData?.signatures[0]?.installerSignature.url}
                alt="gasfitter Signature"
              />
            ) : null} */}
            <Text style={styles.boldText}>
              {jobData?.signatures[0]?.installerSignature?.dateAndTime ? (
                <Text>
                  {moment(
                    jobData?.signatures[0]?.installerSignature?.dateAndTime
                  ).format("DD-MM-YYYY")}
                </Text>
              ) : null}
            </Text>
          </View>
          <View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Gasfitter : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.gasfitters[0]?.installer?.name}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Phone : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.gasfitters[0]?.installer?.mobileNo}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>Address : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.installer?.fullAddress?.unitType &&
                  `${jobData?.gasfitters[0]?.installer?.fullAddress?.unitType} `}
                {jobData?.gasfitters[0]?.installer?.fullAddress?.unitNumber &&
                  `${jobData?.gasfitters[0]?.installer?.fullAddress?.unitNumber}, `}
                {jobData?.gasfitters[0]?.installer?.fullAddress?.streetNumber &&
                  `${jobData?.gasfitters[0]?.installer?.fullAddress?.streetNumber}, `}
                {jobData?.gasfitters[0]?.installer?.fullAddress?.streetName &&
                  `${jobData?.gasfitters[0]?.installer?.fullAddress?.streetName} `}
                {jobData?.gasfitters[0]?.installer?.fullAddress?.streetType &&
                  `${jobData?.gasfitters[0]?.installer?.fullAddress?.streetType}, `}
                {jobData?.gasfitters[0]?.installer?.fullAddress?.suburb &&
                  `${jobData?.gasfitters[0]?.installer?.fullAddress?.suburb}, `}
                {jobData?.gasfitters[0]?.installer?.fullAddress?.state &&
                  `${jobData?.gasfitters[0]?.installer?.fullAddress?.state}, `}
                {jobData?.gasfitters[0]?.installer?.fullAddress?.postCode &&
                  `${jobData?.gasfitters[0]?.installer?.fullAddress?.postCode}`}
              </Text>
            </View>
            <View style={styles.rowData}>
              <Text style={styles.textStyle}>License Number : </Text>
              <Text style={styles.textStyleDynamic}>
                {" "}
                {jobData?.gasfitters[0]?.gasfitterCECID}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
const DownloadSTC = () => {
  const [jobData, setJobData] = useState();
  const [panelsData, setPanelsData] = useState();
  const [inverterData, setInverterData] = useState([]);
  const [batteryData, setBatteryData] = useState([]);
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");
  const fetchData = async () => {
    try {
      const resp = await axios.get(`${baseURL}/jobMaster/${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp.data;
      setJobData(data?.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      {jobData ? (
        jobData?.jobDetails?.jobType === "PV" ? (
          <STCFormData jobData={jobData} />
        ) : (
          <STCFormDataSWH jobData={jobData} />
        )
      ) : null}
    </PDFViewer>
  );
};

export default DownloadSTC;
