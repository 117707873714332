import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Aside from "./FixedBar/Aside";
import { useNavigate } from "react-router-dom";
import AddRetailer from "./Trader/Retailer/AddRetailer";
import EditRetailer from "./Trader/Retailer/EditRetailer";
import RetailerProfile from "./Trader/Retailer/RetailerProfile";
import AddModel from "./Trader/Teamdashboard/AddModel";
import InviteModel from "./Trader/Teamdashboard/InviteModel";
import EditInstallationTeam from "./Trader/Teamdashboard/EditInstallationTeam";
import ProfileInstallationTeam from "./Trader/Teamdashboard/ProfileInstallationTeam";
import HeaderComponent from "./Dashboard/CommonComponents/HeaderComponent";
import { Send } from "@mui/icons-material";
import { FadeLoader } from "react-spinners";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const RetailersList = () => {
  const userType = localStorage.getItem("userType");
  const token = localStorage.getItem("userToken");
  const retailerId = localStorage.getItem("userId");
  const id =
    userType === "Trader"
      ? localStorage.getItem("retailerId")
      : localStorage.getItem("installerId");

  const navigate = useNavigate();

  // Installer Data Mapping Starts
  const [dataShow, setDataShow] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(false);
  const test = async () => {
    setLoading(true);
    let apiUrl = "";
    if (userType === "Trader") {
      apiUrl = `${baseURL}/traders/getRetailers`;
    } else {
      apiUrl = `${baseURL}/installer/details`;
    }

    try {
      const resp = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = resp.data;
      if (userType === "Trader") {
        setDataShow(data?.retailers);
      } else {
        setDataShow(data?.installerData);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error:", err);
      setLoading(false);
    }
  };
  useEffect(() => {
    test();
  }, []);
  const reverseData = [...dataShow].reverse();
  // Installer Data Mapping Ends

  //Add and Invite Buttons Functionality Starts
  const [isInviteModelOpen, setInviteModelOpen] = useState(false);
  const [isAddModelOpen, setAddModelOpen] = useState(false);
  const [isEditTeamOpen, setEditTeamOpen] = useState(false);
  const [isViewTeamOpen, setViewTeamOpen] = useState(false);
  //Add and Invite Buttons Functionality Ends
  const [registeredInstaller, setRegisteredInstaller] = useState("");
  const [verify, setVerify] = useState(false);
  //Delete Installer Starts
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [error, setError] = useState(null);
  const [isError, setIsError] = useState(false);
  const handleDelete = async (e) => {
    e.preventDefault();

    let apiUrl = "";
    if (userType === "Trader") {
      apiUrl = `${baseURL}/traders/retailerDelete/${id}`;
    } else {
      apiUrl = `${baseURL}/users/installerDelete/${id}`;
    }

    try {
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        if (userType === "Trader") {
          alert("Retailer Deleted Successfully");
          localStorage.removeItem("retailerId");
          setTimeout(function () {
            window.location.reload();
          }, 8000);
        } else {
          alert("Team Member Deleted Successfully");
          localStorage.removeItem("installerId");
          setTimeout(function () {
            window.location.reload();
          }, 8000);
        }
        window.location.reload();
      } else if (response.status === 400) {
        alert("User Already Exists: Email or username is already taken");
      } else {
        console.log("Error: " + (response.data || response.statusText));
      }
    } catch (error) {
      console.error("Error in update request:", error);
      console.log("Error status:", error.response?.status);
    }
    setShowDeleteConfirmation(false);
  };

  const handleDisconnect = async (e) => {
    e.preventDefault();
    const data = {
      installerId: id,
    };

    let apiUrl = "";
    if (userType === "Trader") {
      apiUrl = `${baseURL}/traders/retailerDelete/${id}`;
    } else {
      apiUrl = `${baseURL}/installer/disconnectRetailer`;
    }

    try {
      const response = await axios.patch(apiUrl, data, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        if (userType === "Trader") {
          alert("Retailer Disconnected Successfully");
          localStorage.removeItem("retailerId");
          setTimeout(function () {
            window.location.reload();
          }, 8000);
        } else {
          alert("Team Member Disconnected Successfully");
          localStorage.removeItem("installerId");
          setTimeout(function () {
            window.location.reload();
          }, 8000);
        }
        window.location.reload();
      } else if (response.status === 400) {
        alert("User Already Exists: Email or username is already taken");
      } else {
        console.log("Error: " + (response.data || response.statusText));
      }
    } catch (error) {
      console.error("Error in update request:", error);
      console.log("Error status:", error.response?.status);
    }
    setShowDeleteConfirmation(false);
  };

  const [suspendId, setSuspendId] = useState(null);

  // Edit Installer Start from Here
  const editTeam = (id) => {
    {
      userType === "Trader"
        ? localStorage.setItem("retailerId", id)
        : localStorage.setItem("installerId", id);
    }
    setEditTeamOpen(true);
  };
  // Edit Installer End Here

  // View Installer Start from Here
  const viewTeam = (id) => {
    {
      userType === "Trader"
        ? localStorage.setItem("retailerId", id)
        : localStorage.setItem("installerId", id);
    }
    setViewTeamOpen(true);
    document.body.classList.add("page-modal-open");
  };
  // View Installer End Here

  // Suspend Installer Start from Here
  const getInstallerIdForSuspend = (id) => {
    setShowDeleteConfirmation(true);
    setSuspendId(id);
    {
      userType === "Trader"
        ? localStorage.setItem("retailerId", id)
        : localStorage.setItem("installerId", id);
    }
  };
  // Suspend Installer End Here

  //Delete Installer Ends

  //Upload and Download File Starts
  const fileUrl =
    "https://installer-profile-photos.s3.ap-southeast-2.amazonaws.com/Sample_RetailersData.csv";

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDownload = () => {
    window.open(fileUrl, "job-template.csv");
  };
  //Upload and Download File Ends

  // Create Job by Uploading File Starts
  const [file, setFile] = useState(null);

  const handleUploadButtonClick = () => {
    if (userType !== "Retailer") {
      document.getElementById("fileInput").click();
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile.type !== "text/csv") {
      alert("Please select a CSV file.");
      return;
    }

    try {
      const csvData = await readCSVFile(selectedFile);
      await createRetailersFromCSV(csvData);
      alert("Retailers Added Successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error adding retailers:", error);
      alert("Error Adding Retailers. Please try again.");
    }
  };

  const readCSVFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result;
        resolve(csvData);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  const createRetailersFromCSV = async (csvData) => {
    const rows = csvData.split("\n");
    const headers = rows
      .shift()
      .split(",")
      .map((header) => header.trim());

    for (const row of rows) {
      const rowData = row.split(",").map((data) => data.trim());
      if (rowData.length === 0 || rowData.every((cell) => cell === ""))
        continue;
      const formData = prepareFormData(rowData, headers);
      await createRetailer(formData);
    }
  };

  const prepareFormData = (rowData, headers) => {
    const formData = {
      password: "Retailer@123",
      userType: "Retailer",
    };

    headers.forEach((header, index) => {
      const keys = header.split("_");
      let ref = formData;

      keys.forEach((key, idx) => {
        if (idx === keys.length - 1) {
          ref[key] = rowData[index];
        } else {
          if (!ref[key]) ref[key] = {};
          ref = ref[key];
        }
      });
    });

    return formData;
  };

  const createRetailer = async (formData) => {
    try {
      await axios.post(`${baseURL}/traders/addRetailer`, formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error("Error adding retailer:", error);
      throw error;
    }
  };

  // Create Job by Uploading File Ends
  const handleOnCloseModal = () => {
    setViewTeamOpen(false);
    document.body.classList.remove("page-modal-open");
  };

  //Connect Installer Logic Starts
  const handleSearchInstallers = (event) => {
    setSearchQuery(event.target.value);
  };
  const fetchInstallers = async () => {
    try {
      const response = await axios.get(`${baseURL}/users/getCECID`, {
        params: {
          installerCECID: searchQuery,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status === 200) {
        const data = response?.data;
        setRegisteredInstaller(data);
        setVerify(true);
        setIsError(false);
        setError(null);
      }
    } catch (error) {
      console.error("Error fetching installers:", error);
      setIsError(true);
      setError(error.response?.data?.message || "Error fetching installers");
    }
  };

  useEffect(() => {
    if (searchQuery.length > 5) {
      fetchInstallers();
    } else {
      setError(null);
      setVerify(false);
      setIsError(false);
    }
  }, [searchQuery]);

  const handleConnect = async () => {
    const data = {
      installerId: registeredInstaller?._id,
    };
    try {
      const response = await axios.post(
        `${baseURL}/installer/connectRetailer`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response:", response.data);
      setVerify(false);
      window.alert(
        "Installer Connected Successfully. Mail has been sent to the installer"
      );
      test();
    } catch (error) {
      alert(error?.response?.data?.message);
      setVerify(false);
    }
  };

  //Connect Installer Logic Ends

  return (
    <div id="myModel" className="MainOuterContainer">
      <Aside />
      <div style={{width:"100%"}}> 
      <div className="main-part-ratailer">
        <div>
        <HeaderComponent
          PageTitle={userType == "Trader" ? "Retailer" : "Teams"}
          subTitle={`Dashboard / ${
            userType == "Trader" ? "Retailer" : "Teams"
          }`}
          />
          </div>
        <div className="row">
          <div className="TemsTableHeadingContainer">
            {userType === "Trader" ? (
              <div style={{ padding: "10px" }}>
                <b>Retailer</b>
              </div>
            ) : (
              <div className="searchBox_teams">
                <form action="">
                  <input
                    type="text"
                    placeholder="Search by CEC ID"
                    name="search"
                    className="input-search"
                    value={searchQuery}
                    onChange={handleSearchInstallers}
                  />
                </form>
              </div>
            )}

            <div className="team-btn">
              <button
                className="btn-add"
                onClick={() => {
                  setAddModelOpen(true);
                }}
              >
                Add
              </button>
              {userType != "Trader" ? (
                <button
                  className="btn-invite"
                  onClick={() => setInviteModelOpen(true)}
                >
                  Invite
                </button>
              ) : (
                <>
                  <button
                    className="btn-invite"
                    onClick={() => setShowModal(true)}
                  >
                    Add List
                  </button>
                  {showModal && (
                    <div className="add-model-backdrop">
                      <div
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "476px",
                          background: "white",
                          padding: "20px",
                          zIndex: "1000",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          height: "372px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h3>Upload Retailers File</h3>
                          <button
                            className="add-model-close-btn"
                            type="button"
                            onClick={handleCloseModal}
                          >
                            ✕
                          </button>
                        </div>
                        <br />
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            paddingTop: "20px",
                            alignItems: "center",
                            justifyContent: "space-around",
                            height: "80%",
                          }}
                        >
                          <p>
                            Please upload the CSV file containing the retailer
                            details.
                          </p>
                          <div>
                            <div
                              style={{
                                marginTop: "12px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <button
                                type="button"
                                onClick={handleDownload}
                                className="buttonForReports"
                                style={{ width: "190px" }}
                              >
                                Download Template File
                              </button>
                            </div>
                            <br />
                            <button
                              type="button"
                              className="buttonForReports"
                              style={{ width: "190px" }}
                              onClick={handleUploadButtonClick}
                            >
                              Upload Retailers File
                            </button>
                            <input
                              type="file"
                              id="fileInput"
                              accept=".csv"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleFileChange(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {(verify || isError) && userType !== "Trader" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "20px",
                gap: "10px",
                alignItems: "center",
                background: "aliceblue",
                padding: "20px",
              }}
            >
              {error ? (
                <span style={{ color: "rgb(21 67 176)", fontSize: "14px" }}>
                  {error}
                </span>
              ) : (
                <>
                  <p style={{ fontWeight: "bold" }}>
                    {registeredInstaller?.name}
                  </p>
                  <button
                    className="btn-add"
                    style={{ height: "27px", fontSize: "14px" }}
                    onClick={handleConnect}
                  >
                    {" "}
                    {"Connect"}
                  </button>
                </>
              )}
            </div>
          )}
          {/* <div className="team-section"> */}

          {/* </div> */}
            <Table className="team-details">
                <TableHead>
                  <TableRow>
                    <TableCell className="team-main-bg TeamsTableHeading" sx={{ backgroundColor: '#e0f3ff', textAlign: 'left', width: '20%', paddingLeft: '1.5%', color: 'rgba(44, 58, 100, 1)'}}> <b> Name </b></TableCell>
                    <TableCell className="team-data-role" sx={{ backgroundColor: '#e0f3ff', paddingLeft: '1.5%', textAlign: 'left', width: '20%', color: 'rgba(44, 58, 100, 1)' }}> <b> Role </b></TableCell>
                    <TableCell className="team-data-email" sx={{ backgroundColor: '#e0f3ff', paddingLeft: '1.5%', textAlign: 'left', width: '20%', color: 'rgba(44, 58, 100, 1)' }}> <b> Email ID </b></TableCell>
                    <TableCell className="team-data-mobile" sx={{ backgroundColor: '#e0f3ff', paddingLeft: '1.5%', textAlign: 'left', width: '20%', color: 'rgba(44, 58, 100, 1)' }}>
                      <b> {userType === "Retailer" ? "Status" : "Mobile"} </b>
                    </TableCell>
                    <TableCell className="team-data-actions" sx={{ backgroundColor: '#e0f3ff', paddingLeft: '1.5%', textAlign: 'left', width: '20%', color: 'rgba(44, 58, 100, 1)' }}> <b> Actions </b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center" sx={{textAlign:'center', alignItems:'center', display:'flex', justifyContent:'center', position: 'absolute', width: '100%'}}>
                        <FadeLoader color="#154397" />
                      </TableCell>
                    </TableRow>
                  ) : reverseData.length > 0 ? (
                    reverseData.map((data, groupIndex) => {
                      let isConnect = null;
                      if (userType === "Retailer") {
                        const retailer = data?.retailers.find(r => r._id === retailerId);
                        isConnect = retailer ? retailer?.isConnect : null;
                      }

                      return (
                        <TableRow key={groupIndex} className="TeamDetailsRowData">
                          <TableCell className="team-data-main" style={{ overflowWrap: "break-word" }}>
                            {userType === "Trader"
                              ? `${data?.basicInformation?.firstName} ${data?.basicInformation?.lastName}`
                              : data?.installer?.name}
                          </TableCell>
                          <TableCell className="team-data-role">
                            {userType === "Trader"
                              ? data?.basicInformation?.role
                              : `${data?.installer?.selectYourRole.join(' ')}`}
                          </TableCell>
                          <TableCell className="team-data-email">
                            {userType === "Trader"
                              ? data?.basicInformation?.emailId
                              : data?.installer?.emailId}
                          </TableCell>
                          <TableCell className="team-data-mobile">
                            {userType === "Trader" ? (
                              data?.companyInformation?.mobile
                            ) : (
                              <div
                                style={{
                                  fontWeight: "bold",
                                  color:
                                    isConnect === 0
                                      ? "black"
                                      : isConnect === 1
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {isConnect === 1
                                  ? "Connected"
                                  : isConnect === 2
                                  ? "Rejected"
                                  : "Pending"}
                              </div>
                            )}
                          </TableCell>
                          <TableCell className="team-data-actions">
                            <Tooltip title="Edit">
                              <button
                                className="add-action"
                                onClick={() => editTeam(data._id)}
                              >
                                <img
                                  src={`${image}/Assets/Teamdashboard/add-btn.svg`}
                                  alt=""
                                />
                              </button>
                            </Tooltip>
                            <Tooltip title="View">
                              <button
                                className="view-action"
                                onClick={() => viewTeam(data._id)}
                              >
                                <img
                                  src={`${image}/Assets/Teamdashboard/view.svg`}
                                  alt=""
                                />
                              </button>
                            </Tooltip>
                            <Tooltip title={userType === "Trader" ? "Delete" : "Disconnect"}>
                              <button
                                className="notview-action"
                                onClick={() => getInstallerIdForSuspend(data._id)}
                              >
                                <img
                                  src={`${image}/Assets/Teamdashboard/not-view.svg`}
                                  alt=""
                                />
                              </button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No {userType === "Trader" ? "Retailer" : "Installer"} Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
            </Table>
        </div>

        <div className="row">
          {isInviteModelOpen && (
            <InviteModel onClose={() => setInviteModelOpen(false)} />
          )}
          {isAddModelOpen &&
            (userType === "Trader" ? (
              <AddRetailer onClose={() => setAddModelOpen(false)} />
            ) : (
              <AddModel onClose={() => setAddModelOpen(false)} />
            ))}
          {isEditTeamOpen &&
            (userType === "Trader" ? (
              <EditRetailer onClose={() => setEditTeamOpen(false)} />
            ) : (
              <EditInstallationTeam onClose={() => setEditTeamOpen(false)} />
            ))}
          {isViewTeamOpen &&
            (userType === "Trader" ? (
              <RetailerProfile onClose={() => setViewTeamOpen(false)} />
            ) : (
              <ProfileInstallationTeam onClose={handleOnCloseModal} />
            ))}
          {showDeleteConfirmation && (
            <div className="invite-model-backdrop">
              <div className="delete-model-content" style={{ width: "502px" }}>
                <h2>
                  Are you sure you want to{" "}
                  {userType === "Trader" ? "delete" : "disconnect"}?
                </h2>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn-cancel"
                    onClick={
                      userType === "Trader" ? handleDelete : handleDisconnect
                    }
                  >
                    Yes
                  </button>
                  <button
                    className="button-invite"
                    onClick={() => {
                      localStorage.removeItem("retailerId");
                      localStorage.removeItem("installerId");
                      setShowDeleteConfirmation(false);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default RetailersList;
