import React, { useState, useEffect } from 'react';
import { messaging, requestNotificationPermission, onMessage, sendMessage } from '../../../../firebase';

const Chat = ({ userType }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    const getTokenAndSubscribe = async () => {
      const token = await requestNotificationPermission();
      setToken(token);
      const topic = userType === 'Retailer' ? 'Retailer' : 'Trader';
      // Assuming you have a method to subscribe to topic, which you might need on the backend
      console.log(`Subscribed to topic: ${topic}`);
    };

    getTokenAndSubscribe();

    const unsubscribe = onMessage(messaging, (payload) => {
      setMessages((prevMessages) => [...prevMessages, payload.notification.body]);
    });

    return () => unsubscribe();
  }, [userType]);

  const handleSendMessage = async () => {
    const payload = {
      notification: {
        title: `Message from ${userType}`,
        body: newMessage,
      },
      to: `/topics/${userType === 'Retailer' ? 'Trader' : 'Retailer'}`
    };

    await sendMessage(payload, token);
    setNewMessage('');
  };

  return (
    <div className="chat-container" style={{display:"none"}}>
      <div className="messages">
        {messages.map((msg, index) => (
          <p key={index}>{msg}</p>
        ))}
      </div>
      <input
        type="text"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type a message..."
      />
      <button onClick={handleSendMessage}>Send</button>
    </div>
  );
};

export default Chat;
