import React, { useState, useEffect } from "react";
import axios from "axios";
// import styles from "./ComplianceCertificate.module.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment/moment";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const ComplianceCertificate = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");
  const userType = localStorage.getItem("userType")
  const [formdata, setFormdata] = useState({
    testedAndCommissioned: "",
    installedCableFromPanelsToInverter: "",
    installedTwinAndEarthCableFromInverterToSwitchboard: "",
    installedInverterSupplyCircuitBreaker: "",
    contractorName: "",
    contractorLicenseNum: "",
    signature: "",
    testingAndCompliance: false,
    testingAndSafety: false,
    jobId: jobId,
  });

  const certificateRef = React.useRef(null);

  const [jobData, setJobData] = useState();
  useEffect(() => {
    const fetchJobData = async () => {
     
      let apiUrl = "";
      if (userType === "Trader") {
        apiUrl = `${baseURL}/traders/jobs`;
      } else {
        apiUrl = `${baseURL}/job/all`;
      }
      try {
        
        const resp = await axios.get(apiUrl, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        const flattenedData = resp.data.jobs.flat();
        const result = flattenedData.filter((item) => item._id === jobId);
        setJobData(result[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchJobData();
  }, []);

  useEffect(() => {
    const fetchComplianceData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/compliance/gets/${jobId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp.data.QLDCertificate;

        const updatedFormData = { ...formdata };
        for (const key in data) {
          if (key in formdata) {
            updatedFormData[key] = data[key];
          }
        }
        console.log(updatedFormData);
        setFormdata(updatedFormData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchComplianceData();
  }, []);

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const downloadPDF = () => {
    html2canvas(certificateRef.current, {
      scale: 2,
      useCORS: true,
      logging: true,
      onclone: (document) => {},
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
      pdf.save("Compliance Certificate.pdf");
    });
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormdata((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log(formdata);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormdata((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      // Append form data fields
      Object.keys(formdata).forEach((key) => {
        if (key !== "signature") {
          if (typeof formdata[key] === "string") {
            formData.append(key, formdata[key] === "" ? "" : formdata[key]);
          } else {
            formData.append(key, JSON.stringify(formdata[key]));
          }
        }
      });

      // Append file fields
      formData.append("signature", formdata.signature || "");

      const response = await axios.patch(
        `${baseURL}/compliance/update`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response:", response);

      if (response.status === 200 || response.status === 201) {
        alert("Compliance Form Updated Successfully");
        localStorage.removeItem("jobId");

        setTimeout(() => {
          window.close();
        }, 800);
      } else {
        console.error("Login failed. Unexpected status code:", response.status);
      }
    } catch (error) {
      console.log("Error Updating Job");
      alert(error?.response?.data?.message);
    }
  };

  // Function to open the browser's print dialog
  const openPrintDialog = () => {
    window.print();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            fontFamily: "Arial, sans-serif",
            maxWidth: "600px",
            margin: "20px auto",
            padding: "20px",
            border: "1px solid #ccc",
            boxShadow: "2px 2px 10px #eee",
          }}
          ref={certificateRef}
        >
          <h2 style={{ textAlign: "center" }}>Compliance Certificate</h2>
          <form>
            <br />
            <div style={{ marginBottom: "15px", fontSize: "14px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontSize: "14px",
                }}
              >
                Work performed for:
              </label>
              Client Name:{" "}
              <b>
                {jobData?.installingFor === "Individual" ? (
                  <>
                    {jobData?.ownerDetails?.firstName}{" "}
                    {jobData?.ownerDetails?.lastName}
                  </>
                ) : (
                  <>{jobData?.ownerDetails?.companyName}</>
                )}
              </b>
              <br />
              Address:{" "}
              <b>
                {jobData?.installationAddress?.unitType &&
                  `${jobData.installationAddress.unitType} `}
                {jobData?.installationAddress?.unitNumber &&
                  `${jobData.installationAddress.unitNumber}, `}
                {jobData?.installationAddress?.streetNumber &&
                  `${jobData.installationAddress.streetNumber} `}
                {jobData?.installationAddress?.streetName &&
                  `${jobData.installationAddress.streetName} `}
                {jobData?.installationAddress?.streetType &&
                  `${jobData.installationAddress.streetType}, `}
                {jobData?.installationAddress?.suburb &&
                  `${jobData.installationAddress.suburb}, `}
                {jobData?.installationAddress?.state &&
                  `${jobData.installationAddress.state}, `}
                {jobData?.installationAddress?.postCode &&
                  `${jobData.installationAddress.postCode}`}
              </b>
            </div>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Electrical installation / equipment tested:
              </label>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <p style={{ fontSize: "12px" }}>
                Installed, tested and commissioned{" "}
                <b>{formdata?.testedAndCommissioned || "_______"}</b> system.
                Installed{" "}
                <b>
                  {formdata?.installedCableFromPanelsToInverter || "_______"}
                </b>{" "}
                cable from panels to inverter. Installed{" "}
                <b>
                  {formdata?.installedTwinAndEarthCableFromInverterToSwitchboard ||
                    "_______"}
                </b>{" "}
                twin and earth cable from inverter to switchboard. Installed{" "}
                <b>
                  {formdata?.installedInverterSupplyCircuitBreaker || "_______"}
                </b>{" "}
                inverter supply circuit breaker.
              </p>
              {/* <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "15px",
                  color: "black",
                }}
              >
                <li style={{ display: "flex" }}>
                  <span style={{ color: "black" }}>
                    Wattage of Installed, Tested and Commissioned PV system?
                  </span>
                  <input
                    type="text"
                    name="testedAndCommissioned"
                    style={{
                      border: "1px solid black",
                    }}
                    value={formdata?.testedAndCommissioned || ""}
                    onChange={handleInput}
                  />
                </li>
                <li style={{ display: "flex", gap: "62px" }}>
                  <span style={{ color: "black" }}>
                    What cable was installed from panels to inverter?
                  </span>
                  <input
                    type="text"
                    name="installedCableFromPanelsToInverter"
                    style={{
                      border: "1px solid black",
                    }}
                    value={formdata?.installedCableFromPanelsToInverter || ""}
                    onChange={handleInput}
                  />
                </li>
                <li style={{ display: "flex", gap: "62px" }}>
                  <span style={{ color: "black" }}>
                    What was installed from inverter to switchboard?
                  </span>
                  <input
                    type="text"
                    name="installedTwinAndEarthCableFromInverterToSwitchboard"
                    style={{
                      border: "1px solid black",
                    }}
                    value={
                      formdata?.installedTwinAndEarthCableFromInverterToSwitchboard ||
                      ""
                    }
                    onChange={handleInput}
                  />
                </li>
                <li style={{ display: "flex", gap: "62px" }}>
                  <span style={{ color: "black" }}>
                    What was installed as the supply circuit breaker?
                  </span>
                  <input
                    type="text"
                    name="installedInverterSupplyCircuitBreaker"
                    style={{
                      border: "1px solid black",
                    }}
                    value={
                      formdata?.installedInverterSupplyCircuitBreaker || ""
                    }
                    onChange={handleInput}
                  />
                </li>
              </ul> */}
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                CERTIFICATE OF:
              </label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="checkbox"
                  id="installation"
                  name="testingAndCompliance"
                  checked={formdata?.testingAndCompliance === true}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />
                <label style={{ cursor: "pointer" }} htmlFor="installation">
                  Testing and Compliance (Electrical Installations)
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="checkbox"
                  id="equipment"
                  name="testingAndSafety"
                  checked={formdata?.testingAndSafety === true}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />
                <label style={{ cursor: "pointer" }} htmlFor="equipment">
                  Testing and Safety (Electrical Equipment)
                </label>
              </div>
            </div>
            <div style={{ marginBottom: "15px" }}>
              {console.log(jobData)}
              Date of test:{" "}
              <b>
                {jobData?.installationDate?.installingDate
                  ? moment(jobData?.installationDate?.installingDate).format(
                      "DD/MM/YYYY"
                    )
                  : "N/A"}
              </b>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Electrical contractor licence number
              </label>
              <input
                type="text"
                name="contractorLicenseNum"
                value={formdata?.contractorLicenseNum}
                onChange={handleInput}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  boxSizing: "border-box",
                  border: "1px solid black",
                }}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Name of Contractor
              </label>
              <input
                type="text"
                name="contractorName"
                value={formdata?.contractorName}
                onChange={handleInput}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  boxSizing: "border-box",
                  border: "1px solid black",
                }}
              />
            </div>
            <p style={{ fontSize: "12px" }}>
              For <b>electrical installations</b>, this certifies that the
              electrical installation, to the extent it is affected by the
              electrical work, has been tested to ensure that it is electrically
              safe and is in accordance with the requirements of the wiring
              rules and any other standard applying under the Electrical Safety
              Regulation 2013 to the electrical installation.
              <br />
              For <b>electrical equipment</b>, this certifies that the
              electrical equipment, to the extent it is affected by the
              electrical work, is electrically safe.
            </p>
            <br />
            <div style={{ marginBottom: "15px" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Name
              </label>
              <input
                type="text"
                name="licenceNumber"
                value={formdata?.contractorName}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  boxSizing: "border-box",
                  border: "1px solid black",
                }}
              />
            </div>
            <p style={{ fontSize: "12px" }}>
              (Person who performed, or person who is responsible for, the
              electrical work)
            </p>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label>
                Signature
                <div
                  style={{
                    border: "1px solid black",
                    width: "200px",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {formdata?.signature ? (
                    <img
                      src={formdata?.signature}
                      alt="Signature"
                      style={{ maxHeight: "100%", maxWidth: "100%" }}
                    />
                  ) : null}
                </div>
              </label>
              <label>
                Date
                <input
                  type="text"
                  value={moment().format("DD/MM/YYYY")}
                  readOnly
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginTop: "5px",
                    boxSizing: "border-box",
                    border: "1px solid black",
                  }}
                />
              </label>
            </div>
          </form>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          <button
            type="button"
            // onClick={downloadPDF}
            onClick={openPrintDialog}
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              padding: "10px 20px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer",
            }}
          >
            Download
          </button>
        </div>
      </form>
    </>
  );
};

export default ComplianceCertificate;
