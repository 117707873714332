import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const CommissioningDate = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  //Storing Data from API
  const [photos, setPhotos] = useState([]);
  //Get On Site Details Starts
  useEffect(() => {
    const fetchOnSiteData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/onSite`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp?.data?.data;
        const flattenedData = data.flat();
        const result = flattenedData.filter((item) => item.jobId === jobId);
        const photos = result[0]?.photos[0]?.installerPresence;
        setPhotos(photos);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchOnSiteData();
  }, []);
  //Get On Site Details Ends

  const [addPanelPopUp, setAddCommissioningDatePopup] = useState(false);

  return (
    <div className="row">
      <div className="checkin-panel">
        <h3>Commissioning Date</h3>
      </div>
      <div className="row">
        <button
          className="btn-photoupload"
          onClick={() => setAddCommissioningDatePopup(true)}
        >
          Add Photo
        </button>
      </div>

      <div className="row">
        <div className="panel-roof">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b className="text-date">
              The date of commission for your job must match the
              <br /> date on the CEC certificate as per CER requirements.
            </b>
            <div class="paneladd-btn">
              <div className="row">
                {photos &&
                photos.length > 0 &&
                photos[2]?.type === "endSelfie" ? (
                  <img
                    src={photos[2]?.url}
                    alt=""
                    style={{ width: "415px", height: "415px" }}
                    className="selfie-photo"
                  />
                ) : (
                  <img
                    src={`${image}/Assets/OnSiteData/CommissioningDate/duplicatephotoimg.svg`}
                    alt=""
                    className="selfie-photo"
                  />
                )}
              </div>
              <div className="row">
                <span className="selfie-text">
                  Third / last / commissioning selfie
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        <div className="row">
          {addPanelPopUp && (
            <div className="invite-model-backdrop">
              <div className="delete-model-content photoupload-popup-height">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 className="text-panel">Add Photo</h2>
                  <button
                    class="onsitedata-model-close-btn"
                    onClick={() => setAddCommissioningDatePopup(false)}
                  >
                    ✕
                  </button>
                </div>
                <span className="addphoto-bottom-border"></span>

                <div className="row">
                  <form method="post">
                    <div class="retailer-main doc-upload">
                      <input
                        class="photoupload-input"
                        type="file"
                        placeholder="Upload Photo"
                        accept="image/png, image/jpeg, application/pdf"
                        name="design"
                      />
                      <span className="file-text">
                        (JPG Max 50KB, PNG Max 100KB)
                      </span>
                      <img
                        src={`${image}/Assets/OnSiteData/CommissioningDate/uploadphoto.svg`}
                        alt=""
                        className="photoupload"
                      />
                      <input
                        className="addphoto-title"
                        type="text"
                        placeholder="Title"
                        required
                      />
                    </div>
                  </form>
                </div>
                <span className="addphoto-bottom-border"></span>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button className="onsitebtn-cancel">Cancel</button>
                  <button className="onsitebtn-save">Save</button>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default CommissioningDate;
