import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyApQWCdIEo7kiYV1lCcNTnH3bOzDWPwMLY",
  authDomain: "retailer-trader-chat.firebaseapp.com",
  databaseURL: "https://retailer-trader-chat-default-rtdb.firebaseio.com",
  projectId: "retailer-trader-chat",
  storageBucket: "retailer-trader-chat.appspot.com",
  messagingSenderId: "588367571750",
  appId: "1:588367571750:web:d60ed1729235fef1d32fe7",
  measurementId: "G-9XZ1HS13TN",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

navigator.serviceWorker
  .register('/firebase-messaging-sw.js')
  .then((registration) => {
    console.log('Service Worker registered with scope:', registration.scope);
    messaging.useServiceWorker(registration);
  })
  .catch((error) => {
    console.error('Service Worker registration failed:', error);
  });

const requestNotificationPermission = async () => {
  try {
    await Notification.requestPermission();
    console.log("Notification permission granted.");
    const token = await getToken(messaging, { vapidKey: 'YOUR_PUBLIC_VAPID_KEY' });
    console.log("Token:", token);
    return token;
  } catch (error) {
    console.error("Error requesting permission:", error);
  }
};

const sendMessage = async (payload, token) => {
  try {
    const response = await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, // Use Bearer token
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (response.ok) {
      console.log("Message sent:", data);
    } else {
      console.error("Error sending message:", data);
    }
  } catch (error) {
    console.error("Error sending message:", error);
  }
};

onMessage(messaging, (payload) => {
  console.log("Message received:", payload);
});

export { messaging, requestNotificationPermission, onMessage, sendMessage };
