import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Aside from "../../FixedBar/Aside";
import PanelSerial from "./PanelSerial";
import InverterSerial from "./InverterSerial";
import BatterySerial from "./BatterySerial";
import OnSitePhotoPage from "./OnsitePhotos";
import CommissioningDate from "./CommissioningDate";
import SignaturePage from "./SignaturePage";
import RequiredDocuments from "./RequiredDocuments";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const OnSiteData = () => {
  const navigate = useNavigate();

  const [selectedButton, setSelectedButton] = useState("panelSerials");

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const renderSelectedComponent = () => {
    switch (selectedButton) {
      case "panelSerials":
        return <PanelSerial />;
      case "inverter":
        return <InverterSerial />;
      case "battery":
        return <BatterySerial />;
      case "photos":
        return <OnSitePhotoPage />;
      case "commissioningDate":
        return <CommissioningDate />;
      case "signature":
        return <SignaturePage />;
      case "requiredDocuments":
        return <RequiredDocuments />;
      default:
        return null;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <Aside />
      </div>
      <div className="container">
        <div className="row">
          <div style={{ display: "flex" }}>
            <div className="onsite-jobpart">
              <div className="onsite-blue">
                View Onsite Data - {localStorage.getItem("jobIdentifyId")}
              </div>
              <span className="text-onsite">Dashboard / View Onsite Data</span>
            </div>
            <div className="onsite-part">
              <div className="onsitedata">
                <div className="onsitebell-icon">
                  <img
                    src={`${image}/Assets/OnSiteData/LeftPanel/onsitedate-bell.svg`}
                    alt=""
                  />
                </div>
              </div>
              <div className="onsitedata-text">
                <div className="onsite-round">10</div>
              </div>
              <div className="onsite-right">
                <div className="onsite-photo">
                  <img
                    src={`${image}/Assets/OnSiteData/LeftPanel/onsite-person.svg`}
                    alt=""
                    onClick={() => {
                      navigate("/profile-retailer");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className="onsite-panelbutton">
            <div className="date-button">
              <button
                className={`data-chekpanel ${
                  selectedButton === "panelSerials" ? "selected" : ""
                }`}
                onClick={() => handleButtonClick("panelSerials")}
              >
                Check in & Panel Serials
              </button>
              <button
                className={`data-chekpanel ${
                  selectedButton === "inverter" ? "selected" : ""
                }`}
                onClick={() => handleButtonClick("inverter")}
              >
                Inverter Serials
              </button>
              <button
                className={`data-chekpanel ${
                  selectedButton === "battery" ? "selected" : ""
                }`}
                onClick={() => handleButtonClick("battery")}
              >
                Battery
              </button>
              <button
                className={`data-chekpanel ${
                  selectedButton === "photos" ? "selected" : ""
                }`}
                onClick={() => handleButtonClick("photos")}
              >
                Photos
              </button>
              <button
                className={`data-chekpanel ${
                  selectedButton === "commissioningDate" ? "selected" : ""
                }`}
                onClick={() => handleButtonClick("commissioningDate")}
              >
                Commissioning Date
              </button>
              <button
                className={`data-chekpanel ${
                  selectedButton === "signature" ? "selected" : ""
                }`}
                onClick={() => handleButtonClick("signature")}
              >
                Signature
              </button>
              <button
                className={`data-chekpanel ${
                  selectedButton === "requiredDocuments" ? "selected" : ""
                }`}
                onClick={() => handleButtonClick("requiredDocuments")}
              >
                Required Documents
              </button>
            </div>
          </div>
          <div>{renderSelectedComponent()}</div>
        </div>
      </div>
    </div>
  );
};

export default OnSiteData;
