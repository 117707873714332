import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const ProfileView = () => {
  const token = localStorage.getItem("userToken");
  const userType = localStorage.getItem("userType");
  const navigate = useNavigate();

  const [newUser, setNewUser] = useState({
    basicInformation: {
      firstName: "",
      lastName: "",
      emailId: "",
      role: "",
    },
    companyInformation: {
      ABN: null,
      tradingName: "",
      legalEntityName: "",
      mobile: null,
      address: {
        unitNo: null,
        streetNo: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postcode: null,
      },
    },
    bankInformation: {
      bankName: "",
      accName: "",
      bsbNo: null,
      accNo: null,
    },
    userName: "",
    password: "",
    userType: "",
  });

  useEffect(() => {
    const test = async () => {
      try {
        let apiUrl = "";
        if (userType === "Trader") {
          apiUrl = `${baseURL}/traders/getDetails`;
        } else {
          apiUrl = `${baseURL}/users/getDetails`;
        }

        const resp = await axios.get(apiUrl, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = resp.data;
        if (data) {
          setNewUser(data);
        } else {
        }
      } catch (err) {
        console.log("Error:", err);
      }
    };
    test();
  }, []);

  return (
    <>
      {newUser.basicInformation.emailId ? (
        <div className="Signup-main-container">
          <div className="bg-overlay"></div>
          <div className="logo">
            <img src="Assets/images/uveaa-logo.svg" width={"200px"} />
          </div>
          <br />
          <form className="Signup-form-container">
            <div className="Signup-heading-box-container">
              <p
                className="main-profile-retailer"
                style={{ paddingLeft: "22%" }}
              >
                {newUser?.userType} Profile
              </p>
              <div className="ProfileContainerHeaderpart">
                <button
                  className="edit-retailer-profile"
                  onClick={() => {
                    navigate("/profile-edit");
                  }}
                >
                  Edit Profile
                </button>
                <div style={{ }}>
                   <button className="add-model-close-btn" onClick={() => {
                      navigate("/dashboard");
                    }}>
            ✕
          </button>
                </div>
              </div>
            </div>
            <div className="Signup-conatiner-twoBox">
              <div className="Signup-basicDetails-main-container">
                <p>Contact Details</p>
                <div className="inputDetailsSection">
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main">
                      <input
                        type="text"
                        placeholder="First Name"
                        maxLength={30}
                        value={newUser.basicInformation.firstName || ""}
                        name="firstName"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main">
                      <input
                        type="text"
                        placeholder="Surname"
                        maxLength={30}
                        value={newUser.basicInformation.lastName || ""}
                        name="lastName"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main">
                      <input
                        type="email"
                        placeholder="Email"
                        value={newUser.basicInformation.emailId || ""}
                        name="emailId"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main">
                      <input
                        type="text"
                        placeholder="Role"
                        value={newUser.basicInformation.role || ""}
                        name="role"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="Signup-basicDetails-main-container">
                <p>Company Details</p>
                <div className="inputDetailsSection">
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main input-information">
                      <input
                        type="number"
                        placeholder="Australian Business Number"
                        value={newUser.companyInformation.ABN || ""}
                        name="ABN"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main input-information">
                      <input
                        type="text"
                        placeholder="Company Name"
                        value={newUser.companyInformation.tradingName || ""}
                        name="tradingName"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main input-information">
                      <input
                        type="text"
                        placeholder="Legal Entity Name"
                        value={newUser.companyInformation.legalEntityName || ""}
                        name="legalEntityName"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main input-information">
                      <input
                        type="number"
                        placeholder="Phone No"
                        maxLength={60}
                        value={newUser.companyInformation.mobile || ""}
                        name="mobile"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="Signup-Adress-container">
                <div className="inputDetailsSection">
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main street-main-boxes">
                      <input
                        className="Signup-Address-String-boxes"
                        type="text"
                        maxLength={50}
                        value={
                          newUser.companyInformation.address.addressType || ""
                        }
                        name="addressType"
                        placeholder="Address Type"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main street-main-boxes">
                      <input
                        className="Signup-Address-String-boxes"
                        type="text"
                        maxLength={50}
                        value={
                          newUser.companyInformation.address.unitType || ""
                        }
                        name="unitType"
                        placeholder="Unit Type"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main">
                      <input
                        className="Signup-Address-Number-boxes"
                        placeholder="Unit No."
                        value={newUser.companyInformation.address.unitNo || ""}
                        name="unitNo"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main">
                      <input
                        className="Signup-Address-Number-boxes"
                        placeholder="Street No."
                        value={
                          newUser.companyInformation.address.streetNo || ""
                        }
                        name="streetNo"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main">
                      <input
                        className="Signup-Address-String-boxes"
                        type="text"
                        maxLength={50}
                        value={
                          newUser.companyInformation.address.streetName || ""
                        }
                        name="streetName"
                        placeholder="Street Name"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main">
                      <input
                        className="Signup-Address-String-boxes"
                        type="text"
                        maxLength={50}
                        value={
                          newUser.companyInformation.address.streetType || ""
                        }
                        name="streetType"
                        placeholder="Street Type"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main street-main-boxes">
                      <input
                        className="suburb-area"
                        type="text"
                        maxLength={10}
                        value={newUser.companyInformation.address.suburb || ""}
                        name="suburb"
                        placeholder="Suburb"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main street-main-boxes">
                      <input
                        className="suburb-area"
                        type="text"
                        maxLength={10}
                        value={newUser.companyInformation.address.state || ""}
                        name="state"
                        placeholder="State"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main street-main-boxes">
                      <input
                        className="postcode-area"
                        type="number"
                        placeholder="Postcode"
                        value={
                          newUser.companyInformation.address.postcode || ""
                        }
                        name="postcode"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="Signup-basicDetails-main-container">
                <p>Bank Details</p>
                <div className="inputDetailsSection">
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main input-information">
                      <input
                        type="text"
                        placeholder="Bank Name"
                        maxLength={50}
                        value={newUser.bankInformation.bankName || ""}
                        name="bankName"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main input-information">
                      <input
                        type="text"
                        placeholder="Account Name"
                        maxLength={50}
                        value={newUser.bankInformation.accName || ""}
                        name="accName"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main input-information">
                      <input
                        type="number"
                        placeholder="BSB No"
                        maxLength={6}
                        value={newUser.bankInformation.bsbNo || ""}
                        name="bsbNo"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="inputBoxandLabelDiv">
                    <div className="Signup-basicDetails-boxes input-box-main input-information">
                      <form action="#">
                        <input
                          type="number"
                          placeholder="Account No."
                          maxLength={10}
                          value={newUser.bankInformation.accNo || ""}
                          name="accNo"
                          readOnly
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </>
  );
};

export default ProfileView;
