import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const ForgetPswd = () => {
  const location = useLocation();
  const userType = location.state.userType;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    basicInformation: {
      emailId: "",
    },
    userType: userType,
  });
  const handleInputCustomerDetails = (e) => {
    const { name, value, type } = e.target;
    setFormData((prev) => ({
      ...prev,
      basicInformation: {
        ...prev.basicInformation,
        [name]: value,
      },
    }));
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseURL}/users/forgot-password`,
        formData
      );
      alert("Reset Password Link has been sent on your Email ID");
      navigate("/");
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  return (
    <div className="ForgetPassword-main-container">
      <div className="bg-overlay"></div>
      <div className="logo"></div>
      <form onSubmit={formSubmit}>
        <div className="ForgetPassword_main-conatainer-sec1 main-box">
          <div className="ForgetPassword-Logo-container"></div>
          <div className="ForgetPassword-heading-container">
            <p>Forget Password</p>
          </div>
          <div className="ForgetPassword-Email-box">
            <input
              type="email"
              placeholder="E-Mail"
              name="emailId"
              value={formData.basicInformation.emailId || ""}
              onChange={handleInputCustomerDetails}
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            />
            <div className="login-form-imag"></div>
          </div>
          <div className="email-text">
            <p>
              Enter your email ID for verification. We will send the link to set
              the new password.
            </p>
          </div>
          <div className="ForgetPassword-button-container">
            <div className="">
              <button type="submit" className="next action-button">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      <span className="login-text">
        Back to{" "}
        <Popup
          trigger={
            <Link to="/" className="sign-up">
              Login
            </Link>
          }
          nested
        ></Popup>
      </span>
    </div>
  );
};

export default ForgetPswd;
