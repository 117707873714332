import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Aside from "../../FixedBar/Aside";
import HeaderComponent from "../CommonComponents/HeaderComponent";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const LivePrice = () => {
  const retailerToken = localStorage.getItem("userToken");

  //Add Live Price POPUP Starts
  const [addLivePriceOpen, setAddLivePriceOpen] = useState(false);

  //Add formData Starts
  const [addFormData, setAddFormData] = useState({
    livePrice: {
      fromDate: "",
      toDate: "",
      fromDays: null,
      toDays: null,
      livePrice: null,
    },
  });
  //Add formData Ends

  //Function to Add Live Price starts
  const handleAddLivePriceInput = (e) => {
    const { name, value } = e.target;
    setAddFormData((p) => ({
      ...p,
      livePrice: {
        ...p.livePrice,
        [name]: value,
      },
    }));
  };
  //Function to Add Live Price Ends

  //API Call to submit FormData Starts
  const handlePostSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseURL}/livePrices/add`,
        addFormData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${retailerToken}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Live Price Added Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 800);
      } else {
        alert("Error Adding Live Price");
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
    }
  };
  //API Call to submit FormData Ends
  //Add Live Price POPUP Ends

  //Edit Live Price POPUP Starts
  const [editLivePriceOpen, setEditLivePriceOpen] = useState(false);

  // Edit Installer Start from here
  const editLivePrice = (livePriceId) => {
    localStorage.setItem("livePriceId", livePriceId);
    const result = reverseLivePrice.filter((item) => item._id === livePriceId);
    setEditFormData(result[0]);
    setEditLivePriceOpen(true);
  };
  // Edit Installer End here

  //Add formData Starts
  const [editFormData, setEditFormData] = useState({
    livePrice: {
      fromDate: "",
      toDate: "",
      fromDays: null,
      toDays: null,
      livePrice: null,
    },
  });
  //Add formData Ends

  //Function to Edit Live Price starts
  const handleEditLivePriceInput = (e) => {
    const { name, value } = e.target;
    setEditFormData((p) => ({
      ...p,
      livePrice: {
        ...p.livePrice,
        [name]: value,
      },
    }));
  };
  //Function to Edit Live Price Ends

  //API Call to submit FormData Starts
  const handlePatchSubmit = async (e) => {
    e.preventDefault();
    try {
      const priceId = localStorage.getItem("livePriceId");
      const response = await axios.patch(
        `${baseURL}/livePrices/edit/${priceId}`,
        editFormData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${retailerToken}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Live Price Updated Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 800);
        localStorage.removeItem("livePriceId");
      } else {
        alert("Error Updating Live Price");
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
    }
  };
  //API Call to submit FormData Ends
  //Edit Live Price POPUP Ends

  //View Live Price POPUP Starts
  //formData Starts
  const [livePriceShow, setLivePriceShow] = useState([]);
  //formData Ends

  // Live Price data mapping starts
  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/livePrices/details`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${retailerToken}`,
          },
        });
        const data = resp.data.data;
        const formatDateForInput = (dateString) => {
          const date = new Date(dateString);
          const day = date.getDate().toString().padStart(2, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };
        for (let i = 0; i < data.length; i++) {
          if (data[i].livePrice.fromDate) {
            data[i].livePrice.fromDate = formatDateForInput(
              data[i].livePrice.fromDate
            );
          }
          if (data[i].livePrice.toDate) {
            data[i].livePrice.toDate = formatDateForInput(
              data[i].livePrice.toDate
            );
          }
        }
        setLivePriceShow(data);
      } catch (err) {
        console.log("Error:", err);
      }
    };
    getData();
  }, []);
  const reverseLivePrice = [...livePriceShow].reverse();
  // Live Price Data Mapping Ends
  //View Live Price POPUP Ends

  return (
    <>
      {addLivePriceOpen && (
        <div
          className="invite-model-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        >
          <div className="invite-model-content" style={{ height: "420px" }}>
            <div className="invite-model-header">
              <h3>Add New Price</h3>
              <button
                className="invite-model-close-btn"
                onClick={() => {
                  setAddLivePriceOpen(false);
                }}
              >
                ✕
              </button>
            </div>
            <div className="row">
              <span className="popupbottom-border"></span>
            </div>
            <form
              className="invite-model-form"
              style={{ gap: "0px" }}
              onSubmit={handlePostSubmit}
            >
              <div style={{ display: "flex", gap: "152px" }}>
                <span>From Date</span>
                <span>To Date</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <input
                  type="date"
                  className="input-field popup-email"
                  value={addFormData.livePrice.fromDate || ""}
                  onChange={handleAddLivePriceInput}
                  name="fromDate"
                  style={{ width: "209px" }}
                  min={new Date().toISOString().split("T")[0]}
                />
                <input
                  type="date"
                  className="input-field popup-email"
                  value={addFormData.livePrice.toDate || ""}
                  onChange={handleAddLivePriceInput}
                  name="toDate"
                  style={{ width: "209px" }}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <input
                  type="number"
                  className="input-field popup-email"
                  value={addFormData.livePrice.fromDays || ""}
                  onChange={handleAddLivePriceInput}
                  name="fromDays"
                  placeholder="From Days"
                  style={{ width: "209px" }}
                />
                <input
                  type="number"
                  className="input-field popup-email"
                  value={addFormData.livePrice.toDays || ""}
                  onChange={handleAddLivePriceInput}
                  name="toDays"
                  placeholder="To Days"
                  style={{ width: "209px" }}
                />
              </div>
              <input
                type="number"
                className="input-field popup-email"
                style={{ width: "434px" }}
                value={addFormData.livePrice.livePrice || ""}
                onChange={handleAddLivePriceInput}
                placeholder="Live Price ($)"
                name="livePrice"
              />

              <div className="row">
                <span className="popupbottom-border"></span>
              </div>
              <div className="row">
                <div style={{ display: "flex" }}>
                  <div className="footer-popupbtn">
                    <button
                      type="button"
                      onClick={() => {
                        setAddLivePriceOpen(false);
                      }}
                      className="btn-cancel"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="button-invite">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {editLivePriceOpen && (
        <div
          className="invite-model-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        >
          <div className="invite-model-content" style={{ height: "auto" }}>
            <div className="invite-model-header">
              <h3>Edit Live Price</h3>
              <button
                className="invite-model-close-btn"
                onClick={() => {
                  setEditLivePriceOpen(false);
                  localStorage.removeItem("livePriceId");
                }}
              >
                ✕
              </button>
            </div>
            <div className="row">
              <span className="popupbottom-border"></span>
            </div>
            <form className="invite-model-form" onSubmit={handlePatchSubmit}>
              <div style={{ display: "flex", gap: "152px" }}>
                <span>From Date</span>
                <span>To Date</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <input
                  type="date"
                  className="input-field popup-email"
                  value={editFormData?.livePrice?.fromDate || ""}
                  onChange={handleEditLivePriceInput}
                  name="fromDate"
                  style={{ width: "209px" }}
                  min={new Date().toISOString().split("T")[0]}
                />
                <input
                  type="date"
                  className="input-field popup-email"
                  value={editFormData?.livePrice?.toDate || ""}
                  onChange={handleEditLivePriceInput}
                  name="toDate"
                  style={{ width: "209px" }}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <input
                  type="number"
                  className="input-field popup-email"
                  value={editFormData?.livePrice?.fromDays}
                  onChange={handleEditLivePriceInput}
                  name="fromDays"
                  placeholder="From Days"
                  style={{ width: "209px" }}
                />
                <input
                  type="number"
                  className="input-field popup-email"
                  value={editFormData?.livePrice?.toDays || ""}
                  onChange={handleEditLivePriceInput}
                  name="toDays"
                  placeholder="To Days"
                  style={{ width: "209px" }}
                />
              </div>
              <input
                type="number"
                className="input-field popup-email"
                value={editFormData?.livePrice?.livePrice || ""}
                onChange={handleEditLivePriceInput}
                placeholder="Live Price ($)"
                name="livePrice"
              />

              <div className="row">
                <span className="popupbottom-border"></span>
              </div>
              <div className="row">
                <div style={{ display: "flex" }}>
                  <div className="footer-popupbtn">
                    <button
                      type="button"
                      onClick={() => {
                        setEditLivePriceOpen(false);
                        localStorage.removeItem("livePriceId");
                      }}
                      className="btn-cancel"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="button-invite">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <div id="myModel" className="MainOuterContainer">
        {/* <div> */}
          <Aside />
        {/* </div> */}
        <div style={{width:"100%"}}>
        <div className="main-part-ratailer">
          <HeaderComponent
            PageTitle={"Manage Live Prices"}
            subTitle={`Dashboard / Manage Live Prices`}
          />
          <div className="row">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="team-bg">
                <b>List Live Prices</b>
              </div>
              <div className="team-btn">
                <button
                  className="btn-invite"
                  onClick={() => setAddLivePriceOpen(true)}
                >
                  Add
                </button>
              </div>
            </div>
            {/* <div className="team-section"> */}
            <div>
              <table
                style={{
                  flexDirection: "column",
                  gap: "10px",
                  textAlign: "center",
                }}
                className="team-details"
              >
                <th className="CERPage-main-bg TeamsTableHeading">
                  <td className="team-data-main">
                    <b>From Date</b>
                  </td>
                  <td className="team-data-role">
                    <b>To Date</b>
                  </td>
                  <td className="team-data-role">
                    <b>From Days</b>
                  </td>
                  <td className="team-data-role">
                    <b>To Days</b>
                  </td>
                  <td className="team-data-email">
                    <b>Live Price ($)</b>
                  </td>
                  <td className="team-data-actions">
                    <b>Actions</b>
                  </td>
                </th>
                {reverseLivePrice[0]?.livePrice?.livePrice ? (
                  reverseLivePrice.map((item, index) => (
                    <tr className="TeamDetailsRowData" key={index}>
                      <td className="team-data-main">
                        {item?.livePrice?.fromDate}
                      </td>
                      <td className="team-data-role">
                        {item?.livePrice?.toDate}
                      </td>
                      <td className="team-data-role">
                        {item?.livePrice?.fromDays}
                      </td>
                      <td className="team-data-role">
                        {item?.livePrice?.toDays}
                      </td>
                      <td className="team-data-email">
                        {item?.livePrice.livePrice}
                      </td>
                      <td className="team-actions">
                        <button
                          className="add-action"
                          onClick={() => editLivePrice(item?._id)}
                        >
                          <img
                            src={`${image}/Assets/Teamdashboard/add-btn.svg`}
                            alt=""
                          />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px 100px 0px 20px",
                    }}
                  >
                    <p>No Live Price Found</p>
                  </div>
                )}
              </table>
            </div>
          </div>
        </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default LivePrice;
