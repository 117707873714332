import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const RetailerEditProfile = () => {
  const navigate = useNavigate();

  const [streetOptions, setStreetOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [roleOptions, setRoleoptions] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseURL}/get/masterData?type=unitType,streetType,state,roles`)
      .then((response) => {
        const master = response.data;
        setRoleoptions(master.data[0].values);
        setStateOptions(master.data[1].values);
        setStreetOptions(master.data[2].values);
      })
      .catch((error) => {});
  }, []);

  //Get API starts
  const token = localStorage.getItem("userToken");

  useEffect(() => {
    const test = async () => {
      try {
        const resp = await axios.get(`${baseURL}/users/getDetails`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp.data;
        setNewUser(data);
      } catch (err) {
        console.log(err);
      }
    };
    test();
  }, []);
  //Get API ends

  const [newUser, setNewUser] = useState({
    basicInformation: {
      firstName: "",
      lastName: "",
      emailId: "",
      role: "",
    },
    companyInformation: {
      ABN: null,
      tradingName: "",
      mobile: null,
      address: {
        unitNo: null,
        streetNo: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postcode: null,
      },
    },
    bankInformation: {
      bankName: "",
      accName: "",
      bsbNo: null,
      accNo: null,
    },
  });

  const handleInputForBasic = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      basicInformation: {
        ...prev.basicInformation,
        [name]: value,
      },
    }));
  };

  const handleInputCompanyDetail = (e) => {
    const { name, value, type, checked } = e.target;
    setNewUser((p) => ({
      ...p,
      companyInformation: {
        ...p.companyInformation,
        [name]: value,
      },
    }));
  };

  const handleInputAddress = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      companyInformation: {
        ...prev.companyInformation,
        address: {
          ...prev.companyInformation.address,
          [name]: value,
        },
      },
    }));
  };

  const handleInputBankDetails = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      bankInformation: {
        ...prev.bankInformation,
        [name]: value,
      },
    }));
  };

  const SubmitFormUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`${baseURL}/users/update`, newUser, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        alert("Retailer Data Updated Successfully");
        navigate("/dashboard");
      } else if (response.status === 400) {
        alert("User Already Exists: Email or username is already taken");
      } else {
      }
    } catch (error) {
      alert("Error");
      console.log(error);
    }
  };

  return (
    <>
      {newUser.basicInformation.emailId ? (
        <>
          <div className="Signup-main-container">
            <div className="bg-overlay"></div>
            <div className="logo"></div>
            <div className="Signup-heading-box" style={{ height: "650px" }}>
              <p className="editmain-retailer">Retailer Edit Profile</p>
            </div>
            <br />
            <form onSubmit={SubmitFormUpdate} className="Signup-form-container">
              <div className="Signup-conatiner-twoBox">
                <div className="Signup-basicDetails-main-container">
                  <p>Contact Details</p>
                  <div className="Signup-basicDetails-boxes">
                    <input
                      type="text"
                      placeholder="First Name"
                      maxLength={30}
                      value={newUser.basicInformation.firstName || ""}
                      name="firstName"
                      onChange={handleInputForBasic}
                      onKeyDown={(e) => {
                        if (e.target.value.length > 30) {
                          window.alert(
                            "First Name must be of less than 30 characters."
                          );
                          e.target.value = " ";
                        }
                      }}
                      autoComplete="off"
                    />
                    <input
                      type="text"
                      placeholder="Surname"
                      maxLength={30}
                      value={newUser.basicInformation.lastName || ""}
                      name="lastName"
                      onChange={handleInputForBasic}
                      onKeyDown={(e) => {
                        if (e.target.value.length > 30) {
                          window.alert(
                            "Surname must be of less than 30 characters."
                          );
                          e.target.value = " ";
                        }
                      }}
                      autoComplete="off"
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      value={newUser.basicInformation.emailId || ""}
                      name="emailId"
                      onClick={() => {
                        window.alert("This field is not editable.");
                      }}
                      readOnly
                      autoComplete="off"
                    />
                    <form action="#" className="select-dropdown">
                      <select
                        className="drop-main"
                        value={newUser.basicInformation.role || ""}
                        name="role"
                        onChange={handleInputForBasic}
                      >
                        <option value="" defaultValue disabled>
                          Role
                        </option>
                        {roleOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </form>
                  </div>
                </div>

                <div className="Signup-basicDetails-main-container">
                  <p>Company Details</p>
                  <div className="Signup-basicDetails-boxes input-box-main">
                    <div className="company-details">
                      <input
                        type="number"
                        placeholder="Australian Business Number"
                        value={newUser.companyInformation.ABN || ""}
                        name="ABN"
                        onClick={() => {
                          window.alert("This field is not editable.");
                        }}
                        readOnly
                        autoComplete="off"
                      />

                      <input
                        type="text"
                        placeholder="Company Name"
                        value={newUser.companyInformation.tradingName || ""}
                        name="tradingName"
                        onClick={() => {
                          window.alert("This field is not editable.");
                        }}
                        readOnly
                        autoComplete="off"
                      />

                      <input
                        type="number"
                        placeholder="Phone No"
                        maxLength={60}
                        value={newUser.companyInformation.mobile || ""}
                        name="mobile"
                        onChange={handleInputCompanyDetail}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 10) {
                            window.alert(
                              "Phone Number must be of less than 10 characters."
                            );
                            e.target.value = " ";
                          }
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="Signup-Adress-container">
                  <div className="Signup-address-boxes-container">
                    <div className="Signup-Adress-boxes">
                      <input
                        className="Signup-Address-Number-boxes"
                        placeholder="Unit No."
                        value={newUser.companyInformation.address.unitNo || ""}
                        name="unitNo"
                        onChange={handleInputAddress}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 8) {
                            window.alert(
                              "Unit number must be less than or equal to 8 digits."
                            );
                            e.target.value = " ";
                          }
                        }}
                        autoComplete="off"
                      />
                    </div>

                    <div className="Signup-Adress-boxes">
                      <input
                        className="Signup-Address-Number-boxes"
                        placeholder="Street No."
                        value={
                          newUser.companyInformation.address.streetNo || ""
                        }
                        name="streetNo"
                        onChange={handleInputAddress}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 6) {
                            window.alert(
                              "Street number must be less than or equal to 6 digits."
                            );
                            e.target.value = " ";
                          }
                        }}
                        autoComplete="off"
                      />
                    </div>

                    <div className="street-boxes">
                      <input
                        className="Signup-Address-String-boxes"
                        type="text"
                        maxLength={50}
                        value={
                          newUser.companyInformation.address.streetName || ""
                        }
                        name="streetName"
                        placeholder="Street Name"
                        onChange={handleInputAddress}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 50) {
                            window.alert(
                              "Street Name must be less than 50 digits."
                            );
                            e.target.value = " ";
                          }
                        }}
                        autoComplete="off"
                      />
                    </div>
                    <div className="street-main-boxes">
                      <select
                        className="street-area"
                        value={
                          newUser.companyInformation.address.streetType || ""
                        }
                        name="streetType"
                        onChange={handleInputAddress}
                        style={{ width: "255px" }}
                      >
                        <option value="" defaultValue disabled>
                          Street Type
                        </option>
                        {streetOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="suburb-boxes">
                      <input
                        className="suburb-area"
                        type="text"
                        maxLength={10}
                        value={newUser.companyInformation.address.suburb || ""}
                        name="suburb"
                        placeholder="Suburb"
                        onChange={handleInputAddress}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 10) {
                            window.alert("Suburb must be less than 10 digits.");
                            e.target.value = " ";
                          }
                        }}
                        autoComplete="off"
                      />
                    </div>
                    <div className="state-boxes">
                      <select
                        className="state-main-area"
                        value={newUser.companyInformation.address.state || ""}
                        name="state"
                        onChange={handleInputAddress}
                        style={{ width: "255px" }}
                      >
                        <option value="" defaultValue disabled>
                          State
                        </option>
                        {stateOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="postcode-main">
                      <input
                        className="postcode-area"
                        type="number"
                        placeholder="Postcode"
                        value={
                          newUser.companyInformation.address.postcode || ""
                        }
                        name="postcode"
                        onChange={handleInputAddress}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 4) {
                            window.alert(
                              "Post Code must be equal to 4 digits."
                            );
                            e.target.value = " ";
                          }
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>

                <br />
                <div className="Signup-basicDetails-main-container">
                  <p>Bank Details</p>
                  <div className="Signup-basicDetails-boxes">
                    <input
                      type="text"
                      placeholder="Bank Name"
                      maxLength={50}
                      value={newUser.bankInformation.bankName || ""}
                      name="bankName"
                      onChange={handleInputBankDetails}
                      onKeyDown={(e) => {
                        if (e.target.value.length > 50) {
                          window.alert(
                            "Bank Name must be of less than 50 characters."
                          );
                          e.target.value = " ";
                        }
                      }}
                      autoComplete="off"
                    />
                    <input
                      type="text"
                      placeholder="Account Name"
                      maxLength={50}
                      value={newUser.bankInformation.accName || ""}
                      name="accName"
                      onChange={handleInputBankDetails}
                      onKeyDown={(e) => {
                        if (e.target.value.length > 50) {
                          window.alert(
                            "Account Name must be of less than 50 characters."
                          );
                          e.target.value = " ";
                        }
                      }}
                      autoComplete="off"
                    />
                    <input
                      type="number"
                      placeholder="BSB No"
                      maxLength={6}
                      value={newUser.bankInformation.bsbNo || ""}
                      name="bsbNo"
                      onChange={handleInputBankDetails}
                      onKeyDown={(e) => {
                        if (e.target.value.length > 6) {
                          window.alert(
                            "BSB Number must be of less than 6 characters."
                          );
                          e.target.value = " ";
                        }
                      }}
                      autoComplete="off"
                    />
                    <form action="#">
                      <input
                        type="number"
                        placeholder="Account No."
                        maxLength={10}
                        value={newUser.bankInformation.accNo || ""}
                        name="accNo"
                        onChange={handleInputBankDetails}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 10) {
                            window.alert(
                              "Account Number must be of less than 10 characters."
                            );
                            e.target.value = " ";
                          }
                        }}
                        autoComplete="off"
                      />
                    </form>
                  </div>
                </div>
              </div>
              <br />
              <div className="Signup-Button-main-container">
                <div className="editRetailer-buttons">
                  <button
                    type="button"
                    style={{ backgroundColor: "rgb(21 67 176)" }}
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    style={{ backgroundColor: "rgb(21 67 176)" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : null}
    </>
  );
};

export default RetailerEditProfile;
