import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const RequiredDocuments = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  //Get On Site Details Starts
  useEffect(() => {
    const fetchOnSiteData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/onSite`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp?.data?.data;
        const flattenedData = data.flat();
        const result = flattenedData.filter((item) => item.jobId === jobId);
        const documents = result[0];
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchOnSiteData();
  }, []);
  //Get On Site Details Ends

  const [addUploadPopUp, setAddUploadDocumentPopup] = useState(false);

  return (
    <div className="row">
      <div className="checkin-panel">
        <h3>Required Documents</h3>
        <span className="doc-text">Mandatory STC and CCP Documents</span>
      </div>
      <div className="row">
        <button
          className="btn-adddocument"
          onClick={() => {
            setAddUploadDocumentPopup(false);
            alert("This feature will be functional later.");
          }}
        >
          Add Document
        </button>
      </div>

      <div className="row">
        <div className="panel-roof">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <div className="panel-install"></div>

              <div style={{ display: "flex", gap: "18px" }}>
                <div>
                  <div className="blue-bg">
                    <img
                      src={`${image}/Assets/OnSiteData/Documents/pdf-file.svg`}
                      alt=""
                      className="pdf-fileimg"
                    />
                  </div>

                  <span className="set-form">
                    <b>STC Form </b> - waiting for installer to sign off
                  </span>
                </div>
                <div>
                  <div className="blue-bg">
                    <img
                      src={`${image}/Assets/OnSiteData/Documents/pdf-file.svg`}
                      alt=""
                      className="pdf-fileimg"
                    />
                  </div>

                  <span className="set-form">
                    Certificate of Compliance- Electrical Work
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        <div className="row">
          {addUploadPopUp && (
            <div className="invite-model-backdrop">
              <div className="delete-model-content photoupload-popup-height">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 className="text-panel">Add Document</h2>
                  <button
                    class="onsitedata-model-close-btn"
                    onClick={() => setAddUploadDocumentPopup(false)}
                  >
                    ✕
                  </button>
                </div>
                <span className="addphoto-bottom-border"></span>

                <div className="row">
                  <form method="post">
                    <div class="retailer-main doc-upload">
                      <input
                        class="photoupload-input"
                        type="file"
                        placeholder="Upload Photo"
                        accept="image/png, image/jpeg, application/pdf"
                        name="design"
                      />
                      <span className="file-text">
                        (JPG Max 50KB, PNG Max 100KB)
                      </span>
                      <img
                        src={`${image}/Assets/OnSiteData/CommissioningDate/uploadphoto.svg`}
                        alt=""
                        className="photoupload"
                      />
                      <input
                        className="addphoto-title"
                        type="text"
                        placeholder="Title"
                        required
                      />
                    </div>
                  </form>
                </div>
                <span className="addphoto-bottom-border"></span>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button className="onsitebtn-cancel">Cancel</button>
                  <button className="onsitebtn-save">Save</button>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default RequiredDocuments;
