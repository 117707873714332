import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const AcceptReject = () => {
  const { _id, retailerId } = useParams();
  const handleButtonClick = async (status) => {
    try {
      const requestData = {
        isConnect: status,
      };
      const response = await axios.patch(
        `${baseURL}/installer/accept-reject/${_id}/${retailerId}`,
        requestData
      );
      if (response.status === 200 || response.status === 201) {
        if (status === 1) {
          alert("You have accepted the invitation");
          window.close();
        } else {
          alert("You have rejected the invitation");
          window.close();
        }
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };
  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>Invitation to Join Uveaa Installer Network</h1>
        <p>Dear Installer,</p>
        <p>
          We are thrilled to extend this opportunity to you and welcome you to
          our esteemed team.
        </p>
        <p>Please choose an option below</p>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "10%", display: "flex", gap: "12px" }}>
            <button
              type="button"
              className="btn-createjob"
              onClick={() => {
                handleButtonClick(1);
              }}
            >
              Accept
            </button>
            <button
              type="button"
              className="btn-createjob"
              onClick={() => {
                handleButtonClick(2);
              }}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptReject;
