import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment/moment";
import "./PerformanceReduceForm.css";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const PerformanceReduceForm = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  const [data, setData] = useState();

  const fetchData = async () => {
    try {
      const resp = await axios.get(
        `${baseURL}/ReducedPerformanceForm/GetForWeb/${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = resp?.data;
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to open the browser's print dialog
  const openPrintDialog = () => {
    window.print();
  };

  return (
    <>
      <div className="main-container">
        <h2>Reduced Performance Form</h2>
        <br />
        <div className="customer-details">
          <h4>Customer Details:</h4>
          <span>
            Name:{" "}
            {data?.installingFor === "Individual"
              ? data?.customerName
              : data?.companyName}
          </span>
          <span>Address: {data?.customerAddress}</span>
          <span>Contact Number: {data?.customerContactNum}</span>
          <span>E-Mail: {data?.customerEmail}</span>
        </div>
        <br />
        <div>
          <h4>Solar System Performance Reduction Possibilities:</h4>
          <br />
          <p>
            Please Review the following factors that may affect the performance
            of your solar system:
          </p>
          <br />
          <p>Select Relevant Options only:</p>
          <br />
          <ul className="list">
            {data?.ReducedPerformanceForm?.shadingOpt && (
              <li>
                Shading
                <p className="list-description">
                  (Trees, buildings, or other structures may cast shadows on the
                  solar panel)
                </p>
              </li>
            )}

            {data?.ReducedPerformanceForm?.orientationTiltOpt && (
              <li>
                Orientation and Tilt
                <p className="list-description">
                  (The angle and direction of the panels may not be optimal)
                </p>
              </li>
            )}
            {data?.ReducedPerformanceForm?.weatherConditionsTiltOpt && (
              <li>
                Weather Conditions
                <p className="list-description">
                  (Cloud cover, rain, or snow can reduce system efficiency)
                </p>
              </li>
            )}

            {data?.ReducedPerformanceForm?.dirtDebrisTiltOpt && (
              <li>
                Dirt and Debris
                <p className="list-description">
                  (Accumulation on panels may decrease performance)
                </p>
              </li>
            )}

            {data?.ReducedPerformanceForm?.systemAgingOpt && (
              <li>
                System Aging
                <p className="list-description">
                  (Performance may decline over time due to natural wear and
                  tear)
                </p>
              </li>
            )}

            {data?.ReducedPerformanceForm?.equipmentMalfunctionOpt && (
              <li>
                Equipment Malfunction
                <p className="list-description">
                  (Inverter or other component failures may occur)
                </p>
              </li>
            )}

            {data?.ReducedPerformanceForm?.gridIssuesOpt && (
              <li>
                Grid Issues
                <p className="list-description">
                  (Power outages or grid instability can affect performance)
                </p>
              </li>
            )}

            {data?.ReducedPerformanceForm?.maintenanceReqOpt && (
              <li>
                Maintenance Requirements
                <p className="list-description">
                  (Regular cleaning and inspection are necessary for optimal
                  performance)
                </p>
              </li>
            )}
          </ul>
        </div>
        <br />
        <br />
        <div className="customer-details">
          <h4>Installer Notes:</h4>
          <span>
            <span style={{ color: "red" }}>*</span>Please provide any additional
            notes or recommendations for the customer.
          </span>
          <p>{data?.ReducedPerformanceForm?.installerNotes}</p>
        </div>
        <br />
        <br />
        <div className="customer-details">
          <h4>Installer Details:</h4>
          <span>Name: {data?.installerName}</span>
          <span>Contact Number: {data?.installerContactNum}</span>
          <span>E-Mail: {data?.installerEmail}</span>
          <img
            src={data?.ReducedPerformanceForm?.installerURL}
            alt="Installer Signature"
            style={{ width: "100px" }}
          />
          <span>
            Date:{" "}
            {data?.ReducedPerformanceForm?.installerSignDate
              ? moment(data.ReducedPerformanceForm.installerSignDate).format(
                  "DD-MM-YYYY"
                )
              : new Date().toLocaleDateString("en-GB")}
          </span>
        </div>
        <br />
        <div className="customer-details">
          <h4>Aggrement to Terms Prior to Installation:</h4>
          <br />
          <p>
            I,{" "}
            <b>
              {data?.installingFor === "Individual"
                ? data?.customerName
                : data?.companyName}
            </b>
            , acknowledge that I have been informed of the potential factors
            that may affect the performance of the solar system. I agree to the
            terms and understand the importance of regular maintenance and
            monitoring to ensure optimal system performance.
          </p>
          <br />
          <img
            src={data?.ReducedPerformanceForm?.customerURL}
            alt="Customer Signature"
            style={{ width: "100px" }}
          />
          <span>
            Date:{" "}
            {data?.ReducedPerformanceForm?.customerSignDate
              ? moment(data.ReducedPerformanceForm.customerSignDate).format(
                  "DD-MM-YYYY"
                )
              : new Date().toLocaleDateString("en-GB")}
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <button
          type="button"
          // onClick={downloadPDF}
          onClick={openPrintDialog}
          style={{
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            padding: "10px 20px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>
    </>
  );
};

export default PerformanceReduceForm;
