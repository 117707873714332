import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Aside from "../../FixedBar/Aside";
import HeaderComponent from "../CommonComponents/HeaderComponent";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const ApprovedCER = () => {
  const retailerToken = localStorage.getItem("userToken");

  //Add CER POPUP Starts
  const [addCEROpen, setAddCEROpen] = useState(false);
  //Add CER POPUP Ends

  //Add formData Starts
  const [addFormData, setAddFormData] = useState({
    CER: {
      fromDate: "",
      toDate: "",
      approvedCER: null,
    },
  });
  //Add formData Ends

  //Function to Add CER starts
  const handleAddCERInput = (e) => {
    const { name, value } = e.target;
    setAddFormData((p) => ({
      ...p,
      CER: {
        ...p.CER,
        [name]: value,
      },
    }));
  };
  //Function to Add CER Ends

  //API Call to submit FormData Starts
  const handlePostSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/cer/add`, addFormData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${retailerToken}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        alert("CER Added Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 800);
      } else {
        alert("Error Adding CER");
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
    }
  };
  //API Call to submit FormData Ends
  //Add CER POPUP Ends

  //Edit CER POPUP Starts
  const [editCEROpen, setEditCEROpen] = useState(false);

  // Edit Approced CER Start from here
  const editCER = (cerId) => {
    localStorage.setItem("cerId", cerId);
    const result = reverseCERDataShow.filter((item) => item._id === cerId);
    setEditFormData(result[0]);
    setEditCEROpen(true);
  };
  // Edit Approced CER End here

  //Edit formData Starts
  const [editFormData, setEditFormData] = useState({
    CER: {
      fromDate: "",
      toDate: "",
      approvedCER: null,
    },
  });
  //Edit formData Ends

  //Function to Edit CER starts
  const handleEditCERInput = (e) => {
    const { name, value } = e.target;
    setEditFormData((p) => ({
      ...p,
      CER: {
        ...p.CER,
        [name]: value,
      },
    }));
  };
  //Function to Edit CER Ends

  //API Call to submit FormData Starts
  const handlePatchSubmit = async (e) => {
    e.preventDefault();
    try {
      const cer = localStorage.getItem("cerId");
      const response = await axios.patch(
        `${baseURL}/cer/edit/${cer}`,
        editFormData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${retailerToken}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("CER Updated Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 800);
        localStorage.removeItem("cerId");
      } else {
        alert("Error Updating CER");
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
    }
  };
  //API Call to submit FormData Ends
  //Edit CER POPUP Ends

  //View CER POPUP Starts
  //formData Starts
  const [cerDataShow, setCERDataShow] = useState([]);

  // CER data mapping starts
  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/cer/details`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${retailerToken}`,
          },
        });
        const data = resp.data.cerApprovedData;
        const formatDateForInput = (dateString) => {
          const date = new Date(dateString);
          const day = date.getDate().toString().padStart(2, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };
        for (let i = 0; i < data.length; i++) {
          if (data[i].CER.fromDate) {
            data[i].CER.fromDate = formatDateForInput(data[i].CER.fromDate);
          }
          if (data[i].CER.toDate) {
            data[i].CER.toDate = formatDateForInput(data[i].CER.toDate);
          }
        }
        setCERDataShow(data);
      } catch (err) {
        console.log("Error:", err);
      }
    };
    getData();
  }, []);

  const reverseCERDataShow = [...cerDataShow].reverse();
  // CER Data Mapping Ends
  //View CER POPUP Ends

  return (
    <>
      {addCEROpen && (
        <div
          className="invite-model-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        >
          <div className="invite-model-content" style={{ height: "355px" }}>
            <div className="invite-model-header">
              <h3>Add CER</h3>
              <button
                className="invite-model-close-btn"
                onClick={() => {
                  setAddCEROpen(false);
                }}
              >
                ✕
              </button>
            </div>
            <div className="row">
              <span className="popupbottom-border"></span>
            </div>
            <form
              className="invite-model-form"
              style={{ gap: "0px" }}
              onSubmit={handlePostSubmit}
            >
              <div style={{ display: "flex", gap: "152px" }}>
                <span>From Date</span>
                <span>To Date</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <input
                  type="date"
                  className="input-field popup-email"
                  value={addFormData.CER.fromDate || ""}
                  onChange={handleAddCERInput}
                  name="fromDate"
                  style={{ width: "209px" }}
                  min={new Date().toISOString().split("T")[0]}
                />
                <input
                  type="date"
                  className="input-field popup-email"
                  value={addFormData.CER.toDate || ""}
                  onChange={handleAddCERInput}
                  name="toDate"
                  style={{ width: "209px" }}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
              <input
                type="number"
                className="input-field popup-email"
                style={{ width: "434px" }}
                value={addFormData.CER.approvedCER || ""}
                onChange={handleAddCERInput}
                placeholder="Approved CER"
                name="approvedCER"
              />

              <div className="row">
                <span className="popupbottom-border"></span>
              </div>
              <div className="row">
                <div style={{ display: "flex" }}>
                  <div className="footer-popupbtn">
                    <button
                      type="button"
                      onClick={() => {
                        setAddCEROpen(false);
                      }}
                      className="btn-cancel"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="button-invite">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {editCEROpen && (
        <div
          className="invite-model-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        >
          <div className="invite-model-content" style={{ height: "auto" }}>
            <div className="invite-model-header">
              <h3>Edit CER</h3>
              <button
                className="invite-model-close-btn"
                onClick={() => {
                  setEditCEROpen(false);
                  localStorage.removeItem("cerId");
                }}
              >
                ✕
              </button>
            </div>
            <div className="row">
              <span className="popupbottom-border"></span>
            </div>
            <form className="invite-model-form" onSubmit={handlePatchSubmit}>
              <div style={{ display: "flex", gap: "152px" }}>
                <span>From Date</span>
                <span>To Date</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <input
                  type="date"
                  className="input-field popup-email"
                  value={editFormData?.CER?.fromDate || ""}
                  onChange={handleEditCERInput}
                  name="fromDate"
                  style={{ width: "209px" }}
                  min={new Date().toISOString().split("T")[0]}
                />
                <input
                  type="date"
                  className="input-field popup-email"
                  value={editFormData?.CER?.toDate || ""}
                  onChange={handleEditCERInput}
                  name="toDate"
                  style={{ width: "209px" }}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
              <input
                type="number"
                className="input-field popup-email"
                value={editFormData?.CER?.approvedCER || ""}
                onChange={handleEditCERInput}
                placeholder="Approved CER"
                name="approvedCER"
              />

              <div className="row">
                <span className="popupbottom-border"></span>
              </div>
              <div className="row">
                <div style={{ display: "flex" }}>
                  <div className="footer-popupbtn">
                    <button
                      type="button"
                      onClick={() => {
                        setEditCEROpen(false);
                        localStorage.removeItem("cerId");
                      }}
                      className="btn-cancel"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="button-invite">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <div id="myModel" className="modal">
        <div>
            <Aside />
          <div style={{width:"100%"}}>
            <div  className="main-part-ratailer">
            <HeaderComponent
              PageTitle="Manage Approved CER"
              subTitle="Dashboard / Manage Approved CER"
              />
              <div style={{ display: "flex" }}>
                <div className="team-bg">
                  <b>List Approved CER</b>
                </div>
                <div className="team-btn">
                  <button
                    className="btn-invite"
                    onClick={() => setAddCEROpen(true)}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div>
                <table
                  style={{
                    flexDirection: "column",
                    gap: "10px",
                    textAlign: "center",
                  }}
                  className="team-details"
                >
                  <th className="CERPage-main-bg TeamsTableHeading">
                    <td className="team-data-main">
                      <b>From Date</b>
                    </td>
                    <td className="team-data-role">
                      <b>To Date</b>
                    </td>
                    <td className="team-data-email">
                      <b>Approved CER</b>
                    </td>
                    <td className="team-data-actions">
                      <b>Actions</b>
                    </td>
                  </th>

                  {reverseCERDataShow[0]?.CER?.approvedCER ? (
                    // <div className="team-details">
                    //   <table>
                    reverseCERDataShow.map((item, index) => (
                      <tr className="TeamDetailsRowData" key={index}>
                        <td className="team-data-main">
                          {item?.CER?.fromDate}
                        </td>
                        <td className="team-data-role">{item?.CER?.toDate}</td>
                        <td className="team-data-email">
                          {item?.CER?.approvedCER}
                        </td>
                        <td className="team-data-actions">
                          <button
                            className="add-action"
                            onClick={() => editCER(item?._id)}
                          >
                            <img
                              src={`${image}/Assets/Teamdashboard/add-btn.svg`}
                              alt=""
                            />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    /* </table>
                    </div> */
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px 100px 0px 20px",
                      }}
                    >
                      <p>No CER Found</p>
                    </div>
                  )}
                </table>
              </div>
             
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedCER;
