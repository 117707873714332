import React, { useState, useEffect } from "react";
import axios from "axios";
// import styles from "./ComplianceCertificate.module.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment/moment";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const DownloadvariationForm = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  const [formData, setFormData] = useState(null);

  const certificateRef = React.useRef(null);

  const fetchvariationFormData = async () => {
    try {
      const resp = await axios.get(`${baseURL}/VariationForm/GetForWeb/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          jobId: jobId,
        },
      });
      setFormData(resp?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchvariationFormData();
  }, []);

  // Function to open the browser's print dialog
  const openPrintDialog = () => {
    window.print();
  };

  return (
    <>
      <form style={{ width: "100%", padding: "10px" }}>
        <h6 style={{ textAlign: "center" }}>Variation Form</h6>
        <div
          style={{
            // fontFamily: "Arial, sans-serif",
            width: "800px",
            margin: "20px auto",
            padding: "10px 20px",
            border: "1px solid #ccc",
          }}
          ref={certificateRef}
        >
          <p style={{ fontSize: "32px" }}>Variation Form</p>
          <hr style={{ margin: "10px 0", color: "#bdbbbb" }} />
          <div style={{ padding: "10px 0" }}>
            <p style={{ fontSize: "22px" }}>Job Details</p>
            <hr style={{ margin: "4px 0", color: "#bdbbbb" }} />
          </div>
          <form>
            <div style={{ marginBottom: "15px" }}>
              {/* <label style={{ display: "block"}}> */}
              Customer Name:
              {/* </label> */}
              <b style={{ paddingLeft: "10px", fontSize: "15px" }}>
                {formData?.ownerName}
              </b>
              <hr style={{ margin: "4px 0", color: "#bdbbbb" }} />
              Address:
              <b style={{ paddingLeft: "10px", fontSize: "15px" }}>
                {formData?.address}
              </b>
              <hr style={{ margin: "4px 0", color: "#bdbbbb" }} />
              Installer Name:
              <b style={{ paddingLeft: "10px", fontSize: "15px" }}>
                {formData?.installerName}
              </b>
              {/* <hr style={{ margin: "4px 0", color: "#bdbbbb" }} />
              Date & Time:
              <b style={{ paddingLeft: "10px", fontSize: "15px" }}></b> */}
              <hr style={{ margin: "4px 0 0", color: "#bdbbbb" }} />
            </div>
            <br />
            <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
              <label style={{ display: "block", fontSize: "14px" }}>
                Will the production of the solar system be adversely affected
                due to any of the variations?
              </label>
              <label style={{ fontWeight: "bold" }}>
                {formData?.systemAdverselyAffected ? "True" : "False"}
              </label>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Tick applicable variations that you wish to make :
              </label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="checkbox"
                  id="installation"
                  name="testingAndCompliance"
                  checked={formData?.variationForm?.designLayoutChange === true}
                  // onChange={handleCheckboxChange}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />
                <label style={{ cursor: "pointer" }} htmlFor="installation">
                  Design Layout Change
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="checkbox"
                  id="equipment"
                  name="testingAndSafety"
                  checked={
                    formData?.variationForm?.panelsQuantityChange === true
                  }
                  // onChange={handleCheckboxChange}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />
                <label style={{ cursor: "pointer" }} htmlFor="equipment">
                  Panels Quantity Change
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="checkbox"
                  id="equipment"
                  name="testingAndSafety"
                  checked={formData?.variationForm?.priceChange === true}
                  // onChange={handleCheckboxChange}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />
                <label style={{ cursor: "pointer" }} htmlFor="equipment">
                  Price Change
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="checkbox"
                  id="equipment"
                  name="testingAndSafety"
                  checked={formData?.variationForm?.productChange === true}
                  // onChange={handleCheckboxChange}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />
                <label style={{ cursor: "pointer" }} htmlFor="equipment">
                  Product Change
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="checkbox"
                  id="equipment"
                  name="testingAndSafety"
                  // checked={formdata?.testingAndSafety === true}
                  // onChange={handleCheckboxChange}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />
                <label style={{ cursor: "pointer" }} htmlFor="equipment">
                  other(Please mention below)
                </label>
              </div>
            </div>
            <div>
              <textarea
                style={{
                  margin: "0 0 10 0",
                  border: "1px solid #bdbbbb",
                  width: "100%",
                }}
                value={formData?.variationForm?.otherNotes}
              />
            </div>
            <div>
              <b style={{ fontSize: "14px" }}>
                The Customer waives the 7 day period within which it has to
                decide to proceed with the contract or terminate the Agreement.
              </b>
              <br />
              <b style={{ fontSize: "14px" }}>
                In all other respects the terms of the Agreement continue to be
                of full force and effect.
              </b>
              <br />
              <b style={{ fontSize: "14px" }}>
                By signing this variation form, I waive the 7 days period within
                which I have to decide whether to proceed with the contract or
                cancel the contract.
              </b>
            </div>
            <br />
            <div>
              <h3> Declaration</h3>
              <br />
              <div>
                <p>
                  I {formData?.ownerName} as the property owner / an authorised
                  representative of the property have been explained and
                  understand the variations that will take place compared to the
                  original quote.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  height: "5vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    borderBottom: "1px solid #bdbbbb",
                    width: "70%",
                    alignItems: "center",
                  }}
                >
                  <p>SIGNED</p>
                  <img
                    src={formData?.variationForm?.customerURL}
                    alt="signature"
                    height={"50px"}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    borderBottom: "1px  solid #bdbbbb",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p>Date : </p>
                  <p>
                    {moment(formData?.variationForm?.date).format("DD-MM-YYYY")}
                  </p>
                </div>
              </div>
            </div>
            <p>
              In all other respects, the terms of the original agreement
              continue to be in full force and effect. I have been made aware of
              full information and the cost of making a decision about the
              installation of the solar system. By accepting the variation, I
              acknowledge that I do not want a full refund that I am entitled to
              and request to proceed with the order subject to the variation
              mentioned above.
            </p>
          </form>
        </div>
      </form>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <button
          type="button"
          // onClick={downloadPDF}
          onClick={openPrintDialog}
          style={{
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            padding: "10px 20px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>
    </>
  );
};

export default DownloadvariationForm;
