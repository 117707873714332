import React from "react";
import { useNavigate } from "react-router-dom";
import Aside from "../FixedBar/Aside";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const UploadFiles = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");
  const userType = localStorage.getItem("userType");

  const navigate = useNavigate();

  const [formdata, setformData] = useState({});

  const fileMapping = {
    "STC Form": "STCForm",
    "STC Invoice": "STCInvoice",
    Design: "design",
    "Custom Invoice": "customInvoice",
    "Electricity Bill": "uploadElectricityBill",
    "Testing Certificate": "testingCertificate",
    "Notice of Completion": "noticeOfCompletion",
  };

  useEffect(() => {
    const fetchJobData = async () => {
      
      let apiUrl = "";
      if (userType === "Trader") {
        apiUrl = `${baseURL}/traders/jobs`;
      } else {
        apiUrl = `${baseURL}/job/all`;
      }
      try {
        
        const resp = await axios.get(apiUrl, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        const data = resp?.data;
        const flattenedData = data.flat();
        const result = flattenedData.find((item) => item._id === jobId);
        if (result) {
          setformData({
            design: result.design,
            customInvoice: result.customInvoice,
            uploadElectricityBill: result.uploadElectricityBill,
            STCForm: result.STCForm,
            STCInvoice: result.STCInvoice,
            testingCertificate: result.testingCertificate,
            noticeOfCompletion: result.noticeOfCompletion,
          });
        }
      } catch (error) {
        console.error("Error fetching file data:", error);
      }
    };
    fetchJobData();
  }, []);

  const handleFileUpload = (file, key) => {
    setformData((prevFormData) => ({
      ...prevFormData,
      [key]: file,
    }));
  };

  const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileDelete = (formDataKey) => {
    setformData((prevFormData) => {
      const newFormData = { ...prevFormData };
      delete newFormData[formDataKey];
      return newFormData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const uploadFormData = new FormData();
      for (const key in formdata) {
        uploadFormData.append(key, formdata[key]);
      }
      const response = await axios.patch(
        `${baseURL}/job/update/${jobId}`,
        uploadFormData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Files Uploaded Successfully");
        navigate("/dashboard");
      } else {
        console.log("Error: " + (response.data || response.statusText));
      }
    } catch (error) {
      console.error("Error in update request:", error);
      console.log("Error status:", error.response?.status);
      alert(error.response?.data?.message);
    }
  };
  const fileInputRef = useRef(null);

  const handleFileInputChange = (formDataKey, index) => (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFileUpload(file, formDataKey);
    }
  };

  const triggerFileInput = (index) => {
    const fileInput = document.getElementById(`fileInput-${index}`);
    fileInput && fileInput.click();
  };

  const getReadableName = (formDataKey, fileMapping) => {
    const invertedMapping = Object.entries(fileMapping).reduce(
      (acc, [key, value]) => {
        acc[value] = key;
        return acc;
      },
      {}
    );
    return invertedMapping[formDataKey] || "Unknown";
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div>
          <Aside />
        </div>
        <div className="row">
          <div style={{ display: "flex" }}>
            <div className="onsite-jobpart">
              <div className="onsite-blue">Upload Files</div>
              <span className="text-onsite">Dashboard / Upload Files</span>
            </div>
            <div className="onsite-part">
              <div className="onsitedata">
                <div className="onsitebell-icon">
                  <img
                    src={`${image}/Assets/UploadFiles/onsitedate-bell.svg`}
                    alt=""
                  />
                </div>
              </div>
              <div className="onsitedata-text">
                <div className="onsite-round">10</div>
              </div>
              <div className="onsite-right">
                <div className="onsite-photo">
                  <img
                    src={`${image}/Assets/UploadFiles/onsite-person.svg`}
                    alt=""
                    onClick={() => {
                      navigate("/profile-retailer");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="panel-photo">
              <span className="panel-ser">Upload Files</span>
            </div>
          </div>

          <div style={{ padding: "20px" }}>
            {Object.entries(fileMapping).map(
              ([itemName, formDataKey], index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "5px",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "345px",
                      height: "52px",
                      backgroundColor: "#E0EDFF",
                      borderRadius: "5px",
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id={`fileInput-${index}`}
                      accept="image/png, image/jpeg, application/pdf"
                      name="uploadFile"
                      onChange={handleFileInputChange(formDataKey, index)}
                    />
                    <button
                      style={{
                        backgroundColor: "#E0EDFF",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => triggerFileInput(index)}
                    >
                      <img
                        src={`${image}/Assets/UploadFiles/doc-upload.svg`}
                        alt="Upload"
                        style={{ position: "relative", left: "300px" }}
                      />
                    </button>
                    <span style={{ position: "relative", right: "15px" }}>
                      {formdata[formDataKey]
                        ? getReadableName(formDataKey, fileMapping)
                        : "No file selected"}
                    </span>
                  </div>

                  {formdata[formDataKey] && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "300px",
                        height: "52px",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          padding: "0 15px",
                        }}
                      >
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() =>
                            handleDownload(
                              formdata[formDataKey],
                              formdata[formDataKey].name
                            )
                          }
                        >
                          <img
                            src={`${image}/Assets/ManageFolder/download-btn.svg`}
                            alt="Download"
                          />
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => handleFileDelete(formDataKey)}
                        >
                          <img
                            src={`${image}/Assets/UploadFiles/subtract.svg`}
                            alt="Delete"
                          />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
          </div>

          <div className="form-actions">
            <button
              type="button"
              onClick={() => {
                navigate("/dashboard");
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button onClick={handleSubmit} className="save-btn">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFiles;
