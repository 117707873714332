import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Aside.css";
const image = process.env.PUBLIC_URL;

const Aside = () => {
  const userType = localStorage.getItem("userType");
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const isHomeActive = location.pathname === "/home";
  const isJobsActive =
    location.pathname === "/dashboard" ||
    location.pathname === "/dashboard-SWH" ||
    location.pathname === "/createSHWJob" ||
    location.pathname === "/dashboard/onSite-data" ||
    location.pathname === "/dash/stc-details" ||
    location.pathname === "/dashboard/manage-folder" ||
    location.pathname === "/dashboard/document-required" ||
    location.pathname === "/dashboard/upload-files" ||
    location.pathname === "/dashboard/important-actions" ||
    location.pathname === "/dashboard/site-inspection" ||
    location.pathname === "/dashboard/view-site-inspection" ||
    location.pathname === "/dashboard/written-statement" ||
    location.pathname === "/dashboard/unsigned-stc" ||
    location.pathname === "/createPVJob";
  const isTeamsActive =
    location.pathname === "/teams" || location.pathname === "/retailers";
  const isLivePriceActive = location.pathname === "/live-price";
  const isApprovedCERActive = location.pathname === "/approved-cer";
  const isPaymentsActive = location.pathname === "/payments";
  const isConfigurationActive = location.pathname === "/config";
  const isHelpActive = location.pathname === "/help";

  return (
    <>
      <div
        className={`left-panel ${isMobile ? "mobile" : ""} ${
          isMenuOpen ? "open" : ""
        }`}
      >
        <div className="dashboard-logo">
          <img
            src={`${image}/Assets/LogIn/dashboard-uveaa-logo.svg`}
            alt=""
            className="Logo-image-sideMenu"
          />
        </div>
        <div className="sideBarMenu">
          {isMobile && (
            <div className="burger-menu" onClick={toggleMenu}>
              <div className={`burger-icon ${isMenuOpen ? "open" : ""}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          )}
          <button
            className={`left-part ${isHomeActive ? "active" : ""}`}
            style={{ border: "none" }}
            onClick={() => {
              navigate("/home");
            }}
          >
            <div className="left-img">
              <img
                src={`${image}/Assets/LogIn/jobs.svg`}
                alt=""
                className={`default-image ${isHomeActive ? "hide" : ""}`}
              />
              <img
                src={`${image}/Assets/DashboardPV/jobs-white.svg`}
                alt=""
                className={`selected-image ${!isHomeActive ? "hide" : ""}`}
              />
            </div>
            <div className="left-text">Dashboard</div>
          </button>
          {userType === "Trader" ? (
            <>
              <button
                className={`left-part ${isTeamsActive ? "active" : ""}`}
                style={{ border: "none" }}
                onClick={() => {
                  navigate("/retailers");
                }}
              >
                <div className="left-img">
                  <img
                    src={`${image}/Assets/DashboardPV/teams.svg`}
                    alt=""
                    className={`default-image ${isTeamsActive ? "hide" : ""}`}
                  />
                  <img
                    src={`${image}/Assets/DashboardPV/teams-white.svg`}
                    alt=""
                    className={`selected-image ${isTeamsActive ? "" : "hide"}`}
                  />
                </div>
                <div className="left-text">
                  {userType === "Trader" ? "Retailers" : "Teams"}
                </div>
              </button>
              <button
                className={`left-part ${isJobsActive ? "active" : ""}`}
                style={{ border: "none" }}
                onClick={() => {
                  location.pathname === "/createSHWJob"
                    ? navigate("/dashboard-SWH")
                    : navigate("/dashboard");
                }}
              >
                <div className="left-img">
                  <img
                    src={`${image}/Assets/LogIn/jobs.svg`}
                    alt=""
                    className={`default-image ${isJobsActive ? "hide" : ""}`}
                  />
                  <img
                    src={`${image}/Assets/DashboardPV/jobs-white.svg`}
                    alt=""
                    className={`selected-image ${!isJobsActive ? "hide" : ""}`}
                  />
                </div>
                <div className="left-text">Jobs</div>
              </button>
            </>
          ) : (
            <>
              <button
                className={`left-part ${isJobsActive ? "active" : ""}`}
                style={{ border: "none" }}
                onClick={() => {
                  location.pathname === "/createSHWJob"
                    ? navigate("/dashboard-SWH")
                    : navigate("/dashboard");
                }}
              >
                <div className="left-img">
                  <img
                    src={`${image}/Assets/LogIn/jobs.svg`}
                    alt=""
                    className={`default-image ${isJobsActive ? "hide" : ""}`}
                  />
                  <img
                    src={`${image}/Assets/DashboardPV/jobs-white.svg`}
                    alt=""
                    className={`selected-image ${!isJobsActive ? "hide" : ""}`}
                  />
                </div>
                <div className="left-text">Jobs</div>
              </button>

              <button
                className={`left-part ${isTeamsActive ? "active" : ""}`}
                style={{ border: "none" }}
                onClick={() => {
                  navigate("/teams");
                }}
              >
                <div className="left-img">
                  <img
                    src={`${image}/Assets/DashboardPV/teams.svg`}
                    alt=""
                    className={`default-image ${isTeamsActive ? "hide" : ""}`}
                  />
                  <img
                    src={`${image}/Assets/DashboardPV/teams-white.svg`}
                    alt=""
                    className={`selected-image ${isTeamsActive ? "" : "hide"}`}
                  />
                </div>
                <div className="left-text">Teams</div>
              </button>
            </>
          )}

      

          {userType === "Trader" ? (
            <>
                <button
                className={`left-part ${isPaymentsActive ? "active" : ""}`}
                style={{ border: "none" }}
                onClick={() => {
                  navigate("/payments&invoice");
                }}
                disabled
              >
                <div className="left-img">
                  <img
                    src={`${image}/Assets/DashboardPV/payment.svg`}
                    alt=""
                    className={`default-image ${isPaymentsActive ? "hide" : ""}`}
                  />
                  <img
                    src={`${image}/Assets/DashboardPV/payment-white.svg`}
                    alt=""
                    className={`selected-image ${isPaymentsActive ? "" : "hide"}`}
                  />
                </div>
                <div className="left-text">Payments & Invoice</div>
              </button>
            <button
              className={`left-part ${isLivePriceActive ? "active" : ""}`}
              style={{ border: "none" }}
              onClick={() => {
                navigate("/live-price");
              }}
            >
              <div className="left-img">
                <img
                  src={`${image}/Assets/DashboardPV/manage-price.svg`}
                  alt=""
                  className={`default-image ${isLivePriceActive ? "hide" : ""}`}
                />
                <img
                  src={`${image}/Assets/DashboardPV/hover-manage-price.svg`}
                  alt=""
                  className={`selected-image ${
                    isLivePriceActive ? "" : "hide"
                  }`}
                />
              </div>
              <div className="left-text">Manage Live Price</div>
            </button>
            </>
          ) : null}

          {userType === "Trader" ? (
            <button
              className={`left-part ${isApprovedCERActive ? "active" : ""}`}
              style={{ border: "none" }}
              onClick={() => {
                navigate("/approved-cer");
              }}
            >
              <div className="left-img">
                <img
                  src={`${image}/Assets/DashboardPV/manage-cer.svg`}
                  alt=""
                  className={`default-image ${
                    isApprovedCERActive ? "hide" : ""
                  }`}
                />
                <img
                  src={`${image}/Assets/DashboardPV/hover-cer.svg`}
                  alt=""
                  className={`selected-image ${
                    isApprovedCERActive ? "" : "hide"
                  }`}
                />
              </div>
              <div className="left-text">Manage Approved CER</div>
            </button>
          ) : null}

          <button
            className={`left-part ${isConfigurationActive ? "active" : ""}`}
            style={{ border: "none" }}
            onClick={() => {
              navigate("/config");
            }}
            disabled
          >
            <div className="left-img">
              <img
                src={`${image}/Assets/DashboardPV/configuration.svg`}
                alt=""
                className={`default-image ${
                  isConfigurationActive ? "hide" : ""
                }`}
              />
              <img
                src={`${image}/Assets/DashboardPV/configuration-white.svg`}
                alt=""
                className={`selected-image ${
                  isConfigurationActive ? "" : "hide"
                }`}
              />
            </div>
            <div className="left-text">Configuration</div>
          </button>
          <button
            className={`left-part ${isHelpActive ? "active" : ""}`}
            style={{ border: "none" }}
            onClick={() => {
              navigate("/help");
            }}
          >
            <div className="left-img">
              <img
                src={`${image}/Assets/DashboardPV/configuration.svg`}
                alt=""
                className={`default-image ${isHelpActive ? "hide" : ""}`}
              />
              <img
                src={`${image}/Assets/DashboardPV/configuration-white.svg`}
                alt=""
                className={`selected-image ${isHelpActive ? "" : "hide"}`}
              />
            </div>
            <div className="left-text">Help</div>
          </button>
          <button
            className={`left-part`}
            style={{ border: "none" }}
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
          >
            <div className="left-img">
              <img
                src={`${image}/Assets/DashboardPV/logout.svg`}
                alt=""
                className={`default-image`}
              />
              <img
                src={`${image}/Assets/DashboardPV/hover-logout-white.svg`}
                alt=""
                className={`selected-image`}
              />
            </div>
            <div className="left-text">Log Out</div>
          </button>
        </div>
      </div>
    </>
  );
};

export default Aside;
