import React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography, Grid } from '@mui/material';
// import{img1} from '../../../../public/Assets/img1.jpeg';
// import {img2} from '../../../../public/Assets/img2.jpeg';
// import {img3} from '../../../../public/Assets/img3.jpeg';
// Generate Project Data
const image = process.env.PUBLIC_URL;
const createData = (id, address, suburb, imgSrc) => ({
  id,
  address,
  suburb,
  imgSrc,
});

const projects = [
  createData(0, '4 Cowper Pl', 'Coopers Plains 4108',`${image}/Assets/img1.jpeg`),
  createData(1, '3 macknish cr', 'coopers plains 4108', `${image}/Assets/img2.jpeg`),
  createData(2, '14 Mullins Street', 'Coopers Plains 4108', `${image}/Assets/img3.jpeg`),
  createData(3, '46 Jane St', 'West End 4101', `${image}/Assets/img3.jpeg`),
];

export default function Proposals() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>Recent Projects</Typography>
      <Grid container spacing={4}>
        {projects.map((project) => (
          <Grid item key={project.id} xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={project.imgSrc}
                  alt={`Project at ${project.address}`}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {project.address}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {project.suburb}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
