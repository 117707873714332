import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const JobPieChart = ({ data }) => {
  const totalJobs = data.totalJobs;
  const statusData = Object.keys(data.statusCounts).map((status) => {
    const count = data.statusCounts[status];
    return {
      name: status,
      value: count,
      percentage: totalJobs > 0 ? Math.round((count / totalJobs) * 100) : 0,
    };
  });
  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];
  const CustomTooltip = ({ active, payload, label }) => {
    console.log(payload);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="dataTooltip">
            {`${payload[0]?.name} : ${payload[0].payload?.percentage}%`}
          </p>
        </div>
      );
    }
  
    return null;
  };

  return (
    <PieChart width={600} height={400} style={{ marginTop: "13%" }}>
      <Pie
        data={statusData}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={80}
        // fill="#8884d8"
        label={({value, name, percentage }) => value>0?`${name} (${percentage}%)`:null}
      >
        {statusData.map((entry, index) => (
          entry?.value>0 ?(
          <Cell key={`cell-${index}`} fill={ entry?.value >0 ?colors[index % colors.length]:null} />
          ):null
        ))}
      </Pie>
      <Tooltip content={CustomTooltip}/>
      <Legend />
    </PieChart>
  );
};

export default JobPieChart;
