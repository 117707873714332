import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const RetailerProfileView = () => {
  const navigate = useNavigate();

  const [newUser, setNewUser] = useState({
    basicInformation: {
      firstName: "",
      lastName: "",
      emailId: "",
      role: "",
    },
    companyInformation: {
      ABN: null,
      tradingName: "",
      mobile: null,
      address: {
        unitNo: null,
        streetNo: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postcode: null,
      },
    },
    bankInformation: {
      bankName: "",
      accName: "",
      bsbNo: null,
      accNo: null,
    },
    userName: "",
    password: "",
    userType: "Retailer",
  });

  const token = localStorage.getItem("userToken");

  useEffect(() => {
    const test = async () => {
      try {
        const resp = await axios.get(`${baseURL}/users/getDetails`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp.data;
        if (data) {
          setNewUser(data);
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    };
    test();
  }, []);

  return (
    <>
      {newUser.basicInformation.emailId ? (
        <div className="Signup-main-container">
          <div className="bg-overlay"></div>
          <div className="logo"></div>
          <br />
          <form className="Signup-form-container">
            <div className="Signup-heading-box">
              <p className="main-profile-retailer">Retailer Profile</p>
            </div>
            <div className="Signup-conatiner-twoBox">
              <div className="Signup-basicDetails-main-container">
                <p>Contact Details</p>
                <div>
                  <button
                    className="edit-retailer-profile"
                    style={{
                      position: "absolute",
                      right: "40px",
                      bottom: "80px",
                    }}
                    onClick={() => {
                      navigate("/edit-retailer");
                    }}
                  >
                    Edit Profile
                  </button>
                </div>
                <div className="Signup-basicDetails-boxes">
                  <input
                    type="text"
                    placeholder="First Name"
                    value={newUser.basicInformation.firstName}
                    name="firstName"
                    readOnly
                  />
                  <input
                    type="text"
                    placeholder="Surname"
                    value={newUser.basicInformation.lastName}
                    name="lastName"
                    readOnly
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    value={newUser.basicInformation.emailId}
                    name="emailId"
                    readOnly
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    value={newUser.basicInformation.role}
                    name="role"
                    readOnly
                  />
                </div>
              </div>

              <div className="Signup-basicDetails-main-container">
                <p>Company Details</p>
                <div className="Signup-basicDetails-boxes input-box-main">
                  <div className="company-details">
                    <input
                      type="number"
                      placeholder="Australian Business Number"
                      value={newUser.companyInformation.ABN}
                      name="ABN"
                      readOnly
                    />

                    <input
                      type="text"
                      placeholder="Company Name"
                      value={newUser.companyInformation.tradingName}
                      name="tradingName"
                      readOnly
                    />

                    <input
                      type="number"
                      placeholder="Phone No"
                      value={newUser.companyInformation.mobile}
                      name="mobile"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="Signup-Adress-container">
                <div className="Signup-address-boxes-container">
                  <div className="Signup-Adress-boxes">
                    <input
                      className="Signup-Address-Number-boxes"
                      placeholder="Unit No."
                      value={newUser.companyInformation.address.unitNo}
                      name="unitNo"
                      readOnly
                    />
                  </div>

                  <div className="Signup-Adress-boxes">
                    <input
                      className="Signup-Address-Number-boxes"
                      placeholder="Street No."
                      value={newUser.companyInformation.address.streetNo}
                      name="streetNo"
                      readOnly
                    />
                  </div>

                  <div className="street-boxes">
                    <input
                      className="Signup-Address-String-boxes"
                      type="text"
                      value={newUser.companyInformation.address.streetName}
                      name="streetName"
                      placeholder="Street Name"
                      readOnly
                    />
                  </div>
                  <div className="street-main-boxes">
                    <input
                      className="Signup-Address-String-boxes"
                      type="text"
                      value={newUser.companyInformation.address.streetType}
                      name="streetType"
                      placeholder="Street Name"
                      readOnly
                    />
                  </div>

                  <div className="suburb-boxes">
                    <input
                      className="suburb-area"
                      type="text"
                      value={newUser.companyInformation.address.suburb}
                      name="suburb"
                      placeholder="Suburb"
                      readOnly
                    />
                  </div>
                  <div className="state-boxes">
                    <input
                      className="suburb-area"
                      type="text"
                      value={newUser.companyInformation.address.state}
                      name="state"
                      placeholder="State"
                      readOnly
                    />
                  </div>
                  <div className="postcode-main">
                    <input
                      className="postcode-area"
                      type="number"
                      placeholder="Postcode"
                      value={newUser.companyInformation.address.postcode}
                      name="postcode"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <br />
              <div className="Signup-basicDetails-main-container">
                <p>Bank Details</p>
                <div className="Signup-basicDetails-boxes">
                  <input
                    type="text"
                    placeholder="Bank Name"
                    value={newUser.bankInformation.bankName}
                    name="bankName"
                    readOnly
                  />
                  <input
                    type="text"
                    placeholder="Account Name"
                    value={newUser.bankInformation.accName}
                    name="accName"
                    readOnly
                  />
                  <input
                    type="number"
                    placeholder="BSB No"
                    value={newUser.bankInformation.bsbNo}
                    name="bsbNo"
                    readOnly
                  />
                  <form action="#">
                    <input
                      type="number"
                      placeholder="Account No."
                      value={newUser.bankInformation.accNo}
                      name="accNo"
                      readOnly
                    />
                  </form>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </>
  );
};

export default RetailerProfileView;
