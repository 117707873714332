import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const InviteModel = ({ onClose }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    installer: {
      name: "",
      firstName: "",
      lastName: "",
      fullAddress: {
        addressType: "Physical",
        unitType: "",
        unitNumber: null,
        streetNumber: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postCode: null,
      },
      emailId: "",
      mobileNo: "",

      selectYourRole: [],

      permission: [],

      stateOfServices: [],

      password: "Installer@123",
    },
    installerCECID: "",
    installerlicenceExpiry: "",
    installerCECLicensePhoto: "",
    electricianLicenseNo: "",
    electricianlicenceExpiry: "",
    electricianLicensePhoto: "",
    designerCECID: "",
    designerlicenceExpiry: "",
    designerCECLicensePhoto: "",
    gasfitterCECID: "",
    gasfitterLicensePhoto: "",
    gasfitterLicenseExpiry: "",
    plumberCECID: "",
    plumberCECLicensePhoto: "",
    plumberLicenseExpiry: "",
    profilePic: "",
  });

  //Set Data Functions Starts

  const handleInstallerInput = (e) => {
    const { name, value } = e.target;
    setFormData((p) => ({
      ...p,
      installer: {
        ...p.installer,
        [name]: value,
      },
    }));
  };

  const handleCheckboxChange = (role) => {
    setFormData((prevInstaller) => {
      const { selectYourRole } = prevInstaller.installer;
      let updatedRoles = [];

      if (role === "All") {
        updatedRoles =
          selectYourRole.length === 5
            ? []
            : ["Installer", "Electrician", "Designer", "Plumber", "Gasfitter"];
      } else {
        updatedRoles = selectYourRole.includes(role)
          ? selectYourRole.filter((r) => r !== role)
          : [...selectYourRole, role];
      }

      return {
        ...prevInstaller,
        installer: {
          ...prevInstaller.installer,
          selectYourRole: updatedRoles,
        },
      };
    });
  };

  //Set Data Functions Ends

  //API Call to submit FormData Starts
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${baseURL}/installer/invite`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert("Invite Mail Sent Successsfully");
        setTimeout(() => {
          window.location.reload();
        }, 800);
        navigate("/teams");
      } else {
        alert("Error Sending Email");
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };
  //API Call to submit FormData Ends

  return (
    <div className="invite-model-backdrop">
      <div className="invite-model-content">
        <div className="invite-model-header">
          <h3>Invite</h3>
          <button className="invite-model-close-btn" onClick={onClose}>
            ✕
          </button>
        </div>
        <hr className="popupbottom-border" />
        <form className="invite-model-form" onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <input
              type="text"
              className="input-field popup-email"
              value={formData.installer.name || ""}
              onChange={handleInstallerInput}
              placeholder="Name"
              name="name"
            />
            <input
              type="text"
              className="input-field popup-email"
              value={formData.installer.emailId || ""}
              onChange={handleInstallerInput}
              placeholder="Email"
              name="emailId"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            />
            <input
              type="text"
              className="popup-phone"
              value={formData.installer.mobileNo || ""}
              onChange={handleInstallerInput}
              placeholder="Phone"
              name="mobileNo"
              onKeyDown={(e) => {
                if (e.target.value.length > 14) {
                  window.alert("Mobile number must be of 14 digits.");
                  e.target.value = " ";
                }
              }}
            />
          </div>
          <div>
            <div className="row">
              <div className="popup-checkbox">
                <input
                  type="checkbox"
                  className="popup-checkbox"
                  name="All"
                  checked={formData.installer.selectYourRole.length === 5}
                  onChange={() => handleCheckboxChange("All")}
                />
                <span className="check-text">All</span>
                <input
                  type="checkbox"
                  className="popup-checkbox"
                  name="Installer"
                  checked={formData.installer.selectYourRole.includes(
                    "Installer"
                  )}
                  onChange={() => handleCheckboxChange("Installer")}
                />
                <span className="check-text">Installer</span>
                <input
                  type="checkbox"
                  className="popup-checkbox"
                  name="Electrician"
                  checked={formData.installer.selectYourRole.includes(
                    "Electrician"
                  )}
                  onChange={() => handleCheckboxChange("Electrician")}
                />
                <span className="check-text">Electrician</span>
                <input
                  type="checkbox"
                  className="popup-checkbox"
                  name="Designer"
                  checked={formData.installer.selectYourRole.includes(
                    "Designer"
                  )}
                  onChange={() => handleCheckboxChange("Designer")}
                />
                <span className="check-text">Designer</span>
                <input
                  type="checkbox"
                  className="popup-checkbox"
                  name="Plumber"
                  checked={formData.installer.selectYourRole.includes(
                    "Plumber"
                  )}
                  onChange={() => handleCheckboxChange("Plumber")}
                />
                <span className="check-text">Plumber</span>
                <input
                  type="checkbox"
                  className="popup-checkbox"
                  name="gastFitter"
                  checked={formData.installer.selectYourRole.includes(
                    "Gasfitter"
                  )}
                  onChange={() => handleCheckboxChange("Gasfitter")}
                />
                <span className="check-text">GasFitter</span>
              </div>
            </div>
            <span className="popupbottom-border"></span>
            <div className="row">
              <div style={{ display: "flex" }}>
                <div className="footer-popupbtn">
                  <button
                    type="button"
                    onClick={onClose}
                    className="btn-cancel"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="button-invite">
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InviteModel;
