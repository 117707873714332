import React, { useState } from "react";
import axios from "axios";
import { Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const NotesComponent = ({ data,test }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [dashboardNotes, setDashboardNotes] = useState(
    data?.dashboardNotes || ""
  );

  const handleEditClick = () => {
    setIsEditing(true);
  };
const [saveEdit,setSaveEdit]=useState(false)
  const handleSaveClick = async () => {
    const token = localStorage.getItem("userToken");

    const formData = new FormData();
    formData.append("dashboardNotes", dashboardNotes);
    setSaveEdit(true)
    try {
      const response = await axios.patch(
        `${baseURL}/job/update/${data._id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Notes Updated Successfully");
        test()
      }
      setSaveEdit(false)
    } catch (error) {
      console.error("Error saving notes:", error);
      setSaveEdit(false)
    }
  };

  return (
    <li className="system-img">
      <span className="text-notes">
        <form>
          <textarea
            className="address-type"
            type="text"
            name="dashboardNotes"
            style={{
              width: "200px",
              height: "60px",
              border: "none",
            }}
            placeholder="Notes"
            value={dashboardNotes}
            readOnly={!isEditing}
            onChange={(e) => setDashboardNotes(e.target.value)}
          />
        </form>
        {!isEditing ? (
          <Tooltip title="Edit Notes">
            <Edit onClick={handleEditClick} style={{ cursor: "pointer" }} />
          </Tooltip>
        ) : (
          <Tooltip>
            <button
              onClick={handleSaveClick}
              style={{
                border: "none",
                background: "transparent",
                color: "rgb(21 67 176)",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
             {saveEdit? 'Saving...' :'Save'} 
            </button>
          </Tooltip>
        )}
      </span>
    </li>
  );
};

export default NotesComponent;
